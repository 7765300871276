import { Observable, pipe, of, from } from 'rxjs';
import { switchMap, withLatestFrom, map, catchError, mergeMap, filter } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';

import Axios, { AxiosPromise, AxiosResponse } from 'axios';

import { hopplerApi } from '../../../../constants/api';
import * as ResultsActions from './results.actions'
import { ResultsAction } from './results.actions';
import * as LandingActions from '../landing/landing.actions'

import getStore, { appState$ } from '../../store';
import { AppState } from '../app-state';
import { NeighborhoodUtils } from '../../../../core/neighborhood-utils';
import { Listing, Neighborhood, YelpResponse, PropertyMapMarker } from '../../../../types';

let resultsEffects: Epic[] = [];

const test: Epic = test$ => test$.pipe(
  ofType(LandingActions.LANDING_TEST),
  switchMap(value => {

      const action = {
        type: LandingActions.LANDING_TEST3,
        payload: {
          message: 'Results effect received this and emitted Landing_test3',
          count: 420
        } as LandingActions.LandingState
      }

      return of(action);
    }
  )
);

const getNeighborhoodPins: Epic = getNeighborhoodPins$ => getNeighborhoodPins$.pipe(
  ofType<ResultsAction>(ResultsActions.GET_NEIGHBORHOOD_PINS),
  map(action => action.payload),
  withLatestFrom(appState$),
  switchMap(([payload, appState]) => {
    const results$ = appState.results;
    const neighborhoodUtils: NeighborhoodUtils = new NeighborhoodUtils();
    
    const yelpCategories = neighborhoodUtils.getYelpCategories(results$.neighborhoodPinFilterType)

    const promise = Axios.get<YelpResponse>(
      `${hopplerApi.host}/api/yelp/businesses/search`,
      {
        withCredentials: true,
        params: {
          latitude: results$.neighborhoodData.latitude,
          longitude: results$.neighborhoodData.longitude,
          radius: hopplerApi.neighborhoodRadius,
          limit: 50,
          categories: yelpCategories
        }
      }
    )

    return from(promise).pipe(
      mergeMap((response) => {
        
        const places = neighborhoodUtils.prepareNeighborhoodPlaceCategories(
          response.data.businesses,
          results$.neighborhoodPinFilterType
        );

        const action: ResultsAction = {
          type: ResultsActions.GET_NEIGHBORHOOD_PINS_SUCCESS,
          payload: {
            neighborhoodData: {
              places: places,
              latitude: results$.neighborhoodData.latitude,
              longitude: results$.neighborhoodData.longitude,
            }
          }
        }

        return of(action);
      }),
      catchError( errResp => {
        console.log('errNeighborhood', errResp);

        const action: ResultsAction = {
          type: ResultsActions.GET_NEIGHBORHOOD_PINS_FAIL
        }

        return of(action);
      })
    );
  })
);

const previewListingNeighborhood: Epic = previewPropertyNeighborhood$ => previewPropertyNeighborhood$.pipe(
  ofType<ResultsAction>(ResultsActions.PREVIEW_PROPERTY),
  withLatestFrom(appState$),
  switchMap(([action, store]) => {

    const previewedListing: Listing = action.payload.previewedListing;
    const neighborhood: Neighborhood = {
      latitude: previewedListing.latitude,
      longitude: previewedListing.longitude
    };

    const effectAction: ResultsAction = {
      type: ResultsActions.GET_NEIGHBORHOOD_PINS,
      payload: {
        neighborhood: neighborhood,
        previewedListing: previewedListing
      } as ResultsActions.ResultsState
    }

    return of(effectAction);
  })
);

const updateNeighborhoodFilters: Epic = updateNeighborhoodFilters$ => updateNeighborhoodFilters$.pipe(
  ofType<ResultsAction>(ResultsActions.UPDATE_NEIGHBORHOOD_FILTERS),
  switchMap(action => {
    const neighborhoodUtils: NeighborhoodUtils = new NeighborhoodUtils();
    const filters = neighborhoodUtils.updateNeighborhoodFilters(
      action.payload.neighborhoodPinFilterType
    )
    
    const effectAction: ResultsAction = {
      type: ResultsActions.GET_NEIGHBORHOOD_PINS,
      payload: {
        neighborhoodPinFilters: filters
      }
    }

    return of(effectAction);
  })
);

const mapMarkerClicked: Epic = mapMarkerClicked$ => mapMarkerClicked$.pipe(
  ofType<ResultsAction>(ResultsActions.MAP_MARKER_CLICKED),
  switchMap(action => {
    
    const selectedMarker: PropertyMapMarker = action.payload.selectedPropertyMarker;
    const previewedListing: Listing = selectedMarker.properties[0];

    const effectAction: ResultsAction = {
      type: ResultsActions.PREVIEW_PROPERTY,
      payload: {
        previewedListing: previewedListing
      } as ResultsActions.ResultsState
    }

    return of(effectAction);
  })
);

// ! Make sure to add created epics to array 

resultsEffects.push(test);
resultsEffects.push(getNeighborhoodPins);
resultsEffects.push(previewListingNeighborhood);
resultsEffects.push(updateNeighborhoodFilters);
resultsEffects.push(mapMarkerClicked);

export default resultsEffects;