import * as Actions from './landing.actions';
import { LandingState, LandingAction } from './landing.actions';

const initialState: LandingState = {

}

export default function landing(state: LandingState = initialState, action: LandingAction) {
  switch(action.type) {
    case Actions.GET_MAINTENANCE_SCHED:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_MAINTENANCE_SCHED_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_MAINTENANCE_SCHED_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    default:
      return state;
  }
}