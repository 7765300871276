import { PlatformUtility } from '../utils/platform-utility';

/*
 * Facebook Customer Chat loading system implementation
 */

let initiatedCustomerChatLoad = false;
declare var window: Window;

function getCustomerChatPlaceholder() {
  return document.getElementsByClassName('hi-fbcc-loader')[0];
}

function waitForCustomerChat() {
  const FB = window['FB'];
  
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (FB && FB.CustomerChat) {
        clearInterval(interval);
        resolve(FB);
      }
    }, 200);
  });
}

function showCustomerChatDialog() {
  const FB = window['FB'];

  return new Promise((resolve, reject) => {
    let resolved = false;
    const interval = setInterval(() => {
      FB.CustomerChat.show(true);
    }, 500);

    FB.Event.subscribe('customerchat.dialogShow', () => {
      console.log('customerchat:', resolve)
      if (!resolved) {
        resolved = true;
        clearInterval(interval);
        console.log('clearing interval')
        getCustomerChatPlaceholder().remove();
        resolve(null);
      }
    });
  });
}

/**
 * Loads the Facebook Customer Chat SDK
 */
export async function hiLoadCustomerChat() {
  console.log('hi-load-customer-chat:', { initiatedCustomerChatLoad });

  if (initiatedCustomerChatLoad || window['FB']) {
    if (window.location.pathname == '/') {
      await showCustomerChatDialog()
    }
    return;
  };
  initiatedCustomerChatLoad = true;

  const elem = document.createElement('script');
  elem.innerHTML = `
    window.fbAsyncInit = function() {
      const FB = window['FB'];

      FB.init({
        appId            : '566679137040844',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v2.12'
      });
      
      FB.Event.subscribe('customerchat.load', () => console.log('customerchat.load'));
      FB.Event.subscribe('customerchat.show', () => console.log('customerchat.show'));
      FB.Event.subscribe('customerchat.hide', () => console.log('customerchat.hide'));
      FB.Event.subscribe('customerchat.dialogShow', () => console.log('customerchat.dialogShow'));
      FB.Event.subscribe('customerchat.dialogHide', () => console.log('customerchat.dialogHide'));

      FB.Event.subscribe('xfbml.render', () => {
        console.log('FINISHED RENDERING PLUGIN');
        if (window.location.pathname == '/') {
          setTimeout(() => {
            FB.CustomerChat.showDialog()
          }, 500)
        }
      });
    };
    
    (function(d, s, id){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  `;

  const firstScript = document.getElementsByTagName('script')[0];
  firstScript.parentNode.insertBefore(elem, firstScript);
}

if (PlatformUtility.isOnClient()) {
  window['hiLoadCustomerChat'] = hiLoadCustomerChat;

  document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname !== '/') {
      setTimeout(() => {
        console.log('Loading customer chat!');
        hiLoadCustomerChat();
      }, 1000);
    }
  });

}

export function hiHideCustomerChat() {
  const FB = window['FB'];

  console.log('CCL: Hiding Customer Chat');
  if (FB && FB.CustomerChat) {
    FB.CustomerChat.hide();
  } else {
    console.log('Hiding placeholder');
    const placeholder = getCustomerChatPlaceholder();
    if (placeholder) {
      placeholder['hidden'] = 1;
    }
  }
}

export function hiShowCustomerChat() {
  const FB = window['FB'];

  console.log('CCL: Showing Customer Chat');
  if (FB && FB.CustomerChat) {
    FB.CustomerChat.show(false);
  } else {
    console.log('Showing placeholder');
    const placeholder = getCustomerChatPlaceholder();
    if (placeholder) {
      placeholder['hidden'] = 0;
    }
  }
}
