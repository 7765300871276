import { Business, NeighborhoodPinFilter, NeighborhoodFilterTypes } from "../types";
import { yelpCategories } from '../constants/yelp';

export class NeighborhoodUtils {

  determineHopplerCategory(member: Business) {
    let yelpCategory = member.rootCategory;

    switch(yelpCategory) {
      case 'food':
      case 'restaurants':
      case 'cafes':
        member.hopplerCategory = "Dinner";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/resto-001.png';
        return member;

      case 'banks':
        member.hopplerCategory = "Banks";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/bank-001.png';
        return member;

      case 'collegeuniv':
      case 'highschools':
      case 'elementaryschools':
      case 'preschools':
        member.hopplerCategory = "Schools";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/school-001.png';
        return member;

      case 'hospitals':
        member.hopplerCategory = "Hospitals";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/hospital-001.png';
        return member;

      case 'shopping':
        member.hopplerCategory = "Shopping";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/shopping-001.png';
        return member;

      case 'nightlife':
        member.hopplerCategory = "Nightlife";
        member.hopplerImageUrl = 'https://hoppler-inc.imgix.net/hoppler/pages/static/bar-001.png';
        return member;
    }
  }

  determineIfBusinessCategoryIsSameAsFilter(filterType: NeighborhoodFilterTypes, hopplerCategory: string) {
    if (filterType === NeighborhoodFilterTypes.RESTAURANTS && hopplerCategory === 'Dinner') {
      return true;
    }

    if (filterType === NeighborhoodFilterTypes.BANKS && hopplerCategory === 'Banks') {
      return true;
    }

    if (filterType === NeighborhoodFilterTypes.SCHOOLS && hopplerCategory === 'Schools') {
      return true;
    }

    if (filterType === NeighborhoodFilterTypes.HOSPITALS && hopplerCategory === 'Hospitals') {
      return true;
    }

    if (filterType === NeighborhoodFilterTypes.SHOPPING && hopplerCategory === 'Shopping') {
      return true;
    }

    if (filterType === NeighborhoodFilterTypes.NIGHTLIFE && hopplerCategory === 'Nightlife') {
      return true;
    }

    return false;
  }

  prepareNeighborhoodPlaceCategories(businesses: Business[], filterType?: NeighborhoodFilterTypes): Business[] {

    let matched = businesses.filter(member => {
      let matchedCategory = yelpCategories.find(categoryMember => {
        if (categoryMember && member.categories && member.categories.length > 0) {
          return categoryMember.alias === member.categories[0].alias;
        }
      });

      if (matchedCategory) {

        if (!member.alreadyHasRoot) {
          member.rootCategory = matchedCategory.rootParent;

          member = this.determineHopplerCategory(member);

          if (this.determineIfBusinessCategoryIsSameAsFilter(filterType, member.hopplerCategory)) {
            member.visible = true;
            member.alreadyHasRoot = true;
            member.infoWindowOpen = false;

            return member;
          }
        }
      }
    });

    return matched;
  }

  getYelpCategories(filterType: NeighborhoodFilterTypes) {
    switch(filterType) {

      case NeighborhoodFilterTypes.RESTAURANTS:
        return 'food,restaurants,cafes';

      case NeighborhoodFilterTypes.BANKS:
        return 'banks';

      case NeighborhoodFilterTypes.SCHOOLS:
        return 'collegeuniv,highschools,elementaryschools,preschools';

      case NeighborhoodFilterTypes.HOSPITALS:
        return 'hospitals';

      case NeighborhoodFilterTypes.SHOPPING:
        return 'shopping';

      case NeighborhoodFilterTypes.NIGHTLIFE:
        return 'nightlife';
    }
  }

  updateNeighborhoodFilters(filterType: NeighborhoodFilterTypes
  ): NeighborhoodPinFilter {
    let pinFilters: NeighborhoodPinFilter = {
        restaurants: false,
        banks: false,
        schools: false,
        hospitals: false,
        shopping: false,
        nightlife: false,
      };
    
    switch(filterType) {
      case NeighborhoodFilterTypes.RESTAURANTS:
        pinFilters.restaurants = true;
        break;

      case NeighborhoodFilterTypes.BANKS:
        pinFilters.banks = true;
        break;

      case NeighborhoodFilterTypes.HOSPITALS:
        pinFilters.hospitals = true;
        break;

      case NeighborhoodFilterTypes.NIGHTLIFE:
        pinFilters.nightlife = true;
        break;

      case NeighborhoodFilterTypes.SCHOOLS:
        pinFilters.schools = true;
        break;

      case NeighborhoodFilterTypes.SHOPPING:
        pinFilters.nightlife = true;
        break;
    }

    return pinFilters;
  }
}