import Cookies from 'universal-cookie';

import pull from 'lodash.pull';

import { Listing } from '../../types/listing.model';

export class CartCookieUtil {
  private CART_COOKIE = 'hoppingCartProperties';
  private isInBrowser: boolean;
  private cookies = new Cookies();

  constructor() {
    this.isInBrowser = true;
  }

  getPropertyIds(): string[] {
    if (!this.isInBrowser) {
      return [];
    }

    let propertyIds: string[] = this.cookies.get(this.CART_COOKIE) as string[];

    if (!propertyIds) {
      propertyIds = [];
    }

    return propertyIds;
  }

  addProperty(property: Listing): string[] {
    return this.addPropertyId(property.propertyKey);
  }

  addPropertyId(propertyId: string): string[] {
    if (!this.isInBrowser) {
      return [];
    }

    const propertyIds = this.getPropertyIds();
    propertyIds.push(propertyId);
    this.setPropertyIds(propertyIds);

    // console.log(propertyIds);
    return propertyIds;
  }

  removeProperty(property: Listing): string[] {
    return this.removePropertyId(property.propertyKey);
  }

  removePropertyId(propertyId: string): string[] {
    if (!this.isInBrowser) {
      return [];
    }

    const propertyIds = this.getPropertyIds();
    pull(propertyIds, propertyId);
    this.setPropertyIds(propertyIds);

    // console.log(propertyIds);
    return propertyIds;
  }

  setPropertyIds(propertyIds: string[]) {
    if (!this.isInBrowser) {
      return;
    }

    this.cookies.set(this.CART_COOKIE, propertyIds, {
      path: '/'
    });
  }

  clear() {
    this.setPropertyIds([]);
  }
}
