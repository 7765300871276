import { h, Component } from 'preact';
import { hopplerApi } from '../../../../constants';

interface AdState{
  intervalId: NodeJS.Timeout;
  indexCounters: object
}

const SIMPL_IMAGE = '/hoppler/pages/ads/citibank-simplicity-1080x1080.jpg'
const SIMPL_WIDE_IMAGE = '/hoppler/pages/ads/citibank-simplicity-1200x628.jpg'

const CITI_REWARDS = {
  'url': 'https://ad.doubleclick.net/ddm/clk/494388435;301241303;a',
  'image': '/hoppler/pages/ads/citibank-rewards-1080x1080.jpg',
  'wide-image': '/hoppler/pages/ads/citibank-rewards-1200x628.jpg'
}
const CITIADS = [
  CITI_REWARDS,
  {
    'url': 'https://ad.doubleclick.net/ddm/clk/494388429;301241303;d',
    'image': SIMPL_IMAGE,
    'wide-image': SIMPL_WIDE_IMAGE
  }
]
const CITIADS_LOC1 = [
  CITI_REWARDS,
  {
    'url': 'https://ad.doubleclick.net/ddm/clk/501341163;308516487;b',
    'image': SIMPL_IMAGE,
    'wide-image': SIMPL_WIDE_IMAGE
  }
]
const CITIADS_LOC2 = [
  CITI_REWARDS,
  {
    'url': 'https://ad.doubleclick.net/ddm/clk/501341331;308516487;y',
    'image': SIMPL_IMAGE,
    'wide-image': SIMPL_WIDE_IMAGE
  }
]
const CITIADS_LOC3 = [
  CITI_REWARDS,
  {
    'url': 'https://ad.doubleclick.net/ddm/clk/501039776;308516487;p',
    'image': SIMPL_IMAGE,
    'wide-image': SIMPL_WIDE_IMAGE
  }
]
const CITIADS_LOC4 = [
  CITI_REWARDS,
  {
    'url': 'https://ad.doubleclick.net/ddm/clk/501341340;308516487;y',
    'image': SIMPL_IMAGE,
    'wide-image': SIMPL_WIDE_IMAGE
  }
]

const PPAADS = [
  {
    'url': 'https://www.asiapropertyawards.com/en/',
    'image': '/hoppler/pages/ads/propertyguru-online-web-banner-ppa-2021.png',
    'wide-image': '/hoppler/pages/ads/propertyguru-online-web-banner-ppa-2021.png'
  }
]

const ADSETS = {
  'citibank': CITIADS,
  'citibank_1': CITIADS_LOC1,
  'citibank_2': CITIADS_LOC2,
  'citibank_3': CITIADS_LOC3,
  'citibank_4': CITIADS_LOC4,
  'citi+ppa': CITIADS.concat(PPAADS),
  'citi_1+ppa': CITIADS_LOC1.concat(PPAADS),
  'citi_2+ppa': CITIADS_LOC2.concat(PPAADS),
  'citi_3+ppa': CITIADS_LOC3.concat(PPAADS),
  'citi_4+ppa': CITIADS_LOC4.concat(PPAADS),
}

export class Ad extends Component<any, AdState> {
  componentDidMount() {
    var indexCounters = {}
    jQuery.each(Object.keys(ADSETS), function(index, item) {
      indexCounters[item] = 0
    })
    this.setState({
      intervalId: setInterval(() => this.showNextAd(), 5000),
      indexCounters: indexCounters
    })
  }

  showNextAd() {
    var indexCounters = {}
    jQuery.each(Object.keys(ADSETS), function(index, item) {
      indexCounters[item] = this.state.indexCounters[item] + 1
      if (indexCounters[item] >= ADSETS[item].length) {
        indexCounters[item] = 0
      }
    }.bind(this));

    this.setState({
      indexCounters: indexCounters
    })
  }

  render(props) {
    if (typeof this.state.indexCounters === 'undefined' || typeof this.state.indexCounters === null) {
      return
    }
    var adset = ADSETS[props['adset']]
    var index = this.state.indexCounters[props['adset']]
    var ad = adset[index]
    if (props['wide']) {
      var image = ad['wide-image']
    } else {
      var image = ad['image']
    }
    return (
      <a href={ ad['url'] } target="_blank"><img style="max-width: 100%;" src={ `${hopplerApi.images}${image}?auto=format&w=350` } /></a>
    )
  }
}
