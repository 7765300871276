import { Field, Form } from "./form";
import { Subject } from "rxjs";

/**
 * @param {Field} field   The field in which the customValidator is appended to.
 * @param {Form} form     [optional] The form that contains field. Provided only in case needed.
 */
export type Validator = (field: Field, form?: Form) => boolean;

export const required = 'required';
export const email = 'email';

export const emailRegex: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export function validateForm(form: Form) {
  form.isValid = true; // set form isValid to true first.
  // later we set to false if a single field becomes invalid

  for (let key in form.fields) {
    const field: Field = form.fields[key];
    let invalidCounts: number = 0;

    if (!form.options.pristineUntilEdited) {
      // This is the old definition of "pristine"
      // The new definition, where an input is pristine until its value is
      // changed, is hidden behind a flag to preserve backwards compatibility.
      // -----
      // set pristine to false when a form is validated.
      // Something can't be pristine when sent to inspection.
      // That means it's been "touched"
      if (field.isPristine) {
        field.isPristine = false;
      }
    }

    if (field.validators.includes(required)) {
      if (!field.value) {
        invalidCounts++;
      }
    }

    if (field.validators.includes(email)) {
      if (!emailRegex.test(field.value)) {
        invalidCounts++;
      }
    }

    if (field.customValidators) {
      field.customValidators.forEach(validator => {
        let isValid = true;
        isValid = validator(field, form);

        if (isValid == false) {
          invalidCounts++;
        }

      });
    }

    if (invalidCounts > 0) {
      field.isValid = false;
      form.isValid = false;
    } else {
      field.isValid = true;
    }
  }
}

export function touchEntireForm(subject: Subject<Form>, form: Form) {
  const clone = Form.cloneFrom(form);
  for (let key in clone.fields) {
    const field = clone.fields[key];
    field.isPristine = false;
  }

  clone.isPristine = false;
  subject.next(clone);
}

export function touchField(
  subject: Subject<Form>, 
  form: Form, 
  fieldName: string
) {
  return () => {
    let clone = Form.cloneFrom(form);
    clone.fields[fieldName].isPristine = false;
    clone.isPristine = false;

    subject.next(clone);
  }
}

export function cxValidateField(field: Field): boolean {
  if (field.isPristine) {
    return false;
  }

  return !field.isValid;
}

export function isValueInArray<T>(array: T[], value: T) {
  if (array) {
    return array.includes(value);
  }
}