import { PartnerBrokerDetails, User, RequestState } from '../../../../types';


const PREFIX = '[Signup]';

export const INIT = `${PREFIX} INIT`;
export const BROKER_REGISTER_FULL = `${PREFIX} BROKER_REGISTER_FULL`;
export const BROKER_REGISTER_PARTIAL = `${PREFIX} BROKER_REGISTER_PARTIAL`;
export const BROKER_REGISTER_PARTIAL_SUCCESS = `${PREFIX} BROKER_REGISTER_PARTIAL_SUCCESS`;
export const BROKER_REGISTER_PARTIAL_FAIL = `${PREFIX} BROKER_REGISTER_PARTIAL_FAIL`;
export const BROKER_REGISTER_FULL_SUCCESS = `${PREFIX} BROKER_REGISTER_FULL_SUCCESS`;
export const BROKER_REGISTER_FULL_FAIL = `${PREFIX} BROKER_REGISTER_FULL_FAIL`;

export const OWNER_REGISTER = `${PREFIX} OWNER_REGISTER`;
export const OWNER_REGISTER_SUCCESS = `${PREFIX} OWNER_REGISTER_SUCCESS`;
export const OWNER_REGISTER_FAIL = `${PREFIX} OWNER_REGISTER_FAIL`;

export const RETRIEVE_AREAS = `${PREFIX} RETRIEVE_AREAS`;
export const RETRIEVE_AREAS_SUCCESS = `${PREFIX} RETRIEVE_AREAS_SUCCESS`;
export const RETRIEVE_AREAS_FAILURE = `${PREFIX} RETRIEVE_AREAS_SUCCESS`;

export const RESEND_ACTIVATION_EMAIL = `${PREFIX} RESEND_ACTIVATION_EMAIL`;
export const RESEND_ACTIVATION_SUCCESS = `${PREFIX} RESEND_ACTIVATION_SUCCESS`;
export const RESEND_ACTIVATION_FAIL = `${PREFIX} RESEND_ACTIVATION_FAIL`;

export const CUSTOMER_SIGNUP = `${PREFIX} CUSTOMER_SIGNUP`;
export const NEW_CUSTOMER_SIGNUP = `${PREFIX} NEW_CUSTOMER_SIGNUP`;
export const CUSTOMER_SIGNUP_SUCCESS = `${PREFIX} CUSTOMER_SIGNUP_SUCCESS`;
export const CUSTOMER_SIGNUP_FAILURE = `${PREFIX} CUSTOMER_SIGNUP_FAILURE`;

export const CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL = `${PREFIX} CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL`;
export const CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_SUCCESS = `${PREFIX} CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_SUCCESS`;
export const CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_FAILURE = `${PREFIX} CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_FAILURE`;

export interface SignupState {
  brokerDetails?: PartnerBrokerDetails;
  partialSignupComplete?: boolean;
  brokerSignupComplete?: boolean;
  isLoading?: boolean;
  ownerDetails?: User;
  ownerSignupComplete?: boolean;

  usernameForResend?: string;
  activationResent?: boolean;

  customerSignupStatus?: RequestState;
  customerSignupData?: User;
  resendCustomerEmailStatus?: RequestState;
}

export interface SignupAction {
  type: string;
  payload: SignupState;
}

export const DEFAULT_STATE: SignupState = {
  brokerDetails: null,
  partialSignupComplete: false,
  brokerSignupComplete: false,
  isLoading: false,
  ownerSignupComplete: false,
};
