import { Epic, ofType } from 'redux-observable';
import { SignupAction } from './signup.actions';
import * as SignupActions from './signup.actions';
import { switchMap, catchError, mergeMap } from 'rxjs/operators';
import { of, empty, from } from 'rxjs';
import Axios, { AxiosError } from 'axios';
import { hopplerApi } from '../../../../constants';
import { showToast } from '../../../utils/hoppler-toast.service';

let signupEffects: Epic[] = [];

const ownerSignupAction: Epic = user$ => user$.pipe(
  ofType<SignupAction>(SignupActions.OWNER_REGISTER),
  switchMap(action => {
    console.log(action);

    const promise = Axios.post(`${hopplerApi.host}/api/users`, action.payload.ownerDetails);

    return from(promise).pipe(
      switchMap(response => of({
        type: SignupActions.OWNER_REGISTER_SUCCESS
      })),
      catchError(error => of({ type: SignupActions.OWNER_REGISTER_FAIL }))
    )
  }),
);

const brokerPartialSignupAction: Epic = user$ => user$.pipe(
  ofType<SignupAction>(SignupActions.BROKER_REGISTER_PARTIAL),
  switchMap(action => {
    const path = `${hopplerApi.host}/api/partnerbroker/partial`
    const promise = Axios.post(path, action.payload.brokerDetails);

    console.log('Submitting:', action.payload.brokerDetails);

    return from(promise).pipe(
      mergeMap(response => of({
        type: SignupActions.BROKER_REGISTER_PARTIAL_SUCCESS
      })),
      catchError((error: AxiosError) => {
        const msg: string = error.response.data.msg;

        if (msg) {
          showToast({ message: msg });
        }
        return of({ type: SignupActions.BROKER_REGISTER_PARTIAL_FAIL })
      })
    );
  })
)

const brokerFullSignupAction: Epic = user$ => user$.pipe(
  ofType<SignupAction>(SignupActions.BROKER_REGISTER_FULL),
  switchMap(action => {
    const path = `${hopplerApi.host}/api/partnerbroker/complete`
    const promise = Axios.post(path, action.payload.brokerDetails);

    console.log('Final submit:', action.payload.brokerDetails);

    return from(promise).pipe(
      mergeMap(response => of({
        type: SignupActions.BROKER_REGISTER_FULL_SUCCESS
      })),
      catchError(error => {
        return of({ type: SignupActions.BROKER_REGISTER_FULL_FAIL })
      })
    );
  })
);

const resendActivationAction: Epic = user$ => user$.pipe(
  ofType<SignupAction>(SignupActions.RESEND_ACTIVATION_EMAIL),
  switchMap(action => {
    const path = `${hopplerApi.host}/api/users/resend`;
    const data = { username: action.payload.usernameForResend };

    const promise = Axios.post(path, data);

    return from(promise).pipe(
      switchMap(response => {
        return of({
          type: SignupActions.RESEND_ACTIVATION_SUCCESS
        });
      }),
      catchError(error => {
        return of({
          type: SignupActions.RESEND_ACTIVATION_FAIL
        });
      })
    )
  })
)

const customerSignup: Epic = signup$ => signup$.pipe(
  ofType<SignupAction>(SignupActions.CUSTOMER_SIGNUP),
  switchMap((action) => {
    const path = `${hopplerApi.host}/client/sign-up`;
    const data = action.payload.customerSignupData;

    const promise = Axios.post(path, data);

    return from(promise).pipe(
      mergeMap(response => {
        return of({
          type: SignupActions.CUSTOMER_SIGNUP_SUCCESS
        });
      }),
      catchError(errResponse => {
        if (errResponse.response.data && errResponse.response.data.msg) {
          showToast({ message: errResponse.response.data.msg });
        }

        return of({
          type: SignupActions.CUSTOMER_SIGNUP_FAILURE
        });
      })
    )
  })
);

const newCustomerSignup: Epic = signup$ => signup$.pipe(
  ofType<SignupAction>(SignupActions.NEW_CUSTOMER_SIGNUP),
  switchMap((action) => {
    const path = `${hopplerApi.host}/client/sign-up/buyer`;
    const data = action.payload.customerSignupData;

    const promise = Axios.post(path, data);

    return from(promise).pipe(
      mergeMap(response => {
        return of({
          type: SignupActions.CUSTOMER_SIGNUP_SUCCESS
        });
      }),
      catchError(errResponse => {
        if (errResponse.response.data && errResponse.response.data.msg) {
          showToast({ message: errResponse.response.data.msg });
        }

        return of({
          type: SignupActions.CUSTOMER_SIGNUP_FAILURE
        });
      })
    )
  })
);

const resendCustomerEmail: Epic = signup$ => signup$.pipe(
  ofType<SignupAction>(SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL),
  switchMap((action) => {
    const path = `${hopplerApi.host}/client/sign-up/resend`;
    const data = {
      username: action.payload.usernameForResend
    };

    const promise = Axios.post(path, data);

    return from(promise).pipe(
      mergeMap(response => {
        return of({
          type: SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_SUCCESS
        });
      }),
      catchError(errResponse => {
        if (errResponse.response.data && errResponse.response.data.msg) {
          showToast({ message: errResponse.response.data.msg });
        }

        return of({
          type: SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_FAILURE
        });
      })
    )
  })
);


signupEffects.push(ownerSignupAction);
signupEffects.push(brokerPartialSignupAction);
signupEffects.push(brokerFullSignupAction);
signupEffects.push(resendActivationAction);
signupEffects.push(customerSignup);
signupEffects.push(newCustomerSignup);
signupEffects.push(resendCustomerEmail);

export default signupEffects;
