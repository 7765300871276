import { LocationSearchBox, 
  CardVlButton, VlCountBubble, LoginForm, LoginNavButton } 
  from '../components';

import { ResetPasswordForm } from "../components/login/reset-password.component";
import { ResultFilters } from "../components/commons/result-filters.component";
import { PropertyPageVlButton } from "../components/properties/property-page-vl-button.component";

import { PropertyPageContactToggle } from "../components/properties/property-page-contact-toggle.component";
import { LoginMobileMenuButton } from "../components/login/login-mobile-menu-button.component";
import { ToggleSwitch } from "../components/commons/toggle-switch.component";

import { HopplerDatePicker } from "../components/commons/hoppler-date-picker.component";
import { InfoWindowContent } from "../components/properties/info-window-content.component";


export interface SelectorTuple {
  name: string,
  component: any // we use type "any" now because we use Preact.h to construct the vdom
}

export const SELECTORS: SelectorTuple[] = [
  {
    name: 'hi-location-search-box',
    component: LocationSearchBox
  },
  {
    name: 'hi-result-filters',
    component: ResultFilters
  },
  {
    name: 'hi-card-vl-button',
    component: CardVlButton
  },
  {
    name: 'hi-vl-count-bubble',
    component: VlCountBubble
  },
  {
    name: 'hi-login-form',
    component: LoginForm
  },
  {
    name: 'hi-resetpassword-form',
    component: ResetPasswordForm
  },
  {
    name: 'hi-login-nav-button',
    component: LoginNavButton
  },
  {
    name: 'hi-property-page-vl-button',
    component: PropertyPageVlButton
  },
  {
    name: 'hi-property-page-contact-toggle',
    component: PropertyPageContactToggle
  },
  {
    name: 'hi-login-mobile-menu-button',
    component: LoginMobileMenuButton
  },
  {
    name: 'hi-toggle-switch',
    component: ToggleSwitch
  },
  {
    name: 'hi-date-picker',
    component: HopplerDatePicker
  },
  {
    name: 'hi-info-window-content',
    component: InfoWindowContent
  }
];