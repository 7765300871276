import { Component, h, render } from 'preact';
import { Subject, Subscription } from 'rxjs';

// import { Chart } from 'chart.js';
// import 'chartjs-plugin-datalabels';

import { Form, Field, FieldList } from '../../../utils/forms/form';

import hopplerLinkState, { hopplerUpdateFormFieldValue } from '../../../utils/forms/hoppler-linkstate';
import { MoneyCalculator, MonthlyAmortization } from '../../../../core/money-calculator';
import { distinctUntilChanged } from 'rxjs/operators';
import { toDecimal } from '../../../../helpers/decimal';
import { CurrencyInput } from '../commons/hoppler-currency-input.component';

interface MortgageCalculatorProps {
  price?: string
}

interface MortgageCalculatorState {
  mortgageForm?: Form
  price?: number;
  mortgageChart: Chart;
  monthlyAmortization: MonthlyAmortization;
};

export interface BankRate {
  bank: string;
  rate: number;
  selectedByDefault?: boolean;
}

const BANK_RATES: BankRate[] = [
  {
    bank: 'BDO (5.50%)',
    rate: 5.50
  },
  {
    bank: 'BPI (5.25%)',
    rate: 5.25
  },
  {
    bank: 'Chinabank (5.75%)',
    rate: 5.75
  },
  {
    bank: 'EastWest Bank (5.50%)',
    rate: 5.50
  },
  {
    bank: 'HSBC (4.99%)',
    rate: 4.99
  },
  {
    bank: 'Metrobank (5.50%)',
    rate: 5.50
  },
  {
    bank: 'PNB (5.75%)',
    rate: 5.75
  },
  {
    bank: 'RCBC (5.50%)',
    rate: 5.50
  },
  {
    bank: 'Security Bank (6.25%)',
    rate: 6.25,
    selectedByDefault: true
  },
  {
    bank: 'Unionbank (6.00%)',
    rate: 6.00
  }
];


const MORTGAGE_CHART_TYPE: string ="doughnut";

const MORTGAGE_CHART_COLORS = [
  "#007EFE",
  "#888"
];

const MORTGAGE_CHART_LABELS = [
  'Principal',
  'Interest'
];

const MORTGAGE_CHART_OPTIONS = {
  legend: {
    display: false,
    // we create our own legend so we can style it.
    // the default legend overlaps with chartjs-plugin-datalabels
  },
  tooltips: {
    enabled: false
  },
  plugins: {
    datalabels: {
      align: 'end',
      backgroundColor: function(context) {
        return context.dataset.backgroundColor;
      },
      borderColor: 'white',
      borderRadius: 25,
      borderWidth: 2,
      color: 'white',
      font: {
        weight: 'bold'
      },
      formatter: function(value) {
        return `₱ ${toDecimal(value)} `;
      }
    }
  },
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10
    }
  }
};
export class MortgageCalculator extends Component<MortgageCalculatorProps, MortgageCalculatorState> {
  form$: Subject<Form>;
  private subscriptions: Subscription[] = [];
  moneyCalculator = new MoneyCalculator();
  chartNode: any;

  constructor(props: MortgageCalculatorProps) {
    super(props);

    this.setupForm();
    this.getMonthlyAmortization();
  }

  setupForm() {
    const price = parseInt(this.props.price);
    const defaultBank = BANK_RATES.filter(bankRate => bankRate.selectedByDefault)[0];

    this.setState({
      price: price
    });

    let fields: FieldList = {
      loanAmount: new Field([], null, price),
      loanPercent: new Field([], null, 100),

      downAmount: new Field([], null, 0),
      downPercent: new Field([], null, 0),

      annualRate: new Field([], null, defaultBank.rate),
      loanTerm: new Field([], null, 10)
    };

    this.form$ = new Subject<Form>();
    this.setState({
      mortgageForm: new Form(fields)
    });

    this.subscriptions.push(
      this.form$.pipe(distinctUntilChanged())
        .subscribe(form => {
          const nextForm = this.calculateValues(form);

        this.setState({
          mortgageForm: nextForm
        });

        this.getMonthlyAmortization();
        this.updateMortgageChart();
      })
    );
  }

  calculateValues(curr: Form): Form {
    const prev = this.state.mortgageForm

    let next = Object.assign(
      {},
      curr
    );

    // loanAmount & loanPercent
    if (curr.fields['loanPercent'].value != prev.fields['loanPercent'].value) {
      next.fields['loanAmount'].value = this.moneyCalculator.convertPercentToValue(
        curr.fields['loanPercent'].value,
        this.state.price
      );
    }

    if (curr.fields['loanAmount'].value != prev.fields['loanAmount'].value) {
      next.fields['loanPercent'].value = this.moneyCalculator.convertValueToPercent(
        curr.fields['loanAmount'].value,
        this.state.price
      );

      next.fields['downPercent'].value = 100 - next.fields['loanPercent'].value;
    }

    // downAmount & downPercent
    if (curr.fields['downPercent'].value != prev.fields['downPercent'].value) {
      next.fields['downAmount'].value = this.moneyCalculator.convertPercentToValue(
        curr.fields['downPercent'].value,
        this.state.price
      );
    }

    if (curr.fields['downAmount'].value != prev.fields['downAmount'].value) {
      next.fields['downPercent'].value = this.moneyCalculator.convertValueToPercent(
        curr.fields['downAmount'].value,
        this.state.price
      );

      next.fields['loanPercent'].value = 100 - next.fields['downPercent'].value;
      next.fields['loanAmount'].value = this.state.price - next.fields['downAmount'].value;
    }

    return next;
  }

  componentDidMount() {
    // this has to be here because the DOM is required.
    // this.loadChartJS();
    this.createChart();
  }

  componentWillUnmount() {
    this.subscriptions.forEach(member => {
      member.unsubscribe();
    });
  }

  // loadChartJS() {

  //   const scriptPath = `https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.min.js`;

  //   let vdomScript = h(
  //     'script',
  //     {
  //       src: scriptPath,
  //       async: true,
  //       defer: true,
  //       onLoad: () => {
  //         this.onChartJsLoaded();
  //       }
  //     }
  //   );

  //   render(vdomScript, document.body);
  // }

  // onChartJsLoaded = () => {
  //   const scriptPath = `https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@0.6.0/dist/chartjs-plugin-datalabels.min.js`;

  //   let vdomScript = h(
  //     'script',
  //     {
  //       src: scriptPath,
  //       async: true,
  //       defer: true,
  //       onLoad: () => {
  //         this.onChartPluginLabelLoaded();
  //       }
  //     }
  //   );

  //   render(vdomScript, document.body);
  // }

  onChartPluginLabelLoaded = () => {
    this.createChart();
  }

  getMonthlyAmortization(): void {
    const rawLoanAmount = this.state.mortgageForm.fields['loanAmount'].value;

    const loanTerm = this.state.mortgageForm.fields['loanTerm'].value;
    const annualRate = this.state.mortgageForm.fields['annualRate'].value;

    const monthlyAmortization = this.moneyCalculator
      .getMonthlyAmortization(
        Number(rawLoanAmount),
        loanTerm,
        annualRate
      );

    this.setState({
      monthlyAmortization: monthlyAmortization
    });
  }

  createChart(): void {
    import('chart.js').then((Chart: any) => {
      import('chartjs-plugin-datalabels').then(() => {
        const chartColors = MORTGAGE_CHART_COLORS;
        const chartLabels = MORTGAGE_CHART_LABELS;
        const chartOptions = MORTGAGE_CHART_OPTIONS;
        const chartType = MORTGAGE_CHART_TYPE;

        const chart = new Chart(this.chartNode, {
          type: chartType,
          data: {
            labels: chartLabels,
            datasets: [{
              data: [
                this.state.monthlyAmortization.principal,
                this.state.monthlyAmortization.interest
              ],
              backgroundColor: chartColors
            }]
          },
          options: chartOptions
        });

        this.setState({
          mortgageChart: chart
        });
      })
    });
  }

  updateMortgageChart(): void {

    const data = [
      this.state.monthlyAmortization.principal,
      this.state.monthlyAmortization.interest
    ];

    let chart: Chart = this.state.mortgageChart;
    chart.data.datasets[0].data = data;

    this.setState({
      mortgageChart: chart
    });

    this.state.mortgageChart.update();
  }

  onLoanAmountChange = (value) => {
    hopplerUpdateFormFieldValue(this.form$, this.state.mortgageForm, 'loanAmount',value);
  }

  onDownAmountChange = (value) => {
    hopplerUpdateFormFieldValue(this.form$, this.state.mortgageForm, 'downAmount',value);
  }
  
  render(props: MortgageCalculatorProps, state: MortgageCalculatorState): JSX.Element {

    return (
      <div class="hi-listing-page__mortgage-calc my-md-5">
        <div class="p-md-4">
          <span class="hi-listing-page__mortgage-calc__heading">Mortgage calculator</span>
          <p>Default values are estimates and actual values may vary.</p>

          <div class="row">
            <div class="col-12 col-md-6">
              <form>
                <div class="form-group">
                  <label>Loan amount</label>
                  <div class="hi-listing-page__mortgage-calc__money-row">
                    <div class="input-group hi-listing-page__mortgage-calc__money-php">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-white text-muted">Php</span>
                      </div>
                      {/* <input class="form-control border-left-0" type="number"
                        min="0"
                        value={state.mortgageForm.fields.loanAmount.value}
                        onChange={hopplerLinkState(this.form$, state.mortgageForm, 'loanAmount')}>
                      </input> */}
                      <CurrencyInput
                        extraClasses="border-left-0"
                        onValueChange={ this.onLoanAmountChange }
                        value={ state.mortgageForm.fields['loanAmount'].value }
                        fieldName="loanAmount"
                        min={ 0 }>
                      </CurrencyInput>
                    </div>

                    <div class="input-group hi-listing-page__mortgage-calc__money-pct">
                      <input class="form-control border-right-0 pr-0" type="number"
                        min="0" max="100"
                        value={state.mortgageForm.fields.loanPercent.value}
                        onChange={hopplerLinkState(this.form$, state.mortgageForm, 'loanPercent')}>
                      </input>
                      <div class="input-group-append">
                        <span class="input-group-text bg-white text-muted">%</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Downpayment</label>
                  <div class="hi-listing-page__mortgage-calc__money-row">
                    <div class="input-group hi-listing-page__mortgage-calc__money-php">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-white text-muted">Php</span>
                      </div>
                      {/* <input class="form-control border-left-0" type="number"
                        min="0"
                        value={state.mortgageForm.fields.downAmount.value}
                        onChange={hopplerLinkState(this.form$, state.mortgageForm, 'downAmount')}>
                      </input> */}
                      <CurrencyInput
                        extraClasses="border-left-0"
                        onValueChange={ this.onDownAmountChange }
                        value={ state.mortgageForm.fields['downAmount'].value }
                        fieldName="downAmount"
                        min={ 0 }
                        placeholder="0">
                      </CurrencyInput>
                    </div>

                    <div class="input-group hi-listing-page__mortgage-calc__money-pct">
                      <input class="form-control border-right-0 pr-0" type="number"
                        min="0" max="100"
                        value={state.mortgageForm.fields.downPercent.value}
                        onChange={hopplerLinkState(this.form$, state.mortgageForm, 'downPercent')}>
                      </input>
                      <div class="input-group-append">
                        <span class="input-group-text bg-white text-muted">%</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="form-group hi-listing-page__mortgage-calc__annual-rate">
                  <label class="hi-listing-page__mortgage-calc__annual-rate__label">Annual rate</label>
                  <select class="form-control hi-listing-page__mortgage-calc__annual-rate__dropdown mb-2"
                    onChange={hopplerLinkState(this.form$, state.mortgageForm, 'annualRate')}>
                    {
                      BANK_RATES.map(bankRate => {
                        if (bankRate.selectedByDefault)
                          return <option value={bankRate.rate} selected>{bankRate.bank}</option>
                        else
                          return <option value={bankRate.rate}>{bankRate.bank}</option>
                      })
                    }
                  </select>

                  <div class="input-group hi-listing-page__mortgage-calc__annual-rate__input">
                    <input type="number" min="1" max="100" class="form-control border-right-0"
                      value={state.mortgageForm.fields['loanTerm'].value}
                      onChange={hopplerLinkState(this.form$, state.mortgageForm, 'loanTerm')}>
                    </input>
                    <div class="input-group-append">
                      <span class="input-group-text bg-white text-muted">years</span>
                    </div>
                  </div>
                  <span class="hi-type-caption hi-listing-page__mortgage-calc__annual-rate__credits">
                    * Bank rates data taken from imoney.ph
                  </span>
                </div>
              </form>
            </div>
            <div class="col-12 col-md-6">
              <div class="mortgage-flex__flex-item chart">
                <ul class="chart__legend">
                  <li class="chart__legend-item">
                    <div class="chart__legend-mark chart__legend-mark--interest"></div>
                    <small class="chart__legend-text">
                      Interest
                    </small>
                  </li>

                  <li class="chart__legend-item">
                    <div class="chart__legend-mark chart__legend-mark--principal"></div>
                    <small class="chart__legend-text">
                      Principal
                    </small>
                  </li>
                </ul>

                <canvas class="chart__canvas" id="mortgageChart"
                  ref={node => this.chartNode = node}>
                </canvas>
                <small class="chart__canvas-label">Monthly Amortization</small>
                <span class="chart__canvas-value">₱ { toDecimal(state.monthlyAmortization.total) }</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}
