export interface MonthlyAmortization {
  principal: number;
  interest: number;
  total: number;
}

export class MoneyCalculator {

  // 1 period = 1 month
  // A = payment amount per period
  // P = initial Principal (loan amount)
  // r = interest rate per period
  // n = total number of payments or periods
  // formula = p((r(1 + r)^n)/((1+r)^n -1))
  // https://www.vertex42.com/ExcelArticles/amortization-calculation.html

  getMonthlyAmortization(
    loanAmount: number,
    loanTerm: number,
    annualRate: number
  ): MonthlyAmortization {
    if (loanAmount === 0) {
      return {
        total: 0,
        principal: 0,
        interest: 0
      } as MonthlyAmortization;
    }

    let period = loanTerm * 12;
    let ratePerPeriod = (annualRate / 100) / 12;

    let numerator = ratePerPeriod * ((1 + ratePerPeriod) ** period);
    let denominator = ((1 + ratePerPeriod) ** period) - 1;

    let total = Math.round(loanAmount * (numerator/denominator));

    let principal = Math.round(loanAmount / period);
    let interest = Math.round(total - principal);

    return {
      total: this.sanitizeNumber(total),
      principal: this.sanitizeNumber(principal),
      interest: this.sanitizeNumber(interest)
    } as MonthlyAmortization;
  }

  sanitizeNumber(number: number): number {
    if (!number || isNaN(number)) {
      return 0;
    }

    return number;
  }

  convertPercentToValue(value: number, listingPrice: number): number {
    if (!value) {
      return listingPrice * (0/100);
    }
    return listingPrice * (value/100);
  }

  convertValueToPercent(value: number, listingPrice: number): number {
    let rawPercent;
    if (!value) {
      rawPercent = (0 / listingPrice) * 100;
    } else {
      rawPercent = (value / listingPrice) * 100;
    }
       
    return parseFloat(rawPercent.toFixed(2));
  }

}
