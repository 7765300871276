import { h, Component } from 'preact';
import cx from 'classnames';
import { from, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { appState$ } from '../../store';
import { AppState } from '../../reducers/app-state';

import { ModalProps } from '../../types/modal-props';
import { ResultFilters } from './result-filters.component';
import { PropertyFilters, Location, DivCat } from '../../../../types';
import { ResultsPageBundle } from '../../../../types/results-page-bundle.model';

export interface SearchFilterModalProps extends ModalProps {
  params: {
    location?: Location,
    divCat?: DivCat
  }
}

export interface SearchFilterModalState {
}

export class SearchFilterModal extends Component<SearchFilterModalProps, SearchFilterModalState> {
  subscriptions: Subscription[] = [];
  
  constructor(props: SearchFilterModalProps) {
    super(props);
    // console.log('Dialog!', props.params);

    const resultBundle = Object.assign({}, props.params.divCat) as ResultsPageBundle;
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    console.log('this modal has been unmounted properly');
  }

  onClose = () => {
    this.props.onModalFail('closed');
  }

    // this.props.onModalSuccess(filters);
  onSearchFilterSuccess = (filters: PropertyFilters) => {
    this.props.onModalSuccess(filters);
  }

  render(props: SearchFilterModalProps, state: SearchFilterModalState) {
    
    return(
      <div class="hi-modal p-4"> 
        <div class="hi-modal__header">
          <h4>Set filters</h4>
          <button class="hi-modal__header-close" 
            onClick={this.onClose}>
            <i class="fas fa-times"></i> Close
          </button>
        </div>

        <hr class="hi-modal__hr"/>

        <ResultFilters isResultsPage={false} onSearch={this.onSearchFilterSuccess}
          location={props.params.location} resultBundle={props.params.divCat as ResultsPageBundle}
          includePreselling={true} divCat={props.params.divCat} initialFormState={{}} />
      </div>
    );
  }
}