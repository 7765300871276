

export function getSelectorAttribs(namedNodeMap: NamedNodeMap) {
  let attribs = {};

  for (let i = 0; i < namedNodeMap.length; i++) {
    attribs[namedNodeMap[i].name] = namedNodeMap[i].value;
  }

  // console.log('attr', attribs);

  return attribs;
}