import { Component, h } from 'preact';
import { Subscription } from 'rxjs';
import getStore, { appState$ } from '../../store';
import { map, filter } from 'rxjs/operators';
import { CartAction, RELOAD } from '../../reducers/cart/cart.actions';

interface VlCountBubbleState {
  cartSize: number;
}

export class VlCountBubble extends Component<any, VlCountBubbleState> {
  subscriptions: Subscription[] = [];

  constructor(props: any) {
    super(props);

    this.setState({
      cartSize: 0
    });
  }

  componentDidMount() {
    const store = getStore();
    store.dispatch({
      type: RELOAD
    } as CartAction);

    const cart$ = appState$.pipe(
      map(appState => appState.cart),
      filter(cart => !!cart.propertyIds)
    );

    this.subscriptions.push(
      cart$.subscribe((cart) => {
        this.setState({
          cartSize: cart.propertyIds.length
        });
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.subscriptions = [];
  }

  render(props: any, state: any) {
    let thing: JSX.Element = null;

    if (state.cartSize > 0) {
      thing = (
        <span class="hi-navbar__cart-size-count">
          <span class="hi-navbar__cart-size-count__label">{state.cartSize}</span>
        </span>
      );
    }

    return thing;
  }
}