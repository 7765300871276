// previously Hopia's Price Pipe
export class ListingPriceUtils {
  
  transform(value: number, args?: any): string {
    const BILLION = 1000000000;
    const MILLION = 1000000;
    const THOUSAND = 1000;
    
    if (value >= BILLION) {
      // We want something like "7.7B", not just "7B"
      const divisor = BILLION / 10;
      const shortened = Math.floor(value / divisor);

      return `${shortened / 10}B`;
    } else if (value >= MILLION) {
      // We want something like "3.5M", not just "3M"
      const divisor = MILLION / 10;
      const shortened = Math.floor(value / divisor);

      return `${shortened / 10}M`;
    } else if (value >= THOUSAND) {
      // We don't really care about a couple hundred pesos - just chop it off
      return Math.floor(value / THOUSAND) + 'K';
    } else {
      return `${value}`;
    }
  }
}

const listingPriceSingleton: ListingPriceUtils = new ListingPriceUtils();

export default listingPriceSingleton;