import { Component, h } from 'preact';
import { dataLayer } from '../../../helpers/data-layer-util';

interface PropertyPageContactToggleProps {
  
}

interface PropertyPageContactToggleState {
  isNumbersVisible?: boolean;
  buttonMessage?: string;

  hasSentDataLayerEvent?: boolean
}

'hi-property-page-contact-toggle';
export class PropertyPageContactToggle extends Component<any, PropertyPageContactToggleState> {
  constructor(props) {
    super(props);

    this.setState({
      buttonMessage: 'Show numbers',
      isNumbersVisible: true
    });
  }

  getNumbers = (state: PropertyPageContactToggleState) => {
    if (state.isNumbersVisible) {
      return (
        <div class="col-6">
          <div>
            +63 917 703 6568
          </div>
          <div>
            +63 969 039 6651
          </div>
        </div>
      );
    } else {
      return (
        <div class="col-6">
          <div>
            +63 917 703 XXXX
          </div>
          <div>
            +63 928 822 XXXX
          </div>
        </div>
      );
    }
  }

  getWeekdayNumbers = (state: PropertyPageContactToggleState) => {
    if (state.isNumbersVisible) {
      return (
        <div class="row">
          <div class="col-6">
            <div>
              +63 917 703 6568
            </div>
            <div>
              +63 969 039 6651
            </div>
          </div>
          <div class="col-6">
            <div>
              +63 (2) 8 271 3686
            </div>
            {/* <div>
              (02) 8 826 1322
            </div> */}
          </div>
      </div>
      );
    } else {
      return (
        <div class="row">
          <div class="col-6">
            <div>
              +63 917 703 XXXX
            </div>
            <div>
              +63 928 822 XXXX
            </div>
          </div>
          <div class="col-6">
            <div>
              (02) 8 826 XXXX
            </div>
            <div>
              (02) 8 826 XXXX
            </div>
          </div>
        </div>
      );
    }
  }

  getWeekendNumbers = (state: PropertyPageContactToggleState) => {
    if (state.isNumbersVisible) {
      return (
        <div class="row">
          <div class="col-6">
            +63 (2) 8 271 3686
          </div>
          {/* <div class="col-6">
            (02) 7 577 6646
          </div> */}
        </div>
      );
    } else {
      return (
        <div class="row">
          <div class="col-6">
            (02) 7 507 XXXX
          </div>
          <div class="col-6">
            (02) 7 577 XXXX
          </div>
        </div>
      );
    }
  }

  toggleNumbers = e => {
    if (!this.state.hasSentDataLayerEvent) {
      dataLayer.push({
        'event': 'Property Page - Show Numbers',
        'permalink': location.pathname
      });
      this.setState({ hasSentDataLayerEvent: true });
    }

    if (this.state.isNumbersVisible) {
      this.setState({
        buttonMessage: 'Show numbers',
        isNumbersVisible: false
      });
    } else {
      this.setState({
        buttonMessage: 'Hide numbers',
        isNumbersVisible: true
      });
    }
  }

  render(props, state: PropertyPageContactToggleState) {
    return(
      <div class="hi-listing-page__right-section-contact-numbers mt-2">
        <p class="mb-0">
          Monday to Friday (8:00 AM - 5:30 PM):
        </p>
        { this.getWeekdayNumbers(state) }

        <p class="mb-0 mt-3">
          After office hours (5:30 PM - 8:00 PM)
          and on weekends (8:00 AM - 8:00 PM):
        </p>
        { this.getWeekendNumbers(state) }

        {/* <div class="row mt-3">
          <div class="col-8">
            <button class="btn btn-outline-dark"
              onClick={this.toggleNumbers}>
              { state.buttonMessage }
            </button>
          </div>
        </div> */}
      </div>
    );
  }
}