import { User } from '../types';
import { UserTypes, UserGroup, ROLE_CONSUMER, ROLE_BUYER } from '../constants/user-types';
import intersection from 'lodash.intersection';

export class UserUtils {
  isInGroup(user: User, group: UserGroup) {
    const userRoles = user.roles;
    let inGroup: boolean;

    switch (group) {
      case UserGroup.QcGroup: {
        inGroup = intersection(userRoles, UserTypes.QcGroup).length > 0;
        break;
      }

      case UserGroup.ListingsGroup: {
        inGroup = intersection(userRoles, UserTypes.ListingsGroup).length > 0;
        break;
      }

      case UserGroup.InternalGroup: {
        inGroup = intersection(userRoles, UserTypes.InternalGroup).length > 0;
        break;
      }

      case UserGroup.AdminGroup: {
        inGroup = intersection(userRoles, UserTypes.AdminGroup).length > 0;
        break;
      }

      case UserGroup.LogsAdminGroup: {
        inGroup = intersection(userRoles, UserTypes.LogsAdminGroup).length > 0;
        break;
      }

      case UserGroup.BusinessGroup: {
        inGroup = intersection(userRoles, UserTypes.BusinessGroup).length > 0;
        break;
      }

      case UserGroup.BrokerManagementGroup: {
        inGroup = intersection(userRoles, UserTypes.BrokerManagementGroup).length > 0;
        break;
      }

      default: {
        inGroup = false;
      }
    }

    return inGroup;
  }

  isBuyer(user: User) {
    const hasRoleBuyer = user.roles.includes(ROLE_BUYER);
    const hasOnlyOneRole = user.roles.length === 1;

    return hasRoleBuyer && hasOnlyOneRole;
  }
}
