import { Subject } from "rxjs";
import { Form, FieldList, Field } from "./form";

/** 
 * This function is a drop-in replacement for preact's linkState.
 * Please only use when a form requires heavy lifting and validation
*/
export default function hopplerLinkState(
  subject: Subject<any>, 
  form: Form, 
  fieldName: string
) {
  return (event) => {
    let clone = Form.cloneFrom(form);

    let target = event.target;
    let value = target.value

    if (target.nodeName && target.type && target.type.match(/^che/)) {
      value = target.checked;
    }

    clone.fields[fieldName].value = value;
    clone.fields[fieldName].isPristine = false;
    clone.isPristine = false;

    subject.next(clone);
  }
}

/**
 * This is an extended version of the linkState that does not rely
 * on an event or target. It simply receives a new value then updates
 * the subject form. 
 * 
 * Used by:
 *  hi-currency-input
 *  
 * To be used by but not yet implemented:
 *  hi-date-picker
 * 
 * @param subject 
 * @param form 
 * @param fieldName 
 * @param value 
 */
// */
export function hopplerUpdateFormFieldValue(
  subject: Subject<any>, 
  form: Form, 
  fieldName: string,
  value: any
) {
  let clone = Form.cloneFrom(form);

  clone.fields[fieldName].value = value;
  clone.fields[fieldName].isPristine = false;

  subject.next(clone);
  return clone;
}

/**
 * This functions similarly to hopplerUpdateFormFieldValue but instead
 * of a 1 value into 1 field, it is 1 value inside an array in a field.
 * 
 * @param subject 
 * @param form 
 * @param fieldName 
 * @param value 
 */
export function hopplerLinkArray<T>(
  subject: Subject<any>, 
  form: Form, 
  fieldName: string,
  value: T,
) {
  let clone = Form.cloneFrom(form);
  let fieldAsArray = clone.fields[fieldName].value as T[];

  let valueExistsIndex = fieldAsArray.findIndex(member => 
    { return member === value; }
  );

  // check if value is in array.
  // if true then remove from array
  // else add it to array
  if (valueExistsIndex) {
    fieldAsArray.splice(valueExistsIndex, 1);
  } else {
    fieldAsArray.push(value);
  }

  subject.next(clone);
}

/**
 * A non-rxjs based way of handling value to array setting/unsetting
 * 
 * @param component 
 * @param fieldName 
 * @param value Optional. If not specified, function gets target.value
 */
export function preactLinkArray<T>(
  component: any,
  fieldName: string,
  value?: T,
) {

  return (event) => {
    let path = fieldName.split('.');
    let state = Object.assign(
      {},
      component.state
    );

    // The whole point of this is to allow us to take
    // a dot notated path and slowly "go" to that point
    // in the state object. 
    // We don't want to do state['some.dot.path'] because that is not logical.
    // We use obj to hold the position of the subpath we are accessing
    let obj = state;
    let i = 0;
    for ( ; i < path.length -1; i++) {

      if (!component.state[path[i]]) {
        obj[path[i]] = {};
      }

      obj = obj[path[i]];
    }

    let target = event.target;
    let fieldAsArray = obj[path[i]] as T[];

    if (!fieldAsArray) {
      fieldAsArray = [];
    }

    if (value == undefined) {
      if (target.nodeName && target.type.match(/^che|rad/)) {
        value = target.checked
      } else {
        value = target.value;
      }
    }

    let valueExistsIndex = fieldAsArray.findIndex(member => 
      { return member === value; }
    );

    // check if value is in array.
    // if true then remove from array
    // else add it to array
    if (valueExistsIndex >= 0) {
      fieldAsArray.splice(valueExistsIndex, 1);
    } else {
      fieldAsArray.push(value);
    }

    obj[path[i]] = fieldAsArray;

    component.setState(state);
  }
}
