import { Observable, pipe, of, EMPTY, from } from 'rxjs';
import { switchMap, timeout, map, mergeMap, catchError } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';

import Axios, { AxiosPromise, AxiosResponse } from 'axios';

import * as LandingActions from './landing.actions'
import { LandingAction } from './landing.actions';
import { hopplerApi } from '../../../../constants';

/*

  The current system is to define Epics (which are basically effects)
  and add them to the array that is then exported.

  I would like to create a cleaner method, hopefully with annotations
  but alas, this method shall be used until then.

// */

let landingEffects: Epic[] = [];

const getMaintenanceSched: Epic = getMaintenanceSched$ => getMaintenanceSched$.pipe(
  ofType<LandingAction>(LandingActions.GET_MAINTENANCE_SCHED),
  map(action => action.payload),
  switchMap(payload => {
    const promise = Axios.get(
      `${hopplerApi.host}/system/deployment/status`,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap((response) => {

        const action: LandingAction = {
          type: LandingActions.GET_MAINTENANCE_SCHED_SUCCESS,
          payload: {
            maintenanceMessage: response.data.msg
          }
        }

        return of(action);
      }),
      catchError(err => {
        const action: LandingAction = {
          type: LandingActions.GET_MAINTENANCE_SCHED_FAIL
        }

        return of(action);
      })
    );
  })
);

// ! Make sure to add created epics to array 

landingEffects.push(getMaintenanceSched);

export default landingEffects;