import { Neighborhood, NeighborhoodPinFilter, NeighborhoodFilterTypes, PropertyMapMarker } from "../../../../types";
import { Listing, RequestState } from "../../../../types";

const PREFIX = '[Results]';

export const TEST = `${PREFIX} TEST`;
export const SECOND_TEST = `${PREFIX} SECOND_TEST`;

export const PREVIEW_PROPERTY = `${PREFIX} PREVIEW_PROPERTY`;

export const TOGGLE_MAP = `${PREFIX} TOGGLE_MAP`;

export const UPDATE_NEIGHBORHOOD_LOCATION_DATA = `${PREFIX} UPDATE_NEIGHBORHOOD_LOCATION_DATA`;
export const GET_NEIGHBORHOOD_PINS = `${PREFIX} GET_NEIGHBORHOOD_PINS`;
export const GET_NEIGHBORHOOD_PINS_SUCCESS = `${PREFIX} GET_NEIGHBORHOOD_PINS_SUCCESS`;
export const GET_NEIGHBORHOOD_PINS_FAIL = `${PREFIX} GET_NEIGHBORHOOD_PINS_FAIL`;

export const UPDATE_NEIGHBORHOOD_FILTERS = `${PREFIX} UPDATE_NEIGHBORHOOD_FILTERS`;
export const MAP_MARKER_CLICKED = `${PREFIX} MAP_MARKER_CLICKED`;

export const MAP_INFO_WINDOW_OPENED = `${PREFIX} MAP_INFO_WINDOW_OPENED`;
export const MAP_INFO_WINDOW_CLOSED = `${PREFIX} MAP_INFO_WINDOW_CLOSED`;

export const MAP_MOBILE_PROPERTY_CARD_OPENED = `${PREFIX} MAP_MOBILE_PROPERTY_CARD_OPENED`;
export const MAP_MOBILE_PROPERTY_CARD_CLOSED = `${PREFIX} MAP_MOBILE_PROPERTY_CARD_CLOSED`;

export interface ResultsState {
  message?: string;

  isResultsPageMapVisible?: boolean;

  neighborhoodRequestState?: RequestState;
  neighborhoodData?: Neighborhood;
  neighborhoodPinFilters?: NeighborhoodPinFilter;
  neighborhoodPinFilterType?: NeighborhoodFilterTypes;

  previewedListing?: Listing;
  selectedPropertyMarker?: PropertyMapMarker;

  activeInfoWindowProperties?: Listing[];
}

export interface ResultsAction {
  type: string,
  payload?: ResultsState
}