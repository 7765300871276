import { Component, h, render } from 'preact';
import getStore, { appState$ } from '../../store';
import { ADD_TO_CART, CartAction, REMOVE_FROM_CART } from '../../reducers/cart/cart.actions';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

export interface CardVlButtonProps {
  propertykey: string;
  compact?: boolean;
}

export interface CardVlButtonState {
  isInCart: boolean;
}

export class CardVlButton extends Component<CardVlButtonProps, CardVlButtonState> {
  subscriptions: Subscription[] = [];
  props: CardVlButtonProps;

  onAddButtonClick(propertyKey: string) {
    const store = getStore();

    store.dispatch({
      type: ADD_TO_CART,
      payload: {
        propertyKey: propertyKey
      }
    } as CartAction);
  }

  onRemoveButtonClick(propertyKey: string) {
    const store = getStore();

    const isInViewingList: boolean = window.location.pathname == '/viewing-list';

    store.dispatch({
      type: REMOVE_FROM_CART,
      payload: {
        propertyKey: propertyKey
      }
    } as CartAction);

    if (isInViewingList) {
      let card = document.getElementById(propertyKey);
      
      render(null, card.parentElement, card.parentElement);
    }
  }

  constructor(props: CardVlButtonProps) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const isInCart$ = appState$.pipe(
      map(appState => appState.cart),
      filter(cart => !!cart.propertyIds),
      map(cart => cart.propertyIds.includes(this.props.propertykey))
    );

    this.subscriptions.push(
      isInCart$.subscribe(isInCart => {
        this.setState({ isInCart });
      })
    );
  }

  componentWillReceiveProps(props: CardVlButtonProps) {

    if (props.propertykey != this.props.propertykey) {
      const storeSnapshot = getStore();
      const cartSnapshot = storeSnapshot.getState().cart;

      if (!cartSnapshot.propertyIds.includes(props.propertykey)) {
        this.setState({
          isInCart: false
        });
      } else {
        this.setState({
          isInCart: true
        });
      }
    }

    this.props = props;
  }

  componentWillUnmount() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.subscriptions = [];
  }

  getStandardButtons(props: CardVlButtonProps, state: CardVlButtonState) {
    if (!state.isInCart) {
      return (
        <button onClick={() => this.onAddButtonClick(props.propertykey)}
            className="btn btn-outline-dark hi-property-card__vl-button">
          <i className="fa fa-plus"></i>
          &nbsp;Viewing list
        </button>
      );
    } else {
      return (
        <button onClick={() => this.onRemoveButtonClick(props.propertykey)}
            className="btn btn-outline-dark hi-property-card__vl-button">
          Remove
        </button>
      );
    }
  }

  getCompactButtons(props: CardVlButtonProps, state: CardVlButtonState) {
    if (!state.isInCart) {
      return (
        <button onClick={() => this.onAddButtonClick(props.propertykey)}
            className="btn hi-property-card__vl-button">
          Add to viewing list
        </button>
      );
    } else {
      return (
        <button onClick={() => this.onRemoveButtonClick(props.propertykey)}
            className="btn hi-property-card__vl-button">
          Remove from viewing list
        </button>
      );
    }
  }

  render(props: CardVlButtonProps, state: CardVlButtonState): JSX.Element {
    if (!props.compact) {
      return this.getStandardButtons(props, state);
    } else {
      return this.getCompactButtons(props, state);
    }
  }
}
