import { Component, h } from 'preact';

export interface ToggleSwitchProps {
  label?: string,
  initialState?: boolean,
  onChange: (newValue: boolean) => any
}

export interface ToggleSwitchState {
  active: boolean;
}

export class ToggleSwitch extends Component<ToggleSwitchProps, ToggleSwitchState> {

  constructor(props: ToggleSwitchProps) {
    super(props);

    this.setState({
      active: props.initialState
    });
  }

  getSwitchIcon() {
    if (this.state.active) {
      return (<i class="fa fa-toggle-on pr-2"></i>);
    } else {
      return (<i class="fa fa-toggle-off pr-2"></i>);
    }
  }

  onClick() {
    console.log('Toggle click!');
    const newState = !this.state.active;
    this.setState({
      active: newState
    });

    this.props.onChange(newState);
  }

  render(props: ToggleSwitchProps, state: ToggleSwitchState) {
    return (
      <span class="hi-toggle-switch" role="button" onClick={() => this.onClick()}>
        { this.getSwitchIcon() }
        { props.label }
      </span>
    );
  }
}

