
import Axios from 'axios';
import { hopplerApi } from '../../constants/api';

export default document.addEventListener('DOMContentLoaded', () => {
  const propertyKeyPattern = /([cr][rs]\d{7}$)/;

  // Let's consider any path that ends in a valid property key to be a property
  // page.
  let matches = location.pathname.match(propertyKeyPattern);
  let propertyKey: string;

  if (matches) {
    propertyKey = matches[0];
    Axios.post(`${hopplerApi.host}/client/listing/${propertyKey}/view`, {}, {
      withCredentials: true
    });
  }
});
