import { Component, h } from 'preact';
import { Subscription } from 'rxjs';
import cx from 'classnames';
import { openModal } from '../../../utils/hoppler-modal.service';
import { VirtualTourModal } from './virtual-tour-modal.component';

interface VirtualTourButtonState {
  link?: string;
}

interface VirtualTourButtonProps {
  link: string;
  buttonClasses?: string,
  buttonTheme?: 'BLUE' | 'ORANGE'
}

export class VirtualTourButton extends Component<VirtualTourButtonProps, VirtualTourButtonState> {
  subscriptions: Subscription[] = [];

  constructor(props: VirtualTourButtonProps) {
    super(props);

    this.setState({
      link: props.link
    });
  }

  onClick = () => {
    let promise = openModal(VirtualTourModal, {
      modalLocals: {
        link: this.state.link
      }
    });
    promise.then(() => {
      
    }, (err) => {

    });
  }

  render(props: VirtualTourButtonProps, state: VirtualTourButtonState) {
    let extraClasses: { [k: string]: boolean };

    if (!props.buttonTheme || props.buttonTheme == 'ORANGE') {
      extraClasses = {
        'btn-outline-secondary': !!props.link,
        'btn-outline-dark': !props.link,
      };
    } else if (props.buttonTheme == 'BLUE') {
      extraClasses = {
        'btn-primary': true,
        'disabled': !props.link
      };
    }

    if (props.buttonClasses) {
      extraClasses[props.buttonClasses] = true;
    }

    return (
      <button
        disabled={ !props.link } 
        className={ cx('btn', extraClasses) } onClick={ this.onClick }>
        <i className="fas fa-street-view" />&nbsp;
        360&deg; Tour
      </button>
    );
  }
}