// Using these packages allows us to load only the parts of lodash that we need
import capitalize from 'lodash.capitalize';
import kebabCase from 'lodash.kebabcase';

import {
  DivCat, ApiDivCat,
  PropertyType, PropertyDivision, PropertyCategory,
  PropertyApiCategory,
  Listing
} from '../types';

export class DivCatUtils {
  getDivisionForType(propertyType: PropertyType | string): PropertyDivision {
    let division: PropertyDivision = 'Residential';

    switch (propertyType) {
      case 'House and Lot':
      case 'Vacant Lot':
      case 'Condominium':
      case 'Townhouse':
        division = 'Residential';
        break;
      case 'Commercial Lot':
      case 'Commercial Space':
      case 'Office Space':
      case 'Warehouse':
      case 'Building':
      case 'Serviced Office':
      case 'Industrial Lot':
      case 'Resort':
        division = 'Commercial';
        break;
    }

    return division;
  }

  getDivisionString(divisionId: number) {
    const division: PropertyDivision = (divisionId == 1 ? 'Residential' : 'Commercial');
    return division;
  }

  fillDivision(divCat: DivCat): DivCat {
    const copy = Object.assign({}, divCat) as DivCat;
    copy.division = this.getDivisionForType(divCat.type);

    return copy;
  }

  toApiDivCat(divCat: DivCat): ApiDivCat {
    const category: PropertyApiCategory = (divCat.category == 'Rent') ? 1 : 2;
    const apiDivCat: ApiDivCat = {
      division: divCat.division,
      category: category,
      type: divCat.type
    };

    return apiDivCat;
  }

  fromApiDivCat(apiDivCat: ApiDivCat): DivCat {
    const category: PropertyCategory = (apiDivCat.category == 1) ? 'Rent' : 'Sale';
    const divCat: DivCat = {
      division: apiDivCat.division,
      category: category,
      type: apiDivCat.type
    };

    return divCat;
  }

  fromSlug(slug: string): DivCat {
    const divCat = {} as DivCat;
    const typeCat = slug.split('s-for-');

    divCat.type = this.getPropertyTypeFromUrlType(typeCat[0]);
    // added capitalize because somehow 'rent' still passes through
    divCat.category = capitalize(typeCat[1]) as PropertyCategory;
    divCat.division = this.getDivisionForType(divCat.type);

    return divCat;
  }

  toSlug(divCat: DivCat): string {
    const cleanedType = this.cleanStringForRouter(divCat.type)
    const type = kebabCase(cleanedType);
    // console.log('kebab?', type);
    return `${type}s-for-${divCat.category}`.toLowerCase();
  }

  fromListing(property: Listing): DivCat {
    const divCat = {} as DivCat;

    divCat.type = property.type as PropertyType;
    divCat.category = property.category as PropertyCategory;
    divCat.division = this.getDivisionForType(divCat.type);

    return divCat;
  }

  /**
   * Returns a DivCat instance that corresponds to the given building path
   * and filter parameters. For example, a `buildingPath` set to
   * `'/condominiums/the-beacon'` with params `{'category': '1'}` will return
   * the DivCat:
   *
   * ```
   * {
   *   division: 'residential',
   *   category: 'rent',
   *   type: 'Condominium'  // a logical default for this division
   * }
   * ```
   *
   * For commercial building paths, the returned DivCat's type will be
   * `'Office Space'`.
   *
   * @param buildingPath
   * @param params
   */
  fromBuildingPath(buildingPath: string, params: { [k: string]: string }): DivCat {
    let divCat: DivCat = {};

    // For convenience, we also set a default type.
    if (buildingPath.startsWith('/condominiums/')) {
      divCat.division = 'Residential';
      divCat.type = 'Condominium';
    } else {
      divCat.division = 'Commercial';
      divCat.type = 'Office Space';
    }

    if (params['category'] && params['category'] == '1') {
      divCat.category = 'Rent';
    } else {
      divCat.category = 'Sale';
    }

    if (params['type']) {
      divCat.type = params['type'] as PropertyType;
    }

    return divCat;
  }

  getPropertyTypeFromUrlType(urlType: string): PropertyType {
    let propertyType: PropertyType;
    urlType = urlType.toLowerCase();

    switch (urlType) {
      case 'condominium':
        propertyType = 'Condominium';
        break;
      case 'house-and-lot':
        propertyType = 'House and Lot';
        break;
      case 'lot':
        propertyType = 'Vacant Lot';
        break;
      case 'townhouse':
        propertyType = 'Townhouse';
        break;
      case 'commercial-lot':
        propertyType = 'Commercial Lot';
        break;
      case 'commercial-space':
        propertyType = 'Commercial Space';
        break;
      case 'office-space':
        propertyType = 'Office Space';
        break;
      case 'warehouse':
        propertyType = 'Warehouse';
        break;
      case 'building':
        propertyType = 'Building';
        break;
      case 'serviced-office':
        propertyType = 'Serviced Office';
        break;
      case 'industrial-lot':
        propertyType = 'Industrial Lot';
        break;
      case 'resort':
        propertyType = 'Resort';
        break;
      default:
        console.error('getPropertyTypeFromUrlType: unknown type', urlType);
    }

    return propertyType;
  }

  public cleanStringForRouter(placeString: string) {
    // This prevents .../makati/dasmariñas-village from causing the frontend
    // to hang and rapidly leak memory
    placeString = decodeURIComponent(placeString);

    placeString = placeString.replace('.', '');
    placeString = placeString.replace(/ /g, '-');
    placeString = placeString.replace('/', '-');
    placeString = placeString.replace(/ñ/g, 'n');
    placeString = placeString.toLowerCase();

    if (placeString == 'vacant-lot') {
      placeString = 'lot';
    }

    return placeString;
  };

}
