export const subjectOptions = [
  { value: 'buying-property', label: 'Buying a Property' },
  { value: 'buying-renting', label: 'Selling/Renting Out Properties' },
  { value: 'hoppler-associates', label: 'Partner Broker Program' },
  { value: 'investor-relations', label: 'Investor Relations' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'corporate-partnerships', label: 'Corporate Partnerships' },
  { value: 'listings', label: 'Listings' },
  { value: 'human-resources', label: 'Human Resources' },
  { value: 'technical', label: 'Technical' },
  { value: 'others', label: 'Others' },
];