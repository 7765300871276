import { h, render } from 'preact';

export interface ToastParams {
  message?: string;
  title?: string;
  duration?: number;
}

function createToastContainer() {
  let vdom, toastContainer;
  
  vdom = h(
    'div',
    {
      class: 'hi-toast__container',
      id: 'hiToastContainer'
    }
  );

  toastContainer = render(vdom, document.body);

  return toastContainer;
}

function closeToast(id: string) {
  const element = document.getElementById(id);
  render(null, element, element);
}

const onToastClick = (id: string) => {
  closeToast(id);
}

export function showToast(params: ToastParams) {
  const id = `hi-toast-${Math.floor(Math.random() * 1000)}`;
  let toast: any, vdom;
  let toastContainer: any = document.getElementById('hiToastContainer');

  if (!toastContainer) {
    toastContainer = createToastContainer();
  }

  const duration = params.duration ? params.duration : 3000;

  const toastTitleClasses = 'hi-toast__title toast-header';
  const toastMessageClasses = 'hi-toast__message toast-body';

  vdom = h(
    'div',
    {
      class: 'hi-toast toast',
      id: id,
      onClick: ((e) => onToastClick(id))
    },
    [
      h('span', { class: toastTitleClasses }, params.title),
      h('p', { class: toastMessageClasses }, params.message)
    ]
  );

  toast = render(vdom, toastContainer);

  setTimeout(() => {

    // console.log('unmounting toast');
    render(null, toast, toast);
    // call close

  }, duration);

}