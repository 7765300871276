import { h, Component } from 'preact';
import cx from 'classnames';
import { toDecimal } from '../../../../helpers/decimal';

interface CurrencyInputProps {
  onValueChange: Function;
  fieldName: string;
  isInvalid?: boolean;

  extraClasses?: string; // use string over string[]
  isDisabled?: boolean;

  min?: number;

  placeholder?: string;
  value?: number;
}

interface CurrencyInputState {
  value?: number;
  displayValue?: string;
  isEditing?: boolean;
}

// Decided to create a component for this
// vs creating functions to do the swapping of view vs value
// Much cleaner and code stable with this way - J.Jover
export class CurrencyInput extends Component<CurrencyInputProps, CurrencyInputState> {
  constructor(props) {
    super(props);

    if (props.value) {
      this.setState({
        value: this.trimDecimals(props.value),
        displayValue: `₱ ${this.formatToCurrency(props.value)}`
      });
    }
  }

  componentWillReceiveProps(newProps: CurrencyInputProps) {
    if (newProps.value) {
      this.setState({
        value: this.trimDecimals(newProps.value),
        displayValue: `₱ ${this.formatToCurrency(newProps.value)}`
      });
    }
  }

  formatToCurrency(value): string {
    let trimmed = Number.parseFloat(value).toFixed(2);
    let formatted = toDecimal(Number.parseFloat(trimmed));
    return formatted;
  }

  trimDecimals(value): number {
    let trimmed = Number.parseFloat(value).toFixed(2);
    let formatted = Number.parseFloat(trimmed);
    return formatted;
  }

  onChange = (e) => {
    const targetValue = e.target.value;

    let displayValue;

    if (targetValue || targetValue === 0) {
      displayValue  = `₱ ${ this.formatToCurrency(targetValue) }`
    } else {
      displayValue = null;
    }

    this.setState({
      displayValue: displayValue,
      value: this.trimDecimals(targetValue)
    });

    this.props.onValueChange(targetValue);
  }

  onFocus = (e) => {
    this.setState({
      isEditing: true
    });
  }

  onBlur = (e) => {
    this.setState({
      isEditing: false
    });
  }

  render(props: CurrencyInputProps, state: CurrencyInputState) {
    let inputValue;
    let inputType;

    if (state.isEditing) {
      inputValue = state.value
      inputType = 'number';
    } else {
      inputValue = state.displayValue
      inputType = 'text';
    }

    return(
      <div class="hi-currency-input">
        <input
          className={ cx('form-control',
          'hi-text-input',
          props.extraClasses, 
          {
            'invalid': props.isInvalid
          })} 

          disabled={ props.isDisabled }
          type={ inputType }
          min={props.min} 
          placeholder={props.placeholder}

          value={ inputValue }
          onChange={ this.onChange }
          onFocus={ this.onFocus }
          onBlur={ this.onBlur }/>
      </div>
    );
  }
}