import { Validator } from "./validators";

export class Field {
  value?: any;
  isValid?: boolean;
  isPristine?: boolean;
  validators?: string[];
  customValidators?: Validator[];

  constructor(validators?: string[], customValidators?: Validator[], defaultValue?: any) {
    this.isPristine = true;
    this.validators = validators || [];
    this.customValidators = customValidators || null;
    this.value = defaultValue;
  }
}

export interface FieldList {
  [fieldName: string]: Field
}

export interface FormOptions {
  pristineUntilEdited?: boolean;
}

export class Form {
  isValid?: boolean;
  isPristine?: boolean;
  fields: FieldList;
  options: FormOptions;

  constructor(fields: FieldList, options?: FormOptions) {
    this.fields = fields;
    this.options = options || {};

    this.isValid = false;
    this.isPristine = true;
  }

  static cloneFrom(form: Form): Form {
    let clonedFields: FieldList = {};
    
    for (let k in form.fields) {
      let prevField = form.fields[k];
      clonedFields[k] = new Field(prevField.validators, prevField.customValidators, prevField.value);
      clonedFields[k].isValid = prevField.isValid;
      clonedFields[k].isPristine = prevField.isPristine;
    }

    let clone: Form = new Form(clonedFields, form.options);
    clone.isPristine = form.isPristine;
    return clone;
  }
}
