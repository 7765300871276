import { PlatformUtility } from './platform-utility';

export class SmoothScrollService {
  isAnimating: boolean;

  // Converted this to use a promise to help prevent
  // multiple calls and cause overlapping scrolling actions
  scrollToPropertyCard(elementId: string, 
    offset?: number, maxSteps?: number): Promise<any> {
    if (!PlatformUtility.isOnClient()) {
      return;
    }

    if (!elementId) {
      return;
    }

    if (this.isAnimating) {
      return;
    }

    let promise = new Promise(
      (resolve, reject) => {
        // defaulting offset to 40px
        if (!offset) {
          offset = 40;
        }

        // defaulting to 75 max steps for slight "smooth" behavior
        if (!maxSteps) {
          maxSteps = 75;
        }

        let currentScrollY = window.scrollY;
        let element = document.getElementById(elementId);
        
        let topOfElement = this.getPosition(element) - offset;
        if (currentScrollY > element.offsetTop) {
          currentScrollY += (150 + 76);
        }

        this.doScroll(topOfElement, currentScrollY, maxSteps, 0, resolve);
      }
    )

    return promise;    
  }

  getPosition(el) {

    let y = 0;

    while (el != null && (el.tagName || '').toLowerCase() != 'html') {
        y += el.offsetTop || 0; 
        el = el.parentElement;
    }

    return y;
  }

  scrollToTarget(elementId: string, 
    offset?: number, maxSteps?: number): Promise<any> {

    if (!PlatformUtility.isOnClient()) {
      return;
    }

    if (!elementId) {
      return;
    }

    if (this.isAnimating) {
      return;
    }

    let promise = new Promise(
      (resolve, reject) => {

        // defaulting offset to 40px
        if (!offset) {
          offset = 40;
        }

        // defaulting to 75 max steps for slight "smooth" behavior
        if (!maxSteps) {
          maxSteps = 75;
        }

        let element = document.getElementById(elementId);
        let topOfElement = element.offsetTop - offset;

        this.doScroll(topOfElement, window.scrollY, maxSteps, 0, resolve);

      }
    );

    return promise;

  }

  scrollToYOffset(yOffset: number, maxSteps?: number): Promise<any> {
    if (!PlatformUtility.isOnClient()) {
      return;
    }

    if (!yOffset && yOffset != 0) {
      return;
    }

    let promise = new Promise(
      (resolve, reject) => {

        if (!maxSteps) {
          maxSteps = 75;
        }
    
        this.doScroll(yOffset, window.scrollY, maxSteps, 0, resolve);
      }
    );

    return promise;

  }

  scrollToTop(maxSteps?: number) {
    this.scrollToYOffset(0, maxSteps);
  }

  bezierCalculation(step, total) {
    return (3 * (1 - (step/total)) * Math.pow(step/total, 2));
  }

  doScroll(target, current, stepsTotal, stepsCurrent, resolve): boolean {
    if (stepsCurrent > stepsTotal) {
      this.isAnimating = false;
      resolve();
      return;
    }

    if (!this.isAnimating) {
      this.isAnimating = true;
    }

    let movement = this.bezierCalculation(stepsCurrent, stepsTotal) * (target - current);
    movement *= 0.7; // add to scale movement to 70% of original computation
    
    current += movement;
    stepsCurrent++;

    window.scrollBy(0, movement);
    // console.log('movement', movement);

    setTimeout(() => this.doScroll(target, current, stepsTotal, stepsCurrent, resolve));

  }
}