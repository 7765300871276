import { createStore, applyMiddleware, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';

import AppReducer from './reducers/reducer.module';

import { rootEpic } from './reducers/effects.module';
import { from, Observable } from 'rxjs';
import { AppState } from './reducers/app-state';

const epicMiddleware = createEpicMiddleware();

const appStore = createStore(
  AppReducer, 
  composeWithDevTools(
    applyMiddleware(epicMiddleware)
  )
);

export const appState$: Observable<AppState> = from(appStore as any) as Observable<AppState>;

// ! Important line here or else the rootEpic will never be used.
epicMiddleware.run(rootEpic);

export default function getStore() {
  return appStore;
}
