import { Epic, ofType } from 'redux-observable';
import { switchMap, map, withLatestFrom, mergeMap, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';

import Axios, { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { hopplerApi } from '../../../../constants/api';

import { CartAction } from './cart.actions';
import * as CartActions from './cart.actions';
import { CartCookieUtil } from '../../../utils/cart-cookie';
import { appState$ } from '../../store';

import { CustomerInformation, StatusResponse, CartInquiry } from '../../../../types';
import { showToast } from '../../../utils/hoppler-toast.service';
import { adWordsTracker } from '../../../utils/ad-words-tracker';
import { dataLayer } from '../../../helpers/data-layer-util';
import { AppState } from '../app-state';

let cartEffects: Epic[] = [];
let cartCookieUtil = new CartCookieUtil();

const initialLeadPath: string = `${hopplerApi.host}/api/properties/inquire/cart`;
const generalInquirySmallPath: string =`${hopplerApi.host}/api/properties/inquire`;

const add: Epic = action$ => action$.pipe<CartAction, string[], CartAction>(
  ofType(CartActions.ADD_TO_CART),
  map(action => cartCookieUtil.addPropertyId(action.payload.propertyKey)),
  map(keys => ({
    type: CartActions.LOAD_FROM_COOKIES,
    bulkPayload: keys
  } as CartAction))
);
cartEffects.push(add);

const remove: Epic = action$ => action$.pipe(
  ofType(CartActions.REMOVE_FROM_CART),
  switchMap((action: CartAction) => of(cartCookieUtil.removePropertyId(action.payload.propertyKey))),
  switchMap(keys => of({
    type: CartActions.LOAD_FROM_COOKIES,
    bulkPayload: keys
  } as CartAction))
);
cartEffects.push(remove);

const reload: Epic = action$ => action$.pipe<CartAction, string[], CartAction>(
  ofType(CartActions.RELOAD),
  map((action) => {
    const util = new CartCookieUtil();
    return util.getPropertyIds();
  }),
  switchMap(cartIds => of({
    type: CartActions.LOAD_FROM_COOKIES,
    bulkPayload: cartIds
  } as CartAction))
);

function submitInitialLeadImpl(
  payload: CartActions.CartState,
  appState: AppState,
  endpoint: string
) {
  console.log('Effect received payload:', payload);
  const currentCart: CartInquiry = Object.assign({}, payload.cart);
  const adSource = adWordsTracker.getAdCampaignAttribution();

  if (adSource) {
    currentCart.specificSource = adSource.source;
    currentCart.campaign = adSource.campaign;
    if (adSource.referrerUrl) {
      currentCart.referrerUrl = adSource.referrerUrl;
    }
  }

  console.log('Will send cart:', currentCart);
  let promise = Axios.post<CustomerInformation>(
    // initialLeadPath,
    // generalInquirySmallPath,
    endpoint,
    currentCart, // cartInquiry
    {
      withCredentials: true
    }
  );

  if (payload.generalInquiryLead) {
    return from(promise).pipe(
      mergeMap(response => {
        const action: CartAction = {
          type: CartActions.SUBMIT_INITIAL_LEAD_SUCCESS
        };

        return of(action);
      })
    );
  } else {
    return from(promise).pipe(
      mergeMap(response => {
  
        const action: CartAction = {
          type: CartActions.GET_CIS,
          payload: {
            cis: response.data,
            inquiryKey: response.data.inquiry_key
          }
        };
  
        return of(action);
      }),
      catchError((errResponse: AxiosError) => {
        const action: CartAction = {
          type: CartActions.SUBMIT_INITIAL_LEAD_FAIL
        };
  
        return of(action);
      })
    );
  }
}

// for submitting an initial lead
// cart has property keys and not from follow-up
const submitInitialLead: Epic = submitInitialLead$ => submitInitialLead$.pipe(
  ofType<CartAction>(CartActions.SUBMIT_INITIAL_LEAD),
  map(action => action.payload),
  withLatestFrom(appState$),
  switchMap(([payload, appState]) => {
    let endpoint: string;

    if (payload.generalInquiryLead) {
      endpoint = generalInquirySmallPath;
    } else {
      endpoint = initialLeadPath;
    }

    return submitInitialLeadImpl(payload, appState, endpoint);
  })
);

const getCis: Epic = getCis$ => getCis$.pipe(
  ofType<CartAction>(CartActions.GET_CIS),
  map(action => action.payload),
  withLatestFrom(appState$),
  switchMap(([payload, appState]) => {
    const cartState = appState.cart;

    let promise = Axios.get<CustomerInformation>(
      `${hopplerApi.host}/api/cis/${cartState.inquiryKey}`,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(response => {

        const action: CartAction = {
          type: CartActions.SUBMIT_INITIAL_LEAD_SUCCESS,
          payload: {
            cis: response.data,
            inquiryKey: response.data.inquiry_key
          }
        };

        return of(action);
      }),
      catchError((errResponse: AxiosError) => {

        const action: CartAction = {
          type: CartActions.SUBMIT_INITIAL_LEAD_FAIL
        };

        return of(action);
      })
    );
  })
);

const submitFinalFtf: Epic = submitFinalFtf$ => submitFinalFtf$.pipe(
  ofType<CartAction>(CartActions.SUBMIT_FINAL_FTF),
  switchMap(action => {
    const adSource = adWordsTracker.getAdCampaignAttribution();
    let ftf = action.payload.cis;

    console.log('Ad campaign attribution:', JSON.stringify(adSource));
    if (adSource) {
      ftf.specificSource = adSource.source;
      ftf.campaign = adSource.campaign;
    }
    
    let promise = Axios.post(
      `${hopplerApi.host}/api/cis`,
      ftf,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(response => {
        dataLayer.push({ 'event': 'Fast Track Form' });

        if (ftf.lead_property_key) {
          const propertyCount = ftf.lead_property_key.length || 0;
          dataLayer.push({
            'event': 'Fast Track Form - Complete',
            'inquiry list count': propertyCount
          });
        }

        adWordsTracker.clearAdCampaignMarker();

        showToast({
          title: 'Successful send',
          message: 'Your fast track form has been submitted!',
          duration: 3000
        });

        const action: CartAction = {
          type: CartActions.SUBMIT_FINAL_FTF_SUCCESS
        };

        return of(action);
      }),
      catchError((error: AxiosError) => {
        let message: string;
        const response = error.response as AxiosResponse<StatusResponse>;

        const hasMsg = response && response.data && response.data.msg;
        message = hasMsg ? response.data.msg : 'An error occured.';

        showToast({
          title: 'Failed to send submit your fast track form.',
          message: message,
          duration: 3000
        });

        const action: CartAction = {
          type: CartActions.SUBMIT_FINAL_FTF_FAIL
        };

        return of(action);

      })
    );
  })
);

const submitFinalFtfSuccess: Epic = submitFinalFtfSuccess$ => 
  submitFinalFtfSuccess$.pipe(
    ofType<CartAction>(CartActions.SUBMIT_FINAL_FTF_SUCCESS),
    switchMap(action => {

      const nextAction: CartAction = {
        type: CartActions.RESET_CART
      };

      return of(nextAction);
    })
  );

cartEffects.push(reload);
cartEffects.push(submitInitialLead);
cartEffects.push(getCis);
cartEffects.push(submitFinalFtf);
cartEffects.push(submitFinalFtfSuccess);

export default cartEffects;
