import startCase from 'lodash/startCase';

import { FilterLimits, PropertyInfo, TagUrlIdentifier } from '../constants';

import { DivCatUtils } from './div-cat-utils';

import {
  DivCat, Location, PropertyCategory, PropertyType, PropertyFilters
} from '../types';

/**
 * Utility for checking if a URL corresponds to the results page. Ignores
 * everything after the areas part, so query params can be anything.
 */
const RESULTS_PAGE_PATH_PATTERN = /^\/[a-z\-]+s\-for\-(rent|sale)(\/[a-z\-]+(\/[a-z\-]+)?)?/;
const BUILDING_PAGE_PATH_PATTERN = /\/(condominium|building)s\/[a-zA-Z0-9\-]+(\?.*)?/;

export class FilterUtils {
  /**
   * Creates a copy of filterParams that only includes ranges that are different
   * from the default state. For example, if only min_price has been changed
   * by the user, only the price min and max value range will be included in
   * the returned `PropertyFilters` object.
   *
   * This variant accepts the PropertyResultBrowserViewState, instead of the
   * complete PropertyResultBrowserState, and is not aware of buildings.
   * If a building_id filter is required, please use `getQueryParamsFromState`.
   *
   * @param view
   */
  // getQueryParamsFromViewState(view: PropertyResultBrowserViewState): PropertyFilters {
  //   const divCatUtils = new DivCatUtils();

  //   let fp = Object.assign({}, view.filterParams);
  //   const division = divCatUtils.getDivisionForType(view.type);
  //   const category = view.category;

  //   fp.sort_direction = view.sortDirection;

  //   if (view.sortBy == 'price') {
  //     if (division == 'commercial') {
  //       fp.sort = 'pricePerSqm';
  //     } else if (view.category == 'rent') {
  //       fp.sort = 'rentalPrice';
  //     } else {
  //       fp.sort = 'salePrice';
  //     }
  //   } else {
  //     fp.sort = view.sortBy;
  //   }

  //   return this.getCleanedFilterParams(fp, view.category, view.type);
  // }

  /**
   * Creates a copy of filterParams that only includes ranges that are different
   * from the default state. For example, if only min_price has been changed
   * by the user, only the price min and max value range will be included in
   * the returned `PropertyFilters` object.
   *
   * @param view
   */
  // getQueryParamsFromState(state: PropertyResultBrowserState) {
  //   const params = this.getQueryParamsFromViewState(state.view);

  //   if (state.building) {
  //     params.building_id = state.building.id;
  //   }

  //   return params;
  // }

  getCleanedPlaceName(placeName: string): string {
    placeName = decodeURIComponent(placeName);
    placeName = startCase(placeName);

    placeName = placeName.replace('Edsa', 'EDSA');
    placeName = placeName.replace('Mrt', 'MRT');
    placeName = placeName.replace('Mc Kinley', 'McKinley');
    placeName = placeName.replace('Mckinley', 'McKinley');
    placeName = placeName.replace('Las Pinas', 'Las Piñas');
    placeName = placeName.replace('Dasmarinas', 'Dasmariñas');
    placeName = placeName.replace(' De ', ' de ');
    placeName = placeName.replace(' At ', ' at ');

    return placeName;
  }


  /**
   * Parses out location information from given results page URL paths. For
   * example:
   *
   * /condominiums-for-rent/makati/legaspi-village
   *
   * Should return:
   *
   * {
   *   city: 'Makati',
   *   area: 'Legaspi Village'
   * }
   *
   * @param path
   */
  getLocationFromResultsPagePath(path: string): Location {
    const location: Location = {};
    const isTag = path.match(TagUrlIdentifier.full);

    const isResultsPage = path.match(RESULTS_PAGE_PATH_PATTERN);
    const isBuildingPage = path.match(BUILDING_PAGE_PATH_PATTERN);

    if (!(isResultsPage || isBuildingPage)) {
      return null;
    }

    // Remove the query params, if any
    path = path.replace(/\?.*/, '');

    // The leading '/' causes the first element of the split-up string to be ''

    // Added building to handle new routes. Will not replace cityOrBuildingName variable name
    // as it is still applicable for cases where the route points to a building directly.
    // For SEO purposes (related to the robots.txt issue where queryParams are culled)
    // This will be addressed in the future as this task is focused on condos.
    // J. Jover - 11/06/2018
    let [blank, typeCat, cityOrBuildingName, area, building] =  path.split('/');

    if (!cityOrBuildingName) {
      // There is no location info attached
      return undefined;
    }

    cityOrBuildingName = this.getCleanedPlaceName(cityOrBuildingName);

    if (isResultsPage) {
      if (building) {
        building = this.getCleanedPlaceName(building);
        location.building = building;
        location.typeName = 'Building';

        location.name = location.label = location.canonicalName = building;

        area = this.getCleanedPlaceName(area);

      } else if (area) {
        area = this.getCleanedPlaceName(area);
        location.name = location.canonicalName = area;
        location.label = `${area}, ${cityOrBuildingName}`;
        location.typeName = 'Area';

      } else {
        location.name = location.label = location.canonicalName = cityOrBuildingName;
        location.typeName = 'City';
      }

      location.city = cityOrBuildingName;
      location.area = area;
    } else if (isBuildingPage) {
      location.name = location.label = location.canonicalName = cityOrBuildingName;
      location.typeName = 'Building';
    }

    if (isTag) {
      location.typeName = 'Tag';
      location.area = location.canonicalName; // the back-end sets area same as canonical
      location.city = undefined;
    }

    return location;
  };

  cleanupRangeFilterValues(filterParams: PropertyFilters, paramName: string, maxValueLimit: number) {
    const minParam = `min_${paramName}`;
    const maxParam = `max_${paramName}`;

    // Removed the setting of maxParam if only minParam is specified as it is no longer needed
    //
    // We also want to exclude unnecessary filters, keeping our result URLs
    // only as long as needed.

    if (filterParams[maxParam] == maxValueLimit && filterParams[minParam] == 0) {
      delete filterParams[maxParam];
      delete filterParams[minParam];
    }
  }

  /**
   * This is the OLD cleanup code. Currently unused. Cleanup is currently done
   * by the `ResultFilters` component.
   * 
   * @param filters 
   * @param category 
   * @param type 
   */
  getCleanedFilterParams(filters: PropertyFilters, category: PropertyCategory, type: PropertyType): PropertyFilters {
    const divCatUtils = new DivCatUtils();

    let fp = Object.assign({}, filters);
    const division = divCatUtils.getDivisionForType(type);
    const maxFilterPrice = FilterLimits.price[division][category];

    this.cleanupRangeFilterValues(fp, 'price', maxFilterPrice);
    this.cleanupRangeFilterValues(fp, 'floor_area', FilterLimits.area);
    this.cleanupRangeFilterValues(fp, 'lot_area', FilterLimits.area);
    this.cleanupRangeFilterValues(fp, 'bedrooms', FilterLimits.bedrooms);
    this.cleanupRangeFilterValues(fp, 'bathrooms', FilterLimits.bathrooms);
    this.cleanupRangeFilterValues(fp, 'parking', FilterLimits.parking);

    if (fp.page == 1) {
      delete fp.page;
    }

    if (!fp.furnished || fp.furnished == '*') {
      delete fp.furnished;
    }

    // Don't clutter up the URL
    if (fp.sort == PropertyInfo.sortFields.DATE_MODIFIED && fp.sort_direction == 'desc') {
      delete fp.sort_direction;
      delete fp.sort;
    }

    // Residential amenities
    if (!fp.pool) delete fp.pool;
    if (!fp.gym) delete fp.gym;
    if (!fp.garden) delete fp.garden;
    if (!fp.balcony) delete fp.balcony;
    if (!fp.maidsRoom) delete fp.maidsRoom;
    if (!fp.petFriendly) delete fp.petFriendly;

    // Commercial amenities
    if (!fp.peza) delete fp.peza;
    if (!fp.backupPower) delete fp.backupPower;
    if (!fp.operational247) delete fp.operational247;
    if (fp.condition === '*') delete fp.condition;

    return fp;
  }

  getFallbackResultsTitle(path: string, params: { [k: string]: string }): string {
    const dcUtils = new DivCatUtils();
    let divCat: DivCat = null;

    // Work on a copy so external data is not changed
    params = { ...params };

    if (params.typeCatSlug) {
      divCat = dcUtils.fromSlug(params.typeCatSlug);
    } else {
      divCat = dcUtils.fromBuildingPath(path, params);

      let bldgSlug = path.replace(/(\/.*\/)|\?.*/g, '');

      // To format our results page heading correctly, use the building name as
      // a stand-in for city name
      params.city = bldgSlug;
    }

    let placeSegment: string = '';
    let header: string;

    if (params.area && params.city) {
      placeSegment = `in ${this.getCleanedPlaceName(params.area)}, ${this.getCleanedPlaceName(params.city)}`;
    } else if (params.city) {
      let city = startCase(params.city);

      // Fix problematic tag names
      city = this.getCleanedPlaceName(city);

      placeSegment = `in ${city}`;
    }

    // header = `${divCat.type}s for ${startCase(divCat.category)} ${placeSegment}`
    header = `${this.getDivCatType(divCat.type)} for ${startCase(divCat.category)} ${placeSegment}`
    return header;
  }

  // Spiralytics SEO experiment
  getDivCatType(type: string): string {
    switch (type) {
      case 'Condominium':
        return 'Condominiums & Apartments';
      default:
        return `${type}s`;
    }
  }

  getFiltersAsQueryParams(
    filters: PropertyFilters, 
    category: PropertyCategory,
    type: PropertyType,
    preSale?: number
  ) {

    let fp: PropertyFilters =  this.getCleanedFilterParams(filters, category, type);
    let qpString: string = '?';
    let segment: string;
    let isFirstParam: boolean = true;

    if (preSale) {
      fp['preSale'] = preSale;
    }

    for (let key in fp) {
      const filter = fp[key];
      segment = '';

      if (filter) {
        if (isFirstParam) {
          segment = `${key}=${filter}`;
          isFirstParam = false;
        } else {
          segment = `&${key}=${filter}`;
        }

        qpString = qpString.concat(segment);
      }
    }

    return qpString;

  }

  getObjectAsQueryParams(obj: { [k: string]: any }) {
    // From Stack Overflow post:
    // https://stackoverflow.com/a/1714899
    let str = [];

    for (let p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }

    let queryString = str.join('&');
    queryString = queryString.replace(/%2C/g,",");

    return queryString;
  }

}
