import { RequestState, User, Contact } from "../../../../types";

const PREFIX = '[User]';

export const GET_CURRENT_USER = `${PREFIX} GET_CURRENT_USER`;
export const GET_CURRENT_USER_IN_PROGRESS = `${PREFIX} GET_CURRENT_USER_IN_PROGRESS`;
export const GET_CURRENT_USER_SUCCESS = `${PREFIX} GET_CURRENT_USER_SUCCESS`;
export const GET_CURRENT_USER_FAIL = `${PREFIX} GET_CURRENT_USER_FAIL`;

export const LOGIN = `${PREFIX} LOGIN`;
export const LOGIN_SUCCESS = `${PREFIX} LOGIN_SUCCESS`;
export const LOGIN_FAIL = `${PREFIX} LOGIN_FAIL`;
export const USER_REQUIRES_VALIDATION = `${PREFIX} USER_REQUIRES_VALIDATION`;

export const LOGOUT = `${PREFIX} LOGOUT`;
export const LOGOUT_SUCCESS = `${PREFIX} LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${PREFIX} LOGOUT_FAIL`;

export const FORGOT_PASSWORD = `${PREFIX} FORGOT_PASSWORD`;
export const FORGOT_PASSWORD_SUCCESS = `${PREFIX} FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAIL = `${PREFIX} FORGOT_PASSWORD_FAIL`;

export const RESET_PASSWORD = `${PREFIX} RESET`;
export const RESET_PASSWORD_SUCCESS = `${PREFIX} RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${PREFIX} RESET_PASSWORD_FAIL`;

export const SEND_CONTACT_US = `${PREFIX} SEND_CONTACT_US`;
export const SEND_CONTACT_US_SUCCESS = `${PREFIX} SEND_CONTACT_US_SUCCESS`;
export const SEND_CONTACT_US_FAIL = `${PREFIX} SEND_CONTACT_US_FAIL`;

export const UPDATE_USER_PROFILE = `${PREFIX} UPDATE_USER_PROFILE`;
export const UPDATE_USER_PROFILE_SUCCESS = `${PREFIX} UPDATE_USER_PROFILE_SUCCESS`;
export const UPDATE_USER_PROFILE_FAILURE = `${PREFIX} UPDATE_USER_PROFILE_FAILURE`;

export const CHANGE_PASSWORD = `${PREFIX} CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${PREFIX} CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAILURE = `${PREFIX} CHANGE_PASSWORD_FAILURE`;

export interface UserState {
  email?: string;
  password?: string;
  rememberMe?: boolean;
  loginState?: RequestState;
  currentUser?: User;
  getCurrentUserState?: RequestState;
  forgotPasswordState?: RequestState;
  resetPasswordState?: RequestState;
  resetKey?: string;
  resetCode?: string;

  requiresUserVerification?: boolean;

  updateProfileState?: RequestState;

  changePasswordState?: RequestState;
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  hasJustLoggedIn?: boolean;

  sendContactUsState?: RequestState;
  contactUsData?: Contact;
}

export interface UserAction {
  type: string,
  payload?: UserState
}