import { h, Component } from "preact";
import { openModal, ModalParams } from '../../../utils/hoppler-modal.service';
import { LoginModal } from "./login-modal.component";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

import cx from 'classnames';

import getStore, { appState$ } from '../../store';
import { UserAction, GET_CURRENT_USER, LOGOUT } from '../../reducers/user/user.actions';
import { UserUtils } from '../../../../core/user-utils';
import { UserGroup } from "../../../../constants/user-types";
import * as RequestStates from '../../../../types/request-state.model';

interface LoginMobileMenuButtonState {
  username?: string;
  isDropdownVisible?: boolean;
  isPartnerBroker?: boolean;
  isExternalSalesDirector?: boolean;
  isConsumer?: boolean;
};

'hi-login-mobile-menu-button';
export class LoginMobileMenuButton extends Component<any, LoginMobileMenuButtonState> {
  subscriptions: Subscription[] = [];
  userUtils: UserUtils;

  node: HTMLElement;

  constructor(props) {
    super(props);

    this.userUtils = new UserUtils();
  }

  componentDidMount() {

    const store = getStore();

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subscriptions.push(
      user$.subscribe((user) => {

        if (user.currentUser) {

          if (this.userUtils.isBuyer(user.currentUser)) {
            this.setState({
              username: user.currentUser.fullName,
              isPartnerBroker: false,
              isConsumer: true
            });
          } else {
            this.setState({
              username: user.currentUser.fullName,
              isPartnerBroker: user.currentUser.roles.includes('ROLE_PARTNER_BROKER'),
              isExternalSalesDirector: user.currentUser.roles.includes('ROLE_EXTERNAL_SALES_DIRECTOR'),
              isConsumer: false
            });
          }

        } else {
          this.setState({
            username: undefined,
            isDropdownVisible: false
          });

          if (user.getCurrentUserState == RequestStates.REQUEST_UNTOUCHED) {
            store.dispatch({
              type: GET_CURRENT_USER
            } as UserAction);
          }
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  onClick = (e) => {
    e.preventDefault();

    if (!this.state.username) {
      const params: ModalParams = {
        closeOnOutsideClick: true
      };
  
      let promise = openModal(LoginModal, params);
      promise.catch((errorResult) => {
        //do nothing
      });
    } else {

      this.setState({
        isDropdownVisible: !this.state.isDropdownVisible
      });
    }
    
  }

  onLogout = (e) => {
    const store = getStore();

    store.dispatch({
      type: LOGOUT
    } as UserAction);
  }

  getKumitaLinks = (state: LoginMobileMenuButtonState) => {
    if (state.isConsumer) {
      return;
    }

    if (state.isExternalSalesDirector || state.isPartnerBroker) {
      return <a href="/kumita-brokers" class="nav-dropdown__user-action pl-4">Kumita by Hoppler</a>;

    } else {
      return <a href="/kumita-brokers/listings" class="nav-dropdown__user-action pl-4">My Listings</a>;
    }
  }

  render(props, state: LoginMobileMenuButtonState) {
    let displayedEl;
    let dropdown;

    if (state.username) {
      displayedEl = (
        <a href="" class="btn"
          onClick={this.onClick}>
          { state.username } 
          <i class="fa fa-angle-down pl-1"></i>
        </a>
      );
    } else {
      displayedEl = (
        <a href="/login" class="btn">
          Log-in
        </a>
      );
    }

    dropdown = (
      <div className={ cx('collapse navbar-collapse', {'show': state.isDropdownVisible}) }
          ref={node => this.node = node}>
        { this.getKumitaLinks(state) }
        <a href="/profile" class="nav-dropdown__user-action pl-4">Profile</a>
        <a href="/change-password" class="nav-dropdown__user-action pl-4">Change Password</a>
        <a href="" class="nav-dropdown__user-action pl-4"
          onClick={this.onLogout}>
          Logout
          </a>
      </div>
    );
    

    return(
      <div class="hi-login-mobile-menu-button">
        { displayedEl }

        { dropdown }
      </div>      
    );
  }
}