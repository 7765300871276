import Cookies from 'universal-cookie';
import { PlatformUtility } from '../utils/platform-utility';

import Bowser from 'bowser';

// Idea taken from https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie

const USER_AGENT_COOKIE = 'hiUserAgentNotification';

function isOutdatedBrowser(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.satisfies({
    'internet explorer': '<12', // All IE -- there is not IE 12
    'chrome': '<41',
    'firefox': '<60.8',
    'safari': '<11',
    'edge': '<17'
  });
}

// We do this manually instead of using preact in case the browser
// cannot use/support preact
function showNotification() {

  var notifContainer = document.getElementById('hiUserAgentNotification');
  notifContainer.classList.add('visible');

  var notifContent = document.createElement('div');
  notifContent.className = 'hi-user-agent-notification__content';

  notifContent.innerHTML = `
    <h2 class="hi-user-agent-notification__heading">Your Web browser is out of date.</h2>
    <span class="hi-user-agent-notification__span">
      You may experience compatibility issues.
    </span>
    <p class="hi-user-agent-notification__p">
      For the best Hoppler experience,
      <a href="http://www.outdatedbrowser.com" class="hi-user-agent-notification__a"> update your browser</a>
    </p>
    `;

  var notifBtn = document.createElement('button');
  notifBtn.className = 'btn btn-secondary hi-user-agent-notification__btn';
  notifBtn.id = 'hiUserAgentNotificationBtn';
  notifBtn.innerText = 'Got it';

  notifBtn.onclick = ev => {
    removeNotification();
    setNotificationStatus();
  }

  var notifClose = document.createElement('button');
  notifClose.className = 'close hi-user-agent-notification__close';
  notifClose.attributes['aria-label'] = 'Close'
  notifClose.innerHTML = `
    <i class="fas fa-times"></i>
  `;

  notifClose.onclick = ev => {
    removeNotification();
    setNotificationStatus();
  }

  notifContent.appendChild(notifClose);
  notifContent.appendChild(notifBtn);
  notifContainer.appendChild(notifContent);
}

function compareDatesInDays(date1: Date, date2: Date): number {
  const time1 = date1.getTime();
  const time2 = date2.getTime();

  const toDateConstant = (24 * 3600 * 1000);

  return Math.floor((time2 - time1) / toDateConstant);
}

function readCookie() {
  if (!PlatformUtility.isOnClient()) {
    return;
  }

  const cookies = new Cookies();
  return cookies.get(USER_AGENT_COOKIE);

}

function setNotificationStatus() {
  if (!PlatformUtility.isOnClient()) {
    return;
  }

  const dateOfSetting = new Date();

  const cookies = new Cookies();
  cookies.set(
    USER_AGENT_COOKIE,
    dateOfSetting.toDateString(),
    {
      path: '/'
    }
  );

}

function removeNotification() {
  var notifContainer = document.getElementById('hiUserAgentNotification');
  document.body.removeChild(notifContainer);
}

function checkDateElapsed(): boolean {
  const cookieValue = readCookie();

  if (!cookieValue) {
    return true;
  }

  const dateDifference = compareDatesInDays(
    new Date(cookieValue),
    new Date()
  );

  if (dateDifference > 0) {
    return true;
  }
  return false;
}

export default function handleUserAgent() {
  const isOutdated = isOutdatedBrowser();



  if (isOutdated) {
    if (checkDateElapsed()) {
      showNotification();
    } else {
      removeNotification();
    }
  } else {
    removeNotification();
  }

  return;
}

handleUserAgent();