import { DivCat, PropertyFilters, Location } from '../types';

import { DivCatUtils } from './div-cat-utils';
import { FilterUtils } from './filter-utils';

import kebabCase from 'lodash.kebabcase';

/* 

  This is not similar to an SPA's router. All this utility
  class does is to simply help construct URL's for either an
  href OR to be used in a window.location change

// */

class URLRouter {
  private divCatUtils: DivCatUtils;
  private filterUtils: FilterUtils;

  constructor() {
    if (!this.divCatUtils) {
      this.divCatUtils = new DivCatUtils();
    }

    if (!this.filterUtils) {
      this.filterUtils = new FilterUtils();
    }
  }

  buildResultsURL(divCat: DivCat, filters?: PropertyFilters, location?: Location) {
    let urlString: string = '';
    
    const divCatSlug: string = this.divCatUtils.toSlug(divCat);
    urlString = urlString.concat(divCatSlug);
    console.log('er', divCatSlug);

    // it's possible to not always have a location
    if (location) {
      let locationSlug: string = '';

      if (location.city) {
        let city: string = this.divCatUtils.cleanStringForRouter(location.city);
        city = kebabCase(city);

        locationSlug = locationSlug.concat(`/${city}`);

        if (location.area) {
          let area: string = this.divCatUtils.cleanStringForRouter(location.area);
          area = kebabCase(area);

          locationSlug = locationSlug.concat(`/${area}`);

          if (location.building) {
            let building: string = this.divCatUtils.cleanStringForRouter(location.building);
            building = kebabCase(building);

            locationSlug = locationSlug.concat(`/${building}`);
          }
        }
      }

      urlString = urlString.concat(locationSlug);
    }

    if (filters) {
      let filterSlug: string = this.filterUtils
        .getFiltersAsQueryParams(filters, divCat.category, divCat.type);

      urlString = urlString.concat(filterSlug);
    }

    return urlString;
  }

  navigateToURL(url: string) {
    location.href = url;
  }
}

let router: URLRouter = new URLRouter();

export default router;
