import { Component, h } from 'preact';
import getStore, { appState$ } from '../../store';
import { ADD_TO_CART, CartAction, REMOVE_FROM_CART } from '../../reducers/cart/cart.actions';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { DivCat, Location } from '../../../../types';
import { dataLayer } from '../../../helpers/data-layer-util';
import URLRouter from '../../../../core/url-router';
import { readSharedJSON } from '../../../../utils/shared-data';
import cx from 'classnames';

interface PropertyPageVlButtonProps {
  propertykey: string;
  isblocked: string;
}

interface PropertyPageVlButtonState {
  isInCart: boolean;
}

'hi-property-page-vl-button';
export class PropertyPageVlButton extends Component<PropertyPageVlButtonProps, PropertyPageVlButtonState> {
  subscriptions: Subscription[] = [];
  props: PropertyPageVlButtonProps;

  divCat: DivCat;
  location: Location;

  loadSharedData() {
    this.divCat = readSharedJSON<DivCat>('hiListingDivCat');
    this.location = readSharedJSON<Location>('hiListingLocation');
  }  

  onAddButtonClick(propertyKey: string) {
    const store = getStore();

    dataLayer.push({
      'property id': propertyKey,
      'property type': this.divCat.type,
      'property category': this.divCat.category,
      'property city': this.location.city,
      'property area': this.location.area,
      'event': 'Add to Inquiry List - Click'
    });

    store.dispatch({
      type: ADD_TO_CART,
      payload: {
        propertyKey: propertyKey
      }
    } as CartAction);
  }

  onInquireNowClick(propertyKey: string) {
    dataLayer.push({
      'property id': propertyKey,
      'property type': this.divCat.type,
      'property category': this.divCat.category,
      'property city': this.location.city,
      'property area': this.location.area,
      'event': 'Inquire Now - Click'
    });

    // Allow GA some time to send the event
    setTimeout(
      () => URLRouter.navigateToURL('/viewing-list'),
      250
    );
  }

  constructor(props: PropertyPageVlButtonProps) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.loadSharedData();

    const isInCart$ = appState$.pipe(
      map(appState => appState.cart),
      filter(cart => !!cart.propertyIds),
      map(cart => cart.propertyIds.includes(this.props.propertykey))
    );

    this.subscriptions.push(
      isInCart$.subscribe(isInCart => {
        this.setState({ isInCart });
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    this.subscriptions = [];
  }

  render(props: any, state: any): JSX.Element {
    if (!state.isInCart) {
      return (
        <button
          type="button" 
          onClick={() => this.onAddButtonClick(props.propertykey)}
          class={ cx('btn btn-secondary', {
              'btn-block': this.props.isblocked == 'true'
          }) }>
          Add to viewing list
        </button>
      )
    } else {
      return (
        <label
          type="button"
          onClick={() => this.onInquireNowClick(props.propertykey)}
          class={ cx('btn btn-outline-secondary', {
            'btn-block': this.props.isblocked == 'true'
          }) }>
          Inquire now
        </label>
      )
    }
  }
}
