import { CustomerInformation, RequestState, CartInquiry } from "../../../../types";

const PREFIX = '[Cart]';

export const ADD_TO_CART = `${PREFIX} ADD_TO_CART`;
export const REMOVE_FROM_CART = `${PREFIX} REMOVE_FROM_CART`;
export const INIT = `${PREFIX} INIT`;
export const RELOAD = `${PREFIX} RELOAD`;
export const RESET_CART = `${PREFIX} RESET_CART`;
export const COOKIES_UPDATED = `${PREFIX} COOKIES_UPDATED`;
export const LOAD_FROM_COOKIES = `${PREFIX} LOAD_FROM_COOKIES`;
export const LOAD_FROM_EXISTING_FTF = `${PREFIX} LOAD_FROM_EXISTING_FTF`;

export const SUBMIT_INITIAL_LEAD = `${PREFIX} SUBMIT_INITIAL_LEAD`;
export const SUBMIT_INITIAL_LEAD_SUCCESS = `${PREFIX} SUBMIT_INITIAL_LEAD_SUCCESS`;
export const SUBMIT_INITIAL_LEAD_FAIL = `${PREFIX} SUBMIT_INITIAL_LEAD_FAIL`;

export const GET_CIS = `${PREFIX} GET_CIS`;

export const SUBMIT_FINAL_FTF = `${PREFIX} SUBMIT_FINAL_FTF`;
export const SUBMIT_FINAL_FTF_SUCCESS = `${PREFIX} SUBMIT_FINAL_FTF_SUCCESS`;
export const SUBMIT_FINAL_FTF_FAIL = `${PREFIX} SUBMIT_FINAL_FTF_FAIL`;


export interface CartState {
  propertyKey?: string,
  propertyIds?: string[],

  cart?: CartInquiry;

  cis?: CustomerInformation,
  cisSubmitState?: RequestState,
  initialLeadSubmitState?: RequestState,
  inquiryKey?: string,

  generalInquiryLead?: boolean
}

export interface CartAction {
  type: string,
  payload?: CartState,
  bulkPayload?: string[]
}

export const DEFAULT_STATE: CartState = {
  propertyIds: []
};