
export type RequestState = 'IN_PROGRESS' |
  'SUCCESS' |
  'FAILED' |
  'UNTOUCHED';

export const REQUEST_IN_PROGRESS: RequestState = 'IN_PROGRESS';
export const REQUEST_SUCCESS: RequestState = 'SUCCESS';
export const REQUEST_FAILED: RequestState = 'FAILED';
export const REQUEST_UNTOUCHED: RequestState = 'UNTOUCHED';

export enum ReqState {
  'IN_PROGRESS', 
  'SUCCESS',
  'FAILED', 
  'UNTOUCHED'
}
