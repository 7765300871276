
type EncodingOption = 'binary' | 'utf-8';

// From https://git.coolaj86.com/coolaj86/btoa.js
// With changes making it incompatible with the browser-native btoa,
// necessitating a name change.
export function toBase64(str: string | Buffer, encoding?: EncodingOption) {
  if (!encoding) {
    encoding = 'binary';
  }

  var buffer;

  str = str || '';
  if (str instanceof Buffer) {
    buffer = str;
  } else {
    buffer = Buffer.from(str.toString(), encoding);
  }

  return buffer.toString('base64');
}

export function fromBase64(a: string, encoding?: EncodingOption) {
  if (!encoding) {
    encoding = 'binary';
  }

  return new Buffer(a, 'base64').toString(encoding);
};
