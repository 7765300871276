import { h, Component } from 'preact';
import moment, { Moment } from 'moment-timezone';
import cx from 'classnames';

import { Formats } from '../../../../constants/api';

export interface HopplerDatePickerProps {
  onDateSelected: Function;

  placeholder?: string;

  isValid?: boolean; // used as a prop so it can use external validation
  isPristine?: boolean;

  disabled?: boolean;

  // We use string over Date or Moment type so it can be used like it were from pug
  // of course can't be used on a pug level since it has a default required prop func
  min?: string; 
  initialValue?: string;

  includePastCentury?: boolean;
}

export interface HopplerDatePickerState {
  isCalendarVisible?: boolean;
  isCalendarAbove?: boolean;
  isCalendarBelow?: boolean;

  minDate?: Moment;

  selectedDate?: string;

  currentCalendar?: number[] // not sure if we should use string or Moment
  now: Moment;
  currentMonth?: Moment;
  startOfCurrentMonth?: Moment;
  endOfCurrentMonth?: Moment;

  currentYear?: number;

  nextMonth?: Moment;
  previousMonth?: Moment;
}



'hi-date-picker';
export class HopplerDatePicker extends Component<HopplerDatePickerProps, HopplerDatePickerState> {
  node: HTMLElement;
  
  constructor(props: HopplerDatePickerProps) {
    super(props);

    this.setState({
      now: moment()
    });

    if (props.min) {
      this.setState({
        minDate: moment(props.min, Formats.date)
      });
    }
    
    if (props.initialValue) {
      this.setState({
        // selectedDate: moment(props.initialValue).format(Formats.date)
        selectedDate: moment(props.initialValue, Formats.date).format(Formats.date)
      })
    }

    this.setupCalendarData();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.checkClick, false);
  }

  componentWillUnmount() {

    document.removeEventListener('mousedown', this.checkClick, false)
  }

  checkClick = (event) => {
    if (!this.node.contains(event.target)) {
      this.setState({
        isCalendarVisible: false
      });
    }
  }

  clearCalendar() {
    // remove active class from all elements
    const dateElements = document.querySelectorAll('.hi-date-picker__calendar__date');
    dateElements.forEach(el => el.classList.remove('active'));

    this.setState({
      selectedDate: undefined,
      isCalendarVisible: false
    });

    if (this.props.onDateSelected) {
      this.props.onDateSelected(undefined);
    }
  }

  onInputFocus = (e) => {

    if (this.props.disabled) {
      return;
    }

    this.setState({
      isCalendarVisible: true
    });
  }

  // This method runs everytime a user types something 
  // on the date picker input. We only call state change
  // when the e.target.value's length is 0
  onInputChange = (e) => {
    const inputValue: string = e.target.value;
    if (inputValue.length == 0) {
      this.clearCalendar();
    }
  }

  onNextMonthClicked = (e) => {
    e.preventDefault();

    const nextMonth = moment(this.state.currentMonth.add(1, 'months'));
    this.setupCalendarData(nextMonth);
  }

  onPreviousMonthClicked = (e) => {
    e.preventDefault();

    const previousMonth = moment(this.state.currentMonth.subtract(1, 'months'));
    this.setupCalendarData(previousMonth);
  }

  onYearChange = (e) => {
    const year = e.target.value;
    let yearChanged = moment(this.state.currentMonth);
    yearChanged.year(year);
    this.setupCalendarData(yearChanged);
  }

  // for now, we will restrict the user to the only way 
  // to change dates is via the picker. Any typed
  // input will be ignored as we'll have to handle
  // some string -> moment parsing issues
  // onInputValueChange = (e) => {
  // }

  onDateClicked = (day: number, e: MouseEvent) => {
    // remove active class from all elements
    const dateElements = document.querySelectorAll('.hi-date-picker__calendar__date');
    dateElements.forEach(el => el.classList.remove('active'));

    // set target element to have active
    let target: HTMLElement = e.target as HTMLElement;
    target.classList.add('active');

    const referenceDate = moment(this.state.currentMonth);
    const selectedDate = referenceDate.date(day).format(Formats.date);

    this.setState({
      selectedDate: selectedDate,
      isCalendarVisible: false
    });

    if (this.props.onDateSelected) {
      this.props.onDateSelected(selectedDate);
    }
    
  }

  setupCalendarData(dateArg?: Moment) {
    let calendar: number[] = [];
    let referenceMoment: Moment;

    // we use moment to create references because should the dateArg
    // be altered via add, subtract, startOf, etc, it alters
    // the original
    if (dateArg) {
      referenceMoment = moment(dateArg);
    } else {
      referenceMoment = moment(this.state.now);
    }

    const startOfMonth = moment(referenceMoment).startOf('month');
    const endOfMonth = moment(referenceMoment).endOf('month');
    const nextMonth = moment(referenceMoment).add(1, 'months');
    const previousMonth = moment(referenceMoment).subtract(1, 'months');

    for (let day = 1; day <= endOfMonth.date(); day++) {
      calendar.push(day);
    }

    this.setState({
      currentCalendar: calendar,
      startOfCurrentMonth: startOfMonth,
      endOfCurrentMonth: endOfMonth,
      currentMonth: referenceMoment,
      nextMonth: nextMonth,
      previousMonth: previousMonth,
      currentYear: referenceMoment.year()
    });
  }

  getCurrentMonthBlock(props: HopplerDatePickerProps,state: HopplerDatePickerState) {
    // if currentMonth is same as minDate month, that's when you do the disabled dates
    // so we don't have to keep checking on a per date basis.

    const isMinDateSameMonth = state.currentMonth.isSameOrBefore(state.minDate, 'month');
    const isCurMonthBeforeMin = state.currentMonth.isBefore(state.minDate, 'month');

    let initialValueMoment;
    let initialValueDate;

    if (props.initialValue) {
      initialValueMoment = moment(this.props.initialValue, Formats.dateTime);
      initialValueDate = initialValueMoment.date();
    }

    if (state.minDate && isMinDateSameMonth) {
      const minDay = state.minDate.date();

      if (isCurMonthBeforeMin) {
        // auto disabled since it's before the min
        return (
          state.currentCalendar.map(date => {
            return(
              <div class="hi-date-picker__calendar__date disabled">
                { date }
              </div>
            )
          })
        );
      } else {

        return (
          state.currentCalendar.map(date => {
            return (
              <div className={ cx(
                "hi-date-picker__calendar__date",
                {
                  'disabled': date < minDay,
                  'active': initialValueDate == date
                }) }
                value={ date }
                onClick={this.onDateClicked.bind(this, date)}>
                { date }
              </div>
            )
          })
        );
      }

    } else {

      return (
        state.currentCalendar.map(date => {
          return (
            <div
              className={ cx('hi-date-picker__calendar__date',
              {
                'active': initialValueDate == date
              })}
              value={ date }
              onClick={this.onDateClicked.bind(this, date)}>
              { date }
            </div>
          )
        })
      );
    }

  }

  // a calendar has 7 columns, for the 7 days of the week
  // Sun, Mon -> Sat

  getCalendarBlock(props: HopplerDatePickerProps, state: HopplerDatePickerState) {

    const startOfMonthDay: number = state.startOfCurrentMonth.day();
    const endOfPreviousMonthDay: number = moment(state.previousMonth).endOf('month').date();

    const daysBeforeStartOfMonth: number[] = [];
    // we start on 0 in order to push the day alignment by 1,
    // so that the sundays are on the left side of the calendar
    for (let i = 0; i < startOfMonthDay; i++) {
      daysBeforeStartOfMonth.push(endOfPreviousMonthDay - ( 5 - i));
    }

    const endOfMonthDay: number = state.endOfCurrentMonth.day();
    const daysAfterEndOfMonth: number[] = [];
    for (let i = 1; i <= 6 - endOfMonthDay; i++) {
      daysAfterEndOfMonth.push(i);
    }

    return(
      <div class="hi-date-picker__calendar">
        {
          // prevMonth
          daysBeforeStartOfMonth.map(day => 
            <div class="hi-date-picker__calendar__date previous">
              { day }
            </div>
          )
        }

        {
          this.getCurrentMonthBlock(props, state)
        }

        {
          // nextMonth
          daysAfterEndOfMonth.map(day => 
            <div class="hi-date-picker__calendar__date previous">
              { day }
            </div>
          )
        }

      </div>
    ); 

  }

  render(props: HopplerDatePickerProps, state: HopplerDatePickerState) {
    const erp: number[] = [];
    for (let i = 2020; i <= 2030; i++) {
      erp.push(i);
    }

    const currentyear = state.currentMonth.year();
    let pastThreeYears: number[] = [];
    let nextThreeYears: number[] = [];
    const maxYearCount = 3;

    let i = 0;

    if (props.includePastCentury) {
      for (; i < 100; i++) {
        pastThreeYears.push(currentyear - 100 + i);
      }
    } else {
      for (i = 0; i < maxYearCount; i++) {
        pastThreeYears.push(currentyear - (maxYearCount - i));
        nextThreeYears.push(currentyear + (i + 1));
      }
    }

    return (
      <div class="hi-date-picker" ref={node => this.node = node}>
        <div
          className={ cx(
            'hi-date-picker__calendar-container',
            {
              'active': state.isCalendarVisible
            }
          ) }>

          <div class="hi-date-picker__calendar-container__control-row">
            <div class="hi-date-picker__calendar-container__control-row__current-date">
              <span class="hi-date-picker__calendar-container__control-row__current-date__month">
                { state.currentMonth.format('MMMM') }
              </span>
              <span class="hi-date-picker__calendar-container__control-row__current-date__year">
                <select class="hi-date-picker__calendar-container__control-row__current-date__year-selector"
                  onChange={this.onYearChange}
                  value={state.currentYear}>
                  {
                    pastThreeYears.map(year => {
                      return (
                        <option value={year}>
                          {year}
                        </option>
                      )
                    })
                  }
                  <option selected value={ state.currentMonth.year() }>
                    { state.currentMonth.year() }
                  </option>
                  {
                    nextThreeYears.map(year => {
                      return (
                        <option value={year}>
                          {year}
                        </option>
                      )
                    })
                  }
                </select>
                
                
              </span>
            </div>
            <div class="hi-date-picker__calendar-container__control-row__controls">
              <button type="button" class="btn" onClick={ this.onPreviousMonthClicked }>
                <i class="fas fa-chevron-left"></i>
              </button>

              <button type="button" class="btn ml-1" onClick={ this.onNextMonthClicked }>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
          
          <div class="hi-date-picker__calendar-headers">
            <div class="hi-date-picker__calendar__date header">
              Sun
            </div>
            <div class="hi-date-picker__calendar__date header">
              Mon
            </div>
            <div class="hi-date-picker__calendar__date header">
              Tue
            </div>
            <div class="hi-date-picker__calendar__date header">
              Wed
            </div>
            <div class="hi-date-picker__calendar__date header">
              Thu
            </div>
            <div class="hi-date-picker__calendar__date header">
              Fri
            </div>
            <div class="hi-date-picker__calendar__date header">
              Sat
            </div>
          </div>
          { this.getCalendarBlock(props, state) }

        </div>

        <div class="hi-date-picker__input-container" 
          onClick={this.onInputFocus}>
          <input type="text" 
            disabled={ props.disabled }
            className={ cx(
              'hi-date-picker__input-container__input',
              'form-control',
              {
                invalid: !props.isValid && !props.isPristine
              }
            )}
            placeholder={ props.placeholder || 'Select a date' }
            value={ state.selectedDate }
            onFocus={this.onInputFocus}
            onInput={this.onInputChange}/>

          <span class="hi-date-picker__input-container__icon">
            <i class="fas fa-calendar-alt"></i>
          </span>
        </div>
      </div>
    );
  }
}