const BASE_URL: string = 'https://api.hoppler.com.ph';

const NO_PHOTO_IMAGE_PATH = 'noPhoto02.png';

export const HOPPLER_TIMEZONE = 'Asia/Manila';

/*
  IMGIX REPLACEMENT CLOUDFRONT HOS

  Development: d1mpjhw26v8ovw.cloudfront.net
   - Used for dev work on Imgix replacement itself

  Production: d2wy52y0hrt3v.cloudfront.net
*/

const HOPPIMG_HOST = process.env.HOPPIMG_HOST || 'd2wy52y0hrt3v.cloudfront.net';

// Use only for static images.
// Never use this for listing images, unless you have a good reason to.
export const ORIGINAL_IMAGE_HOST = 'dzjqf1alh39sw.cloudfront.net';

export const hopplerApi = {
  host: BASE_URL,
  system: `${BASE_URL}/system`,
  images: 'https://hoppler-inc.imgix.net',
  hoppimg: `https://${HOPPIMG_HOST}`,

  noPhotoImagePath: NO_PHOTO_IMAGE_PATH,
  noPhoto: `//hoppler-inc.imgix.net/${NO_PHOTO_IMAGE_PATH}`,
  neighborhoodRadius: 2000
};

export const Formats = {
  dateTime: 'YYYY/MM/DD HH:mm:ss',
  date: 'YYYY/MM/DD'
}

export const TagUrlIdentifier = {
  full: 'nhlc=505b',
  key: 'nhlc',
  value: '505b',
};
