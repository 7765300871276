import { Listing, PropertyMapMarker, MapSettings } from '../../types';
import { DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE, DEFAULT_MAP_ZOOM } from '../preact/constants/maps';


import sortBy from 'lodash.sortby';
import property from 'lodash.property';
import each from 'lodash.foreach';

// this class' functions were transferred from Hopia's locations-landing-utility service
export class ListingMarkerUtils {
  constructor() {

  }

  doCoordinatesMatch(prop1: Listing, prop2: Listing) {
    return prop1.latitude == prop2.latitude &&
      prop1.longitude == prop2.longitude;
  }

  prepareMapMarkers(listings: Listing[]): PropertyMapMarker[] {
    if (!listings) return;

    let mapMarkers: PropertyMapMarker[] = [];

    let previousListing: Listing = null;
    let marker: PropertyMapMarker = null;

    let sortedProperties = sortBy(listings, property('longitude')) as Listing[];
    sortedProperties = sortBy(sortedProperties, property('latitude')) as Listing[];

    each(sortedProperties, (listing: Listing, index: number) => {
      if (listing.latitude == 0 || listing.longitude == 0) {
        return;
      }

      if (!previousListing) {
        // First run of the loop
        previousListing = listing;
        marker = new PropertyMapMarker(listing);
        marker.zIndex = index;
            
        mapMarkers.push(marker);
      } else if (this.doCoordinatesMatch(previousListing, listing)) {
        // Property is in same building as previous run's property
        marker.addProperty(listing);
        marker.zIndex = index;
      } else {
        // This property has different coords from the last one - new marker
        marker = new PropertyMapMarker(listing);
        mapMarkers.push(marker);
        previousListing = listing;
        marker.zIndex = index;
      }
    });

    return mapMarkers;
  }

  determineMapSettings(markers: PropertyMapMarker[], isAreaFocused?: boolean): MapSettings {
    let settings: MapSettings = {};
    const numProperties = markers.length;

    const latitudeSum: number = markers.map(marker => marker.latitude)
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    const longitudeSum: number = markers.map(marker => marker.longitude)
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    if (numProperties > 0) {
      settings.centerLat = latitudeSum / numProperties;
      settings.centerLong = longitudeSum / numProperties;

      if (isAreaFocused) {
        settings.zoom = 16;
      } else {
        settings.zoom = 15;
      }

    } else {
      settings.centerLat = DEFAULT_MAP_LATITUDE;
      settings.centerLong = DEFAULT_MAP_LONGITUDE;
      settings.zoom = DEFAULT_MAP_ZOOM;
    }

    return settings;
  }

}