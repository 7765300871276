import $ from 'jquery';

export default function ToggleUtil() {
  const elements = document.querySelectorAll('[data-hi-toggle]');

  elements.forEach((element: HTMLElement) => {
    element.onclick = (evt) => {
      const id = element.getAttribute('data-hi-toggle');
      const targetElement = document.getElementById(id);

      if (targetElement.className.indexOf('d-none') >= 0) {
        element.className += ' opened';
        targetElement.className = targetElement.className.replace('d-none', '');
      } else {
        element.className = element.className.replace('opened', '');
        targetElement.className += ' d-none';
      }
    }
  });
}

ToggleUtil();


function HiClassToggleUtil() {
  const elements = document.querySelectorAll('[data-hi-class-toggle]')

  elements.forEach((element: HTMLElement) => {
    element.onclick = evt => {
      const id = element.getAttribute('data-target');
      const className = element.getAttribute('data-hi-class-toggle');
      const targetElement = document.getElementById(id);

      if (targetElement.classList.contains(className)) {
        targetElement.classList.remove(className);
        element.classList.remove('active');
      } else {
        targetElement.classList.add(className);
        element.classList.add('active');
      }
    }
  });
}

HiClassToggleUtil();

// ---- Jquery powered functions below ----

$('#readMoreBtn').click((ev) => {

  const articleContent = $('#articleContent');
  articleContent.removeClass('covered');
  $('#readMoreBtn').parent().hide();
});



$('.nav-dropdown').click((ev) => {
  let catContainer = $(ev.currentTarget).children('.nav-dropdown__category-container');
  
  catContainer.addClass('visible');
});

document.addEventListener(
  'mousedown',
  (ev) => {
   
    let sale = document.getElementById('navDropSale');
    let rent = document.getElementById('navDropRent');

    // Don't cause random JS errors on click
    if (!sale || !rent) {
      return;
    }

    if (!sale.contains(ev.target as HTMLElement)) {
      $(sale).children('.nav-dropdown__category-container').removeClass('visible');
    }
    
    if (!rent.contains(ev.target as HTMLElement)) {
      $(rent).children('.nav-dropdown__category-container').removeClass('visible');
    }

  },
  false
);