/** @jsx h */
import { h, Component } from "preact";
import cx from 'classnames';
import { Subject, Subscription } from "rxjs";
import { map } from 'rxjs/operators';

import hopplerLinkState from "../../../utils/forms/hoppler-linkstate";
import { validateForm, cxValidateField } from "../../../utils/forms/validators";
import { Form, Field, FieldList } from '../../../utils/forms/form';
import { appState$ } from '../../store';
import * as RequestStates from '../../../../types/request-state.model';
import URLRouter from '../../../../core/url-router';
import getStore from "../../store";
import * as UserActions from '../../reducers/user/user.actions';
import { UserAction, UserState } from '../../reducers/user/user.actions';


export interface Props {
  resetkey: string;
  resetcode: string;
}

interface State {
  passwordForm: Form;
  buttonDisabled: boolean;
  requestInProgress: boolean;
}

'hi-resetpassword-form';
export class ResetPasswordForm extends Component<Props, State> {
  subject$: Subject<Form>;
  private subscriptions: Subscription[] = [];

  constructor(props: Props) {
    super(props);
    let fields: FieldList = {
      'password': new Field(['required'], [], ''),
      'repeat_password': new Field(['required'], [], '')
    };
    this.subject$ = new Subject<Form>();
    this.setState({
      passwordForm: new Form(fields),
      buttonDisabled: true,
      requestInProgress: false
    });
    this.subject$.subscribe((form) => {
      validateForm(form);
      let state = {};
      state['passwordForm'] = form;
      this.setState(state);
    });
  }

  componentDidMount() {
    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subscriptions.push(
      user$.subscribe((user) => {

        if (user.resetPasswordState == RequestStates.REQUEST_IN_PROGRESS) {
          this.setState({
            requestInProgress: true
          });
        } else {
          this.setState({
            requestInProgress: false
          });
          if (user.resetPasswordState == RequestStates.REQUEST_SUCCESS) {
            URLRouter.navigateToURL('/reset-password-success');
          }
        }
      })
    );

  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  onButtonClicked = (e) => {
    e.preventDefault();
    const store = getStore();
    const payload: UserState = {
      newPassword: this.state.passwordForm.fields['password'].value,
      resetKey: this.props.resetkey,
      resetCode: this.props.resetcode
    };
    store.dispatch({
      type: UserActions.RESET_PASSWORD,
      payload: payload
    } as UserAction);

  }

  doPasswordsMatch(form: Form) {
    const password = form.fields.password.value;
    const confirmPassword = form.fields.repeat_password.value;
    return password === confirmPassword;
  }

  isFormValid(form: Form) {
    if (!form) return false;

    const completed = form.isValid;
    const passwordsMatch = this.doPasswordsMatch(form);

    return completed && passwordsMatch;
  }

  render(props: Props, state: State) {
    return(
      <div class="hi-login-form">
        <h2 class="my-3 hi-login-form__heading">Reset Password</h2>

        <div class="mt-3 pt-1">
          <input type="password"
            value={ state.passwordForm.fields['password'].value }
            className={cx('hi-text-input form-control', {
              'invalid' : cxValidateField(state.passwordForm.fields['password'])
            })}
            onChange={hopplerLinkState(this.subject$, state.passwordForm, 'password')}
            required={true}
            placeholder="New password"/>
        </div>

        <div class="mt-3 pt-1">
          <input type="password"
            value={ state.passwordForm.fields['repeat_password'].value }
            className={cx('hi-text-input form-control', {
              'invalid' : cxValidateField(state.passwordForm.fields['repeat_password'])
            })}
            onChange={hopplerLinkState(this.subject$, state.passwordForm, 'repeat_password')}
            required={true}
            placeholder="Repeat new password"/>
        </div>

        <button class="mt-3 mb-3 btn btn-secondary w-100"
          disabled={!this.isFormValid(state.passwordForm) || state.requestInProgress}
          type="submit"
          onClick={this.onButtonClicked}
        >
          Set new password
        </button>

      </div>
    );
  }
}
