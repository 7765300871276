import { h, Component } from 'preact';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import cx from 'classnames';

import * as Validators from '../../../utils/forms/validators';
import { Validator, cxValidateField } from '../../../utils/forms/validators';
import hopplerLinkState from '../../../utils/forms/hoppler-linkstate';
import { Form, FieldList, Field } from '../../../utils/forms/form';

import { subjectOptions } from '../../../../constants/contact-us-subjects';
import { Contact, RequestState } from '../../../../types';
import getStore, { appState$ } from '../../store';
import { UserAction, SEND_CONTACT_US } from '../../reducers/user/user.actions';
import * as RequestStates from '../../../../types/request-state.model';

import URLRouter from '../../../../core/url-router';
interface ContactUsFormProps {
  field?: string;
  usedin?: string;
}

interface ContactUsFormState {
  contactUsForm?: Form;
  isRequestInProgress?: boolean;
}

'hi-contact-us-form';
export class ContactUsForm extends Component<ContactUsFormProps, ContactUsFormState> {
  form$: Subject<Form>;
  private subscriptions: Subscription[] = [];
  
  constructor(props: ContactUsFormProps) {
    super(props);

    this.setupForm(props);
    this.setState({
      isRequestInProgress: false
    });
  }

  setupForm(props: ContactUsFormProps) {
    let fields: FieldList = {
      'subject': new Field(
        [ Validators.required ],
        null
      ),
      'firstName': new Field(
        [ Validators.required ],
        null
      ),
      'lastName': new Field(
        [ Validators.required ],
        null
      ),
      'mobileNumber': new Field(
        [ Validators.required ],
        null
      ),
      'emailAddress': new Field(
        [ Validators.required, Validators.email ],
        null
      ),
      'message': new Field(
        [ Validators.required ],
        null
      )
    };

    this.form$ = new Subject<Form>();
    this.setState({
      contactUsForm: new Form(fields)
    });

    this.subscriptions.push(
      this.form$.pipe(distinctUntilChanged())
        .subscribe(form => {

          Validators.validateForm(form);

          this.setState({
            contactUsForm: form
          });
        })
    );
  }

  componentDidMount() {
    const pathname = window.location.pathname
    const contactForm = this.state.contactUsForm
    contactForm.fields.subject.value = "buying-renting"

    if (pathname.replace("/", "") == "preselling-condos") {
      contactForm.fields.subject.value = "buying-property"
    }

    const user$ = appState$.pipe(
      map(state => state.user)
    );

    this.subscriptions.push(
      user$.pipe(
        map(user => user.sendContactUsState),
        distinctUntilChanged()
      ).subscribe(sendContactUsState => {

        if (sendContactUsState == RequestStates.REQUEST_IN_PROGRESS) {
          this.setState({
            isRequestInProgress: true
          });
        } else if (sendContactUsState == RequestStates.REQUEST_SUCCESS) {
          
          this.setState({
            isRequestInProgress: false
          })

          setTimeout(() => {
            URLRouter.navigateToURL('/contact-us-complete');
          }, 250)
        } else {
          this.setState({
            isRequestInProgress: false
          });
        }
      })
    );

  }

  onSubmit = (e) => {
    e.preventDefault();

    const form = this.state.contactUsForm;
    Validators.validateForm(form);

    this.setState({
      contactUsForm: form
    });

    if (!form.isValid) {
      return;
    }

    const fullName = `${form.fields['firstName'].value} ${form.fields['lastName'].value}`;

    const data: Contact = {
      name: fullName,
      email: form.fields['emailAddress'].value,
      mobileNumber: form.fields['mobileNumber'].value,
      message: form.fields['message'].value,
      purpose: form.fields['subject'].value,
    }

    const store = getStore();
    const action = {
      type: SEND_CONTACT_US,
      payload: {
        contactUsData: data
      }
    } as UserAction;

    store.dispatch(action);
  }

  getLoadingSpinnerBlock(state: ContactUsFormState) {
    if (state.isRequestInProgress) {
      return (
        <div class="">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  }

  getErrorBlock(state: ContactUsFormState, fieldName: string, message: string) {
    if (cxValidateField(state.contactUsForm.fields[fieldName])) {
      return (
        <div class="hi-error-help-text mt-1">
          <p className="hi-type-caption">
            <i class="fas fa-exclamation-circle"></i> { message }
          </p>
        </div>
      );
    }
  }

  render(props: ContactUsFormProps, state: ContactUsFormState) {
    let form = state.contactUsForm;
    const usedin = this.props.usedin

    return (
      <div class="hi-contact-us-form__container">
        <form class="">
          <div class="row">
            <div class="col-12">
              <label class="hi-input-label">I am interested in...</label>
              <select
                className={ cx('form-control', 'mt-1',
                  { 'invalid': cxValidateField(form.fields['subject']) }
                )}
                value={form.fields['subject'].value}
                disabled={state.isRequestInProgress}
                onChange={hopplerLinkState(this.form$, form, 'subject')}>
                  <option class="text-muted" value="" disabled={true} selected>Choose Subject</option>
                  {
                    subjectOptions.map(subject => {
                      return <option value={subject.value}>{ subject.label }</option>
                    })
                  }
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class={ cx("col-12", {
              "col-lg-12": usedin == 'ownerpage',
              "col-md-6": !usedin
            }) }>
              <div class="form-group">
                <input className={ cx('form-control hi-text-input', 
                    { 'invalid': cxValidateField(form.fields['firstName']) }
                  )}
                  type="text"
                  placeholder="First name"
                  disabled={state.isRequestInProgress}
                  value={form.fields['firstName'].value}
                  onChange={hopplerLinkState(this.form$, form, 'firstName')}/>
                  { this.getErrorBlock(state, 'firstName', 'First name is required') }
              </div>
            </div>
            <div class={ cx("col-12", {
              "col-lg-12": usedin == 'ownerpage',
              "col-md-6": !usedin
            }) }>
              <div class="form-group">
                <input className={ cx('form-control hi-text-input', 
                    { 'invalid': cxValidateField(form.fields['lastName']) }
                  )}
                  type="text"
                  placeholder="Surname"
                  disabled={state.isRequestInProgress}
                  value={form.fields['lastName'].value}
                  onChange={hopplerLinkState(this.form$, form, 'lastName')}/>
                  { this.getErrorBlock(state, 'lastName', 'Surname is required') }
              </div>
            </div>
          </div>

          <div class="row">
            <div class={ cx("col-12", {
              "col-lg-12": usedin == 'ownerpage',
              "col-md-6": !usedin
            }) }>
              <div class="form-group">
                <input className={ cx('form-control hi-text-input', 
                    { 'invalid': cxValidateField(form.fields['mobileNumber']) }
                  )}
                  type="text"
                  placeholder="Mobile number"
                  disabled={state.isRequestInProgress}
                  value={form.fields['mobileNumber'].value}
                  onChange={hopplerLinkState(this.form$, form, 'mobileNumber')}/>
                  { this.getErrorBlock(state, 'mobileNumber', 'Mobile number is required') }
              </div>
            </div>
            <div class={ cx("col-12", {
              "col-lg-12": usedin == 'ownerpage',
              "col-md-6": !usedin
            }) }>
              <div class="form-group">
                <input className={ cx('form-control hi-text-input', 
                  { 'invalid': cxValidateField(form.fields['emailAddress']) }
                  )}
                  type="email"
                  placeholder="Email address"
                  disabled={state.isRequestInProgress}
                  value={form.fields['emailAddress'].value}
                  onChange={hopplerLinkState(this.form$, form, 'emailAddress')}/>
                  { this.getErrorBlock(state, 'emailAddress', 'Email is required') }
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <textarea
                  placeholder="Write your message here"
                  class={ cx("form-control", {
                    "hi-text-area": !usedin,
                    "invalid": cxValidateField(form.fields['message'])
                  }) }
                  disabled={state.isRequestInProgress}
                  value={ form.fields['message'].value }
                  onChange={ hopplerLinkState(this.form$, form, 'message') }>
                </textarea>

                { this.getErrorBlock(state, 'message', 'Message is required') }
              </div>
            </div>
          </div>

          <div class="row align-items-center">
            <div class={ cx("col-12", {
              "text-center": usedin == 'ownerpage'
            }) }>
              <button 
                class="btn btn-secondary hi-contact-us-form__submit-btn"
                type="submit"
                disabled={state.isRequestInProgress}
                onClick={this.onSubmit}>
                Submit form 
              </button>
            </div>
            <div class="col-12 col-md-2">
              { this.getLoadingSpinnerBlock(state) }
            </div>
          </div>
          
        </form>
      </div>
    );
  }
}