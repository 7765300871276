import { Epic, combineEpics, ofType } from 'redux-observable';

import LandingEffects from './landing/landing.effects';
import ResultsEffects from './results/results.effects';
import CartEffects from './cart/cart.effects'
import UserEffects from './user/user.effects';
import SignupEffects from './signup/signup.effects';
// import * as ResultsActions from './results/results.actions';
// import getStore from '../store';

export let appEffects: Epic[] = [];

appEffects = appEffects.concat(LandingEffects);
appEffects = appEffects.concat(ResultsEffects);
appEffects = appEffects.concat(CartEffects);
appEffects = appEffects.concat(UserEffects);
appEffects = appEffects.concat(SignupEffects);

export const rootEpic = combineEpics(...appEffects);

// ------- THE SECTION BELOW IS FOR TESTING STORE --------

// setTimeout(() => {
//   const action = {
//     type: ResultsActions.TEST,
//     payload: {
//       message: 'wew'
//     } as ResultsActions.ResultsState
//   }

//   getStore().dispatch(action);
// }, 1000);

