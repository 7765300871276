import Cookies, { CookieSetOptions } from 'universal-cookie';
import moment from 'moment-timezone';

import { PlatformUtility } from './platform-utility';

const CAMPAIGN_QUERY_PARAM = 'utm_campaign';
const SOURCE_QUERY_PARAM = 'utm_source';
const MEDIUM_QUERY_PARAM = 'utm_medium';
const CONVERSION_WINDOW_DAYS = 7;

const AD_CAMPAIGN_COOKIE = 'hopplerAdCampaign';
const AD_SOURCE_COOKIE = 'hopplerAdSource';
const AD_MEDIUM_COOKIE = 'hopplerAdMedium';
const AD_REFERRAL_PATH_COOKIE = 'hopplerAdRefPath';
const EXPIRY_COOKIE = 'hopplerAdCampaignExpiry';

// Campaign & UTM TRACKER GSHEET https://docs.google.com/spreadsheets/d/1lbZ7N82wVuKkbxlJYSRv668D4Hxeps2EIKPdHyZcOTg
const SOURCE_FACEBOOK = 11; //FACEBOOK is paid source while FBP is organic.
const SOURCE_GAD = 22;
const SOURCE_GOOGLE = 23;
const SOURCE_TROVIT = 24;
const SOURCE_MITULA = 25;

const SOURCE_BLOG = 18;
const SOURCE_FBP = 21;
const SOURCE_NEWSLETTER = 41;
const SOURCE_INSTAGRAM = 40;

const SOURCE_PLDT_GLOBAL = 44;

const facebookReferrers: string[] = [
  'www.facebook.com',
  'facebook.com',
  'm.facebook.com',
];

const blogReferrers: string[] = [
  'hoppler.com.ph/blog',
  'hoppler.com.ph/magazine'
];

const facebookReferrerSource: string = 'fbp';
const facebookReferrerMedium: string = 'organic-post';

export interface AdSource {
  campaign: string,
  source: number,
  medium: string,
  referrerUrl?: string
};


class AdWordsTrackerService {
  cookies = new Cookies();

  constructor(
    // private route: ActivatedRoute,
    // private cookieService: CookieService,
    // private platform: PlatformUtilitiesService,
  ) {
  }

  private getSourceId(sourceName: string): number {
    let sourceId: number = null;
    sourceName = sourceName.toUpperCase();

    switch (sourceName) {
      case 'FACEBOOK':
        sourceId = SOURCE_FACEBOOK;
        break;
      case 'GAD':
        sourceId = SOURCE_GAD;
        break;
      case 'GOOGLE':
        sourceId = SOURCE_GOOGLE;
        break;
      case 'TROVIT':
        sourceId = SOURCE_TROVIT;
        break;
      case 'MPC': // Mitula
      case 'MITULA': // Accept both of these until we get the utm_source values cleared up
        sourceId = SOURCE_MITULA;
        break;

      case 'BLOG':
        sourceId = SOURCE_BLOG;
        break;
      case 'FBP':
        sourceId = SOURCE_FBP;
        break;
      case 'MAILERLITE':
        sourceId = SOURCE_NEWSLETTER;
        break;
      case 'IG':
        sourceId = SOURCE_INSTAGRAM;
        break;

      case 'PLDTGLOBAL':
        sourceId = SOURCE_PLDT_GLOBAL;
        break;
    }

    return sourceId;
  }

  /**
   * Checks whether the user arrived at the current page from somewhere that we
   * want to track, e.g. paid Google/Facebook ads or Trovit. If so, this gets
   * stored in a cookie. This will be submitted along with an inquiry if the
   * user inquires.
   */

  /** 
   * Change as of IT-774
   * Check first if an existing cookie exists.
   * If a cookie exists, check if it is paid or not.
   * If paid, do not generate new one / do nothing.
   * Else if existing cookie has lower "priority" (refers to source)
   * then set new cookie.
   * 
   * If there is no source but a referrer exists and referrer is Facebook,
   * then treat as if it came from an organic facebook post
   * 
   * Paid ads always take priority over everything else.
   */
  checkIncomingNavigationSource() {
    const cookies = this.cookies;
    // const queryParams = this.route.snapshot.queryParams;
    const queryParams = new URLSearchParams(location.search);

    // Controlled by utm_medium=CPC for some sources.
    let fromPaidAd = true;

    if (!PlatformUtility.isOnClient()) {
      return;
    }

    // Check for existing adSource cookie.
    // If is paid attribution, don't do anything
    const adSource: AdSource = this.getAdCampaignAttribution();
    if (adSource && this.isAdSourceFromPaidAd(adSource.source, adSource.medium)) {
      return;
    }

    // GAD and GOOGLE sources must have utm_medium=CPC
    const sourceQueryParams = queryParams.get(SOURCE_QUERY_PARAM);
    const mediumQueryParams = queryParams.get(MEDIUM_QUERY_PARAM);
    let sourceId = null;

    if (sourceQueryParams) {
      sourceId = this.getSourceId(sourceQueryParams);

      switch (sourceId) {
        case SOURCE_GAD:
        case SOURCE_GOOGLE:
        case SOURCE_FACEBOOK:
          if (!mediumQueryParams || mediumQueryParams.toUpperCase() !== 'CPC') {
            // If coming from Google or Facebook, we're only interested in
            // tracking them if they clicked an ad. Mitula and Trovit count as
            // paid.
            fromPaidAd = false;
          }
          break;

        case SOURCE_BLOG:
        case SOURCE_FBP:
        case SOURCE_NEWSLETTER:
        case SOURCE_PLDT_GLOBAL:
          fromPaidAd = false;
          break;
      }
    }

    if (queryParams.has(SOURCE_QUERY_PARAM) && fromPaidAd) {
      // console.log('paid');
      this.createAdSourceCookie(queryParams);

    } else if (sourceId) {

      switch (sourceId) {
        case SOURCE_PLDT_GLOBAL:
        case SOURCE_BLOG:
        case SOURCE_FBP:
        case SOURCE_NEWSLETTER:
        case SOURCE_INSTAGRAM:
          this.createAdSourceCookie(queryParams);
          break;
      }
      
    } else {
      const referrer = document.referrer;
      // console.log('referrer', referrer);

      if (referrer) {
        // we use filter instead of Array.some so we don't have to add a polyfill for .some
        const fbReferrers: string[] = facebookReferrers.filter(member => {
          return (referrer.includes(member));
        });

        if (fbReferrers && fbReferrers.length > 0) {
          this.createFacebookReferrerSourceCookie();
        }

        const blogRefs: string[] = blogReferrers.filter(member => {
          return referrer.includes(member)
        });
        const currentlyInBlog = location.pathname.startsWith('/magazine') || location.pathname.startsWith('/blog');

        if (blogRefs && blogRefs.length > 0 && !currentlyInBlog) {
          this.createBlogReferrerSourceCookie(referrer);
        }
      }
    }
  }

  private createFacebookReferrerSourceCookie() {
    // console.log('creating new fb referrer cookie');

    const cookies = this.cookies;
    const expiry = moment().add(CONVERSION_WINDOW_DAYS, 'days');

    const cookieOptions: CookieSetOptions = {
      path: '/',
      expires: expiry.toDate()
    };

    cookies.set(AD_SOURCE_COOKIE, facebookReferrerSource, cookieOptions);
    cookies.set(AD_MEDIUM_COOKIE, facebookReferrerMedium, cookieOptions);
    cookies.set(EXPIRY_COOKIE, expiry.toISOString(), cookieOptions);
  }

  // Add blog referrer cookie creation util
  private createBlogReferrerSourceCookie(referrerPath: string) {
    // console.log('Creating blog referral cookie!');
    const cookies = this.cookies;
    const expiry = moment().add(CONVERSION_WINDOW_DAYS, 'days');

    const cookieOptions: CookieSetOptions = {
      path: '/',
      expires: expiry.toDate()
    };

    cookies.set(AD_SOURCE_COOKIE, 'blog', cookieOptions);
    cookies.set(AD_MEDIUM_COOKIE, '', cookieOptions);
    cookies.set(AD_REFERRAL_PATH_COOKIE, referrerPath, cookieOptions);
    cookies.set(EXPIRY_COOKIE, expiry.toISOString(), cookieOptions);
  }

  private isAdSourceFromPaidAd(source: number, medium: string) {
    if (source == SOURCE_GAD || source == SOURCE_GOOGLE ||
      source == SOURCE_FACEBOOK) {
      return (medium && medium.toUpperCase() == 'CPC')
    }
    return false;
  }

  private checkAdCampaignMarkerValidity(): boolean {
    if (!PlatformUtility.isOnClient()) {
      return false;
    }

    const cookies = this.cookies;
    const expiry = cookies.get(EXPIRY_COOKIE);
    const expiryDate = moment(expiry);
    const now = moment();

    if (expiryDate.isSameOrBefore(now)) {
      this.clearAdCampaignMarker();
      return false;
    } else {
      return true;
    }
  }

  private createAdSourceCookie(queryParams: URLSearchParams) {
    const cookies = this.cookies;
    const expiry = moment().add(CONVERSION_WINDOW_DAYS, 'days');

    // CHANGE FROM HOPIA!
    // Let the browser expire the cookie by itself. Our own expiry code is no
    // longer needed, but keep it in there just to be safe for now.
    const cookieOptions: CookieSetOptions = {
      path: '/',
      expires: expiry.toDate()
    };

    // Clean up. Prevent cases of FB source with blog referrers.
    cookies.remove(AD_REFERRAL_PATH_COOKIE, cookieOptions);

    cookies.set(AD_CAMPAIGN_COOKIE, queryParams.get(CAMPAIGN_QUERY_PARAM), cookieOptions);
    cookies.set(AD_SOURCE_COOKIE, queryParams.get(SOURCE_QUERY_PARAM), cookieOptions);
    cookies.set(AD_MEDIUM_COOKIE, queryParams.get(MEDIUM_QUERY_PARAM), cookieOptions);
    cookies.set(EXPIRY_COOKIE, expiry.toISOString(), cookieOptions);
  }

  clearAdCampaignMarker() {
    if (!PlatformUtility.isOnClient()) {
      return;
    }

    const cookies = this.cookies;
    cookies.remove(AD_CAMPAIGN_COOKIE);
    cookies.remove(AD_SOURCE_COOKIE);
    cookies.remove(AD_MEDIUM_COOKIE);
    cookies.remove(AD_REFERRAL_PATH_COOKIE);
    cookies.remove(EXPIRY_COOKIE);
  }

  runExternalReportingTriggers() {
    if (!PlatformUtility.isOnClient()) {
      return;
    }

    const cookies = this.cookies;
    const source = cookies.get(AD_SOURCE_COOKIE);
    const medium = cookies.get(AD_MEDIUM_COOKIE);

    if ('ta' in window) {
      // Do a case-insensitive check, just to be safe
      const ciSource = source.toUpperCase();
      const ciMedium = medium.toUpperCase();
      if (ciSource == 'TROVIT' && ciMedium == 'CPC') {
        // This makes it easy for Alvier to check that this actually works.
        // DO NOT REMOVE!
        console.log('Matched criteria for Trovit - sending lead.');
        (window as any)['ta']('send', 'lead');
      }
    }
  }

  /**
   * Returns ad campaign attribution, if any. Otherwise, returns null.
   */
  getAdCampaignAttribution(): AdSource {
    if (!PlatformUtility.isOnClient()) {
      return null;
    }

    // console.log('Referrer:', document.referrer);

    // If the inquiry is being made while inside /magazine/, count the source
    // as BLOG
    if (location.pathname.startsWith('/magazine')) {
      return { source: SOURCE_BLOG } as AdSource;
    }

    const fromAd = this.checkAdCampaignMarkerValidity();

    if (fromAd) {
      // Default to an empty string
      // Context: The originally-deployed Adwords gets the ad source data from
      // an incorrect query param, causing it to always be null. We need to
      // handle the cases with bad cookies, so these users can still inquire if
      // they desire.
      //
      // Additionally, these may be null if e.g. somebody messed with the URL.
      // Don't break on that case, either.
      const campaign = this.cookies.get(AD_CAMPAIGN_COOKIE) || '';
      const source = this.cookies.get(AD_SOURCE_COOKIE) || '';
      const medium = this.cookies.get(AD_MEDIUM_COOKIE) || '';
      const referralUrl = this.cookies.get(AD_REFERRAL_PATH_COOKIE) || '';

      return {
        campaign: campaign,
        source: this.getSourceId(source),
        medium: medium,
        referrerUrl: referralUrl
      } as AdSource;
    } else {
      return null;
    }
  }

}


export const adWordsTracker = new AdWordsTrackerService();
