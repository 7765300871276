import { h, Component } from "preact";
import { ModalProps } from "../../types/modal-props";
import { LoginForm } from "./login-form.component";

interface LoginModalState {
}

export class LoginModal extends Component<ModalProps, LoginModalState> {
  constructor(props) {
    super(props);
  }

  onClose = () => {
    this.props.onModalSuccess('closed');
  }

  render(props, state: LoginModalState) {
    return(
      <LoginForm onClose={this.onClose}></LoginForm>
    );
  }
}