import { fromBase64 } from '../utils/btoa';

/**
 * Returns the contents of the element with the specified ID as a string.
 * 
 * Intended for use where data is bundled in the page from the Express
 * controller to be used by a client-side component.
 * 
 * @param id The ID of the element to read from
 */
export function readSharedData(id: string): string {
  const element = document.getElementById(id);

  if (element) {
    const content = fromBase64(element.innerHTML, 'utf-8');
    return content;
  }
}

export function readSharedJSON<T = any>(id: string): T {
  const data = readSharedData(id);

  if (data) {
    return JSON.parse(data);
  }
}
