const PREFIX = '[RvB]';

export const INIT = `${PREFIX} INIT`;
export const RVB_INPUTS_CHANGED = `${PREFIX} RVB_INPUTS_CHANGED`;

export interface RvBState {
  targetMonthlyRent?: number;
  stayingFor?: number;
  targetHomePrice?: number;
  downPaymentPercent?: number;
  loanAmountPercent?: number;
  loanTerm?: number;
  annualRate?: number;
}

export interface RvBAction {
  type: string;
  payload: RvBState;
}

export const DEFAULT_STATE: RvBState = {};
