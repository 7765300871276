import union from 'lodash.union';

const AdminGroup = ['ROLE_ADMIN', 'ROLE_SUPERADMIN'];

const BusinessGroup = union([
  'ROLE_RES_ASSOCIATE_BROKER', 'ROLE_COM_ASSOCIATE_BROKER',
  'ROLE_RES_INTERNAL_BROKER', 'ROLE_COM_INTERNAL_BROKER',
  'ROLE_BROKER_MANAGER', 'ROLE_ACCOUNT_MANAGER'
], AdminGroup);

const InternalGroup = union([
    'ROLE_RES_INTERNAL_BROKER', 'ROLE_COM_INTERNAL_BROKER',
    'ROLE_CONTENT_PROVIDER', 'ROLE_LISTINGS',
    'ROLE_MARKETING', 'ROLE_SALES_SUPPORT', 'ROLE_BROKER_MANAGER',
], AdminGroup);

const LogsAdminGroup = union(['ROLE_BROKER_MANAGER', 'ROLE_LISTINGS',
    'ROLE_SALES_SUPPORT'], AdminGroup);

const BrokerManagementGroup = union([
    'ROLE_BROKER_MANAGER',
], AdminGroup);

const QcGroup = union([
    'ROLE_SALES_SUPPORT', 'ROLE_LISTINGS', 'ROLE_RES_INTERNAL_BROKER',
    'ROLE_COM_INTERNAL_BROKER',
], AdminGroup);

const ListingsGroup = union([
    'ROLE_SALES_SUPPORT', 'ROLE_LISTINGS', 'ROLE_RES_INTERNAL_BROKER',
    'ROLE_COM_INTERNAL_BROKER',
], AdminGroup);

export const ROLE_CONSUMER = 'ROLE_CONSUMER';
export const ROLE_BUYER = 'ROLE_BUYER';

export const UserTypes = {
  AdminGroup,
  BusinessGroup,
  InternalGroup,
  LogsAdminGroup,
  BrokerManagementGroup,
  QcGroup,
  ListingsGroup
};

export enum UserGroup {
  AdminGroup,
  BusinessGroup,
  InternalGroup,
  LogsAdminGroup,
  BrokerManagementGroup,
  QcGroup,
  ListingsGroup
};

// There used to be a type 1 back in v1 API,
// but that was changed in v2, makin
// consumers are also categorized as Owners with value 2

// As of August 8, 2019 - The statement above will be false.
// Consumers/Customers now have their own classication as "buyers"
// And have their own role.
export const ClientTypes = {
  Owner: 2,
  Broker: 3
}
