import { h, render } from 'preact';

import $ from 'jquery';
import 'bootstrap';

import { GOOGLE_MAP_API_KEY, PROPERTY_PAGE_MARKER_ICON } from '../preact/constants/maps';
import { Listing, PropertyMapMarker, MapListing, Business, MapSettings } from '../../types';
import { ListingMarkerUtils } from './listing-marker-utils';
import getStore from '../preact/store';
import { MAP_MARKER_CLICKED, ResultsAction, MAP_INFO_WINDOW_OPENED, MAP_MOBILE_PROPERTY_CARD_OPENED, MAP_MOBILE_PROPERTY_CARD_CLOSED } from '../preact/reducers/results/results.actions';

import * as PropertyMapMarkerModel from '../../types/property-map-marker.model';
import { renderComponentsWithin } from '../preact/component-renderer';
import { xlMinBreakpoint } from '../preact/constants/ui-sizes';
import { PropertyUtils } from '../../core/property-utils';

export class HopplerMapUtils {
  listings: MapListing[];
  propertyMapMarkers: PropertyMapMarker[];

  googleMarkers: google.maps.Marker[];
  neighborhoodMarkers: google.maps.Marker[];

  currActiveMarker: google.maps.Marker;
  currActiveOriginalIcon: string;
  currActiveMarkerInfoWindow: google.maps.InfoWindow;

  map: google.maps.Map;
  mapSettings: MapSettings;
  isPropertyPage: boolean;

  ListingMarkerUtils: ListingMarkerUtils;
  propertyUtils: PropertyUtils = new PropertyUtils();
  
  isMapCalled: boolean;
  isMobile?: boolean;

  constructor(listings: MapListing[], isPropertyPage?: boolean) {
    this.listings = listings;
    this.googleMarkers = [];
    this.neighborhoodMarkers = [];
    this.isPropertyPage = isPropertyPage;

    this.ListingMarkerUtils = new ListingMarkerUtils();
    this.propertyMapMarkers = this.ListingMarkerUtils.prepareMapMarkers(listings);
  }

  getIconPrimaryIcon(iconUrl: string) {
    if (iconUrl == PropertyMapMarkerModel.ICON_URL_SINGLE) {
      return PropertyMapMarkerModel.ICON_URL_SINGLE_PRIMARY;
    }

    if (iconUrl == PropertyMapMarkerModel.ICON_URL_MULTIPLE_BIG) {
      return PropertyMapMarkerModel.ICON_URL_MULTIPLE_BIG_PRIMARY;
    }

    if (iconUrl == PropertyMapMarkerModel.ICON_URL_MULTIPLE_SMALL) {
      return PropertyMapMarkerModel.ICON_URL_MULTIPLE_SMALL_PRIMARY;
    }

    return iconUrl;
  }

  // this needs to do marker icon determination on what kind of icon to use
  swapGoogleMarkerIcons(googleMarker: google.maps.Marker) {

    if (googleMarker == this.currActiveMarker) {
      
      const markerIcon: string = googleMarker.getIcon() as string;
      const nextIcon: string = this.getIconPrimaryIcon(markerIcon);
      if (markerIcon == nextIcon) {
        this.currActiveMarker.setIcon(this.currActiveOriginalIcon);
      } else {
        this.currActiveMarker.setIcon(nextIcon);
      }
      
    } else {
      // set current marker's icon back to original.
      if (this.currActiveMarker) {
        this.currActiveMarker.setIcon(this.currActiveOriginalIcon);
      }
  
      this.currActiveMarker = googleMarker;

      const markerIcon = this.currActiveMarker.getIcon() as string;
      const nextIcon: string = this.getIconPrimaryIcon(markerIcon);

      this.currActiveOriginalIcon = markerIcon;
      this.currActiveMarker.setIcon(nextIcon);
    }

  }

  // function called when the info window's DOM is attached and ready
  onInfoWindowLoaded = (event, infoWindowId: string) => {
    let infoWindowCard = document.getElementById(infoWindowId)
    if (infoWindowCard) {
      renderComponentsWithin('hi-info-window-content', infoWindowCard);
    }

  }

  togglePropertyIconInfoWindow(googleMarker: google.maps.Marker, propertyMarker: PropertyMapMarker) {
    
    // based on map behavior, check if we are on mobile.
    if (this.isMobile) {
      const store = getStore();

      if (this.currActiveMarker == googleMarker) {

        const resultsStoreSnapshot = store.getState().results;
        const previewedProperties = resultsStoreSnapshot.activeInfoWindowProperties;

        if (previewedProperties) {
          store.dispatch({
            type: MAP_MOBILE_PROPERTY_CARD_CLOSED
          } as ResultsAction);
        } else {
          store.dispatch({
            type: MAP_MOBILE_PROPERTY_CARD_OPENED,
            payload: {
              activeInfoWindowProperties: propertyMarker.properties
            }
          } as ResultsAction);
        }        
      } else {
        store.dispatch({
          type: MAP_MOBILE_PROPERTY_CARD_OPENED,
          payload: {
            activeInfoWindowProperties: propertyMarker.properties
          }
        } as ResultsAction);
      }

      return;
    }

    if (this.currActiveMarkerInfoWindow) {
      this.currActiveMarkerInfoWindow.close();
      this.currActiveMarkerInfoWindow = null;

      if (this.currActiveMarker == googleMarker) {
        return;
      }
    }

    const store = getStore();
    store.dispatch({
      type: MAP_INFO_WINDOW_OPENED,
      payload: {
        activeInfoWindowProperties: propertyMarker.properties
      }
    } as ResultsAction);

    const infoWindowId = `hiInfoWindow${Math.floor(Math.random() * 1000)}`;

    let infoWindowContent = document.createElement('div');
    infoWindowContent.id = `${infoWindowId}`;
    infoWindowContent.className = 'hi-properties-map__info-window';
    infoWindowContent.innerHTML = `

      <hi-info-window-content>
      </hi-info-window-content>
    `;

    let infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent,
      maxWidth: 400
    });

    this.currActiveMarkerInfoWindow = infoWindow;
    infoWindow.addListener('domready', e => this.onInfoWindowLoaded(e, infoWindowId));

    infoWindow.open(this.map, googleMarker);

    this.map.panTo(googleMarker.getPosition());
  }

  handleMarkerClick = (googleMarker: google.maps.Marker, propertyMarker: PropertyMapMarker, e) => {

    this.hideMapToggleButton();
    this.togglePropertyIconInfoWindow(googleMarker, propertyMarker);  
    this.swapGoogleMarkerIcons(googleMarker);

    if (!this.isMobile) {
      const store = getStore();
      store.dispatch({
        type: MAP_MARKER_CLICKED,
        payload: {
          selectedPropertyMarker: propertyMarker
        }
      });
    }
  }

  hideMapToggleButton = () => {
    const buttonToggle = document.getElementById('hiResultsMapMobileToggle');

    if (buttonToggle) {
      buttonToggle.classList.add('d-none');
    }
  }

  showMapToggleButton = () => {
    const buttonToggle = document.getElementById('hiResultsMapMobileToggle');

    if (buttonToggle) {
      buttonToggle.classList.remove('d-none');
    }
  }

  onGoogleMapLoaded = () => {

    const isMobile = document.documentElement.clientWidth < xlMinBreakpoint;
    this.isMobile = isMobile;

    const mapSettings = this.mapSettings = this.ListingMarkerUtils.determineMapSettings(this.propertyMapMarkers);
    // const streetViewControlPosition = this.isMobile ? google.maps.ControlPosition.LEFT_BOTTOM 
    //   : google.maps.ControlPosition.RIGHT_BOTTOM;

    let centerLatLong = new google.maps.LatLng(mapSettings.centerLat, mapSettings.centerLong);
    let map = this.map = new google.maps.Map(document.getElementById('hiMap'), {
      center: centerLatLong,
      zoom: mapSettings.zoom,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      
    });

    if (this.isPropertyPage) {
      let markerData = this.propertyMapMarkers[0];
      
      let marker = new google.maps.Marker({
        position: {
          lat: markerData.latitude, 
          lng: markerData.longitude
        },
        opacity: 1,
        icon: PROPERTY_PAGE_MARKER_ICON,
      });

      marker.setMap(map);
  
      this.googleMarkers.push(marker);
    } else {
      this.propertyMapMarkers.forEach((member) => {
        let marker = new google.maps.Marker({
          position: {
            lat: member.latitude, 
            lng: member.longitude
          },
          title: member.labelOptions.text,
          opacity: 1,
          label: member.labelOptions as google.maps.ReadonlyMarkerLabel,
          icon: member.iconUrl,

          clickable: true
        });

        marker.addListener('click', (e) => this.handleMarkerClick(marker, member, e));
  
        marker.setMap(map);
  
        this.googleMarkers.push(marker);
      });
    }
    
    this.map.addListener('click', (e) => {

      this.showMapToggleButton();
      const isMobile = document.documentElement.clientWidth < xlMinBreakpoint;
      this.isMobile = isMobile;

      if (this.currActiveMarkerInfoWindow) {
        this.currActiveMarkerInfoWindow.close();
        this.currActiveMarkerInfoWindow = null;
      }

      if (this.currActiveMarker) {
        this.swapGoogleMarkerIcons(this.currActiveMarker);
      }

      if (this.isMobile) {

        const store = getStore();
        store.dispatch({
          type: MAP_MOBILE_PROPERTY_CARD_CLOSED
        } as ResultsAction);
  
        return;
      }
    });

    // adding same click behavior to drag event
    this.map.addListener('dragstart', (e) => {

      this.showMapToggleButton();
      const isMobile = document.documentElement.clientWidth < xlMinBreakpoint;
      this.isMobile = isMobile;

      if (this.currActiveMarkerInfoWindow) {
        this.currActiveMarkerInfoWindow.close();
        this.currActiveMarkerInfoWindow = null;
      }

      if (this.currActiveMarker) {
        this.swapGoogleMarkerIcons(this.currActiveMarker);
      }

      if (this.isMobile) {

        const store = getStore();
        store.dispatch({
          type: MAP_MOBILE_PROPERTY_CARD_CLOSED
        } as ResultsAction);
  
        return;
      }
    });
    
  }

  updateNeighborhoodPins(places: Business[]) {
    this.removeExistingNeighborhoodPins();
    
    places.forEach((member) => {
      let image = {
        url: member.hopplerImageUrl,
        scaledSize: new google.maps.Size(20, 26),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 0)
      }

      let marker = new google.maps.Marker({
        position: {
          lat: member.coordinates.latitude, 
          lng: member.coordinates.longitude
        },
        title: member.name,
        opacity: 1,
        icon: image,
      });

      marker.addListener('click', (e) => {
          
          let infoWindowContent = `
            <div style="max-width: 260px;">
              <div class="hi-properties-map__info-window-content">
                <div class="row no-gutters">
                  ${this.getInfoWindowPicture(member)}
                  <div class="${this.getInfoWindowClassName(member)}">
                    <h4 class="name">
                      ${member.name}
                    </h4>
                    <span class="contact mat-caption">
                      ${member.display_phone}
                    </span>
                    <p class="full-address">
                      <span class="d-block mat-caption">
                      ${member.location.display_address[0]}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          `;

        let infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent
        });

        infoWindow.open(this.map, marker);

      });

      marker.setMap(this.map);
      this.neighborhoodMarkers.push(marker);
    });
  }

  removeExistingNeighborhoodPins() {
    this.neighborhoodMarkers.forEach(marker => {
      marker.setMap(null);
    });
  }

  getInfoWindowClassName(business: Business) {
    if (this.isInfoWindowPictureAvailable(business)) {
      return 'info col-8';
    } else {
      return 'info';
    }
  }

  isInfoWindowPictureAvailable(business: Business): boolean {
    if (business.image_url && business.image_url !== '') {
      return true;
    }

    return false;
  }

  getInfoWindowPicture(business: Business) {
    if (!this.isInfoWindowPictureAvailable(business)) {
      return '';
    }

    const htmlString: string = `
      <div class="col-4 pr-2">
        <img class="hi-properties-map__info-window-img"
          src="${business.image_url}" 
          alt="business_image"/>
      </div>
    `;

    return htmlString;
  }

  loadGoogleMapDependencies(): void {

    const scriptPath = `https://maps.googleapis.com/maps/api/js?v=3.34&key=${GOOGLE_MAP_API_KEY}`;
    
    let vdomScript = h(
      'script',
      {
        src: scriptPath,
        async: true,
        defer: true,
        onLoad: () => {
          this.onGoogleMapLoaded();
        }
      }
    );

    render(vdomScript, document.body);
  }

}