import { Observable, pipe, of, EMPTY, from, Observer, throwError } from 'rxjs';
import { switchMap, catchError, mergeMap, map, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType, ActionsObservable } from 'redux-observable';

import Axios, { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { hopplerApi } from '../../../../constants/api';

import * as UserActions from './user.actions'
import { UserAction } from './user.actions';
import { User, Contact } from '../../../../types';

import * as RequestStates from '../../../../types/request-state.model';
import { appState$ } from '../../store';
import { response } from 'express';
import { showToast } from '../../../utils/hoppler-toast.service';
import signupButtonToggle from '../../../utils/signup-button-toggle';
import postPropertyButtonToggle from '../../../utils/post-property-button-toggle';
import URLRouter from '../../../../core/url-router';
import { UserUtils } from '../../../../core/user-utils';

const userUtils: UserUtils = new UserUtils();

let userEffects: Epic[] = [];
const userBasePath = `${hopplerApi.host}/api/users`;

const getCurrentUser: Epic = getCurrentUser$ => getCurrentUser$.pipe(
  ofType<UserAction>(UserActions.GET_CURRENT_USER),
  switchMap((action: UserAction) => {

    const promise = Axios.get<User>(
      `${userBasePath}/me`,
      {
        withCredentials: true
      }
    );

    return from (promise).pipe(
      mergeMap((response) => {
        const currentUser: User = response.data[0];
        const action: UserAction = {
          type: UserActions.GET_CURRENT_USER_SUCCESS,
          payload: {
            currentUser: currentUser,
            getCurrentUserState: RequestStates.REQUEST_SUCCESS
          }
        }

        signupButtonToggle(true);
        if (userUtils.isBuyer(currentUser)) {
          postPropertyButtonToggle(true);
        }

        return of(action);
      }),
      catchError((errResp) => {
        const action: UserAction = {
          type: UserActions.GET_CURRENT_USER_FAIL,
          payload: {
            getCurrentUserState: RequestStates.REQUEST_FAILED
          }
        }

        signupButtonToggle(false);
        postPropertyButtonToggle(false);
        
        return of(action);
      })
    );
  })
);

const updateCurrentUserState: Epic = updateCurrentUserState$ => updateCurrentUserState$.pipe(
  ofType<UserAction>(UserActions.GET_CURRENT_USER),
  switchMap(() => {
    const action: UserAction = {
      type: UserActions.GET_CURRENT_USER_IN_PROGRESS,
      payload: {
        getCurrentUserState: RequestStates.REQUEST_IN_PROGRESS
      }
    }

    return of(action);
  })
);

const login: Epic = login$ => login$.pipe(
  ofType<UserAction>(UserActions.LOGIN),
  switchMap((action: UserAction) => {
    
    const userData = {
      username: action.payload.email,
      password: action.payload.password,
      remember_me: 1 // defaulted to 1
    };

    const promise = Axios.post<User>(
      `${userBasePath}/auth`,
      userData, 
      {
        withCredentials: true
      }
    );

    // Please do a second pipe with mergemap and catch to handle the error or else
    // this WILL crash the rootEpic and other storeMiddleware
    return from(promise).pipe(
      mergeMap(response => {

        const action: UserAction = {
          type: UserActions.LOGIN_SUCCESS,
          payload: {
            loginState: RequestStates.REQUEST_SUCCESS,
            hasJustLoggedIn: true
          }
        }
    
        return of(action);
      }),
      catchError((errResponse: AxiosError) => {

        let responseAction;

        if (errResponse.response.data && errResponse.response.data.action) {
          responseAction = errResponse.response.data.action;
        }

        let message;

        if (errResponse.response.data && errResponse.response.data.msg) {
          message = errResponse.response.data.msg;
        }
        
        if (errResponse.response.status == 400) {
          showToast({
            title: 'Failed to login',
            message: message ? message : 'Invalid email or password.',
            duration: 3000
          })
        } else {
          showToast({
            title: 'Failed to login',
            message: message,
            duration: 3000
          });
        }       
        

        let action: UserAction;
        if (responseAction && responseAction == 'RESEND-ACTIVATION') {
          action = {
            type: UserActions.USER_REQUIRES_VALIDATION,
            payload: {
              loginState: RequestStates.REQUEST_FAILED
            }
          }
        } else {
          action = {
            type: UserActions.LOGIN_FAIL,
            payload: {
              loginState: RequestStates.REQUEST_FAILED
            }
          }
        }
    
        return of(action);
      })
    );
  })
);

const loginSuccess: Epic = loginSuccess$ => loginSuccess$.pipe(
  ofType<UserAction>(UserActions.LOGIN_SUCCESS),
  switchMap(() => {

    const action: UserAction = {
      type: UserActions.GET_CURRENT_USER
    }

    return of(action);
  })
);

const logout: Epic = logout$ => logout$.pipe(
  ofType<UserAction>(UserActions.LOGOUT),
  switchMap(() => {

    const promise = Axios.delete(
      `${userBasePath}/auth`,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(() => {
        const action: UserAction = {
          type: UserActions.LOGOUT_SUCCESS
        }
    
        signupButtonToggle(false);

        // 250 ms before the route is changed
        setTimeout(() => {
          URLRouter.navigateToURL('/login');
        }, 250);

        return of(action);
      }),
      catchError(() => {
        const action: UserAction = {
          type: UserActions.LOGOUT_FAIL
        }
    
        return of(action);
      })
    );
  })
);

const forgotPassword: Epic = forgotPassword$ => forgotPassword$.pipe(
  ofType<UserAction>(UserActions.FORGOT_PASSWORD),
  switchMap((action: UserAction) => {

    const promise = Axios.get<User>(
      `${hopplerApi.host}/api/users/forgot_password`,
      {
        withCredentials: true,
        params: {
          'username': action.payload.email
        }
      }
    );

    return from(promise).pipe(
      mergeMap(response => {
        const action: UserAction = {
          type: UserActions.FORGOT_PASSWORD_SUCCESS,
          payload: {
            forgotPasswordState: RequestStates.REQUEST_SUCCESS
          }
        }

        return of(action);
      }),
      catchError((errResp: AxiosError) => {
        const action: UserAction = {
          type: UserActions.FORGOT_PASSWORD_FAIL,
          payload: {
            forgotPasswordState: RequestStates.REQUEST_FAILED
          }
        }

        let message = errResp.response.data.msg ? errResp.response.data.msg : 'An error occured';

        showToast({
          title: 'Failed to send email',
          message: message,
          duration: 3000
        });

        return of(action);
      })
    );

  })
);

const resetPassword: Epic = resetPassword$ => resetPassword$.pipe(
  ofType<UserAction>(UserActions.RESET_PASSWORD),
  switchMap((action: UserAction) => {

    const userData = {
      newPassword: action.payload.newPassword,
      key: action.payload.resetKey,
      code: parseInt(action.payload.resetCode)
    };

    const promise = Axios.post<User>(
      `${hopplerApi.host}/api/users/reset_password`,
      userData,
      {
        withCredentials: false
      }
    );

    return from(promise).pipe(
      mergeMap(response => {
        const action: UserAction = {
          type: UserActions.RESET_PASSWORD_SUCCESS,
          payload: {
            resetPasswordState: RequestStates.REQUEST_SUCCESS
          }
        }

        return of(action);
      }),
      catchError((errResp: AxiosError) => {
        const action: UserAction = {
          type: UserActions.RESET_PASSWORD_FAIL,
          payload: {
            resetPasswordState: RequestStates.REQUEST_FAILED
          }
        }

        let message = errResp.response.data.msg ? errResp.response.data.msg : 'An error occured';

        showToast({
          title: 'Failed to reset password',
          message: message,
          duration: 3000
        });

        return of(action);
      })
    );

  })
);

const sendContactUs: Epic = sendContactUs$ => sendContactUs$.pipe(
  ofType<UserAction>(UserActions.SEND_CONTACT_US),
  switchMap((action: UserAction) => {
    const contactUsData: Contact = action.payload.contactUsData;

    const promise = Axios.post<User>(
      `${hopplerApi.host}/api/contact-us`,
      contactUsData
    );

    return from(promise).pipe(
      mergeMap(response => {

        const action: UserAction = {
          type: UserActions.SEND_CONTACT_US_SUCCESS
        };
        
        showToast({
          title: 'Successful send',
          message: 'Your contact message has been successfully sent.',
          duration: 3000
        });

        return of (action);

      }), catchError(errResp => {

        const action: UserAction = {
          type: UserActions.SEND_CONTACT_US_FAIL
        };

        let message = errResp.response.data.msg ? errResp.response.data.msg : 'An error occured sending contact-form data.';

        showToast({
          title: 'Failed to send email',
          message: message,
          duration: 3000
        });

        return of(action);
      })
    );

  })
);

const updateUserProfile: Epic = updateUserProfile$ => updateUserProfile$.pipe(
  ofType<UserAction>(UserActions.UPDATE_USER_PROFILE),
  switchMap((action: UserAction) => {
    const user = action.payload.currentUser;

    const promise = Axios.put<User>(
      `${hopplerApi.host}/api/users`,
      user,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(response => {

        showToast({
          title: 'Success',
          message: 'Your profile has been updated',
          duration: 3000
        });

        const action: UserAction = {
          type: UserActions.UPDATE_USER_PROFILE_SUCCESS
        }

        return of(action);
      }), 
      catchError(errResponse => {
        let message;

        if (errResponse.response && errResponse.response.data) {
          message = errResponse.response.data.msg ? errResponse.response.data.msg : null;
        }

        showToast({
          title: 'Failed to update profile',
          message: message,
          duration: 3000
        });

        const action: UserAction = {
          type: UserActions.UPDATE_USER_PROFILE_FAILURE
        }

        return of(action);
      })
    );

  })
);

const changePassword: Epic = changePassword$ => changePassword$.pipe(
  ofType<UserAction>(UserActions.CHANGE_PASSWORD),
  switchMap((action: UserAction) => {

    const data = {
      'currentPassword': action.payload.currentPassword,
      'newPassword': action.payload.newPassword,
      'confirmPassword': action.payload.confirmNewPassword,
    };

    const promise = Axios.post<User>(
      `${hopplerApi.host}/api/users/change_password`,
      data,
      {
        withCredentials: true
      }
    );

    return from(promise).pipe(
      mergeMap(response => {

        showToast({
          title: 'Password succesfully changed',
          duration: 6000
        });

        const action: UserAction = {
          type: UserActions.CHANGE_PASSWORD_SUCCESS
        }

        return of(action);

      }), catchError(errResponse => {

        let message;

        if (errResponse.response && errResponse.response.data) {
          message = errResponse.response.data.msg ? errResponse.response.data.msg : null;
        }

        showToast({
          title: 'Failed to change password',
          message: message,
          duration: 6000
        });

        const action: UserAction = {
          type: UserActions.CHANGE_PASSWORD_FAILURE
        }

        return of(action);

      })
    );

  })
);

// ! Make sure to add created epics to array 

userEffects.push(getCurrentUser);
userEffects.push(updateCurrentUserState);
userEffects.push(login);
userEffects.push(loginSuccess);
userEffects.push(logout);
userEffects.push(forgotPassword);
userEffects.push(resetPassword);
userEffects.push(sendContactUs);
userEffects.push(updateUserProfile);
userEffects.push(changePassword);

export default userEffects;