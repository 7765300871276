export const yelpCategories = [
  {
    "alias": "abruzzese",
    "title": "Abruzzese",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "absinthebars",
    "title": "Absinthe Bars",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "CZ"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "acaibowls",
    "title": "Acai Bowls",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "MX",
      "CL",
      "TR",
      "AR",
      "PL",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "accessories",
    "title": "Accessories",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "adult",
    "title": "Adult",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "adultentertainment",
    "title": "Adult Entertainment",
    "parents": [
      "nightlife"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "afghani",
    "title": "Afghan",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "african",
    "title": "African",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "airportlounges",
    "title": "Airport Lounges",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "alentejo",
    "title": "Alentejo",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "algarve",
    "title": "Algarve",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "alsatian",
    "title": "Alsatian",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "DE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "altoatesine",
    "title": "Altoatesine",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "andalusian",
    "title": "Andalusian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "ES",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "antiques",
    "title": "Antiques",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "appliances",
    "title": "Appliances",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "apulian",
    "title": "Apulian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "arabian",
    "title": "Arabian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "arabpizza",
    "title": "Arab Pizza",
    "parents": [
      "arabian"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "argentine",
    "title": "Argentine",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "armenian",
    "title": "Armenian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "BE",
      "GB",
      "ES",
      "IT",
      "FR",
      "TR",
      "AR",
      "PL",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "arroceria_paella",
    "title": "Arroceria \/ Paella",
    "parents": [
      "spanish"
    ],
    "country_whitelist": [
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "artsandcrafts",
    "title": "Arts & Crafts",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "artsupplies",
    "title": "Art Supplies",
    "parents": [
      "artsandcrafts"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "asianfusion",
    "title": "Asian Fusion",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "asturian",
    "title": "Asturian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ateliers",
    "title": "Ateliers",
    "parents": [
      "artsandcrafts"
    ],
    "country_whitelist": [
      "NO",
      "AT",
      "BE",
      "JP",
      "IT",
      "NL",
      "DE",
      "TR",
      "CH",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "auctionhouses",
    "title": "Auction Houses",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "AT",
      "NZ",
      "HK",
      "JP",
      "MY",
      "SG",
      "TW",
      "DE",
      "PH",
      "CL",
      "TR",
      "AR",
      "CH",
      "CA",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "australian",
    "title": "Australian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "austrian",
    "title": "Austrian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "ES",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "auvergnat",
    "title": "Auvergnat",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "azores",
    "title": "Azores",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "baby_gear",
    "title": "Baby Gear & Furniture",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "backshop",
    "title": "Backshop",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "food"
  },
  {
    "alias": "baden",
    "title": "Baden",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bagels",
    "title": "Bagels",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "ES",
      "AU"
    ],
    "rootParent": "food"
  },
  {
    "alias": "baguettes",
    "title": "Baguettes",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "CZ",
      "MX",
      "SE",
      "IT",
      "PT",
      "DE",
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bakeries",
    "title": "Bakeries",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "bangladeshi",
    "title": "Bangladeshi",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "TR",
      "ES",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "banks",
    "title": "Banks & Credit Unions",
    "parents": [
      "financialservices"
    ],
    "rootParent": "banks"
  },
  {
    "alias": "barcrawl",
    "title": "Bar Crawl",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "CZ",
      "AT",
      "MX",
      "AU",
      "DE",
      "AR",
      "PL",
      "CH",
      "US"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "bars",
    "title": "Bars",
    "parents": [
      "nightlife"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "basque",
    "title": "Basque",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "SG",
      "TR",
      "AU",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "batterystores",
    "title": "Battery Stores",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "bavarian",
    "title": "Bavarian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bbq",
    "title": "Barbeque",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "AU",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "beachbars",
    "title": "Beach Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "CZ",
      "NZ",
      "FI",
      "HK",
      "BE",
      "JP",
      "GB",
      "MY",
      "TW",
      "PH",
      "TR",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "beer_and_wine",
    "title": "Beer, Wine & Spirits",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "beerbar",
    "title": "Beer Bar",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "beergarden",
    "title": "Beer Garden",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CZ",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "beergardens",
    "title": "Beer Gardens",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "DK",
      "CZ",
      "NO",
      "MX",
      "SE",
      "JP",
      "AU",
      "GB",
      "IT",
      "FR",
      "PL",
      "US",
      "IE"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "beerhall",
    "title": "Beer Hall",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "beira",
    "title": "Beira",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "beisl",
    "title": "Beisl",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "belgian",
    "title": "Belgian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bento",
    "title": "Bento",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "food"
  },
  {
    "alias": "berrichon",
    "title": "Berrichon",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bespoke",
    "title": "Bespoke Clothing",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "MX",
      "FI",
      "SE",
      "JP",
      "CL",
      "TR",
      "AR",
      "BR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "beverage_stores",
    "title": "Beverage Store",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "AT",
      "BE",
      "AU",
      "ES",
      "IT",
      "PT",
      "NL",
      "DE",
      "CL",
      "TR",
      "CH",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "bikes",
    "title": "Bikes",
    "parents": [
      "sportgoods"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "bistros",
    "title": "Bistros",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "blacksea",
    "title": "Black Sea",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "blowfish",
    "title": "Blowfish",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bookstores",
    "title": "Bookstores",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "bourguignon",
    "title": "Bourguignon",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "brasseries",
    "title": "Brasseries",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "AR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "brazilian",
    "title": "Brazilian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "brazilianempanadas",
    "title": "Brazilian Empanadas",
    "parents": [
      "brazilian"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "breakfast_brunch",
    "title": "Breakfast & Brunch",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "breweries",
    "title": "Breweries",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "brewingsupplies",
    "title": "Brewing Supplies",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "HK",
      "CL",
      "JP",
      "AR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "brewpubs",
    "title": "Brewpubs",
    "parents": [
      "breweries"
    ],
    "country_blacklist": [
      "FR",
      "MX",
      "CL",
      "AR",
      "ES",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "bridal",
    "title": "Bridal",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "british",
    "title": "British",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bubbletea",
    "title": "Bubble Tea",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "AT",
      "MX",
      "TR",
      "AR",
      "CH",
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "buffets",
    "title": "Buffets",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "bulgarian",
    "title": "Bulgarian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "NO",
      "NZ",
      "NL",
      "SG",
      "TR",
      "BR",
      "CA",
      "US",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "burgers",
    "title": "Burgers",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "burmese",
    "title": "Burmese",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "FI",
      "TR",
      "ES",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "butcher",
    "title": "Butcher",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cafes",
    "title": "Cafes",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "FI",
      "ES",
      "AR"
    ],
    "rootParent": "cafes"
  },
  {
    "alias": "cafeteria",
    "title": "Cafeteria",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "SG",
      "FR",
      "NZ",
      "BR",
      "SE",
      "CA",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cajun",
    "title": "Cajun\/Creole",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "ES",
      "SG",
      "AU",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cakeshop",
    "title": "Patisserie\/Cake Shop",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "calabrian",
    "title": "Calabrian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "US",
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cambodian",
    "title": "Cambodian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "FI",
      "TR",
      "ES",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "candlestores",
    "title": "Candle Stores",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "candy",
    "title": "Candy Stores",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cannabisdispensaries",
    "title": "Cannabis Dispensaries",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "canteen",
    "title": "Canteen",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "AT",
      "FI",
      "BE",
      "JP",
      "IT",
      "NL",
      "DE",
      "PL",
      "CH",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cantonese",
    "title": "Cantonese",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "NZ",
      "HK",
      "BE",
      "SE",
      "JP",
      "AU",
      "GB",
      "MY",
      "IT",
      "NL",
      "SG",
      "TW",
      "FR",
      "AR",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "caribbean",
    "title": "Caribbean",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI",
      "TR",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "catalan",
    "title": "Catalan",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MX",
      "ES",
      "IT",
      "PT",
      "FR",
      "CL",
      "TR",
      "AR",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cellphoneaccessories",
    "title": "Mobile Phone Accessories",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "AT",
      "NZ",
      "FI",
      "BE",
      "GB",
      "MY",
      "NL",
      "DE",
      "PH",
      "TR",
      "PL",
      "CH",
      "CA",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "centralbrazilian",
    "title": "Central Brazilian",
    "parents": [
      "brazilian"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ceremonialclothing",
    "title": "Ceremonial Clothing",
    "parents": [
      "fashion"
    ],
    "country_blacklist": [
      "DE",
      "AT",
      "FR",
      "JP",
      "CH"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "champagne_bars",
    "title": "Champagne Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "AU"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "cheekufta",
    "title": "Chee Kufta",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cheese",
    "title": "Cheese Shops",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cheesesteaks",
    "title": "Cheesesteaks",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NL",
      "CA",
      "PL",
      "AU",
      "GB",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "chicken_wings",
    "title": "Chicken Wings",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "NZ",
      "BE",
      "AU",
      "MY",
      "IT",
      "PT",
      "NL",
      "FR",
      "DK",
      "NO",
      "FI",
      "JP",
      "PH",
      "CL",
      "CH",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "chickenshop",
    "title": "Chicken Shop",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "childcloth",
    "title": "Children's Clothing",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "chilean",
    "title": "Chilean",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "FI",
      "CL",
      "FR",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "chimneycakes",
    "title": "Chimney Cakes",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "US",
      "CZ",
      "PL",
      "FR"
    ],
    "rootParent": "food"
  },
  {
    "alias": "chinese",
    "title": "Chinese",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "chinesebazaar",
    "title": "Chinese Bazaar",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "ES",
      "PT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "chocolate",
    "title": "Chocolatiers & Shops",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "christmastrees",
    "title": "Christmas Trees",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "churros",
    "title": "Churros",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "MX",
      "CL",
      "AR",
      "BR",
      "ES",
      "PT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cideries",
    "title": "Cideries",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "NO",
      "BE",
      "JP",
      "IT",
      "NL",
      "FR",
      "TR",
      "DK"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cigarbars",
    "title": "Cigar Bars",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "clothingrental",
    "title": "Clothing Rental",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "clubcrawl",
    "title": "Club Crawl",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "cocktailbars",
    "title": "Cocktail Bars",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "coffee",
    "title": "Coffee & Tea",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "coffeeroasteries",
    "title": "Coffee Roasteries",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "coffeeshops",
    "title": "Coffeeshops",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "NL",
      "PT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "coffeeteasupplies",
    "title": "Coffee & Tea Supplies",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "CZ",
      "MX",
      "FI",
      "ES",
      "MY",
      "IT",
      "PT",
      "PH",
      "CL",
      "AR",
      "BR",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "collegeuniv",
    "title": "Colleges & Universities",
    "parents": [
      "education"
    ],
    "rootParent": "collegeuniv"
  },
  {
    "alias": "colombian",
    "title": "Colombian",
    "parents": [
      "latin"
    ],
    "country_whitelist": [
      "MX",
      "FI",
      "BE",
      "ES",
      "FR",
      "CL",
      "AR",
      "CA",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "comedyclubs",
    "title": "Comedy Clubs",
    "parents": [
      "nightlife"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "comfortfood",
    "title": "Comfort Food",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MX",
      "FI",
      "CL",
      "AR",
      "JP",
      "CA",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "comicbooks",
    "title": "Comic Books",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "computers",
    "title": "Computers",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "concept_shops",
    "title": "Concept Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "NZ",
      "HK",
      "SE",
      "JP",
      "ES",
      "IT",
      "SG",
      "CL",
      "TR",
      "AR",
      "BR",
      "PL",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "congee",
    "title": "Congee",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "TW",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "convenience",
    "title": "Convenience Stores",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "FI"
    ],
    "rootParent": "food"
  },
  {
    "alias": "conveyorsushi",
    "title": "Conveyor Belt Sushi",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "AT",
      "HK",
      "BE",
      "SE",
      "JP",
      "IT",
      "NL",
      "SG",
      "TW",
      "DE",
      "PL",
      "CH",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cookingclasses",
    "title": "Cooking Classes",
    "parents": [
      "artsandcrafts"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "corsican",
    "title": "Corsican",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "BE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "costumes",
    "title": "Costumes",
    "parents": [
      "artsandcrafts"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "countrydancehalls",
    "title": "Country Dance Halls",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "US",
      "SE"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "creperies",
    "title": "Creperies",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "csa",
    "title": "CSA",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "DE",
      "AT",
      "FR",
      "CH",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "cuban",
    "title": "Cuban",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "DK",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cucinacampana",
    "title": "Cucina campana",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "cupcakes",
    "title": "Cupcakes",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "TR",
      "ES",
      "CZ",
      "CA"
    ],
    "rootParent": "food"
  },
  {
    "alias": "currysausage",
    "title": "Curry Sausage",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "customcakes",
    "title": "Custom Cakes",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "DE",
      "CH",
      "AT",
      "FR"
    ],
    "rootParent": "food"
  },
  {
    "alias": "custommerchandise",
    "title": "Customized Merchandise",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "cypriot",
    "title": "Cypriot",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "czech",
    "title": "Czech",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DK",
      "CZ",
      "NO",
      "FI",
      "BE",
      "SE",
      "AU",
      "GB",
      "IT",
      "DE",
      "FR",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "czechslovakian",
    "title": "Czech\/Slovakian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MX",
      "AR",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "dagashi",
    "title": "Dagashi",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "food"
  },
  {
    "alias": "danceclubs",
    "title": "Dance Clubs",
    "parents": [
      "nightlife"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "dancerestaurants",
    "title": "Dance Restaurants",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "FI"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "dancewear",
    "title": "Dance Wear",
    "parents": [
      "sportswear"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "danish",
    "title": "Danish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "DK",
      "SE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "delicatessen",
    "title": "Delicatessen",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "delis",
    "title": "Delis",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "NL",
      "FR",
      "CL",
      "BE",
      "SE",
      "IT",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "deptstores",
    "title": "Department Stores",
    "parents": [
      "fashion",
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "desserts",
    "title": "Desserts",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "dimsum",
    "title": "Dim Sum",
    "parents": [
      "chinese"
    ],
    "country_blacklist": [
      "TR",
      "PT",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "diners",
    "title": "Diners",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI",
      "CZ",
      "AU",
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "dinnertheater",
    "title": "Dinner Theater",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "NL",
      "FR",
      "BE",
      "JP",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "discountstore",
    "title": "Discount Store",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "distilleries",
    "title": "Distilleries",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "divebars",
    "title": "Dive Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "NL",
      "CZ",
      "FR",
      "BE",
      "AU",
      "IT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "diveshops",
    "title": "Dive Shops",
    "parents": [
      "sportgoods"
    ],
    "country_whitelist": [
      "AT",
      "NZ",
      "MX",
      "JP",
      "AU",
      "ES",
      "IT",
      "DE",
      "CL",
      "AR",
      "BR",
      "CH",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "diyfood",
    "title": "Do-It-Yourself Food",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "CZ",
      "AT",
      "NZ",
      "MX",
      "FI",
      "SE",
      "IT",
      "DE",
      "FR",
      "CL",
      "CH",
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "dominican",
    "title": "Dominican",
    "parents": [
      "caribbean"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "donairs",
    "title": "Donairs",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CZ",
      "FR",
      "TR",
      "BE",
      "PL",
      "CA",
      "DK"
    ],
    "rootParent": "food"
  },
  {
    "alias": "donburi",
    "title": "Donburi",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "HK",
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "donuts",
    "title": "Donuts",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "driedfruit",
    "title": "Dried Fruit",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "CZ",
      "MX",
      "HK",
      "SE",
      "JP",
      "TW",
      "CL",
      "TR",
      "AR",
      "BR",
      "PL",
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "drivethrubars",
    "title": "Drive-Thru Bars",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "drugstores",
    "title": "Drugstores",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "NO",
      "FR",
      "MX",
      "CL",
      "TR",
      "AR",
      "SE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "dumplings",
    "title": "Dumplings",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "dutyfreeshops",
    "title": "Duty-Free Shops",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "eastern_european",
    "title": "Eastern European",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "CZ",
      "FR",
      "AU",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "easterngerman",
    "title": "Eastern German",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "easternmexican",
    "title": "Eastern Mexican",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "egyptian",
    "title": "Egyptian",
    "parents": [
      "mideastern"
    ],
    "country_whitelist": [
      "FR",
      "BE",
      "CA",
      "US",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "electronics",
    "title": "Electronics",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "elementaryschools",
    "title": "Elementary Schools",
    "parents": [
      "education"
    ],
    "rootParent": "elementaryschools"
  },
  {
    "alias": "eltern_cafes",
    "title": "Parent Cafes",
    "parents": [
      "restaurants",
      "food"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "embroideryandcrochet",
    "title": "Embroidery & Crochet",
    "parents": [
      "artsandcrafts"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "MX",
      "SE",
      "ES",
      "IT",
      "PT",
      "CL",
      "AR",
      "BR",
      "US",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "emilian",
    "title": "Emilian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "empanadas",
    "title": "Empanadas",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CL",
      "US",
      "AR",
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "ethicgrocery",
    "title": "Ethical Grocery",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "CZ",
      "AT",
      "BE",
      "JP",
      "MY",
      "NL",
      "DE",
      "PH",
      "FR",
      "TR",
      "CH",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "ethiopian",
    "title": "Ethiopian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "TR",
      "CZ",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fabricstores",
    "title": "Fabric Stores",
    "parents": [
      "artsandcrafts"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fado_houses",
    "title": "Fado Houses",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "falafel",
    "title": "Falafel",
    "parents": [
      "mediterranean"
    ],
    "country_blacklist": [
      "MX",
      "AR",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "farmersmarket",
    "title": "Farmers Market",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "farmingequipment",
    "title": "Farming Equipment",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fashion",
    "title": "Fashion",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fasil",
    "title": "Fasil Music",
    "parents": [
      "nightlife"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "filipino",
    "title": "Filipino",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "DK",
      "TR",
      "CZ",
      "FI"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fireworks",
    "title": "Fireworks",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "CZ",
      "NZ",
      "MX",
      "AU",
      "GB",
      "PT",
      "CL",
      "AR",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fischbroetchen",
    "title": "Fischbroetchen",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fishmonger",
    "title": "Fishmonger",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "AT",
      "FI",
      "BE",
      "SE",
      "AU",
      "IT",
      "PT",
      "NL",
      "DE",
      "PL",
      "DK"
    ],
    "rootParent": "food"
  },
  {
    "alias": "fishnchips",
    "title": "Fish & Chips",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "PT",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fitnessequipment",
    "title": "Fitness\/Exercise Equipment",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "flatbread",
    "title": "Flatbread",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "AT",
      "PL",
      "CH",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fleamarkets",
    "title": "Flea Markets",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "flemish",
    "title": "Flemish",
    "parents": [
      "belgian"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "florists",
    "title": "Florists",
    "parents": [
      "flowers"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "flowers",
    "title": "Flowers & Gifts",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fondue",
    "title": "Fondue",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "DK",
      "ES",
      "CZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "food",
    "title": "Food",
    "parents": [

    ],
    "rootParent": "food"
  },
  {
    "alias": "food_court",
    "title": "Food Court",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "FI",
      "TW",
      "FR",
      "CL",
      "TR",
      "AR",
      "PL",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "fooddeliveryservices",
    "title": "Food Delivery Services",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "foodstands",
    "title": "Food Stands",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "foodtrucks",
    "title": "Food Trucks",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "SG"
    ],
    "rootParent": "food"
  },
  {
    "alias": "formalwear",
    "title": "Formal Wear",
    "parents": [
      "fashion"
    ],
    "country_blacklist": [
      "FI",
      "HK",
      "SE",
      "JP",
      "GB",
      "MY",
      "IT",
      "TW",
      "PH",
      "TR",
      "PL",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "framing",
    "title": "Framing",
    "parents": [
      "artsandcrafts"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "franconian",
    "title": "Franconian",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "freiduria",
    "title": "Freiduria",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MX",
      "CL",
      "AR",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "french",
    "title": "French",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "friterie",
    "title": "Friterie",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "NL",
      "FR",
      "BE",
      "PL",
      "AU",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "friulan",
    "title": "Friulan",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "frozenfood",
    "title": "Frozen Food",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "SG",
      "FR",
      "BE",
      "GB",
      "ES",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "furclothing",
    "title": "Fur Clothing",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "furniture",
    "title": "Furniture Stores",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "fuzhou",
    "title": "Fuzhou",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "TW",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "galician",
    "title": "Galician",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "ES",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "gamemeat",
    "title": "Game Meat",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "FI",
      "HK",
      "JP",
      "MY",
      "PT",
      "TW",
      "PH",
      "CL",
      "TR",
      "AR",
      "BR",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "gardening",
    "title": "Nurseries & Gardening",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "gastropubs",
    "title": "Gastropubs",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "gaybars",
    "title": "Gay Bars",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "gelato",
    "title": "Gelato",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "NO",
      "CZ",
      "SE",
      "AU",
      "IT",
      "PT",
      "SG",
      "PH",
      "PL",
      "US",
      "DK"
    ],
    "rootParent": "food"
  },
  {
    "alias": "georgian",
    "title": "Georgian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "DE",
      "AT",
      "FI",
      "PL",
      "CH",
      "GB"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "german",
    "title": "German",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "giblets",
    "title": "Giblets",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "giftshops",
    "title": "Gift Shops",
    "parents": [
      "flowers"
    ],
    "country_blacklist": [
      "TR",
      "SG",
      "PL"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "gluhwein",
    "title": "Mulled Wine",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CZ",
      "DE",
      "AT",
      "SE",
      "CH"
    ],
    "rootParent": "food"
  },
  {
    "alias": "gluten_free",
    "title": "Gluten-Free",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "goldbuyers",
    "title": "Gold Buyers",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "AT",
      "BE",
      "SE",
      "ES",
      "IT",
      "PT",
      "NL",
      "DE",
      "FR",
      "BR",
      "CH",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "golfequipment",
    "title": "Golf Equipment",
    "parents": [
      "sportgoods"
    ],
    "country_whitelist": [
      "NL",
      "DE",
      "BE",
      "AU",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "gourmet",
    "title": "Specialty Food",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "gozleme",
    "title": "Gozleme",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "greek",
    "title": "Greek",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "grillingequipment",
    "title": "Grilling Equipment",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "grocery",
    "title": "Grocery",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "guamanian",
    "title": "Guamanian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FR",
      "MX",
      "CL",
      "TR",
      "AR",
      "PL",
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "guns_and_ammo",
    "title": "Guns & Ammo",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "AT",
      "MX",
      "ES",
      "MY",
      "PT",
      "DE",
      "PH",
      "CL",
      "AR",
      "PL",
      "CH",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "gyudon",
    "title": "Gyudon",
    "parents": [
      "donburi"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hainan",
    "title": "Hainan",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "SG",
      "PH",
      "HK",
      "US",
      "MY"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "haitian",
    "title": "Haitian",
    "parents": [
      "caribbean"
    ],
    "country_whitelist": [
      "US",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hakka",
    "title": "Hakka",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "PH",
      "HK",
      "CA",
      "MY"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "halal",
    "title": "Halal",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "handrolls",
    "title": "Hand Rolls",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "TW",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hardware",
    "title": "Hardware Stores",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "hats",
    "title": "Hats",
    "parents": [
      "fashion"
    ],
    "country_blacklist": [
      "AT",
      "HK",
      "SE",
      "JP",
      "GB",
      "TR",
      "PL",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "hawaiian",
    "title": "Hawaiian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "TR",
      "AU",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hawkercentre",
    "title": "Hawker Centre",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "PH",
      "HK",
      "MY"
    ],
    "rootParent": "food"
  },
  {
    "alias": "headshops",
    "title": "Head Shops",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "healthmarkets",
    "title": "Health Markets",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "henghwa",
    "title": "Henghwa",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "TW",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "herbsandspices",
    "title": "Herbs & Spices",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "hessian",
    "title": "Hessian",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "heuriger",
    "title": "Heuriger",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hifi",
    "title": "High Fidelity Audio Equipment",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "highschools",
    "title": "Middle Schools & High Schools",
    "parents": [
      "education"
    ],
    "rootParent": "highschools"
  },
  {
    "alias": "himalayan",
    "title": "Himalayan\/Nepalese",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hkcafe",
    "title": "Hong Kong Style Cafe",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "HK",
      "US",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hobbyshops",
    "title": "Hobby Shops",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "hokkien",
    "title": "Hokkien",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "TW",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "holidaydecorations",
    "title": "Holiday Decorations",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "homeandgarden",
    "title": "Home & Garden",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "homedecor",
    "title": "Home Decor",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "homemadefood",
    "title": "Homemade Food",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "honduran",
    "title": "Honduran",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "honey",
    "title": "Honey",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "FI",
      "SE",
      "IT",
      "DE",
      "FR",
      "TR",
      "PL",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "hookah_bars",
    "title": "Hookah Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "CL",
      "SG",
      "AU",
      "PT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "horsequipment",
    "title": "Horse Equipment Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "NZ",
      "HK",
      "JP",
      "MY",
      "TW",
      "PH",
      "TR",
      "CH"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "horumon",
    "title": "Horumon",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hospitals",
    "title": "Hospitals",
    "parents": [
      "health"
    ],
    "rootParent": "hospitals"
  },
  {
    "alias": "hotdog",
    "title": "Hot Dogs",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hotdogs",
    "title": "Fast Food",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hotel_bar",
    "title": "Hotel bar",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "NO",
      "FI",
      "BR",
      "SE",
      "DK",
      "PT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "hotpot",
    "title": "Hot Pot",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "HK",
      "SE",
      "JP",
      "MY",
      "SG",
      "TW",
      "PH",
      "FR",
      "BR",
      "CA",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hottubandpool",
    "title": "Hot Tub & Pool",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "hunan",
    "title": "Hunan",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "FR",
      "HK",
      "MY"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "hungarian",
    "title": "Hungarian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "huntingfishingsupplies",
    "title": "Hunting & Fishing Supplies",
    "parents": [
      "sportgoods"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "hydroponics",
    "title": "Hydroponics",
    "parents": [
      "gardening"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "iberian",
    "title": "Iberian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "US",
      "PT",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "icecream",
    "title": "Ice Cream & Frozen Yogurt",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "importedfood",
    "title": "Imported Food",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "indonesian",
    "title": "Indonesian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "MX",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "indpak",
    "title": "Indian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "international",
    "title": "International",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "PH",
      "FI",
      "TR",
      "US",
      "MY",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "internetcafe",
    "title": "Internet Cafes",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "intlgrocery",
    "title": "International Grocery",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "irish",
    "title": "Irish",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "irish_pubs",
    "title": "Irish Pub",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "island_pub",
    "title": "Island Pub",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "israeli",
    "title": "Israeli",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CZ",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "italian",
    "title": "Italian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "izakaya",
    "title": "Izakaya",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "NZ",
      "MX",
      "HK",
      "JP",
      "AU",
      "SG",
      "TW",
      "BR",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "jaliscan",
    "title": "Jaliscan",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "japacurry",
    "title": "Japanese Curry",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "HK",
      "JP",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "japanese",
    "title": "Japanese",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "jewelry",
    "title": "Jewelry",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "jewish",
    "title": "Jewish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "IT",
      "PL"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "jpsweets",
    "title": "Japanese Sweets",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "food"
  },
  {
    "alias": "juicebars",
    "title": "Juice Bars & Smoothies",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "kaiseki",
    "title": "Kaiseki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "karaoke",
    "title": "Karaoke",
    "parents": [
      "nightlife"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "kebab",
    "title": "Kebab",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "kimonos",
    "title": "Kimonos",
    "parents": [
      "fashion"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "kiosk",
    "title": "Kiosk",
    "parents": [
      "food",
      "shopping"
    ],
    "country_blacklist": [
      "NZ",
      "HK",
      "GB",
      "IT",
      "SG",
      "FR",
      "BR",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "food"
  },
  {
    "alias": "kitchenandbath",
    "title": "Kitchen & Bath",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "knittingsupplies",
    "title": "Knitting Supplies",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "kombucha",
    "title": "Kombucha",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "kopitiam",
    "title": "Kopitiam",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MY",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "korean",
    "title": "Korean",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "kosher",
    "title": "Kosher",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "kurdish",
    "title": "Kurdish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "kushikatsu",
    "title": "Kushikatsu",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "lahmacun",
    "title": "Lahmacun",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "laos",
    "title": "Laos",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "laotian",
    "title": "Laotian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "latin",
    "title": "Latin American",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "leather",
    "title": "Leather Goods",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "lebanese",
    "title": "Lebanese",
    "parents": [
      "mideastern"
    ],
    "country_blacklist": [
      "HK",
      "JP",
      "AR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ligurian",
    "title": "Ligurian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "linens",
    "title": "Linens",
    "parents": [
      "homeandgarden"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "AT",
      "BE",
      "IT",
      "PT",
      "DE",
      "FR",
      "CH",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "lingerie",
    "title": "Lingerie",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "livestocksupply",
    "title": "Livestock Feed & Supply",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "lounges",
    "title": "Lounges",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "luggage",
    "title": "Luggage",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "lumbard",
    "title": "Lumbard",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "lyonnais",
    "title": "Lyonnais",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "BE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "macarons",
    "title": "Macarons",
    "parents": [
      "gourmet"
    ],
    "country_blacklist": [
      "MY",
      "PH",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "madeira",
    "title": "Madeira",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "mags",
    "title": "Newspapers & Magazines",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "malaysian",
    "title": "Malaysian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "ES",
      "CZ",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "mamak",
    "title": "Mamak",
    "parents": [
      "malaysian"
    ],
    "country_whitelist": [
      "MY",
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "markets",
    "title": "Fruits & Veggies",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "marketstalls",
    "title": "Market Stalls",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "DK",
      "CZ",
      "NO",
      "AT",
      "GB",
      "IT",
      "PT",
      "DE",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "materialeelettrico",
    "title": "Materiale elettrico",
    "parents": [
      "homeandgarden"
    ],
    "country_whitelist": [
      "MX",
      "CL",
      "AR",
      "ES",
      "IT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "maternity",
    "title": "Maternity Wear",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "mattresses",
    "title": "Mattresses",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "mauritius",
    "title": "Mauritius",
    "parents": [
      "french"
    ],
    "country_blacklist": [
      "NO",
      "AT",
      "NZ",
      "MX",
      "JP",
      "AU",
      "DE",
      "CL",
      "TR",
      "AR",
      "BR",
      "PL",
      "CH",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "meatballs",
    "title": "Meatballs",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR",
      "NL",
      "BE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "meats",
    "title": "Meat Shops",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "media",
    "title": "Books, Mags, Music & Video",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "medicalsupplies",
    "title": "Medical Supplies",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "mediterranean",
    "title": "Mediterranean",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "menscloth",
    "title": "Men's Clothing",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "mexican",
    "title": "Mexican",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "mideastern",
    "title": "Middle Eastern",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "militarysurplus",
    "title": "Military Surplus",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "milkbars",
    "title": "Milk Bars",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AU",
      "PL"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "milkshakebars",
    "title": "Milkshake Bars",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "GB"
    ],
    "rootParent": "food"
  },
  {
    "alias": "minho",
    "title": "Minho",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "mobilephones",
    "title": "Mobile Phones",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "modern_australian",
    "title": "Modern Australian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "modern_european",
    "title": "Modern European",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "mongolian",
    "title": "Mongolian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "FI",
      "ES",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "moroccan",
    "title": "Moroccan",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "motorcyclinggear",
    "title": "Motorcycle Gear",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "NZ",
      "IE",
      "GB",
      "CA"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "musicalinstrumentsandteachers",
    "title": "Musical Instruments & Teachers",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "musicvideo",
    "title": "Music & DVDs",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "napoletana",
    "title": "Napoletana",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "CZ",
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nasilemak",
    "title": "Nasi Lemak",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "MY",
      "SG"
    ],
    "rootParent": "food"
  },
  {
    "alias": "newamerican",
    "title": "American (New)",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DK",
      "NO",
      "SE",
      "GB",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "newcanadian",
    "title": "Canadian (New)",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "newmexican",
    "title": "New Mexican Cuisine",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "newzealand",
    "title": "New Zealand",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nicaraguan",
    "title": "Nicaraguan",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CL",
      "AR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nicois",
    "title": "Nicoise",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nightfood",
    "title": "Night Food",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "TR",
      "SE",
      "PL",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nightlife",
    "title": "Nightlife",
    "parents": [

    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "nikkei",
    "title": "Nikkei",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "MX",
      "CL",
      "AR",
      "BR",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "noodles",
    "title": "Noodles",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "DE",
      "CH",
      "AT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "norcinerie",
    "title": "Norcinerie",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "northeasternbrazilian",
    "title": "Northeastern Brazilian",
    "parents": [
      "brazilian"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "northernbrazilian",
    "title": "Northern Brazilian",
    "parents": [
      "brazilian"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "northerngerman",
    "title": "Northern German",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "northernmexican",
    "title": "Northern Mexican",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "norwegian",
    "title": "Traditional Norwegian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "nyonya",
    "title": "Nyonya",
    "parents": [
      "malaysian"
    ],
    "country_whitelist": [
      "MY",
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "oaxacan",
    "title": "Oaxacan",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "oden",
    "title": "Oden",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "officeequipment",
    "title": "Office Equipment",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "okinawan",
    "title": "Okinawan",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "okonomiyaki",
    "title": "Okonomiyaki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "oliveoil",
    "title": "Olive Oil",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "DE",
      "AT",
      "FR",
      "BE",
      "US",
      "ES"
    ],
    "rootParent": "food"
  },
  {
    "alias": "onigiri",
    "title": "Onigiri",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "opensandwiches",
    "title": "Open Sandwiches",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR",
      "NO",
      "DK",
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "opticians",
    "title": "Eyewear & Opticians",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "organic_stores",
    "title": "Organic Stores",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "oriental",
    "title": "Oriental",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "AT",
      "FR",
      "CH",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ottomancuisine",
    "title": "Ottoman Cuisine",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "outdoorfurniture",
    "title": "Outdoor Furniture Stores",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "outdoorgear",
    "title": "Outdoor Gear",
    "parents": [
      "sportgoods"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "outlet_stores",
    "title": "Outlet Stores",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "oyakodon",
    "title": "Oyakodon",
    "parents": [
      "donburi"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "packingsupplies",
    "title": "Packing Supplies",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "AT",
      "BE",
      "JP",
      "PT",
      "NL",
      "DE",
      "FR",
      "BR",
      "CH",
      "US",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "paintstores",
    "title": "Paint Stores",
    "parents": [
      "homeandgarden"
    ],
    "country_blacklist": [
      "TW",
      "PH",
      "FI",
      "HK",
      "TR",
      "MY",
      "PT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "paintyourownpottery",
    "title": "Paint-Your-Own Pottery",
    "parents": [
      "artsandcrafts"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "SG",
      "TW",
      "PH",
      "DE",
      "CA",
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "pakistani",
    "title": "Pakistani",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "palatine",
    "title": "Palatine",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "panasian",
    "title": "Pan Asian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "panzerotti",
    "title": "Panzerotti",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "parma",
    "title": "Parma",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pastashops",
    "title": "Pasta Shops",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "DK",
      "CZ",
      "NO",
      "NZ",
      "BE",
      "JP",
      "AU",
      "GB",
      "IT",
      "NL",
      "SG",
      "CL",
      "AR",
      "US",
      "IE"
    ],
    "rootParent": "food"
  },
  {
    "alias": "pawn",
    "title": "Pawn Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "TR",
      "BR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "pekinese",
    "title": "Pekinese",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "FR",
      "HK",
      "JP",
      "MY",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "persian",
    "title": "Persian\/Iranian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "personal_shopping",
    "title": "Personal Shopping",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "CZ",
      "MX",
      "CL",
      "AR",
      "BR",
      "ES"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "peruvian",
    "title": "Peruvian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "SG",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pfcomercial",
    "title": "PF\/Comercial",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "photographystores",
    "title": "Photography Stores & Services",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "piadina",
    "title": "Piadina",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "US",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "pianobars",
    "title": "Piano Bars",
    "parents": [
      "nightlife"
    ],
    "country_blacklist": [
      "AT",
      "NZ",
      "FI",
      "AU",
      "SG",
      "BR",
      "PL",
      "CH",
      "ES",
      "IE"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "piemonte",
    "title": "Piemonte",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pierogis",
    "title": "Pierogis",
    "parents": [
      "polish"
    ],
    "country_whitelist": [
      "PL"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pita",
    "title": "Pita",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pizza",
    "title": "Pizza",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "playsets",
    "title": "Playsets",
    "parents": [
      "homeandgarden"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "plus_size_fashion",
    "title": "Plus Size Fashion",
    "parents": [
      "fashion"
    ],
    "country_blacklist": [
      "MX",
      "HK",
      "JP",
      "GB",
      "SG",
      "CL",
      "TR",
      "AR",
      "PL",
      "CA",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "poke",
    "title": "Poke",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "NL",
      "FR",
      "BE",
      "SE",
      "CA",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "polish",
    "title": "Polish",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "poolbilliards",
    "title": "Pool & Billiards",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "CZ",
      "NO",
      "AT",
      "MX",
      "FI",
      "SE",
      "DE",
      "CL",
      "AR",
      "PL",
      "CH",
      "ES"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "poolhalls",
    "title": "Pool Halls",
    "parents": [
      "nightlife"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "popcorn",
    "title": "Popcorn Shops",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "JP",
      "US",
      "GB"
    ],
    "rootParent": "food"
  },
  {
    "alias": "popuprestaurants",
    "title": "Pop-Up Restaurants",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DK",
      "NO",
      "HK",
      "BE",
      "SE",
      "GB",
      "MY",
      "NL",
      "SG",
      "TW",
      "DE",
      "PH",
      "FR",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "popupshops",
    "title": "Pop-up Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "PT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "portuguese",
    "title": "Portuguese",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "potatoes",
    "title": "Potatoes",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CH",
      "DE",
      "AU",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "poutineries",
    "title": "Poutineries",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "US",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "preschools",
    "title": "Preschools",
    "parents": [
      "education"
    ],
    "country_blacklist": [
      "DK"
    ],
    "rootParent": "preschools"
  },
  {
    "alias": "pretzels",
    "title": "Pretzels",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "US",
      "DE",
      "CH",
      "PT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "props",
    "title": "Props",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "US",
      "BE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "provencal",
    "title": "Provencal",
    "parents": [
      "french"
    ],
    "country_whitelist": [
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pubfood",
    "title": "Pub Food",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "publicmarkets",
    "title": "Public Markets",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "pubs",
    "title": "Pubs",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "pueblan",
    "title": "Pueblan",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "puertorican",
    "title": "Puerto Rican",
    "parents": [
      "caribbean"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "pulquerias",
    "title": "Pulquerias",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "pumpkinpatches",
    "title": "Pumpkin Patches",
    "parents": [
      "homeandgarden"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "ramen",
    "title": "Ramen",
    "parents": [
      "japanese"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "raw_food",
    "title": "Live\/Raw Food",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "religiousitems",
    "title": "Religious Items",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "restaurants",
    "title": "Restaurants",
    "parents": [

    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "reunion",
    "title": "Reunion",
    "parents": [
      "french"
    ],
    "country_blacklist": [
      "NO",
      "AT",
      "NZ",
      "MX",
      "JP",
      "AU",
      "DE",
      "CL",
      "TR",
      "AR",
      "BR",
      "PL",
      "CH",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "rhinelandian",
    "title": "Rhinelandian",
    "parents": [
      "german"
    ],
    "country_whitelist": [
      "DE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ribatejo",
    "title": "Ribatejo",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "riceshop",
    "title": "Rice",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR",
      "JP"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "robatayaki",
    "title": "Robatayaki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "HK",
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "rodizios",
    "title": "Rodizios",
    "parents": [
      "brazilian"
    ],
    "country_whitelist": [
      "PT",
      "AR",
      "BR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "roman",
    "title": "Roman",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "JP",
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "romanian",
    "title": "Romanian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "AT",
      "BE",
      "DE",
      "FR",
      "PL",
      "CH",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "rotisserie_chicken",
    "title": "Rotisserie Chicken",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NZ",
      "MX",
      "BE",
      "AU",
      "IT",
      "PT",
      "NL",
      "FR",
      "CL",
      "AR",
      "BR",
      "PL",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "rugs",
    "title": "Rugs",
    "parents": [
      "homeandgarden"
    ],
    "country_blacklist": [
      "CZ",
      "AT",
      "HK",
      "JP",
      "DE",
      "PL",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "russian",
    "title": "Russian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "safestores",
    "title": "Safe Stores",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "safetyequipment",
    "title": "Safety Equipment",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sakebars",
    "title": "Sake Bars",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "salad",
    "title": "Salad",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "salumerie",
    "title": "Salumerie",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "MX",
      "CL",
      "AR",
      "ES",
      "IT",
      "PT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "salvadoran",
    "title": "Salvadoran",
    "parents": [
      "latin"
    ],
    "country_whitelist": [
      "US",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "sandwiches",
    "title": "Sandwiches",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "sardinian",
    "title": "Sardinian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "US",
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "scandinavian",
    "title": "Scandinavian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "scandinaviandesign",
    "title": "Scandinavian Design",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "FI",
      "NO",
      "DK",
      "SE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "schnitzel",
    "title": "Schnitzel",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT",
      "PL"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "scottish",
    "title": "Scottish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "AT",
      "CA",
      "CH",
      "GB",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "seafood",
    "title": "Seafood",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "seafoodmarkets",
    "title": "Seafood Markets",
    "parents": [
      "gourmet"
    ],
    "rootParent": "food"
  },
  {
    "alias": "senegalese",
    "title": "Senegalese",
    "parents": [
      "african"
    ],
    "country_whitelist": [
      "FR",
      "BE",
      "CA",
      "US",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "serbocroatian",
    "title": "Serbo Croatian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "AT",
      "BE",
      "SE",
      "IT",
      "DE",
      "FR",
      "CL",
      "AR",
      "PL",
      "CH"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "shanghainese",
    "title": "Shanghainese",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "BE",
      "SE",
      "JP",
      "AU",
      "MY",
      "IT",
      "SG",
      "TW",
      "FR",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "shavedice",
    "title": "Shaved Ice",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CZ",
      "MX",
      "JP",
      "IT",
      "SG",
      "TW",
      "CL",
      "AR",
      "US"
    ],
    "rootParent": "food"
  },
  {
    "alias": "shavedsnow",
    "title": "Shaved Snow",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "SG",
      "TW",
      "HK",
      "CA",
      "US",
      "MY"
    ],
    "rootParent": "food"
  },
  {
    "alias": "shoes",
    "title": "Shoe Stores",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "shopping",
    "title": "Shopping",
    "parents": [

    ],
    "rootParent": "shopping"
  },
  {
    "alias": "shoppingcenters",
    "title": "Shopping Centers",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "shoppingpassages",
    "title": "Shopping Passages",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "US"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sicilian",
    "title": "Sicilian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "CZ",
      "FR",
      "PL",
      "US",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "signature_cuisine",
    "title": "Signature Cuisine",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "MX",
      "SE",
      "PT",
      "CL",
      "AR",
      "ES",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "singaporean",
    "title": "Singaporean",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "FI",
      "TR",
      "ES",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "skateshops",
    "title": "Skate Shops",
    "parents": [
      "sportgoods"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "skishops",
    "title": "Ski & Snowboard Shops",
    "parents": [
      "sportgoods"
    ],
    "country_blacklist": [
      "MX",
      "HK",
      "MY",
      "PT",
      "SG",
      "TW",
      "PH",
      "TR",
      "BR",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sleepwear",
    "title": "Sleepwear",
    "parents": [
      "fashion"
    ],
    "country_whitelist": [
      "AU",
      "PT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "slovakian",
    "title": "Slovakian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "BE",
      "AU",
      "GB",
      "IT",
      "FR",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "smokehouse",
    "title": "Smokehouse",
    "parents": [
      "food"
    ],
    "country_blacklist": [
      "FR",
      "MX",
      "CL",
      "TR",
      "AR",
      "PL",
      "IT"
    ],
    "rootParent": "food"
  },
  {
    "alias": "soba",
    "title": "Soba",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "soulfood",
    "title": "Soul Food",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "SE",
      "GB",
      "ES",
      "NL",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "soup",
    "title": "Soup",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "southafrican",
    "title": "South African",
    "parents": [
      "african"
    ],
    "country_whitelist": [
      "FR",
      "BE",
      "AU",
      "CA",
      "US",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "southern",
    "title": "Southern",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NZ",
      "SE",
      "GB",
      "NL",
      "TR",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "souvenirs",
    "title": "Souvenir Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "SG",
      "CA"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "spanish",
    "title": "Spanish",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "speakeasies",
    "title": "Speakeasies",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "NO",
      "FR",
      "TR",
      "PL",
      "DK",
      "IT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "spiritual_shop",
    "title": "Spiritual Shop",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sportgoods",
    "title": "Sporting Goods",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sportsbars",
    "title": "Sports Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "CH",
      "AT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "sportswear",
    "title": "Sports Wear",
    "parents": [
      "fashion",
      "sportgoods"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "srilankan",
    "title": "Sri Lankan",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "stationery",
    "title": "Cards & Stationery",
    "parents": [
      "flowers",
      "artsandcrafts",
      "eventservices"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "steak",
    "title": "Steakhouses",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "stockings",
    "title": "Stockings",
    "parents": [
      "fashion"
    ],
    "country_whitelist": [
      "NL",
      "CZ",
      "DE",
      "AT",
      "BE",
      "CH",
      "PT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "streetvendors",
    "title": "Street Vendors",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "stripclubs",
    "title": "Strip Clubs",
    "parents": [
      "adultentertainment"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "stripteasedancers",
    "title": "Striptease Dancers",
    "parents": [
      "adultentertainment"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "sud_ouest",
    "title": "French Southwest",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "BE",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "sugarshacks",
    "title": "Sugar Shacks",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "CA"
    ],
    "rootParent": "food"
  },
  {
    "alias": "sukiyaki",
    "title": "Sukiyaki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "supperclubs",
    "title": "Supper Clubs",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "ES",
      "GB",
      "US",
      "CA"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "surfshop",
    "title": "Surf Shop",
    "parents": [
      "fashion"
    ],
    "country_blacklist": [
      "CZ",
      "NO",
      "FI",
      "BE",
      "SE",
      "NL",
      "SG",
      "FR",
      "BR",
      "CA",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "sushi",
    "title": "Sushi Bars",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "swabian",
    "title": "Swabian",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "swedish",
    "title": "Swedish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "swimwear",
    "title": "Swimwear",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "swissfood",
    "title": "Swiss Food",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "CZ",
      "DE",
      "MX",
      "CL",
      "AR",
      "CH",
      "ES"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "syrian",
    "title": "Syrian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "szechuan",
    "title": "Szechuan",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "NZ",
      "HK",
      "JP",
      "AU",
      "GB",
      "MY",
      "SG",
      "TW",
      "FR",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tabac",
    "title": "Tabac",
    "parents": [
      "bars"
    ],
    "country_whitelist": [
      "CZ",
      "FR",
      "BE",
      "ES",
      "IT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "tabernas",
    "title": "Tabernas",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "TR",
      "ES",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tabletopgames",
    "title": "Tabletop Games",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tableware",
    "title": "Tableware",
    "parents": [
      "homeandgarden"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "AT",
      "MX",
      "BE",
      "PT",
      "NL",
      "DE",
      "FR",
      "CL",
      "AR",
      "BR",
      "CH",
      "US",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tacos",
    "title": "Tacos",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "taiwanese",
    "title": "Taiwanese",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "CZ",
      "FI",
      "TR",
      "ES",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "taiyaki",
    "title": "Taiyaki",
    "parents": [
      "jpsweets"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "food"
  },
  {
    "alias": "takoyaki",
    "title": "Takoyaki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tamales",
    "title": "Tamales",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tapas",
    "title": "Tapas Bars",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "FI",
      "SG",
      "AU"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tapasmallplates",
    "title": "Tapas\/Small Plates",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tavolacalda",
    "title": "Tavola Calda",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tea",
    "title": "Tea Rooms",
    "parents": [
      "food"
    ],
    "rootParent": "food"
  },
  {
    "alias": "teachersupplies",
    "title": "Teacher Supplies",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "IT",
      "FR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tempura",
    "title": "Tempura",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "SG",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "teochew",
    "title": "Teochew",
    "parents": [
      "chinese"
    ],
    "country_whitelist": [
      "HK",
      "MY",
      "TW",
      "SG"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "teppanyaki",
    "title": "Teppanyaki",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "NZ",
      "MX",
      "HK",
      "JP",
      "AU",
      "SG",
      "TW",
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tex-mex",
    "title": "Tex-Mex",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "ES",
      "DK",
      "AU",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "thai",
    "title": "Thai",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "themedcafes",
    "title": "Themed Cafes",
    "parents": [
      "cafes"
    ],
    "country_whitelist": [
      "FI",
      "HK",
      "BE",
      "JP",
      "MY",
      "NL",
      "SG",
      "TW",
      "DE",
      "PH",
      "FR",
      "PL",
      "US"
    ],
    "rootParent": "cafes"
  },
  {
    "alias": "thrift_stores",
    "title": "Thrift Stores",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "FI"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tickets",
    "title": "Tickets",
    "parents": [
      "shopping"
    ],
    "country_whitelist": [
      "CZ",
      "NO",
      "FI",
      "SE",
      "PT",
      "CL",
      "PL",
      "DK"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tikibars",
    "title": "Tiki Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "DE",
      "AT",
      "FR",
      "TR",
      "PL",
      "JP",
      "CH"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "tobaccoshops",
    "title": "Tobacco Shops",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tofu",
    "title": "Tofu Shops",
    "parents": [
      "gourmet"
    ],
    "country_whitelist": [
      "JP"
    ],
    "rootParent": "food"
  },
  {
    "alias": "tonkatsu",
    "title": "Tonkatsu",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "SG",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "torshi",
    "title": "Torshi",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "food"
  },
  {
    "alias": "tortillas",
    "title": "Tortillas",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "food"
  },
  {
    "alias": "toys",
    "title": "Toy Stores",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "tradamerican",
    "title": "American (Traditional)",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tradclothing",
    "title": "Traditional Clothing",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "traditional_swedish",
    "title": "Traditional Swedish",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "FI",
      "SE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tras_os_montes",
    "title": "Tras-os-Montes",
    "parents": [
      "portuguese"
    ],
    "country_whitelist": [
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "trattorie",
    "title": "Trattorie",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "FR",
      "CL",
      "AR",
      "PL",
      "CH",
      "IT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "trinidadian",
    "title": "Trinidadian",
    "parents": [
      "caribbean"
    ],
    "country_whitelist": [
      "US"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "trophyshops",
    "title": "Trophy Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "SG",
      "NZ",
      "FI",
      "TR",
      "SE",
      "BR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "turkish",
    "title": "Turkish",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "turkishravioli",
    "title": "Turkish Ravioli",
    "parents": [
      "turkish"
    ],
    "country_whitelist": [
      "TR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "tuscan",
    "title": "Tuscan",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "US",
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "udon",
    "title": "Udon",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "NO",
      "TW",
      "FI",
      "HK",
      "SE",
      "JP",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "ukrainian",
    "title": "Ukrainian",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "TR",
      "ES",
      "DK"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "unagi",
    "title": "Unagi",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "uniforms",
    "title": "Uniforms",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "CZ",
      "AT",
      "SE",
      "JP",
      "NL",
      "TR",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "usedbooks",
    "title": "Used Bookstore",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "TR",
      "CL",
      "AR",
      "AU",
      "CA"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "uzbek",
    "title": "Uzbek",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "US",
      "CZ"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "vapeshops",
    "title": "Vape Shops",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "SG",
      "BR"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "vegan",
    "title": "Vegan",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "vegetarian",
    "title": "Vegetarian",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "venetian",
    "title": "Venetian",
    "parents": [
      "italian"
    ],
    "country_whitelist": [
      "IT",
      "FR"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "venezuelan",
    "title": "Venezuelan",
    "parents": [
      "latin"
    ],
    "country_whitelist": [
      "FR",
      "CL",
      "AR",
      "US",
      "CA",
      "ES",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "venison",
    "title": "Venison",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "DK",
      "CZ",
      "NO",
      "NZ",
      "AU",
      "GB",
      "IT",
      "SG",
      "PL",
      "CA",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "vermouthbars",
    "title": "Vermouth Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "NO",
      "JP",
      "IT",
      "FR",
      "TR",
      "BR",
      "PL",
      "DK"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "videoandgames",
    "title": "Videos & Video Game Rental",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "videogamestores",
    "title": "Video Game Stores",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "vietnamese",
    "title": "Vietnamese",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "vintage",
    "title": "Used, Vintage & Consignment",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "vinyl_records",
    "title": "Vinyl Records",
    "parents": [
      "media"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "vitaminssupplements",
    "title": "Vitamins & Supplements",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "AT",
      "HK",
      "AU",
      "GB",
      "MY",
      "SG",
      "TW",
      "PH",
      "CH",
      "CA",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "waffles",
    "title": "Waffles",
    "parents": [
      "restaurants"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "watches",
    "title": "Watches",
    "parents": [
      "shopping"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "waterstores",
    "title": "Water Stores",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "MX",
      "US",
      "BR"
    ],
    "rootParent": "food"
  },
  {
    "alias": "westernjapanese",
    "title": "Western Style Japanese Food",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "SG",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "whiskeybars",
    "title": "Whiskey Bars",
    "parents": [
      "bars"
    ],
    "country_blacklist": [
      "IT"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "wholesale_stores",
    "title": "Wholesale Stores",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "DE",
      "CH",
      "AT"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "wigs",
    "title": "Wigs",
    "parents": [
      "shopping"
    ],
    "country_blacklist": [
      "AT",
      "NZ",
      "HK",
      "SE",
      "JP",
      "GB",
      "MY",
      "SG",
      "TW",
      "PH",
      "TR",
      "BR",
      "CH",
      "IE"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "wine_bars",
    "title": "Wine Bars",
    "parents": [
      "bars"
    ],
    "rootParent": "nightlife"
  },
  {
    "alias": "wok",
    "title": "Wok",
    "parents": [
      "restaurants"
    ],
    "country_blacklist": [
      "NZ",
      "HK",
      "JP",
      "AU",
      "GB",
      "IT",
      "SG",
      "TW",
      "TR",
      "AR",
      "BR",
      "PL",
      "CA",
      "US",
      "IE"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "womenscloth",
    "title": "Women's Clothing",
    "parents": [
      "fashion"
    ],
    "rootParent": "shopping"
  },
  {
    "alias": "wraps",
    "title": "Wraps",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "NO",
      "CZ",
      "TR",
      "SE",
      "US",
      "DK",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "yakiniku",
    "title": "Yakiniku",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "SG",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "yakitori",
    "title": "Yakitori",
    "parents": [
      "japanese"
    ],
    "country_whitelist": [
      "JP",
      "SG",
      "TW"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "yucatan",
    "title": "Yucatan",
    "parents": [
      "mexican"
    ],
    "country_whitelist": [
      "MX"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "yugoslav",
    "title": "Yugoslav",
    "parents": [
      "restaurants"
    ],
    "country_whitelist": [
      "FR",
      "BE",
      "SE",
      "AU",
      "IT",
      "PT"
    ],
    "rootParent": "restaurants"
  },
  {
    "alias": "zapiekanka",
    "title": "Zapiekanka",
    "parents": [
      "food"
    ],
    "country_whitelist": [
      "PL"
    ],
    "rootParent": "food"
  }
]
