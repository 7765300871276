
import $ from 'jquery';
import 'bootstrap';

function loadCarouselSlideImage(image: HTMLImageElement) {
  const slideSrc = image.dataset.slideSrc;
  const slideSrcset = image.dataset.slideSrcset;

  if (slideSrc && image.src !== slideSrc) {
    image.src = slideSrc;
  }

  if (slideSrcset && image.srcset !== slideSrcset) {
    image.srcset = slideSrcset;
  }
}

function loadCarouselSlideImageJq(lazy: JQuery<HTMLImageElement>) {
  if (lazy.length > 0) {
    loadCarouselSlideImage(lazy[0]);
  }
}


// This is from
// https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
document.addEventListener('DOMContentLoaded', () => {
  // console.log(carousels);

  let lazyImages: HTMLImageElement[] = [].slice.call(
    document.querySelectorAll('img[data-src], img[data-srcset]')
  );

  if ('IntersectionObserver' in window) {
    let lazyImageObserver = new IntersectionObserver((entries, observer) => {
      // console.log(entries);
      entries.forEach((entry) => {
        let lazyImage = entry.target as HTMLImageElement;
        
        if (entry.isIntersecting) {
          // console.log('Now visible, loading:', lazyImage);
          if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src;
          }
  
          if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
          }
          
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach((lazyImage) => {
      lazyImageObserver.observe(lazyImage);
    });
  }
});

// Carousel next-image-preloader
$(() => {
  // See this for the general idea:
  // https://coderwall.com/p/6qaeya/lazy-carousel-in-bootstrap

  const carousels = $('.carousel.hi-lazy');

  carousels.carousel({ interval: false })
  carousels.on('slide.bs.carousel', (event) => {
    const lazy = $(event.relatedTarget).find<HTMLImageElement>('img[data-slide-src]');
    // console.log(lazy, lazy.data('slide-src'), lazy.data('slide-srcset'));

    const item = lazy.closest('.carousel-item');
    const nextImg = item.next().find<HTMLImageElement>('img[data-slide-src], img[data-slide-srcset]');
    const prevImg = item.prev().find<HTMLImageElement>('img[data-slide-src], img[data-slide-srcset]');

    // Run this in case the user skipped via the carousel dashes
    loadCarouselSlideImageJq(lazy);

    // Then load the adjacent images
    loadCarouselSlideImageJq(prevImg);
    loadCarouselSlideImageJq(nextImg);
  });
});

// Carousel initial preloader
document.addEventListener('DOMContentLoaded', () => {
  const carousels = document.querySelectorAll('.carousel.hi-lazy');

  if ('IntersectionObserver' in window) {
    const carouselObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const carousel = entry.target;
          const lastImage = carousel.querySelector<HTMLImageElement>('.carousel-item:last-of-type > img[data-slide-src]');
          const secondImage = carousel.querySelector<HTMLImageElement>('.carousel-item:nth-of-type(2) > img[data-slide-src]')

          if (carousel.classList.contains('hi-carousel--preload-initial-images')) {
            if (lastImage) {
              loadCarouselSlideImage(lastImage);
            }
  
            if (secondImage) {
              loadCarouselSlideImage(secondImage);
            }
          }

          carouselObserver.unobserve(carousel);
        }
      })
    });

    carousels.forEach(carousel => carouselObserver.observe(carousel));
  }
});
