import './polyfills';
import './libraries';

import './helpers/customer-chat-loader';
import './helpers/ua-notification';
import './helpers/lazyload';
import './helpers/map-restrictor';
import './helpers/property-pageview';
import './helpers/toggle';
import './helpers/social-media';

import './preact';

import { adWordsTracker } from './utils/ad-words-tracker';
adWordsTracker.checkIncomingNavigationSource();
