import { h, Component } from 'preact';
import cx from 'classnames';
import { Subscription } from 'rxjs';

import { propertyTypes, PropertyTypeListItem } from '../../../../constants';
import { LocationSearchBox } from '../commons/location-search.component';
import { Location, DivCat, PropertyFilters } from '../../../../types';
import { openModal } from '../../../utils/hoppler-modal.service';
import { SearchFilterModal } from '../commons/search-filter-modal.component';

import URLRouter from '../../../../core/url-router';
import { FilterUtils } from '../../../../core/filter-utils';
import { DivCatUtils } from '../../../../core/div-cat-utils';
import { PlatformUtility } from '../../../utils/platform-utility';

export interface HeroSearchProps {
  showheader?: string;
}

export interface HeroSearchState {
  searchLayout: string;
  isBuyActive: boolean;
  isRentActive: boolean;
  isPresellingActive: boolean;
  propertyType: string;
  currentLocation: Location;
  currentDivCat: DivCat;
  currentFilters: PropertyFilters;
  preSale: number;
}

export class HeroSearchBox extends Component<HeroSearchProps, HeroSearchState> {
  subscriptions: Subscription[] = [];
  locationSearchValidation: Function;
  setLocationSearchDivCat: Function;
  clearLocation: Function;
  filterUtils: FilterUtils;
  divCatUtils: DivCatUtils;
  
  constructor(props: HeroSearchProps) {
    super(props);

    this.filterUtils = new FilterUtils();
    this.divCatUtils = new DivCatUtils();

    this.setState({
      propertyType: propertyTypes[0].label,
      isBuyActive: true,
      currentDivCat: {
        category: 'Sale',
        type: 'Condominium'
      }
    });

    this.setState({
      currentDivCat: this.divCatUtils.fillDivision(this.state.currentDivCat)
    });

    this.defineSearchLayout();
  }

  defineSearchLayout() {
    let searchLayout: string;
    
    if (PlatformUtility.isOnClient()) {
      const url = location.pathname;
      const sliced = url.split('/');
      const exists = sliced.indexOf('homeloan');

      if (exists === 1) {
        searchLayout = 'homeloan';
      } else {
        searchLayout = 'default';
      }
    } else {
      searchLayout = 'default';
    }

    this.setState({ searchLayout });
  }

  onCategoryClicked = (e: any, id: number) => {
    switch (id) {
      case 0:
        this.setState(
          {
            currentDivCat: {
              category: 'Sale',
              type: this.state.currentDivCat.type
            },
            isBuyActive: true,
            isRentActive: false,
            isPresellingActive:false,
            preSale: 0
          }
        );
        break;

      case 1:
        this.setState(
          {
            currentDivCat: {
              category: 'Rent',
              type: this.state.currentDivCat.type
            },
            isBuyActive: false,
            isRentActive: true,
            isPresellingActive:false,
            preSale: 0
          }
        );
        break;

      case 2:
        this.setState(
          {
            currentDivCat: {
              category: 'Sale',
              type: this.state.currentDivCat.type
            },
            isBuyActive: false,
            isRentActive: false,
            isPresellingActive:true,
            preSale: 1
          }
        );
        break;
    }

    this.clearLocation();
    this.setState({
      currentLocation: null,
      currentDivCat: this.divCatUtils.fillDivision(this.state.currentDivCat)
    });

    if (this.state.isPresellingActive) {
      this.setLocationSearchDivCat(this.state.currentDivCat, 1);
    } else {
      this.setLocationSearchDivCat(this.state.currentDivCat);
    }
    
  }

  onPropertyTypeChange = (e: any) => {
    const type = e.target.value;

    this.setState({
      propertyType: type,
      currentDivCat: {
        category: this.state.currentDivCat.category,
        type: type,
      }
    });

    this.clearLocation();
    this.setState({
      currentLocation: null,
      currentDivCat: this.divCatUtils.fillDivision(this.state.currentDivCat)
    });

    if (this.state.isPresellingActive) {
      this.setLocationSearchDivCat(this.state.currentDivCat, 1);
    } else {
      this.setLocationSearchDivCat(this.state.currentDivCat);
    }
  }

  onLocationChange = (location: Location) => {

    this.setState({
      currentLocation: location
    });
  }

  onSetFiltersClick(state: HeroSearchState) {
    let promise = openModal(SearchFilterModal, {
      modalLocals: {
        location: state.currentLocation,
        divCat: state.currentDivCat
      }
    });
    promise.then((results) => {
      
      this.setState({
        currentFilters: results
      });

    }, (err) => {
    });
  }

  onSearch = (e: any) => {
    if (!this.state.currentLocation) {
      this.locationSearchValidation(false);
      return;
    } else {
      this.locationSearchValidation(true);
    }

    // const url = URLRouter.buildResultsURL(
    //   this.state.currentDivCat,
    //   null,
    //   this.state.currentLocation
    // );

    // URLRouter.navigateToURL(url);

    const filterString = this.filterUtils
      .getFiltersAsQueryParams(
        this.state.currentFilters, 
        this.state.currentDivCat.category,
        this.state.currentDivCat.type,
        this.state.preSale
      );

    const url = `${this.state.currentLocation.permalink}${filterString && filterString.length > 1 ? filterString : ''}`;

    URLRouter.navigateToURL(url);
  }

  setClearLocation = (passedFunction: Function) => {
    this.clearLocation = passedFunction;
  }

  setLocationDivCat = (passedFunction: Function) => {
    this.setLocationSearchDivCat = passedFunction;
  }

  setLocationValidation = (passedValidation: Function) => {
    this.locationSearchValidation = passedValidation;
  }

  getAvailableTypes(props: HeroSearchProps, state: HeroSearchState) {
    const divCat = state.currentDivCat;
    
    return propertyTypes.filter(propType => {
      let rentMatch: boolean, saleMatch: boolean;
      rentMatch = divCat.category == 'Rent' && propType.hasRent;
      saleMatch = divCat.category == 'Sale' && propType.hasSale
      return rentMatch || saleMatch;
    });
  }

  render(props: HeroSearchProps, state: HeroSearchState) {
    
    return (
      <div className={cx({
        'hp-card': props.showheader == 'false',
        'hi-hero-search-box': state.searchLayout == 'default',
        'hi-hero-search-box__homelone': state.searchLayout == 'homeloan',
      })}> 

        {
          // state.searchLayout == 'default' ||
          props.showheader == 'true' && (
            <div>
              <h1 class="hi-hero-search-box__header">We'll lead you home </h1>
              <p>Find homes in Metro Manila through our trusted brokers and top agents.</p>
            </div>
          )
        }

        <div class="hi-hero-search-box__categories">
          <button 
            className={cx('hi-hero-search-box__category-button mr-2', {
              active : state.isBuyActive,
              'bg-transparent': state.searchLayout == 'homeloan'
            })}
            onClick={(e) => this.onCategoryClicked(e, 0)}>
            Buy
          </button>
          <button
            className={cx('hi-hero-search-box__category-button mx-2', {
              active : state.isRentActive,
              'bg-transparent': state.searchLayout == 'homeloan'
            })}
            onClick={(e) => this.onCategoryClicked(e, 1)}>
            Rent
          </button>

        </div>

        <div className={cx({
          'row align-items-center mt-2 pl-3': state.searchLayout == "homeloan"
        })}>
          <div className={cx("hi-hero-search-box__types mx-0", {
            'col-md-2 my-0 homeloan-select': state.searchLayout == 'homeloan'
          })}>
            <select 
              class="form-control hi-hero-search-box__types-select"
              onChange={this.onPropertyTypeChange}
              value={state.propertyType}>
              {
                this
                  .getAvailableTypes(props, state)
                  .map((type: PropertyTypeListItem) => {
                  return <option value={type.label}>{type.label}</option>;
                })
              }
            </select>
            <span class="hi-hero-search-box__types-icon">
              <i className="fas fa-angle-down"></i>
            </span>
          </div>

            <LocationSearchBox
              searchLayout={state.searchLayout}
              initialDivCat={state.currentDivCat}
              onLocationSelected={this.onLocationChange} 
              setDivCat={this.setLocationDivCat}
              setValidation={this.setLocationValidation}
              setClearLocation={this.setClearLocation}/>

          <a className={cx('hi-hero-search-box__set-filters my-3', {
            'px-0 col-md-1': state.searchLayout == 'homeloan'
          })}
            onClick={() => this.onSetFiltersClick(state)}>
            Set Filters
          </a>

          <button className={cx("btn btn-secondary w-100", {
            'col-md-1': state.searchLayout == 'homeloan'
          })}
            onClick={this.onSearch}>
            Search
          </button>

          </div>

 

      </div>
    );
  }
}