export interface NeighborhoodPinFilter {
  restaurants: boolean,
  banks: boolean,
  schools: boolean,
  hospitals: boolean,
  shopping: boolean,
  nightlife: boolean,
  mostRecentEditedFilter?: NeighborhoodFilterTypes,
}

export enum NeighborhoodFilterTypes {
  RESTAURANTS = 'RESTAURANTS',
  BANKS = 'BANKS',
  SCHOOLS = 'SCHOOLS',
  HOSPITALS = 'HOSPITALS',
  SHOPPING = 'SHOPPING',
  NIGHTLIFE = 'NIGHTLIFE'
}