import { Component, h } from "preact";
import cx from 'classnames';
import { Subscription } from "rxjs";
import { map, filter, distinctUntilChanged, pairwise, startWith } from "rxjs/operators";

import { HopplerMapUtils } from '../../../utils/hoppler-map-utils';
import { Listing, MapListing, Neighborhood, NeighborhoodPinFilter, NeighborhoodFilterTypes } from "../../../../types";
import getStore, { appState$ } from '../../store';

import * as ResultsActions from '../../reducers/results/results.actions';
import { ResultsState } from '../../reducers/results/results.actions';
import { SmoothScrollService } from "../../../utils/smooth-scroll.service";
import { showToast } from '../../../utils/hoppler-toast.service';
import { xlMinBreakpoint } from '../../../preact/constants/ui-sizes';
import { InfoWindowPropertyCard } from "../properties/info-window-property-card.component";
import { readSharedJSON } from "../../../../utils/shared-data";

export interface PropertiesMapProps {
  'external-map-toggle'?: string;
  isMapVisibleProp?: boolean;
  'passed-listings'?: MapListing[] | string;
  'is-property-page'?: string;

  databundle?: string;
}

export interface PropertiesMapState {
  isMapVisible?: boolean;
  isMapVisibilityPristine?: boolean; // used to determine whether map has been visible once or more
  externalMapToggle?: boolean;
  isPropertyPage?: boolean;

  listings?: Listing[];
  filters?: NeighborhoodPinFilter;
  
  isResultsPageScrolling?: boolean;
  isMobilePropertyCardScrollOpen?: boolean;
  mobilePreviewedProperties?: Listing[];
}

'hi-properties-map';
export class PropertiesMap extends Component<PropertiesMapProps, PropertiesMapState> {
  subscriptions: Subscription[] = [];
  hopplerMapUtils: HopplerMapUtils;
  smoothScrollService: SmoothScrollService;

  constructor(props: PropertiesMapProps) {
    super(props);

    if (props["passed-listings"]) {
      if (typeof props['passed-listings'] === 'string') {
        const parsedListings = JSON.parse(props['passed-listings']);
  
        this.setState({
          listings: parsedListings
        });
      } else {
        this.setState({
          listings: props['passed-listings']
        });
      }
    } else if (props.databundle) {
      const dataElement = document.getElementById(props.databundle);
      if (dataElement) {
        this.handleSharedData(props.databundle);
      }
    }

    if (props['is-property-page'] == 'true') {
      this.setState({
        isPropertyPage: true
      });
    } else {
      this.setState({
        isPropertyPage: false
      });
    }

    if (props['external-map-toggle'] == 'true') {
      this.setState({
        externalMapToggle: true
      });
    } else {
      this.setState({
        externalMapToggle: false
      });
    }

    if (props.isMapVisibleProp) {
      this.setState({
        isMapVisible: props.isMapVisibleProp,
        isMapVisibilityPristine: true
      });
    } else {
      this.setState({
        isMapVisible: false,
        isMapVisibilityPristine: true
      });
    }

    this.hopplerMapUtils = new HopplerMapUtils(this.state.listings, this.state.isPropertyPage);
 
    this.setState({
      filters: {
        restaurants: false,
        banks: false,
        schools: false,
        hospitals: false,
        shopping: false,
        nightlife: false,
      }
    });

    this.smoothScrollService = new SmoothScrollService();
  }

  componentDidMount() {

    // as of now, we use this component to send to the store 
    // new neighborhood data if on the property page
    // because there is no way to push to store from the PugController
    if (this.state.isPropertyPage) {
      const store = getStore();
      store.dispatch({
        type: ResultsActions.UPDATE_NEIGHBORHOOD_LOCATION_DATA,
        payload: {
          neighborhoodData: {
            latitude: this.state.listings[0].latitude,
            longitude: this.state.listings[0].longitude
          } as Neighborhood
        }
      });
    } else {
      const store = getStore();
      const mapSettings = this.hopplerMapUtils
        .ListingMarkerUtils.determineMapSettings(this.hopplerMapUtils.propertyMapMarkers);

      store.dispatch({
        type: ResultsActions.UPDATE_NEIGHBORHOOD_LOCATION_DATA,
        payload: {
          neighborhoodData: {
            latitude: mapSettings.centerLat,
            longitude: mapSettings.centerLong
          } as Neighborhood
        }
      });
    }

    const results$ = appState$.pipe(
      map(appState => appState.results)
    );

    this.subscriptions.push(
      results$.pipe(
        map(results => results.neighborhoodData),
        filter(neighborhoodData => !!neighborhoodData)
      ).subscribe((neighborhoodData: Neighborhood) => {
        if (neighborhoodData && neighborhoodData.places) {
          this.hopplerMapUtils.updateNeighborhoodPins(
            neighborhoodData.places
          );
        }
      })
    );

    this.subscriptions.push(
      results$.pipe(
        map(results => results.neighborhoodPinFilters),
        filter(neighborhoodPinFilters => !!neighborhoodPinFilters)
      ).subscribe((neighborhoodPinFilters: NeighborhoodPinFilter) => {
        if (neighborhoodPinFilters) {
          this.setState({
            filters: neighborhoodPinFilters
          });
        }
      })
    );

    // this.subscriptions.push(
    //   results$.pipe(
    //     map(results => results.previewedListing),
    //     // startWith(null),
    //     pairwise(),
    //   ).subscribe(([previous, previewedListing]) => {

    //     if (previewedListing) {

    //       if (previous && previous.propertyKey == previewedListing.propertyKey) {
    //         return;
    //       }

    //       if (!this.state.isResultsPageScrolling) {
    //         this.setState({
    //           isResultsPageScrolling: true
    //         });
            
    //         const promise = this.smoothScrollService.scrollToPropertyCard(previewedListing.propertyKey, 100, 30);

    //         promise.then(() => {
    //           this.clearAllCardsState();
    //           this.addPropertyCardActiveState(previewedListing.propertyKey);
    //           this.setState({
    //             isResultsPageScrolling: false
    //           });
    //         }, (err) => {
    //           this.setState({
    //             isResultsPageScrolling: false
    //           });
    //           showToast({
    //             title: 'Failed to scroll',
    //             message: 'An error occurred when scrolling to the property.',
    //             duration: 3000
    //           });
    //         });
    //       }
    //     }

    //   })
    // );

    if (this.state.externalMapToggle) {
      this.subscriptions.push(
        results$.pipe(
          map(results => results.isResultsPageMapVisible),
          distinctUntilChanged()
        )
        .subscribe(isResultsPageMapVisible => {
          if (isResultsPageMapVisible != undefined) {
            this.onToggleMapClicked(null);
          }
        })
      )
    }

    // based on map behavior, check if we are on mobile.
    const isMobile = document.documentElement.clientWidth < xlMinBreakpoint;
    if (isMobile) {
      this.subscriptions.push(
        results$.pipe(
          map(results => results.activeInfoWindowProperties),
          distinctUntilChanged()
        ).subscribe(activeInfoWindowProperties => {
          // console.log('changed', activeInfoWindowProperties);

          if (activeInfoWindowProperties) {

            this.setState({
              isMobilePropertyCardScrollOpen: true,
              mobilePreviewedProperties: activeInfoWindowProperties
            });

          } else {

            this.setState({
              isMobilePropertyCardScrollOpen: false,
              mobilePreviewedProperties: null
            });
          }
        })
      );
    }
  }

  handleSharedData(elementId: string) {
    const sharedResult = readSharedJSON<Listing[]>(elementId);

    this.setState({
      listings: sharedResult
    });
  }

  clearAllCardsState = () => {
    const cards = document.querySelectorAll('.hi-property-card');
    cards.forEach(card => {
      // console.log(card.getAttribute('id'));
      if (card.classList.contains('active')) {
        card.classList.remove('active')
      }
    })
  }

  addPropertyCardActiveState = (id: string) => {
    const el = document.getElementById(id);
    el.classList.add('active');
  }

  componentWillUnmount() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  onToggleMapClicked = (e) => {
    this.setState({
      isMapVisible: !this.state.isMapVisible
    })

    if (this.state.isMapVisibilityPristine) {

      // giving the component 300ms to render the div with the map ID
      // to ensure when the map is loaded, there is an element.
      // This fix is for IE
      setTimeout(() => {
        this.hopplerMapUtils.loadGoogleMapDependencies();
      }, 300);      

      this.setState({
        isMapVisibilityPristine: false
      })
    }
  }

  updateNearbyPlacesFilter = (e) => {
    const type: string = e.target.text;
    let filterType: NeighborhoodFilterTypes;
    
    switch(type) {
      case 'Restaurants':
          filterType = NeighborhoodFilterTypes.RESTAURANTS;
        break;

      case 'Banks':
          filterType = NeighborhoodFilterTypes.BANKS;
        break;
        
      case 'Schools':
        filterType = NeighborhoodFilterTypes.SCHOOLS;
        break;

      case 'Hospitals':
          filterType = NeighborhoodFilterTypes.HOSPITALS;
        break;

      case 'Shopping':
          filterType = NeighborhoodFilterTypes.SHOPPING;
        break;

      case 'Nightlife':
          filterType = NeighborhoodFilterTypes.NIGHTLIFE;
        break;
    }

    const store = getStore();
    store.dispatch({
      type: ResultsActions.UPDATE_NEIGHBORHOOD_FILTERS,
      payload: {
        neighborhoodPinFilterType: filterType
      } as ResultsState
    });
  }

  getNearbyPlacesButtonBlock(props: PropertiesMapProps, state: PropertiesMapState) {

    return (
      <div class="hi-properties-map__nearby-places">
        <div class="dropdown">
          <button class="btn btn-outline-dark dropdown-toggle hi-properties-map__nearby-places-toggle" 
            type="button" 
            id="nearbyPlacesButton" 
            data-toggle="dropdown" 
            aria-haspopup="true" aria-expanded="false">
            See nearby places
          </button>
          <div class="dropdown-menu" aria-labelledby="nearbyPlacesButton">
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.restaurants
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Restaurants
            </a>
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.banks
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Banks
            </a>
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.schools
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Schools
            </a>
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.hospitals
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Hospitals
            </a>
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.shopping
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Shopping
            </a>
            <a class="dropdown-item hi-properties-map__nearby-places-option"
              className={cx('dropdown-item hi-properties-map__nearby-places-option', 
                {
                  active: state.filters.nightlife
                }
              )}
              onClick={this.updateNearbyPlacesFilter}>
              Nightlife
            </a>
          </div>
        </div>
      </div>
    );

  }

  getMobilePropertyPreviewBlock(props: PropertiesMapProps, state: PropertiesMapState) {

    if (!state.isMobilePropertyCardScrollOpen) {
      return;
    }

    return (
      <div class="hi-properties-map__map-container__card-scroll">
        {
          state.mobilePreviewedProperties.map(member => {
            return (
              <InfoWindowPropertyCard 
                isMobile={ state.isMobilePropertyCardScrollOpen }
                property={ member }>
              </InfoWindowPropertyCard>
            )
          })
        }
      </div>
    );

  }

  render(props: PropertiesMapProps, state: PropertiesMapState) {

    let mapContainer, map, toggleButton;
    let nearbyPlacesButton;

    map = (
      <div id="hiMap" style="height:100%; width: 100%;">
      </div>
    );

    // this is important because the map must be set to undefined first.
    // but once the map has been defined, toggling it should be less of
    // remove/adding dom elements and more of css hiding
    if (state.isMapVisible) {  
      mapContainer = (
        <div className={ cx('hi-properties-map__map-container', 
          {
            'visible': state.isMapVisible,
            'results-page': !state.isPropertyPage
          }
        ) }>
          { map }

          { this.getNearbyPlacesButtonBlock(props, state) }
          { this.getMobilePropertyPreviewBlock(props, state) }
        </div>
      );
    } else {
      if (state.isMapVisibilityPristine) {
        mapContainer = undefined;
      } else {
        mapContainer = (
          <div className={ cx('hi-properties-map__map-container', 
            {
              'visible': state.isMapVisible,
              'results-page': !state.isPropertyPage
            }
          ) }>
            { map }
  
            { this.getNearbyPlacesButtonBlock(props, state) }
            { this.getMobilePropertyPreviewBlock(props, state) }
          </div>
        );
      }   
    }

    if (!state.externalMapToggle) {
      toggleButton = (
        <div class="hi-properties-map__open-map-button" onClick={ this.onToggleMapClicked }>
          <img class="hi-properties-map__open-map-button-img"
            src="https://hoppler-inc.imgix.net/hoppler/pages/static/tiny-placeholder-map.jpg?auto=format"
            alt="hoppler-map" />
          <span class="hi-properties-map__open-map-button-label">
            See Map and Nearby Places
          </span>
        </div>
      );
    }

    if (state.isPropertyPage) {
      return (
        <div class="hi-properties-map__property-page">
          { toggleButton }
  
          { mapContainer }
        </div>
      );
    } else {
      return (
        <div class="hi-properties-map__results-page">  
          { mapContainer }
        </div>
      );
    }
    
  }
}