

import { xlMinBreakpoint } from '../preact/constants/ui-sizes';

export function RestrictMapElements() {
  let isMobile = document.documentElement.clientWidth < xlMinBreakpoint;

  if (isMobile) {
    let el = document.getElementById('hiResultsPageMapDesktop');
    
    if (!el) {
      return;
    }
    
    let parent = el.parentElement;
    if (el && parent) {
      parent.removeChild(el);
    }
  } else {
    let el = document.getElementById('hiResultsPageMapMobile');
    
    if (!el) {
      return;
    }
    
    let parent = el.parentElement;
    if (el && parent) {
      parent.removeChild(el);
    }
  }
}

RestrictMapElements();
