import { Property } from './property.model';

import listingPriceSingleton  from '../core/listing-price-transform';
import { Listing } from 'listing.model';
import { MapListing } from 'map-listing.model';

export const ICON_URL_SINGLE = 'https://hoppler-inc.imgix.net/hoppler/pages/static/single-pin-001.png';
export const ICON_URL_MULTIPLE_BIG = 'https://hoppler-inc.imgix.net/hoppler/pages/static/range-pin-big-001.png';
export const ICON_URL_MULTIPLE_SMALL = 'https://hoppler-inc.imgix.net/hoppler/pages/static/range-pin-small-001.png';

export  const ICON_URL_SINGLE_PRIMARY = 'https://hoppler-inc.imgix.net/hoppler/pages/static/single-pin-primary-001.png';
export  const ICON_URL_MULTIPLE_BIG_PRIMARY = 'https://hoppler-inc.imgix.net/hoppler/pages/static/range-pin-big-primary-001.png';
export  const ICON_URL_MULTIPLE_SMALL_PRIMARY = 'https://hoppler-inc.imgix.net/hoppler/pages/static/range-pin-small-primary-001.png';
// const ICON_URL_MULTIPLE_SMALL = '/assets/range-pin-small.png';
// const ICON_URL_MULTIPLE_BIG = '/assets/range-pin-big.png';
// const ICON_URL_SINGLE = '/assets/single-pin.png';

export class PropertyMapMarker {
  latitude: number;
  longitude: number;
  propertyKey: string;
  properties: MapListing[];

  labelOptions?: {
    color?: string,
    text?: string,
    fontSize?: string,
  };
  iconUrl?: string;

  private minPrice?: number;
  private maxPrice?: number;

  zIndex: number;

  constructor(property: MapListing) {
    this.latitude = property.latitude;
    this.longitude = property.longitude;
    this.propertyKey = property.propertyKey;
    this.properties = [];

    this.iconUrl = ICON_URL_SINGLE;

    if (property) {
      this.addProperty(property);
    }
  }

  private isForRent(property: Property): boolean {
    return property.category == 'Rent';
  }

  private isForSale(property: Property): boolean {
    return property.category == 'Sale';
  }

  updateLabel() {
    const numProps = this.properties.length;

    this.labelOptions = {
      color: '#fff',
      fontSize: `12px`,
    };

    const minFmtd = listingPriceSingleton.transform(this.minPrice);
    const maxFmtd = listingPriceSingleton.transform(this.maxPrice);

    if (numProps > 1) {
      this.labelOptions.text = `${minFmtd} - ${maxFmtd}`;
      this.iconUrl = ICON_URL_MULTIPLE_SMALL;

      // "12.5M - 24.5M" needs a wider marker, but "12M - 24M" does not
      if (this.labelOptions.text.length >= 9) {
        this.iconUrl = ICON_URL_MULTIPLE_BIG;
      }
    } else {
      this.labelOptions.text = `${minFmtd}`;
    }
  }

  addProperty(property: MapListing) {
    let price: number;
    
    this.properties.push(property);

    // if (this.isForRent(property)) {
    //   price = property.rentalPrice;
    // } else {
    //   price = property.salePrice;
    // }

    price = property.minPrice;

    if (!this.minPrice || this.minPrice > price) {
      this.minPrice = price;
    }
    if (!this.maxPrice || this.maxPrice < price) {
      this.maxPrice = price;
    }

    this.updateLabel();
  }
}

export interface MapSettings {
  centerLat?: number;
  centerLong?: number;
  zoom?: number;
}
