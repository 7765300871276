import { combineReducers } from 'redux';

import ResultsReducer from './results/results.reducer';
import LandingReducer from './landing/landing.reducer';
import CartReducer from './cart/cart.reducer';
import UserReducer from './user/user.reducer';
import RvBReducer from './rvb/rvb.reducer';
import SignupReducer from './signup/signup.reducer';

let reducers = {
  results: ResultsReducer,
  landing: LandingReducer,
  cart: CartReducer,
  user: UserReducer,
  rvb: RvBReducer,
  signup: SignupReducer
};

let rootReducer = combineReducers(reducers);

export default rootReducer;
