
import { CartState, CartAction, DEFAULT_STATE } from './cart.actions';
import * as Actions from './cart.actions';
import pull from 'lodash.pull';
import * as RequestStates from '../../../../types';

// export default function cart(state: CartState)
export default function cart(state: CartState = DEFAULT_STATE, action: CartAction): CartState {
  switch (action.type) {
    case Actions.ADD_TO_CART: {
      const newIds = state.propertyIds.slice();
      const propertyId = action.payload.propertyKey;
      const alreadyInCart = newIds.includes(propertyId);

      if (!alreadyInCart) {
        return Object.assign(
          {},
          state,
          { propertyIds: [ ...newIds, propertyId ] }
        );
      } else {
        return state;
      }
    }
    case Actions.REMOVE_FROM_CART: {
      const propertiesCopy = state.propertyIds.slice();
      pull(propertiesCopy, action.payload.propertyKey);

      // Already copied -- no need to copy it again
      return Object.assign({}, state, { propertyIds: propertiesCopy });
    }
    case Actions.LOAD_FROM_COOKIES:
      return Object.assign({}, state, {
        propertyIds: [ ...action.bulkPayload ]
      } as CartState);

    case Actions.LOAD_FROM_EXISTING_FTF:
      return Object.assign(
        {},
        state,
        action.payload
      );
    
    case Actions.SUBMIT_INITIAL_LEAD:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          initialLeadSubmitState: RequestStates.REQUEST_IN_PROGRESS
        }
      );

    case Actions.SUBMIT_INITIAL_LEAD_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          initialLeadSubmitState: RequestStates.REQUEST_SUCCESS
        }
      );

    case Actions.SUBMIT_INITIAL_LEAD_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          initialLeadSubmitState: RequestStates.REQUEST_FAILED
        }
      );

    case Actions.GET_CIS:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.SUBMIT_FINAL_FTF:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          cisSubmitState: RequestStates.REQUEST_IN_PROGRESS
        }
      );

    case Actions.SUBMIT_FINAL_FTF_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          cisSubmitState: RequestStates.REQUEST_SUCCESS
        }
      );

    case Actions.SUBMIT_FINAL_FTF_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          cisSubmitState: RequestStates.REQUEST_FAILED
        }
      );

    default:
      return state;
  }
}
