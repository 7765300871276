import { Observable, Subject } from "rxjs";

import { h, render } from 'preact';

export interface ModalParams {
  closeOnOutsideClick?: boolean;
  modalLocals?: any;
}

let isModalOpen: boolean = false;

export function openModal(modalComponent: any, params?: ModalParams): Promise<any> {

  let vdomNode: HTMLElement;

  if (isModalOpen) {
    return Promise.reject('A modal is already open'); // do nothing if there is an open modal.
  }

  // this is the current approach to working with modals.
  // Create a vdom rendered element, give its container div
  // a simple id for ease of query selection during unmounting
  let promise = new Promise(
    (resolve, reject) => {
      const id = `hi-modal-${Math.floor(Math.random() * 1000)}`;
      let modal;

      let unmountModalComponent = () => {
        if (modal) {
          const element = document.querySelector(`#${id}`);

          if (params && params.closeOnOutsideClick) {
            element.removeEventListener('mousedown', onClickedOutside, false);
          }
          
          render(null, element, modal);
          isModalOpen = false;
        }
      }
      
      let onModalSuccess = (result) => {
        unmountModalComponent();
        resolve(result);
      }

      let onModalFail = (failResult) => {
        unmountModalComponent();
        console.error(failResult);
        reject(failResult);
      }

      let onClickedOutside = (event) => {
        const target: HTMLElement = event.target;

        // is clicking on the container not the modal
        if (target.className.includes('hi-modal-container')) {
          onModalFail('');
        }
      }

      const attributes = {
        onModalSuccess: onModalSuccess,
        onModalFail: onModalFail,
        params: (params && params.modalLocals ? params.modalLocals : undefined),
      };

      let vdom = h(modalComponent, attributes);
      let container = h(
        'div',
        { 
          class: 'hi-modal-container',
          id: id
        },
        vdom
      );
      
      modal = render(container, document.body);
      isModalOpen = true;

      if (params && params.closeOnOutsideClick == true) {
        const element = document.querySelector(`#${id}`);
        element.addEventListener('mousedown', onClickedOutside, false);
      }
    }
  );

  return promise;
}