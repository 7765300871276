import * as RvBActions from './rvb.actions';
import { RvBState, RvBAction, DEFAULT_STATE } from "./rvb.actions";

export function rvb(
  state: RvBState = DEFAULT_STATE,
  action: RvBAction
) {
  switch (action.type) {
    case RvBActions.INIT:
      return Object.assign({}, state, DEFAULT_STATE);
    case RvBActions.RVB_INPUTS_CHANGED:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}

export default rvb;
