import * as SignupActions from './signup.actions';
import { SignupState, SignupAction } from './signup.actions';
import * as RequestStates from '../../../../types/request-state.model';


export function signup(
  state: SignupState = SignupActions.DEFAULT_STATE,
  action: SignupAction
) {
  switch (action.type) {
    case SignupActions.BROKER_REGISTER_PARTIAL:
    case SignupActions.BROKER_REGISTER_FULL:
    case SignupActions.OWNER_REGISTER:
      return Object.assign(
        {}, state,
        action.payload,
        { isLoading: true }
      );
    case SignupActions.BROKER_REGISTER_PARTIAL_SUCCESS:
      return Object.assign(
        {}, state,
        { partialSignupComplete: true, isLoading: false } as SignupState
      );
    case SignupActions.BROKER_REGISTER_FULL_SUCCESS:
      return Object.assign(
        {}, state,
        { 
          brokerSignupComplete: true,
          isLoading: false 
        } as SignupState
      )
    case SignupActions.OWNER_REGISTER_SUCCESS:
      return Object.assign(
        {}, state,
        {
          ownerSignupComplete: true,
          isLoading: false
        }
      );
    
    case SignupActions.BROKER_REGISTER_PARTIAL_FAIL:
    case SignupActions.BROKER_REGISTER_FULL_FAIL:
      return Object.assign(
        {}, state,
        {
          brokerDetails: null,
          isLoading: false
        }
      );
    
    case SignupActions.RESEND_ACTIVATION_EMAIL:
      return Object.assign(
        {}, state,
        {
          usernameForResend: action.payload.usernameForResend,
          isLoading: true
        } as SignupState
      );
    case SignupActions.RESEND_ACTIVATION_SUCCESS:
      return Object.assign(
        {}, state,
        {
          isLoading: false,
          activationResent: true
        } as SignupState
      )
    case SignupActions.RESEND_ACTIVATION_FAIL:
        return Object.assign(
          {}, state,
          {
            isLoading: false
          } as SignupState
        );

    case SignupActions.CUSTOMER_SIGNUP:
        return Object.assign(
          {},
          state,
          action.payload,
          {
            customerSignupStatus: RequestStates.REQUEST_IN_PROGRESS
          }
        );

    case SignupActions.CUSTOMER_SIGNUP_SUCCESS:
        return Object.assign(
          {},
          state,
          {
            customerSignupStatus: RequestStates.REQUEST_SUCCESS
          }
        );

    case SignupActions.CUSTOMER_SIGNUP_FAILURE:
        return Object.assign(
          {},
          state,
          {
            customerSignupStatus: RequestStates.REQUEST_FAILED
          }
        );

    case SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL:
        return Object.assign(
          {},
          state,
          action.payload,
          {
            resendCustomerEmailStatus: RequestStates.REQUEST_IN_PROGRESS
          }
        );

    case SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_SUCCESS:
        return Object.assign(
          {},
          state,
          action.payload,
          {
            resendCustomerEmailStatus: RequestStates.REQUEST_SUCCESS
          }
        );

    case SignupActions.CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL_FAILURE:
        return Object.assign(
          {},
          state,
          action.payload,
          {
            resendCustomerEmailStatus: RequestStates.REQUEST_FAILED
          }
        );

    case SignupActions.INIT: 
      return Object.assign(
        {},
        SignupActions.DEFAULT_STATE
      );
    default:
      return state;
  }
}

export default signup;
