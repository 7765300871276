import { h, Component } from "preact";
import { openModal, ModalParams } from '../../../utils/hoppler-modal.service';
import { LoginModal } from "./login-modal.component";

import getStore, { appState$ } from '../../store';
import { UserAction, GET_CURRENT_USER, LOGOUT } from '../../reducers/user/user.actions';
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UserUtils } from "../../../../core/user-utils";
import { UserGroup } from "../../../../constants/user-types";
import * as RequestStates from '../../../../types/request-state.model';

import { dataLayer } from '../../../helpers/data-layer-util';

interface LoginNavButtonState {
  username?: string;
  isDropdownVisible?: boolean;
  isPartnerBroker?: boolean;
  isInternalSalesDirector?: boolean;
  isConsumer?: boolean;
  isExternalSalesDirector?: boolean
};

'hi-login-nav-button';
export class LoginNavButton extends Component<any, LoginNavButtonState> {
  subscriptions: Subscription[] = [];
  userUtils: UserUtils;

  node: HTMLElement;

  constructor(props) {
    super(props);

    this.userUtils = new UserUtils();
  }

  attachDocumentEventListeners() {
    document.addEventListener('mousedown', this.checkClick, false);
  }

  dettachDocumentEventListeners() {
    document.removeEventListener('mousedown', this.checkClick, false);
  }

  componentDidMount() {
    this.attachDocumentEventListeners();

    const store = getStore();

    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    this.subscriptions.push(
      user$.subscribe((user) => {
        if (user.currentUser) {
          if (this.userUtils.isBuyer(user.currentUser)) {
            this.setState({
              username: user.currentUser.fullName,
              isPartnerBroker: false,
              isConsumer: true
            });
          } else {
            this.setState({
              username: user.currentUser.fullName,
              isPartnerBroker: this.userUtils.isInGroup(
                user.currentUser,
                UserGroup.BusinessGroup
              ),
              isConsumer: false
            });
          }
          
          this.setState({
            username: user.currentUser.fullName,
            isPartnerBroker: user.currentUser.roles.includes('ROLE_PARTNER_BROKER'),
            isConsumer: this.userUtils.isBuyer(user.currentUser),
            isInternalSalesDirector: user.currentUser.roles.includes('ROLE_SALES_DIRECTOR'),
            isExternalSalesDirector: user.currentUser.roles.includes('ROLE_EXTERNAL_SALES_DIRECTOR')
          })

        } else {
          this.setState({
            username: undefined,
            isDropdownVisible: false
          });

          if (user.getCurrentUserState == RequestStates.REQUEST_UNTOUCHED) {
            store.dispatch({
              type: GET_CURRENT_USER
            } as UserAction);
          }
        }
      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });

    this.dettachDocumentEventListeners();
  }

  getKumitaLinks = (state: LoginNavButtonState) => {
    if (state.isConsumer) {
      return;
    }

    if (state.isExternalSalesDirector || state.isPartnerBroker) {
      return <a href="/kumita-brokers" class="nav-dropdown__user-action">Kumita by Hoppler</a>
    }
    else {
      return <a href="/kumita-brokers/listings" class="nav-dropdown__user-action">My Listings</a>
    }
  }

  checkClick = (event) => {
    // click is outside
    if (this.node && !this.node.contains(event.target)) {
      this.setState({
        isDropdownVisible: false
      });
    }
  }

  onClick = (e) => {
    e.preventDefault();

    if (!this.state.username) {
      const params: ModalParams = {
        closeOnOutsideClick: true
      };
  
      dataLayer.push({
        'event': 'Signup - Login-Register'
      });

      let promise = openModal(LoginModal, params);
      promise.catch((errorResult) => {
        //do nothing
      });
    } else {
      this.setState({
        isDropdownVisible: !this.state.isDropdownVisible
      });
    }
    
  }

  onLogout = (e) => {
    const store = getStore();

    store.dispatch({
      type: LOGOUT
    } as UserAction);
  }

  render(props, state: LoginNavButtonState) {
    let navText;
    let dropdown;

    if (state.username) {
      navText = <span>
        {state.username}
        <i class="fa fa-angle-down">
        </i>
      </span>;
    } else {
      navText = <span>Log-in</span>;
      
    }

    if (state.isDropdownVisible) {
      dropdown = (
        <div class="nav-dropdown__user-container" ref={node => this.node = node}>
          { this.getKumitaLinks(state) }
          <a href="/profile" class="nav-dropdown__user-action">Profile</a>
          <a href="/change-password" class="nav-dropdown__user-action">Change Password</a>
          <hr class="m-2"/>
          <a href="#" class="nav-dropdown__user-action"
            onClick={this.onLogout}>
            Logout
          </a>
        </div>
      );
    } else {
      dropdown = undefined;
    }
    

    return(
      <span class="nav-dropdown">
        <a href="#" class="hi-navbar__nav-button btn btn-link mr-1"
          onClick={this.onClick}>
          {navText}
        </a>

        {dropdown}
      </span>
      
    );
  }
}