
export interface GtmEvent {
  [key: string]: any
};

export class DataLayerService {
  constructor() {}

  push(data: GtmEvent) {
    // Silently check for dataLayer.
    // We need to quietly do nothing if we're running serverside.
    const hasWindow = typeof window == 'object';
    if (hasWindow && window['dataLayer']) {
      window['dataLayer'].push(data);
    }
  }
}

export const dataLayer = new DataLayerService();
