import * as Actions from './results.actions';
import { ResultsState, ResultsAction } from './results.actions';
import * as RequestStates from "../../../../types/request-state.model";

const initialState: ResultsState = {
  neighborhoodPinFilters: {
    restaurants: false,
    banks: false,
    schools: false,
    hospitals: false,
    shopping: false,
    nightlife: false,
  }
}

export default function results(state: ResultsState = initialState, action: ResultsAction) {
  switch (action.type) {
    case Actions.TEST:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.SECOND_TEST:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.UPDATE_NEIGHBORHOOD_LOCATION_DATA:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.GET_NEIGHBORHOOD_PINS: {
      
      let newState: ResultsState = {
        neighborhoodRequestState: RequestStates.REQUEST_IN_PROGRESS
      };

      if (action.payload.neighborhoodData) {
        newState.neighborhoodData = action.payload.neighborhoodData;
      }

      if (action.payload.neighborhoodPinFilterType) {
        newState.neighborhoodPinFilterType = action.payload.neighborhoodPinFilterType;
      }

      if (action.payload.neighborhoodPinFilters) {
        newState.neighborhoodPinFilters = action.payload.neighborhoodPinFilters;
      }

      return Object.assign(
        {},
        state,
        action.payload,
        newState
      );
    }

    case Actions.GET_NEIGHBORHOOD_PINS_SUCCESS: {
      let newState: ResultsState = {
        neighborhoodRequestState: RequestStates.REQUEST_SUCCESS
      };

      if (action.payload.neighborhoodData) {
        newState.neighborhoodData = action.payload.neighborhoodData;
      }

      return Object.assign(
        {},
        state,
        newState
      );
    }
      
    case Actions.GET_NEIGHBORHOOD_PINS_FAIL:
      return Object.assign(
        {},
        state,
        {
          neighborhoodRequestState: RequestStates.REQUEST_FAILED
        } as ResultsState
      );

    case Actions.UPDATE_NEIGHBORHOOD_FILTERS:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.TOGGLE_MAP:
      return Object.assign(
        {},
        state,
        {
          isResultsPageMapVisible: !state.isResultsPageMapVisible
        }
      );

    case Actions.PREVIEW_PROPERTY:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.MAP_MARKER_CLICKED:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.MAP_INFO_WINDOW_OPENED:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.MAP_INFO_WINDOW_CLOSED:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.MAP_MOBILE_PROPERTY_CARD_OPENED:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.MAP_MOBILE_PROPERTY_CARD_CLOSED:
      return Object.assign(
        {},
        state,
        {
          activeInfoWindowProperties: null
        }
      );


    default:
      return state;
  }
}