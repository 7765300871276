import { h, Component } from 'preact';
import cx from 'classnames';
import { from, Subscription } from 'rxjs';

import { ModalProps } from '../../types/modal-props';
import { PropertyFilters, Location, DivCat } from '../../../../types';

export interface VirtualTourModalProps extends ModalProps {
  params: {
    link?: string
  }
}

export interface VirtualTourModalState {
}

export class VirtualTourModal extends Component<VirtualTourModalProps, VirtualTourModalState> {
  subscriptions: Subscription[] = [];
  
  constructor(props: VirtualTourModalProps) {
    super(props);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    console.log('this modal has been unmounted properly');
  }

  onClose = () => {
    this.props.onModalFail('closed');
  }

    // this.props.onModalSuccess(filters);
  onSearchFilterSuccess = (filters: PropertyFilters) => {
    this.props.onModalSuccess(filters);
  }

  render(props: VirtualTourModalProps, state: VirtualTourModalState) {
    
    return(
      <div class="hi-modal p-4"> 
        <div class="hi-modal__header">
          <h4>Virtual Tour</h4>
          <button class="hi-modal__header-close" 
            onClick={this.onClose}>
            <i class="fas fa-times"></i> Close
          </button>
        </div>

        <hr class="hi-modal__hr"/>

        <iframe class="hi-listings-page__virtual-tour"
          src={props.params.link}>
        </iframe>

      </div>
    );
  }
}