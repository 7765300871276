import { PropertyType, PropertyDivision } from '../types';

export const PropertyDivisionIds = {
  ResidentialId: 1,
  CommercialId: 2
};

export const PropertyCategoryIds = {
  rent: 1,
  sale: 2
}

export interface PropertySortOption {
  label: string;
  sortBy: string;
  sortDirection: 'desc' | 'asc';
  value?: any;
};

export interface PropertyOfflineReason {
  header: string;
  details: string;
  photo: string;
}

export interface PropertyOfflineReasonSet {
  [key: string]: PropertyOfflineReason
};

export const PropertyInfo = {
  propertiesPerPage: 30,
  minMarkdownDate: 'Mar 8, 2018',
  emptyResultsFeaturedPropertiesCount: 9,

  requiresLotArea: [
    'House and Lot',
    'Townhouse',
    'Vacant Lot',

    'Building',
    'Commercial Lot',
    'Commercial Space',
    'Industrial Lot',
    'Warehouse',
    'Resort'
  ],

  sortFields: {
    DATE_MODIFIED: 'dateModified',
    PRICE: 'price',
  },

  sortOptions: [
    { value: 1, label: 'Date Modified (Newest First)', sortBy: 'dateModified', sortDirection: 'desc' },
    { value: 2, label: 'Date Modified (Oldest First)', sortBy: 'dateModified', sortDirection: 'asc'  },
    { value: 3, label: 'Price (Highest First)', sortBy: 'price', sortDirection: 'desc' },
    { value: 4, label: 'Price (Lowest First)', sortBy: 'price', sortDirection: 'asc' },
    // { value: 5, label: 'Floor Area (Highest First)' },
    // { value: 6, label: 'Floor Area (Lowest First)' },
    // { value: 7, label: 'Lot Area (Highest First)' },
    // { value: 8, label: 'Lot Area (Lowest First)' },
  ] as PropertySortOption[],
  defaultSortIndex: 0,

  offlineReasons: {
    'for approval': {
      'header': 'Listing is under review.',
      'details': 'The listing is awaiting its approval. For the meantime, go and get yourself a cup of coffee or something while we review if the property can pass your standards.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-under-review-001.png'
    },
    'modified': {
      'header': 'Listing is under review.',
      'details': 'The listing is awaiting its approval. For the meantime, go and get yourself a cup of coffee or something while we review if the property can pass your standards.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-under-review-001.png'
    },
    'sold': {
      'header': 'The property has been sold.',
      'details': 'Someone just moved into the property you are trying to view. No worries! You can still browse our other properties.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-sold-001.png'
    },
    'expired': {
      'header': 'Property is outdated.',
      'details': 'The listing the you are trying to view is outdated and has expired.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-outdated-001.png'
    },
    'locked': {
      'header': 'Property is outdated.',
      'details': 'The listing the you are trying to view is outdated and has expired.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-outdated-001.png'
    },
    'on hold': {
      'header': 'Property is currently on hold.',
      'details': 'The property appears to be on hold. It might be under renovation.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-on-hold-001.png'
    },
    'declined': {
      'header': 'Listing was declined.',
      'details': 'The listing did not meet our standards and was declined. It is now under quality assurance to check what went wrong.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-declined-001.png'
    },
    'draft': {
      'header': 'Listing is under review.',
      'details': 'The listing is awaiting its approval. For the meantime, go and get yourself a cup of coffee or something while we review if the property can pass your standards.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-property-draft-001.png'
    },

    'not found': {
      'header': 'Listing not found.',
      'details': 'There are no listings in our system that match this ID.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-declined-001.png',
    },
    'deleted': {
      'header': 'Listing not found.',
      'details': 'There are no listings in our system that match this ID.',
      'photo': 'https://hoppler-inc.imgix.net/hoppler/pages/static/photo-declined-001.png',
    }
  } as PropertyOfflineReasonSet
};

export const propertyAmenityList = [
  { label: 'Aircon', field: 'aircon' },
  { label: 'Balcony/Terrace', field: 'balconyTerrace' },
  { label: 'Cable TV', field: 'cableTv' },
  { label: 'Clubhouse', field: 'clubhouse' },
  { label: 'Den', field: 'den' },
  { label: 'Function Room', field: 'functionRoom', readOnlyIfInBuilding: true },
  { label: 'Garden', field: 'garden' },
  { label: 'Gym', field: 'gym', readOnlyIfInBuilding: true },
  { label: 'Internet Access', field: 'internet' },
  { label: 'Maid\'s Room', field: 'maidsRoom' },
  { label: 'Pet Friendly', field: 'petFriendly' },
  { label: 'Playground', field: 'playground' },
  { label: 'Porch/Lanai', field: 'porchLanai' },
  { label: 'Security', field: 'security', readOnlyIfInBuilding: true },
  { label: 'Swimming Pool', field: 'swimmingPool', readOnlyIfInBuilding: true }
];

export interface PropertyTypeListItem {
  label: PropertyType;
  division: PropertyDivision;
  hasRent: boolean;
  hasSale: boolean;
};

export const propertyTypes: PropertyTypeListItem[] = [
  { label: 'Condominium', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'House and Lot', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Townhouse', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Vacant Lot', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,

  { label: 'Commercial Lot', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Commercial Space', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Office Space', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Warehouse', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Building', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Serviced Office', division: 'Commercial', hasRent: true, hasSale: false } as PropertyTypeListItem,
  { label: 'Industrial Lot', division: 'Commercial', hasRent: false, hasSale: true } as PropertyTypeListItem,
  { label: 'Resort', division: 'Commercial', hasRent: false, hasSale: true } as PropertyTypeListItem,
];

export const residentialPropTypes: PropertyTypeListItem[] = [
  { label: 'Condominium', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'House and Lot', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Townhouse', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Vacant Lot', division: 'Residential', hasRent: true, hasSale: true } as PropertyTypeListItem,
];

export const commercialPropTypes: PropertyTypeListItem[] = [
  { label: 'Commercial Lot', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Commercial Space', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Office Space', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Warehouse', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Building', division: 'Commercial', hasRent: true, hasSale: true } as PropertyTypeListItem,
  { label: 'Serviced Office', division: 'Commercial', hasRent: true, hasSale: false } as PropertyTypeListItem,
  { label: 'Industrial Lot', division: 'Commercial', hasRent: false, hasSale: true } as PropertyTypeListItem,
  { label: 'Resort', division: 'Commercial', hasRent: false, hasSale: true } as PropertyTypeListItem,
];

export const ComOperationalHoursOther = { label: 'Other', value: 7 };
export const ComOperationalHours = [
  { label: '6:00 AM to 3:00 PM', value: 1 },
  { label: '7:00 AM to 4:00 PM', value: 2 },
  { label: '8:00 AM to 5:00 PM', value: 3 },
  { label: '9:00 AM to 6:00 PM', value: 4 },
  { label: 'Night Operations', value: 5 },
  { label: '24 Hours', value: 6 },
  ComOperationalHoursOther,
];

export const GenderChoices = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
];

export const FilterLimits = {
  'price': {
    'Residential': {
      'Rent': 5 * Math.pow(10, 5),
      'Sale': 3 * Math.pow(10, 8)
    },
    'Commercial': {
      'Rent': 1000,
      'Sale': 3 * Math.pow(10, 5)
    },

    'plusLimit': Math.pow(10, 10), // 10B
  },
  'bedrooms': 10,
  'bathrooms': 10,
  'parking': 10,
  'area': 1000
};

export const FurnishingChoices = [
  // { label: 'Any', value: '*' },
  { label: 'Fully Furnished', value: 'Fully Furnished', fieldName: 'furnishing_ff' },
  { label: 'Semi-Furnished', value: 'Semi-Furnished', fieldName: 'furnishing_sf' },
  { label: 'Unfurnished', value: 'Unfurnished', fieldName: 'furnishing_uf' },
];

export const ConditionChoices = [
  { label: 'Any', value: '*' },
  { label: 'Fitted Out', value: 'Fitted Out' },
  { label: 'Semi-Fitted', value: 'Semi-Fitted' },
  { label: 'Warm Shell', value: 'Warm Shell' },
  { label: 'Bare', value: 'Bare' },
  { label: 'Finished', value: 'Finished' },
];

export const LeaseTerms = [
  { label: '0.5', value: '6' },
  { label: '1', value: '12' },
  { label: '2', value: '24' },
  { label: '3', value: '36' },
  { label: '4', value: '48' },
  { label: '5', value: '60' },
];

export const ResultsPageQueryParamKeys = [
  'page',
  'availability',
  'furnished',

  // Residential amenities
  'pool',
  'gym',
  'garden',
  'balcony',
  'maidsRoom',
  'petFriendly',

  // Commercial amenities
  'peza',
  'backupPower',
  'operational247',
];
