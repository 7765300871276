import { h, Component } from 'preact';
import cx from 'classnames';
import { from, of, Subscription, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import Axios, { AxiosPromise } from 'axios';

import { debounce } from '../../../../utils/debounce';

import { LocationSearchResult } from '../../../../types/location-search-result.model';
import { hopplerApi } from '../../../../constants';
import { Location, DivCat, PropertyFilters } from '../../../../types';

import { showToast } from '../../../utils/hoppler-toast.service';

import { dataLayer } from '../../../helpers/data-layer-util';

export interface LocationSearchProps {
  searchLayout?: string,
  onLocationSelected?: Function; // params: location: Location
  setClearLocation?: Function;
  setDivCat?: Function;
  setValidation?: Function;
  initialDivCat: DivCat;
  initialText?: string;
}

export interface LocationSearchState {
  searchResults: LocationSearchResult[],
  isSearchResultsVisible: boolean,
  isValid: boolean;
  currentLocation: Location,
  currentDivCat: DivCat;
  currentFilters: PropertyFilters;
  preSale: number;
  search?: string;
}

export class LocationSearchBox extends Component<LocationSearchProps, LocationSearchState> {
  subscriptions: Subscription[] = [];
  search$: Observable<string>;

  node: HTMLElement;

  private resultsUrl: string = `${hopplerApi.host}/client/results-page/search`;
  
  constructor(props: LocationSearchProps) {
    super(props);

    this.setState({
      searchResults: [],
      isValid: true,
      currentDivCat: props.initialDivCat
    });

    if (props.initialText) {
      this.setState({ search: props.initialText });
    }

    if (props.setValidation) {
      props.setValidation(this.onValidate);
    }

    props.setDivCat(this.setDivCat);

    if (props.setClearLocation) {
      props.setClearLocation(this.clearLocation);
    }
  }

  attachDocumentEventListeners() {
    document.addEventListener('mousedown', this.checkClick, false);
  }

  dettachDocumentEventListeners() {
    document.removeEventListener('mousedown', this.checkClick, false);
  }

  onValidate = (isValid) => {
    if (isValid) {
      this.setState({
        isValid: true
      });
    } else {
      this.setState({
        isValid: false
      });
    }
  }

  setDivCat = (divCat: DivCat, preSale?: number) => {
    this.setState({
      currentDivCat: divCat,
      preSale: preSale
    })
  }

  clearLocation = () => {
    this.setState({
      search: null,
      currentLocation: null
    });
  }

  componentDidMount() {
    // const landing$ = appState$.pipe(
    //   map(appState => appState.landing)
    // );

    // this.subscriptions.push(
    //   landing$.subscribe((landing) => {
    //     this.setState({
    //       msg: landing.message
    //     });
    //   })
    // );

    this.attachDocumentEventListeners();
  }

  componentWillUnmount() {
    // this.subscriptions.forEach((s) => {
    //   s.unsubscribe();
    // });

    this.dettachDocumentEventListeners();
  }

  checkClick = (event) => {
    // click is outside
    if (!this.node.contains(event.target)) {
      this.setState({
        isSearchResultsVisible: false
      });
    }
  }

  getSearchResults(searchTerm?: string) {
    let params = {
      search: searchTerm,
      category: this.state.currentDivCat.category,
      type: this.state.currentDivCat.type,
      preSale: this.state.preSale
    };

    const axiosPromise = Axios.get(
      this.resultsUrl,
      {
        params: params
      }
    );

    axiosPromise.then((response) => {
      const locationResults = response.data.results;

      this.setState({
        searchResults: locationResults as LocationSearchResult[],
        isSearchResultsVisible: true
      });
    }).catch(errResp => {
      let message = errResp.response.data.msg ? errResp.response.data.msg : null;
      
      showToast({
        title: 'Failed to retrieve search',
        message: message,
        duration: 3000
      })
    })

  }

  onSearchInputChange = debounce((event) => {

    const searchTerm = event.target.value;

    if (searchTerm && searchTerm.length >= 2) {
      this.getSearchResults(searchTerm);
    } 
    
    this.setState({
      search: searchTerm
    });

  }, 500)

  onSearchInputFocus = (event) => {
    const searchTerm = event.target.value;
    this.getSearchResults(searchTerm);

    // if (!this.state.search) {
    //   this.getSearchResults(searchTerm);
    // } else if (this.state.search.length == 0) {
    //   this.getSearchResults(searchTerm);
    // }
  }

  onResultClick = (result: LocationSearchResult) => {
    const location: Location = {
      city: result.city,
      name: result.label,
      label: result.label,
      area: result.area,
      building: result.building,
      region: result.region,
      permalink: result.permalink,
      locationPermalink: result.locationPermalink,
      type: result.type,
      level: result.level,
      division: result.division,
      category: result.category,
      count: result.count,
    };
    
    this.setState({
      search: result.label,
      searchResults: [],
      isSearchResultsVisible: false,
      currentLocation: location
    });

    dataLayer.push({
      'SearchQuery': result.label,
      'Division': result.division,
      'PropertyType': result.type,
      'Category': result.category,
      'event': 'Property Search'
    });

    if (this.props.onLocationSelected) {
      this.props.onLocationSelected(location);
    }
  }

  getLevelLabel(level: string) {
    if (level === 'Tags') {
      return 'Landmark';
    }

    return level;
  }

  render(props: LocationSearchProps, state: LocationSearchState) {
    
    const result = (member, index) => {
      let displayLabel;
      if (member.level && member.level == 'Area') {
        displayLabel = `${member.label}, ${member.city}`;
      } else {
        displayLabel = member.label;
      }

      return <p onClick={this.onResultClick.bind(this, member)}> 
        { displayLabel } ({ this.getLevelLabel(member.level) })
      </p>;
    }

    return(
      <div 
        className={cx("hi-location-search-box", {
          'col-md-4 pl-0': props.searchLayout == 'homeloan'
        })}
        ref={node => this.node = node}> 
        <input
          className={ cx('hi-location-search-box__input form-control', { 'invalid': !state.isValid}) } 
          type="text"
          placeholder="Search by city, neighborhood, or building"
          value={state.search}
          onInput={this.onSearchInputChange.bind(this)}
          onFocus={this.onSearchInputFocus}
          />
          <span class="hi-location-search-box__icon">
            <i class="fas fa-search"></i>
          </span>
          <div className={cx('hi-location-search-box__results', {active : state.isSearchResultsVisible})}>
            { this.state.searchResults.map((member, i) => result(member, i)) }
          </div>
      </div>
    );
  }
}