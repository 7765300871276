import { h, Component } from 'preact';
import { from, Subscription } from 'rxjs';
import linkState from 'linkstate';

import { FurnishingChoices, ConditionChoices } from '../../../../constants/properties';
import { PropertyFilters } from '../../../../types/property-filters.model';
import { FilterUtils } from '../../../../core/filter-utils';

import { DivCat } from '../../../../types/div-cat.model';
import { ResultsPageBundle } from '../../../../types/results-page-bundle.model';
import { Location } from '../../../../types';
import { DivCatUtils } from '../../../../core/div-cat-utils';
import { HopplerDatePicker } from './hoppler-date-picker.component';
import moment from 'moment-timezone';
import { Formats, HOPPLER_TIMEZONE } from '../../../../constants';
import { preactLinkArray } from '../../../utils/forms/hoppler-linkstate';
import { isValueInArray } from '../../../utils/forms/validators';

export interface ResultFiltersProps {
  isResultsPage?: boolean,
  onSearch: Function,
  location?: Location,
  resultBundle?: ResultsPageBundle
  divCat?: DivCat,

  initialFormState?: PropertyFilters;
  includePreselling?: boolean
}

export interface ResultFiltersState {
  formState: PropertyFilters,
  sharedResult?: ResultsPageBundle,
  availabilityDate?: string,
}

const initialFormstate: PropertyFilters = {
  min_bedrooms: -1,
  min_bathrooms: -1,
  min_parking: -1
}

export class ResultFilters extends Component<ResultFiltersProps, ResultFiltersState> {
  subscriptions: Subscription[] = [];
  filterUtils = new FilterUtils();
  divCatUtils = new DivCatUtils();
  
  constructor(props: ResultFiltersProps) {
    super(props);
  
    this.setState({
      formState: props.initialFormState || {},

      // Duplicate. Necessary to work around VS Code not detecting ResultsPageBundle's
      // inheritance of DivCat
      sharedResult: props.resultBundle,
    });

    if (props.initialFormState && props.initialFormState.maxDateAvailability) {
      this.setState({
        availabilityDate: props.initialFormState.maxDateAvailability
      })
    }
  }

  componentDidMount() {
    // console.log('divcat', this.props.divCat);
  }

  componentWillUnmount() {
  }

  onSearchClicked = () => {
    let returnedFilters = Object.assign(
      {},
      this.state.formState
    );

    for (let k in returnedFilters) {
      if (returnedFilters[k]) {
        if (returnedFilters[k] < 0) {
          returnedFilters[k] = null;
        }
      }
    }

    if (this.state.availabilityDate) {
      returnedFilters['maxDateAvailability'] = this.state.availabilityDate;
    }

    this.props.onSearch(returnedFilters);
  }

  isCommercial(props: ResultFiltersProps) {
    return props.divCat.division == 'Commercial';
  }

  isResidential(props: ResultFiltersProps) {
    return props.divCat.division == 'Residential';
  }

  isLot(props: ResultFiltersProps) {
    return (
      props.divCat.type === 'Vacant Lot' ||
      props.divCat.type === 'Industrial Lot' ||
      props.divCat.type === 'Commercial Lot'
    );
  }

  isResort(props: ResultFiltersProps) {
    return props.divCat.type === 'Resort';
  }

  onAvailabilityDateSelected = (date: string) => {
    if (date) {
      const isoDate = moment(date, Formats.date).tz(HOPPLER_TIMEZONE).format();
      
      this.setState({
        availabilityDate: isoDate
      })
    } else {
      this.setState({
        availabilityDate: undefined
      })
    }
  }

  getSearchButtonHref = (props: ResultFiltersProps, state: ResultFiltersState) => {
    let query: string = null;
    const formState = state.formState;
    const tempObject = {};
    let locationLink: string = '';

    if (props.location) {
      locationLink = props.location.locationPermalink;
    } else if (state.sharedResult.locationPermalink) {
      locationLink = state.sharedResult.locationPermalink;
    }

    const slug = this.divCatUtils.toSlug(props.divCat);

    for (const key in formState) {
      const value = formState[key];

      if (value < 0) continue;

      if (value === false) continue;

      if (key == 'furnished') {
        // we don't want to get previous furnished values
        continue;
      }

      if (value) {
        tempObject[key] = value;
      }

      if (key == 'bedroomFilter') {
        if (value && (value as Array<any>).length > 0) {
          tempObject['bedrooms'] = (value as Array<any>).join(',');
        } else {
          delete tempObject['bedrooms'];
        }
        delete tempObject[key];
      }

      if (key == 'bathroomFilter') {
        if (value && (value as Array<any>).length > 0) {
          tempObject['bathrooms'] = (value as Array<any>).join(',');
        } else {
          delete tempObject['bathrooms']; 
        }
        delete tempObject[key];
      }

      if (key == 'restroomFilter') {
        if (value && (value as Array<any>).length > 0) {
          tempObject['restRoom'] = (value as Array<any>).join(',');
        } else {
          delete tempObject['restRoom'];
        }
        delete tempObject[key];
      }

      if (key == 'parkingSpaceFilter') {
        if (value && (value as Array<any>).length > 0) {
          tempObject['parkingSpaces'] = (value as Array<any>).join(',');
        } else {
          delete tempObject['parkingSpaces'];
        }
        delete tempObject[key];
      }

      if (key == 'condition' && value == '*') {
        delete tempObject[key];
      }
    }

    // console.log('Evaluating includePreselling', props.includePreselling);
    if (!props.includePreselling) {
      tempObject['preSale'] = 0;
    } else {
      delete tempObject['preSale'];
    }

    if (this.state.availabilityDate) {
      tempObject['maxDateAvailability'] =  this.state.availabilityDate;
    } else {
      delete tempObject['maxDateAvailability'];
    }

    // we iterate over each furnishing choice and 
    // properly add into the tempObject['furnished'] the actual values
    FurnishingChoices.forEach(choice => {
      if (tempObject[choice.fieldName]) {
        if (tempObject['furnished']) {
          tempObject['furnished'] = (tempObject['furnished'] as string).concat(`,${choice.value}`);
        } else {
          tempObject['furnished'] = choice.value;
        }
        delete tempObject[choice.fieldName];
      }
    })

    query = this.filterUtils.getObjectAsQueryParams(tempObject);

    const baseUrl = `/${slug}${locationLink}`;
    if (query) {
      return `${baseUrl}?${query}`;
    } else {
      return baseUrl;
    }
  }

  createSearchButton = (props: ResultFiltersProps, state: ResultFiltersState): JSX.Element => {
    // console.log('About to render search button:', props.includePreselling);

    return (
      <a class="btn hi-filter-group__submit"
        href={this.getSearchButtonHref(props, state)}>
        Search properties
      </a>
    );
  }

  getBrBaBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    let bedroomBlock = this.getBedroomBlock(props, state);
    let bathroomBlock = this.getBathroomBlock(props, state);
    let parkingSpacesBlock = this.getParkingSpacesBlock(props, state);

    if (!bedroomBlock && !bathroomBlock && !parkingSpacesBlock) {
      return;
    }
    
    return (
      <div>
        { bedroomBlock }
        { bathroomBlock }
        { parkingSpacesBlock }
      </div>
    )
  }

  getBedroomBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    let formState = state.formState;

    if (this.isCommercial(props) || this.isLot(props)) {
      return;
    }
    
    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Bedrooms</label>
        <div class="d-flex flex-wrap">
          {/* <span class="hi-filter-group__checkbox">
            <label>
              <input class="first" type="checkbox" name="bedrooms" value="-1"
                checked={isValueInArray<number>(formState.bedroomFilter, -1)}
                onChange={preactLinkArray(this, 'formState.bedroomFilter', -1)}/>
              Any
            </label>
          </span> */}

          <span class="hi-filter-group__checkbox">
            <label>
              <input type="checkbox" name="bedrooms" 
                value="0" checked={isValueInArray<number>(formState.bedroomFilter, 0)}
                onChange={preactLinkArray(this, 'formState.bedroomFilter', 0)}/>
              Studio
            </label>
          </span>

          {
            ([1,2,3,4,5]).map(index =>
              <span class="hi-filter-group__checkbox">
                <label>
                  <input type="checkbox" name="bedrooms" 
                    value={index} checked={isValueInArray<number>(formState.bedroomFilter, index)}
                    onChange={preactLinkArray(this, 'formState.bedroomFilter', index)}
                    />
                  { index == 5 ? '5+' : index }
                </label>
              </span>
            )
          }
        </div>
      </div>
    );
  }

  getBathroomBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    let formState = state.formState;

    if (this.isLot(props) || props.divCat.type == 'Resort') {
      return;
    }

    if (this.isCommercial(props)) {
      return (
        <div class="hi-filter-group my-4">
          <label class="hi-filter-group__label">Restrooms</label>
          <div class="d-flex flex-wrap">
            {/* <span class="hi-filter-group__checkbox">
              <label>
                <input class="first" type="checkbox" name="restRoom"
                  checked={isValueInArray<number>(formState.restroomFilter, -1)}
                  onChange={preactLinkArray(this, 'formState.restroomFilter', -1)}/>
                Any
              </label>
            </span> */}
  
            {
              ([1,2,3,4,5]).map(index =>
                <span class="hi-filter-group__checkbox">
                  <label>
                    <input type="checkbox" name="restRoom" 
                      value={index} checked={isValueInArray<number>(formState.restroomFilter, index)}
                      onChange={preactLinkArray(this, 'formState.restroomFilter', index)}
                      />
                    { index == 5 ? '5+' : index }
                  </label>
                </span>
              )
            }
          </div>
        </div>
      );
    }

    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Bathrooms</label>
        <div class="d-flex flex-wrap">
          {/* <span class="hi-filter-group__checkbox">
            <label>
              <input class="first" type="checkbox" name="bathrooms" value="-1"
                checked={state.formState.bathrooms == -1}
                onChange={linkState(this, 'formState.bathrooms', 'target.value')}/>
              Any
            </label>
          </span> */}

          {
            ([1,2,3,4,5]).map(index =>
              <span class="hi-filter-group__checkbox">
                <label>
                  <input type="checkbox" name="bathrooms" 
                    value={index} checked={isValueInArray<number>(formState.bathroomFilter, index)}
                    onChange={preactLinkArray(this, 'formState.bathroomFilter', index)}
                    />
                  { index == 5 ? '5+' : index }
                </label>
              </span>
            )
          }
        </div>
      </div>
    );
  }

  getParkingSpacesBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    let formState = state.formState;

    if (this.isResort(props) || this.isLot(props)) {
      return;
    } 

    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Parking spaces</label>
        <div class="d-flex flex-wrap">
          {/* <span class="hi-filter-group__checkbox ">
            <label>
              <input class="first" type="checkbox" name="parkingSpaces" value="-1"
                checked={isValueInArray<number>(formState.parkingSpaceFilter, -1)}
                onChange={preactLinkArray(this, 'formState.parkingSpaceFilter', -1)}/>
              Any
            </label>
          </span> */}

          {
            ([1,2,3,4,5]).map(index =>
              <span class="hi-filter-group__checkbox">
                <label>
                  <input type="checkbox" name="parkingSpaces" 
                    value={index} 
                    checked={isValueInArray<number>(formState.parkingSpaceFilter, index)}
                    onChange={preactLinkArray(this, 'formState.parkingSpaceFilter', index)}
                    />
                  { index == 5 ? '5+' : index }
                </label>
              </span>
            )
          }
        </div>
      </div>
    );
  }

  getCheckboxesBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    if (this.isLot(props)) {
      return;
    }

    return (
      <div class="order-6">
        { this.getFurnishingBlock(props, state) }
        { this.getConditionBlock(props, state) }

        <div class="hi-filter-group checkbox-group my-4">
          <label class="hi-filter-group__label">Amenities</label>
          { this.getResidentialAmenities(props, state) }
          { this.getCommercialAmenities(props, state) }
        </div>

        <div class="hi-filter-group checkbox-group my-4">
          <label class="hi-filter-group__label">Issuing official receipts</label>
          <div class="hi-filter-group__checkbox">
            <label>
              <input class="" type="checkbox" name="ownerReceipt" checked={state.formState.ownerReceipt}
                onChange={linkState(this, 'formState.ownerReceipt')}/>
              With official receipt only
            </label>
          </div>
        </div>

      </div>
    )
  }

  getFurnishingBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    if (!this.isResidential(props) || this.isLot(props)) {
      return;
    }

    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Furnishing</label>
        <div class="d-flex flex-wrap">
          {
            FurnishingChoices.map((choice, index) => {
              return (
                <span class="hi-filter-group__checkbox ">
                  <label>
                    <input
                      type="checkbox" name={choice.fieldName}
                      onChange={linkState(this, `formState.${choice.fieldName}`)}
                      checked={state.formState[choice.fieldName]}/>
                    {choice.label}
                  </label>
                </span>
              );
            })
          }
        </div>
      </div>
    );
  }

  getConditionBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    if (!this.isCommercial(props) || this.isLot(props)) {
      return;
    }

    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Condition</label>
        <div class="d-flex flex-wrap">
          {
            ConditionChoices.map((choice, index) => {
              return (
                <span class="hi-filter-group__radio ">
                  <label>
                    <input
                      type="radio" name="condition" 
                      onChange={linkState(this, 'formState.condition', 'target.value')}
                      value={choice.value} 
                      checked={state.formState.condition == choice.value}/>
                    {choice.label}
                  </label>
                </span>
              );
            })
          }
        </div>
      </div>
    );
  }

  getCommercialAmenities(props: ResultFiltersProps, state: ResultFiltersState) {
    if (this.isResidential(props) || this.isLot(props)) {
      return;
    }

    return (
      <div class="d-flex flex-wrap">
          <span class="hi-filter-group__checkbox">
            <label>
              <input class="first" type="checkbox" name="peza" checked={state.formState.peza}
                onChange={linkState(this, 'formState.peza')}/>
              PEZA Accredited
            </label>
          </span>

          <span class="hi-filter-group__checkbox">
            <label>
              <input class="first" type="checkbox" name="backupPower" checked={state.formState.backupPower}
                onChange={linkState(this, 'formState.backupPower')}/>
              Backup Power
            </label>
          </span>

          <span class="hi-filter-group__checkbox">
            <label>
              <input class="first" type="checkbox" name="operational247" checked={state.formState.operational247}
                onChange={linkState(this, 'formState.operational247')}/>
              Operational 24/7
            </label>
          </span>
        </div>
    );
  }

  getResidentialAmenities(props: ResultFiltersProps, state: ResultFiltersState) {
    if (this.isCommercial(props) || this.isLot(props)) {
      return;
    }

    return (
      <div class="d-flex flex-wrap">
        <span class="hi-filter-group__checkbox">
          <label>
            <input type="checkbox" name="pool" checked={state.formState.pool}
              onChange={linkState(this, 'formState.pool')}/>
            Pool
          </label>
        </span>

        <span class="hi-filter-group__checkbox">
          <label>
            <input class="" type="checkbox" name="gym" checked={state.formState.gym}
              onChange={linkState(this, 'formState.gym')}/>
            Gym
          </label>
        </span>

        <span class="hi-filter-group__checkbox">
          <label>
            <input class="" type="checkbox" name="garden" checked={state.formState.garden}
              onChange={linkState(this, 'formState.garden')}/>
            Garden
          </label>
        </span>

        <span class="hi-filter-group__checkbox">
          <label>
            <input class="" type="checkbox" name="balcony" checked={state.formState.balcony}
              onChange={linkState(this, 'formState.balcony')}/>
            Balcony
          </label>
        </span>

        <span class="hi-filter-group__checkbox">
          <label>
            <input class="first" type="checkbox" name="maidsRoom" checked={state.formState.maidsRoom}
              onChange={linkState(this, 'formState.maidsRoom')}/>
            Maid's room
          </label>
        </span>

        <span class="hi-filter-group__checkbox">
          <label>
            <input class="" type="checkbox" name="petFriendly" checked={state.formState.petFriendly}
              onChange={linkState(this, 'formState.petFriendly')}/>
            Pet-friendly
          </label>
        </span>
      </div>
    );
  }

  getPriceRangeFilterGroup(props: ResultFiltersProps, state: ResultFiltersState) {
    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Price range (PHP)</label>
        <div class="hi-filter-group__row">
          <input class="form-control hi-filter-group__number first" type="number" min="0" step="1000" placeholder="Minimum"
            value={state.formState.minPrice}
            onInput={linkState(this, 'formState.minPrice')}/>
          <input class="form-control hi-filter-group__number last" type="number" min="0" step="1000" placeholder="Maximum"
            value={state.formState.maxPrice}
            onInput={linkState(this, 'formState.maxPrice')}/>
        </div>
      </div>
    );
  }

  getFloorAreaFilterGroup(props: ResultFiltersProps, state: ResultFiltersState) {
    return (
      <div class="hi-filter-group my-4">
        <label class="hi-filter-group__label">Floor area (sqm)</label>
        <div class="hi-filter-group__row">
          <input class="form-control hi-filter-group__number first" type="number" min="0" step="10" placeholder="Minimum"
            value={state.formState.minFloorArea}
            onInput={linkState(this, 'formState.minFloorArea')}/>
          <input class="form-control hi-filter-group__number last" type="number" min="0" step="10" placeholder="Maximum"
            value={state.formState.maxFloorArea}
            onInput={linkState(this, 'formState.maxFloorArea')}/>
        </div>
      </div>
    );
  }

  getNumbersBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    return (
      <div>
        { this.getPriceRangeFilterGroup(props, state) }
        { this.getFloorAreaFilterGroup(props, state) }
        { this.getAvailabilityDateBlock(props, state) }
      </div>
    )
  }

  getAvailabilityDateBlock(props: ResultFiltersProps, state: ResultFiltersState) {
    return (
      <div class="my-4">
        <label class="hi-filter-group__label">Available by</label>
        {/* there is no validation needed */}
        <HopplerDatePicker 
          onDateSelected={this.onAvailabilityDateSelected}
          initialValue={state.formState.maxDateAvailability}
          isValid={true}>
        </HopplerDatePicker>
      </div>
    )
  }

  getLeftSection(props: ResultFiltersProps, state: ResultFiltersState) {
    if (this.isLot(props)) {
      return (
        <div class="d-lg-none">
          { this.getPriceRangeFilterGroup(props, state) }
          { this.getFloorAreaFilterGroup(props, state) }
          { this.getAvailabilityDateBlock(props, state) }
        </div>
      );
    }

    let leftSectionDesktop = this.getBrBaBlock(props, state);
    if (leftSectionDesktop) {
      return (
        <div class="col-md">
          { leftSectionDesktop }
        </div>
      );
    }    
  }

  getRightSection(props: ResultFiltersProps, state: ResultFiltersState) {
    if (this.isLot(props)) {
      return;
      // return (
      //   <div class="col-md d-lg-none flex-order order-1 order-lg-9">
      //     {/* { this.getFloorAreaFilterGroup(props, state) } */}
      //     {/* { this.getPriceRangeFilterGroup(props, state) } */}
      //   </div>
      // );
    } else {
      return (
        <div class="col-md d-flex flex-column">
          { this.getCheckboxesBlock(props, state) }
          <div class="d-lg-none flex-order order-1 order-lg-9">
            { this.getFloorAreaFilterGroup(props, state) }
            { this.getPriceRangeFilterGroup(props, state) }
            { this.getAvailabilityDateBlock(props, state) }
          </div>
        </div>
      );
    }
    
  }

  render(props: ResultFiltersProps, state: ResultFiltersState) {
    // console.log('Preselling:', props.includePreselling);
    return (
      <div class="hi-result-filters">
        <form class="row">
          { this.getLeftSection(props, state) }
          
          <div class="d-none d-lg-block col-lg">
            { this.getNumbersBlock(props, state) }
          </div> {/* /.col */}

          { this.getRightSection(props, state) }
        </form>

        <div class="d-flex justify-content-center">
          { this.createSearchButton(props, state) }
        </div>

        {/* <pre>{JSON.stringify(state.formState, null, 2)}</pre> */}
      </div>
    );
  }
}
