export default function UpdateTwitterShareLinks() {
  const elements = document.querySelectorAll('.twitter-share-button');
  // const baseHref = 'https://twitter.com/intent/tweet?text=Hey check this property out!&url`';

  elements.forEach((element: HTMLElement) => {

    let elementMessage: string = element.getAttribute('message');

    if (!elementMessage || elementMessage.length < 1) {
      elementMessage = `Found this property at Hoppler:&url=${window.location}`;
    } else {
      elementMessage = elementMessage.concat(` ${window.location}`);
    }
    
    element.setAttribute(
      'href',
      `https://twitter.com/intent/tweet?text=${elementMessage}`
    );
  });
}

UpdateTwitterShareLinks();


function UpdateFacebookShareLinks() {
  const elements = document.querySelectorAll('.fb-share-button');
  elements.forEach((element: HTMLElement) => {

    let queryParams: string = element.getAttribute('data-qp');
    
    element.setAttribute(
      'href',
      `https://www.facebook.com/sharer/sharer.php?u=${window.location}${queryParams ? '?' + queryParams : ''}`
    );

    element.setAttribute(
      'onclick',
      "javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
    );
  });
}

UpdateFacebookShareLinks();

function UpdateLinkedinShareLinks() {
  const elements = document.querySelectorAll('.linkedin-share-button');
  elements.forEach((element: HTMLElement) => {

    let queryParams: string = element.getAttribute('data-qp');
    element.setAttribute(
      'href',
      `https://www.linkedin.com/shareArticle?mini=true&url=${window.location}${queryParams ? '?' + queryParams : ''}`
    );

    element.setAttribute(
      'onclick',
      "javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
    );
  });
}

UpdateLinkedinShareLinks();

function UpdatePinterestShareLinks() {
  const elements = document.querySelectorAll('.pinterest-share-button');
  elements.forEach((element: HTMLElement) => {
    let media: string = element.getAttribute('data-media');
    let description: string = element.getAttribute('data-description');
    let url = location.href;

    if (description) {
      description = encodeURIComponent(description);
    } else {
      description = 'Hoppler Magazine';
    }

    let shareLink = `https://www.pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${description}`;

    element.setAttribute(
      'href',
      shareLink
    );
  });
}

UpdatePinterestShareLinks();


