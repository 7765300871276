import { PlatformUtility } from '../utils/platform-utility';
import { SELECTORS } from './constants/selectors';
import { h, render } from 'preact';

function getSelectorAttribs(namedNodeMap: NamedNodeMap) {
  let attribs = {};

  for (let i = 0; i < namedNodeMap.length; i++) {
    attribs[namedNodeMap[i].name] = namedNodeMap[i].value;
  }

  // console.log('attr', attribs);

  return attribs;
}

export default document.addEventListener('DOMContentLoaded', () => {
  if (PlatformUtility.isOnClient()) {
    // console.log('Running on client');
    
    SELECTORS.forEach((selector) => {
      let targets = document.querySelectorAll(selector.name);

      targets.forEach((member) => {
        //get attributes from pug level
        const attributes = getSelectorAttribs(member.attributes);

        //create VDOM of component with attributes
        let componentVDOM = h(selector.component, attributes);
        render(componentVDOM, member);
      });
    });
  }
});

export function renderComponentsWithin(selectorName: string, parentElement?: Element) {
  const selector = SELECTORS.find(member => {
    return member.name == selectorName;
  })

  let targets;

  if (parentElement) {
    targets = parentElement.querySelectorAll(selector.name);
  } else {
    targets = document.querySelectorAll(selector.name);
  }

  targets.forEach((member) => {
    //get attributes from pug level
    const attributes = getSelectorAttribs(member.attributes);

    //create VDOM of component with attributes
    let componentVDOM = h(selector.component, attributes);
    render(componentVDOM, member);
  });
}