import * as Actions from './user.actions';
import { UserState, UserAction } from './user.actions';

import * as RequestStates from '../../../../types/request-state.model';

const initialState: UserState = {
  getCurrentUserState: RequestStates.REQUEST_UNTOUCHED
}

export default function User(state: UserState = initialState, action: UserAction) {
  switch (action.type) {
    case Actions.GET_CURRENT_USER:
      return Object.assign(
        {},
        state,
        action.payload
      );
    case Actions.GET_CURRENT_USER_IN_PROGRESS:
      return Object.assign(
        {},
        state,
        action.payload
      );
    case Actions.GET_CURRENT_USER_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          getCurrentUserState: RequestStates.REQUEST_SUCCESS
        }
      );

    case Actions.GET_CURRENT_USER_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          getCurrentUserState: RequestStates.REQUEST_FAILED
        }
      );

    case Actions.LOGIN:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          loginState: RequestStates.REQUEST_IN_PROGRESS
        } as UserState
      );

    case Actions.LOGIN_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          email: null,
          password: null,
          requiresUserVerification: false
        } as UserState
      );

    case Actions.LOGIN_FAIL:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          email: null,
          password: null,
        } as UserState
      );

    case Actions.USER_REQUIRES_VALIDATION:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          email: null,
          password: null,
          requiresUserVerification: true
        } as UserState
      );

    case Actions.LOGOUT:
      return Object.assign(
        {}, {
          email: null,
          password: null,
          currentUser: null
        } as UserState
      );

    case Actions.FORGOT_PASSWORD:
        return Object.assign(
          {},
          state,
          action.payload, {
            forgotPasswordState: RequestStates.REQUEST_IN_PROGRESS
          }
        );

    case Actions.FORGOT_PASSWORD_SUCCESS:
    case Actions.FORGOT_PASSWORD_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

      case Actions.RESET_PASSWORD:
        return Object.assign(
          {},
          state,
          action.payload, {
            resetPasswordState: RequestStates.REQUEST_IN_PROGRESS
          }
        );

    case Actions.RESET_PASSWORD_SUCCESS:
    case Actions.RESET_PASSWORD_FAIL:
      return Object.assign(
        {},
        state,
        action.payload
      );

    case Actions.SEND_CONTACT_US:
      return Object.assign(
        {},
        state,
        action.payload, {
          sendContactUsState: RequestStates.REQUEST_IN_PROGRESS
        }
      );

    case Actions.SEND_CONTACT_US_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload, {
          sendContactUsState: RequestStates.REQUEST_SUCCESS
        }
      );

    case Actions.SEND_CONTACT_US_FAIL:
      return Object.assign(
        {},
        state,
        action.payload, {
          sendContactUsState: RequestStates.REQUEST_FAILED
        }
      );

    case Actions.UPDATE_USER_PROFILE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          updateProfileState: RequestStates.REQUEST_IN_PROGRESS
        } 
      );

    case Actions.UPDATE_USER_PROFILE_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          updateProfileState: RequestStates.REQUEST_SUCCESS
        } 
      );

    case Actions.UPDATE_USER_PROFILE_FAILURE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          updateProfileState: RequestStates.REQUEST_FAILED
        } 
      );

    case Actions.CHANGE_PASSWORD:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordState: RequestStates.REQUEST_IN_PROGRESS
        } 
      );

    case Actions.CHANGE_PASSWORD_SUCCESS:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordState: RequestStates.REQUEST_SUCCESS
        } 
      );

    case Actions.CHANGE_PASSWORD_FAILURE:
      return Object.assign(
        {},
        state,
        action.payload,
        {
          changePasswordState: RequestStates.REQUEST_FAILED
        } 
      );

    default:
      return state;
  }
}