const PREFIX = '[Landing]';

export const LANDING_TEST = `${PREFIX} LANDING_TEST`;
export const LANDING_TEST2 = `${PREFIX} LANDING_TEST2`;
export const LANDING_TEST3 = `${PREFIX} LANDING_TEST3`;

export const GET_MAINTENANCE_SCHED = `${PREFIX} GET_MAINTENANCE_SCHED`;
export const GET_MAINTENANCE_SCHED_SUCCESS = `${PREFIX} GET_MAINTENANCE_SCHED_SUCCESS`;
export const GET_MAINTENANCE_SCHED_FAIL = `${PREFIX} GET_MAINTENANCE_SCHED_FAIL`;

export interface LandingState {
  message?: string;
  count?: number;

  maintenanceMessage?: string;
}

export interface LandingAction {
  type: string,
  payload?: LandingState
}