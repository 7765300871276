import { Component, h } from "preact";
import cx from 'classnames';

import $ from 'jquery';
import 'bootstrap';

import { Listing } from "../../../../types";
import { hopplerApi } from "../../../../constants";
import { toDecimal } from '../../../../helpers/decimal';
import { CardVlButton } from "./card-vl-button.component";

import { GRAY_PIXEL } from '../../../../constants/color-pixels';

export interface InfoWindowPropertyCardProps {
  property: Listing;
  isMobile?: boolean;
}

export interface InfoWindowPropertyCardState {
  cardCarouselId: string;
}

'hi-info-window-property-card';
export class InfoWindowPropertyCard extends Component<InfoWindowPropertyCardProps, InfoWindowPropertyCardState> {
  node: HTMLElement;

  constructor(props: InfoWindowPropertyCardProps) {
    super(props);

    this.setState({
      cardCarouselId: `hiMapInfoWindowPropertyCard${props.property.propertyKey}`
    });
  }

  componentDidMount() {
    const jqSelector = $(`#${this.state.cardCarouselId}`).carousel({interval: false});
    $(jqSelector).on('slide.bs.carousel', (event) => {
      const lazy = $(event.relatedTarget).find('img[data-slide-src]');

      // console.log('from map info window',lazy.data('slide-src'));

      if (lazy.data('slide-src')) {
        lazy.attr('src', lazy.data('slide-src'));
      }
  
      if (lazy.data('slide-srcset')) {
        lazy.attr('srcset', lazy.data('slide-srcset'));
      }
    });
  }

  getCarouselBlock(props: InfoWindowPropertyCardProps, state: InfoWindowPropertyCardState) {
    const watermark = 'aHR0cHM6Ly9ob3BwbGVyLWluYy5pbWdpeC5uZXQvaG9wcGxlcldhdGVybWFyay9ob3BwbGVyV2F0ZXJtYXJrX2xvZ28ucG5nP3NhdD0tMTAwJnc9OTYmZXhwPTEw';
    const params = `auto=compress,format&fit=crop&h=220&w=350&mark64=${watermark}`;

    const property = props.property;

    if (property.photos && property.photos.length > 1 && !props.isMobile) {
      return (
        <div class="carousel slide hi-lazy info-window-carousel" id={state.cardCarouselId}>
          <ol class="carousel-indicators">
            { 
              property.photos.map((photo, index) => {
                return <li data-target={state.cardCarouselId} data-slide-to={index}></li>;
              }) 
            }
          </ol>
          <div class="carousel-inner">
            {
              property.photos.map((photo, index) => {
                let img;

                if (index == 0) {
                  img = <img src={`${hopplerApi.images}/${photo}?${params}`} 
                    alt="" 
                    class="hi-property-card__photo d-block w-100"/>;
                } else {
                  img = <img alt="" 
                    class="hi-property-card__photo d-block w-100"
                    data-slide-src={ `${hopplerApi.images}/${photo}?${params}` }
                    data-slide-srcset={ `${hopplerApi.images}/${photo}?${params} 1x, ${hopplerApi.images}/${photo}?${params}&dpr=2 2x` } 
                  />
                }

                return (
                  <div class={`carousel-item ${index == 0 ? 'active' : ''}`}>
                    { img }
                  </div>
                )
              })
            }
          </div>
          <a class="carousel-control-prev" href={`#${state.cardCarouselId}`} role='button' data-slide='prev'>
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href={`#${state.cardCarouselId}`} role='button' data-slide='next'>
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      );
    } else if (property.photos) {
      const path = `${hopplerApi.images}/${property.photos[0]}?${params}`;
      return (
        <img class="hi-property-card__photo"
          src={path}
          data-srcset={`${path} 1x, ${path}&dpr=2 2x`} 
          alt={`Primary photo for ${property.propertyKey}`}/>
      );
    } else {
      const path = `${hopplerApi.noPhoto}`;
      return (
        <img class="hi-property-card__photo"
          src={path}
          data-srcset={`${path} 1x, ${path}&dpr=2 2x`} 
          alt={`Primary photo for ${property.propertyKey}`}/>
      );
    }

  }

  getPriceBlock(props: InfoWindowPropertyCardProps, state: InfoWindowPropertyCardState) {
    const property = props.property;

    if (property.preSale == 1) {
      return (
        <div>
          <p class="hi-property-card__starts-at">
            Starts at
          </p>
          <div class="hi-property-card__price">
            ₱ {toDecimal(property.minPrice)}+
          </div>
        </div>
      );
    } else {
      if (property.category == 'Sale') {

        return (
          <div class="hi-property-card__price">
            ₱ {toDecimal(property.minPrice)}
          </div>
        );
      } else {
        return (
          <div class="hi-property-card__price">
            ₱ {toDecimal(property.minPrice)}
            <span class="hi-property-card__price-suffix">
             /month
            </span>
          </div>
        );
      }
    }

  }

  getPricePerSquareBlock(property: Listing) {
    if (property.pricePerSqm) {
      return (
        <span class="hi-property-card__info">
          ₱ { toDecimal( Math.ceil(property.pricePerSqm)) } /sqm
        </span>
      );
    }
  }

  getSmallStatsBlock(props: InfoWindowPropertyCardProps, state: InfoWindowPropertyCardState) {
    const property = props.property;

    if (property.division === 'Residential' && property.type != 'Vacant Lot') {
      let bedString: string;
      
      if (property.bedrooms > 0) {
        bedString = `${property.bedrooms} Bed`;
        if (property.bedrooms > 1) {
          bedString = bedString.concat('s');
        }
      } else {
        bedString = 'Studio';
      }

      return (
        <div class="hi-property-card__labels__small-stats">
          <span class="hi-property-card__info">
            { bedString }
          </span>
          <span class="hi-property-card__info">
            { property.bathrooms } { property.bathrooms > 1 ? 'Baths' : 'Bath'}
          </span>
          <span class="hi-property-card__info">
            { Math.floor(property.floorArea || property.lotArea) } sqm
          </span>
        </div>
      );
    } else {
      return (
        <div class="hi-property-card__labels__small-stats">
          <span class="hi-property-card__info">
            { Math.floor(property.floorArea || property.lotArea) } sqm
          </span>
          {
            this.getPricePerSquareBlock(property)
          }
        </div>
      );
    }
  }

  render(props: InfoWindowPropertyCardProps, state: InfoWindowPropertyCardState) {
    const property = props.property;
    
    return (
      <div class="hi-properties-map__info-window__property-card" ref={node => this.node = node}>
        <div class="position-relative hi-properties-map__info-window__property-card__img-container">
          <a href={props.property.permalink} class="d-block">
            { this.getCarouselBlock(props, state) }
          </a>
        </div>

        <div class="hi-properties-map__info-window__property-card__details">
            <div class="hi-property-card__type-loc d-none d-lg-block">
              { property.type } &bull; { property.city }
            </div>
            <a class="hi-type-title hi-property-card__title" href={property.permalink}>
              { property.building || property.area }
            </a>
            <div class="hi-property-card__bottom-group">
              <div class="hi-property-card__labels">
                { this.getPriceBlock(props, state) }
                { this.getSmallStatsBlock(props, state) }
              </div>

              <hr class="d-lg-none my-1 w-100"/>

              <CardVlButton propertykey={props.property.propertyKey}
                compact={ true } />
            </div>
        </div>

      </div>
    );
  }
}