import 'intersection-observer';
import 'url-search-params-polyfill';
import 'nodelist-foreach-polyfill';
import 'promise-polyfill/src/polyfill';

import './polyfills/ie-array-find';
import './polyfills/ie-array-find-index';
import './polyfills/ie-object-assign';
import './polyfills/ie-object-includes';
import './polyfills/ie-parse-int';
import './polyfills/ie-string-includes';