const listingsIdentifier = document.getElementById('hiListingsPage');

// ways to trigger lazy loading:
// onLoad: default
// button click
// scroll: Intersection Observer

if (listingsIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading calculators');

  import('./modules/listing.module').then((module) => {
    module.renderComponents();
  });
}


// ----- Viewing List Section -----

const viewingListIdentifier = document.getElementById('hiViewingListPage');

if (viewingListIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading ftf');

  import('./modules/fast-track-form.module').then((module) => {
    module.renderComponents();
  });
}


// ----- Viewing List Section -----

const docShareIdentifier = document.getElementById('hiDocumentSharePage');

if (docShareIdentifier) {
  import('./modules/document-share.module').then((module) => {
    module.renderComponents();
  });
}


// ----- Contact Us Section -----

const contactUsIdentifier = document.getElementById('hiContactUsPage');

if (contactUsIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading ftf');

  import('./modules/contact-us.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Homepage Section -----

const homepageIdentifier = document.getElementById('hiHomepage');

if (homepageIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading ftf');

  import('./modules/homepage.module').then((module) => {
    module.renderComponents();
  });
}

// ------ Ads -------

const adIdentifier = document.getElementById('hiAd');

if (adIdentifier) {
  import('./modules/ads.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Results Section -----

const resultsIdentifier = document.getElementById('hiResultsPage');

if (resultsIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading ftf');

  import('./modules/results.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Profile Page -----

const profileIdentifier = document.getElementById('hiProfilePage');

if (profileIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading calculators');

  import('./modules/profile.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Signup Page -----

const signupIdentifier = document.getElementById('hiSignupPage');

if (signupIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading calculators');

  import('./modules/sign-up.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Magazine Page -----

const magazineIdentifier = document.getElementById('hiMagazinePage');

if (magazineIdentifier) {
  // do lazy load imports here
  // console.log('Lazy loading calculators');

  import('./modules/magazine.module').then((module) => {
    module.renderComponents();
  });
}

// ----- Rent vs Buy Page -----

const rentVsBuyIdentifier = document.getElementById('hiRentVsBuyPage');

if (rentVsBuyIdentifier) {
  import('./modules/rent-vs-buy.module').then((module) => {
    module.renderComponents();
  })
}

// --- homeloan
const homeLoan = document.getElementById('hiHomeLoan');

if (homeLoan) {
  import('./modules/homeloan.module').then((module) => {
    module.renderComponents();
  })
}

// --- owner page
const ownerPage = document.getElementById('hiOwnerPage');

if (ownerPage) {
  import('./modules/owner-page.module').then((module) => {
    module.renderComponents();
  })
}

// --- booking homepage
const bookingHomePage = document.getElementById('hiBookingHomePage')

if (bookingHomePage) {
  import('./modules/owner-page.module').then(module => {
    module.renderComponents();
  })
}

// --- booking project page
const projectPage = document.getElementById('hiBookingProject')

if (projectPage) {
  import('./modules/project.module').then(module => {
    module.renderComponents();
  })
}

// ------ 404 page
const notFoundPage = document.getElementById('hi404Page');

if (notFoundPage) {
  import ('./modules/404page.module').then(module => module.renderComponents())
}

// ----- Booking -----
const bookingIdentifier = document.getElementById('hiBookingModule');
if (bookingIdentifier) {
  import('./modules/booking.module').then(module => module.renderComponents());
}

const bookingResultsPageIdentifier = document.getElementById('hiBookingResultsPage');
if (bookingResultsPageIdentifier) {
  import('./modules/booking-results-page.module').then(module => module.renderComponents());
}
