import { Village } from 'village.model';

export const villages: Village[] = [
  {
    id: 1,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Salcedo Village',
    slug: 'salcedo-village',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Salcedo Village, Makati City | Hoppler.com.ph',
    metaDescription: "Sophisticated architecture and a community-centric neighborhood is what's to love about Salcedo Village. Explore places to live in Salcedo Village - a budding community ideal for young families in Makati City.",
    contentTitle: 'Welcome to Salcedo Village, Makati City',
    contentBody: [
      "Tall buildings with elegant architecture and the abundance of trees easily bring out the simplicity and beauty of Salcedo Village. Commercial-residential areas prevail in this area, standing side-by-side many office spaces. What this creates is a humble community of young artists, families, and working professionals, interacting with one another courteously as they pace through their day.",
      "Salcedo Village is primarily known and remembered for the Salcedo Saturday Market at the St. Jaime C. Velasquez Park. Many people flock to the Saturday Market to purchase fresh produce and eat delectable dishes, but it also successfully brings people together with the rest of the community to enjoy their Saturday. The same park is the venue of Art in the Park, an annual outdoor visual fair that brings art, collectors, enthusiasts, and artists together. There are also third wave coffee shops, international restaurants, and specialty stores that are all a few streets away to keep residents and visitors happy."
    ],
    uniqueInfoTitle: 'Perfect for those who enjoy interacting with the community',
    uniqueInfo: "As Salcedo Village is able to consistently engage its community with activities, it also allows its residents to feel a sense of belonging. During seasonal holidays, community events are hosted, bringing people together. It’s a safe environment and this closeness is what makes Salcedo Village special to those who have experienced its community.",
    attractions: [
      {
        title: 'Salcedo Park, A Multipurpose Urban Park in Makati',
        link: '//www.hoppler.com.ph/blog/featured-articles/salcedo-park-a-multipurpose-urban-park-in-makati',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-salcedo-park-a-multipurpose-urban-park-in-makati.jpg',
        snippet: `Salcedo Village is a popular business and residential address in Makati City. It houses high-rise residential towers where one can find a luxury condo in Makati and commercial`
      },
      {
        title: 'Three Specialty Restaurants in Salcedo Village',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-specialty-restaurants-in-salcedo-village',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-specialty-restaurants-in-salcedo-village.jpg',
        snippet: `Salcedo Village is popular for several reasons. There’s the Salcedo Saturday Market, where residents of Makati and nearby cities flock to buy organically grown fruits and`
      },
      {
        title: 'Three Not Your Usual Coffee Shops to Experience',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-not-your-usual-coffee-shops-to-experience-in-salcedo-village',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/03/hoppler-three-not-your-usual-coffee-shops-to-experience-in-salcedo-village.jpg',
        snippet: `The strong presence of coffee shops in Metro Manila in recent years raised a new generation of enthusiasts. Now, men and women, students and professionals, are intentional in visiting`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-ateneo-school-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-playground-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-cover-restaurant-cartel-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-children-park-003.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-children-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-architecture-003.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/salcedo-village-architecture-001.jpg' },
    ],
  },

  {
    id: 2,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Legazpi Village',
    slug: 'legaspi-village',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Legazpi Village, Makati City | Hoppler.com.ph',
    metaDescription: "Legazpi Village is a haven for young professionals. It is the hipper neighborhood in the Makati CBD. Find places to stay in Legazpi Village as you experience its laid-back vibe.",
    contentTitle: 'Welcome to Legazpi Village, Makati City',
    contentBody: [
      "Legazpi Village is a haven for young professionals. It is the hipper and more laid-back neighborhood in the Makati Central Business District. Although it houses many offices and is near main thoroughfares, it is popular for its less crowded tree-lined streets, pocket gardens, and cafés and restaurants where foodies whet their palate. Despite it being slightly older than its neighbor, Salcedo Village, it notably attracts the younger population as it is nearby hangout spots. Also, it is where top executives reside, work, and have fun.",
      "Stereotypically, people in the Makati CBD are permanently on the rush but, the calm vibe of Legazpi Village is a reminder to slow down. From cafés offering artisanal drinks, under-the-radar restaurants serving delectable cuisines, jolly jeeps serving affordable yet delicious food employees love (the famous Sisig at Rada St. is a must try!), to urban gardens where residents jog, do yoga, walk their pet, and saunter, Legazpi Village is an easygoing community where the usual fast-paced setting of Makati is nonexistent."
    ],
    uniqueInfoTitle: 'Perfect for those who seek an unhurried life in the metro',
    uniqueInfo: "Residents and those who work in Legazpi Village never worry about the insane rush hour traffic and crowd. A retreat in the middle of the busy Makati CBD, it is a go-to place even for those who live nearby to unwind after a long day. Its overall unhurried setting is an invitation to relax, meet friends to relish great food in hip restaurants, and enjoy a leisurely stroll or exercise in the park.",
    attractions: [
      {
        title: 'Recharge at Legazpi Villages Newly Reopened Parks',
        link: '//www.hoppler.com.ph/blog/featured-articles/recharge-at-legaspi-villages-newly-reopened-parks',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/02/hoppler-recharge-at-legaspi-villages-newly-reopened-parks.jpg',
        snippet: `In December 2017, two of Makati’s public parks, the Washington Sycip Park and Legaspi Active Park, reopened in Legazpi Village, encouraging its residents to visit`
      },
      {
        title: 'Four Hoppler Quality Restaurants in Legazpi Village',
        link: '//www.hoppler.com.ph/blog/featured-articles/four-quality-restaurants-in-legaspi-village-you-should-visit',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-four-quality-restaurants-in-legaspi-village-you-should-visit.jpg',
        snippet: `Legazpi Village is a haven for foodies; its streets are lined up with restaurants that residents and office goers can enjoy either by dining alone or with company`
      },
      {
        title: 'Jollijeeps: The Popular Food Stalls in Makati City',
        link: '//www.hoppler.com.ph/blog/featured-articles/jollijeeps-the-popular-food-stalls-in-makati-city',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/03/hoppler-jollijeeps.jpg',
        snippet: `In the 80s, a number of enterprising old ladies – or those we, Filipinos, fondly call manangs – would flock to the streets of Ayala Avenue to sell home-cooked meals`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/legaspi-village-active-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/legaspi-village-architecture-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/legaspi-village-jollijeep-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/legaspi-village-park-bridge-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/legaspi-village-park-cover-001.jpg' },
    ],
  },

  {
    id: 3,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Poblacion',
    slug: 'poblacion',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Poblacion, Makati City | Hoppler.com.ph',
    metaDescription: "Flashing lights and endless activities shape life in Poblacion, making it the one of the most iconic spots in Makati City. Discover places to stay in Poblacion as you explore its electric culture.",
    contentTitle: 'Welcome to Poblacion, Makati City',
    contentBody: [
      "Flashing lights and an electric culture shape life in Poblacion. Despite the dizzying hustle and bustle of cars and visitors, there’s always something new to discover within its streets, and the welcoming atmosphere is one primary reason residents of Poblacion consider it home. When Poblacion is most alive after work, residents and outsiders flock to the many alluring hole-in-the-wall restaurants for cross-cultural culinary experiences.",
      "Galleries, rooftop bars, and performance art spaces draw outsiders to this neighborhood, making Poblacion one of Metro Manila’s central hubs for meeting and socializing for the art community. However, anyone can visit these places, and people of all ages and of various cultures are given a space to interact. The neighborhood even has a number of hostels for guests to stay in, with their own unique features and amenities for its visitors. There’s never a dull moment in Poblacion."
    ],
    uniqueInfoTitle: 'Perfect for late night hangouts with friends',
    uniqueInfo: "Late nights highlight the culture of Poblacion. Listen to live DJ sets or jazz bands or watch an improv show in the many bars and pubs around the neighborhood. There are enough coffee shops to relax in for those looking for a more laid-back evening. All the establishments of Poblacion are close to one another, making it possible to walk from one place to another. And since these places close late, the possibilities are endless.",
    attractions: [
      {
        title: 'Three Must-Visit Restaurants in Poblacion, Makati',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-must-visit-restaurants-in-poblacion-makati',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-must-visit-restaurants-in-poblacion-makati.jpg',
        snippet: `Poblacion is a popular food hub in Makati. A hideaway for foodies who enjoy going from one food spot to another, it is home to restaurants that offer palatable cuisines that satisfy`
      },
      {
        title: 'Filling Station Bar Café: An American-Style Diner in Poblacion',
        link: '//www.hoppler.com.ph/blog/featured-articles/filling-station-bar-cafe-an-american-style-diner-in-poblacion',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-filling-station-bar-cafe-an-american-style-diner-in-poblacion.jpg',
        snippet: `Neon-lit and nostalgic, Filling Station Bar Café is a popular restaurant on Burgos Street in Poblacion, Makati due to its photogenic interiors and tasty American cuisines. A place`
      },
      {
        title: 'DULO: A Multi-Platform Hangout in Poblacion for the Creatives',
        link: '//www.hoppler.com.ph/blog/featured-articles/dulo-a-multi-platform-hangout-in-poblacion-for-the-creatives',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2017/10/hoppler-dulo.jpg',
        snippet: `Barangay Poblacion is a breath of fresh air in the professional and consistently progressive Makati City. It is home to artisanal cafés where students and young freelancers study and work`
      },
    ],
    multimedias: [
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/poblacion-cover-street.jpg'
      }
    ],
  },

  {
    id: 4,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Rockwell Center',
    slug: 'rockwell-center',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Rockwell Center, Makati City | Hoppler.com.ph',
    metaDescription: "Rockwell Center is a mixed-use development known for providing a community for affluent families, young socialites, and professionals. Discover high-end living at Rockwell Center, Makati City.",
    contentTitle: 'Welcome to Rockwell Center, Makati City',
    contentBody: [
      "A yardstick for upscale urban residential living, Rockwell Center is a mixed-use development notable for providing a convivial community for affluent families, young socialites, and professionals. A self-sustained district that houses residential towers, the high-end Power Plant Mall, and offices, everything one needs is readily within the neighborhood. For people who appreciate the modern lifestyle and deem convenience as a priority, Rockwell Center offers a not too crowded hangout and a sought-after address regarded for its contribution to the well-being of its residents.",
      "The clean streets within the Rockwell Center are a proof that residents and visitors are orderly. Since it caters to the upscale market, rowdy crowds and loiterers are almost never in sight. In the mornings, residents flock to coffee shops or spend an hour or two at the gym to start their day. At night after a long day, one can watch a movie or have a sumptuous dinner at Power Plant Mall. In general, Rockwell is an ideal residential community for locals and expats who have a laid-back yet driven lifestyle."
    ],
    uniqueInfoTitle: 'Perfect for affluent individuals who put value on well-being',
    uniqueInfo: "Life at Rockwell promotes well-being. Secure and slow-paced, residents need not worry about unwanted crowds within the community and people who are unnecessarily on the rush. It is home to a myriad of dining options where families and friends dine together. Events to encourage a sense of community take place occasionally and during the Christmas season, the Power Plant Mall becomes a go-to hangout due to its exceptional decorations that further highlight the yuletide spirit. For residents who work outside Rockwell, it is nearby major roads such as Kalayaan Avenue and EDSA, and a few minutes’ drive from the Makati Central Business District. At Rockwell, convenience and comfort are priorities.",
    attractions: [
      {
        title: 'Three Unforgettable Dining Destinations in Rockwell',
        link: '//www.hoppler.com.ph/blog/featured-articles/3-unforgettable-dining-destinations-in-rockwell',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-3-unforgettable-dining-destinations-in-rockwell.jpg',
        snippet: `Rockwell is known to be the home of Powerplant Mall, providing an upscale shopping experience in a comfortable and leisure setting. However, the neighborhood is also home to many`
      },
      {
        title: 'Power Plant Mall: The Premier Upscale Mall in Rockwell Center',
        link: '//www.hoppler.com.ph/blog/featured-articles/power-plant-mall-the-premier-upscale-mall-in-rockwell-center',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-power-plant-mall-the-premier-upscale-mall-in-rockwell-center.jpg',
        snippet: `Rockwell Center has set the standard for urban residential living in Makati. A city within a city, residents take pride in the assurance that it puts a high value on convenience`
      },
      {
        title: 'Harlan + Holden Dine: An Interior Design Haven',
        link: '//www.hoppler.com.ph/blog/featured-articles/harlan-holden-dine-an-interior-design-haven',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/harlan-holden-dine-an-interior-design-haven.jpg',
        snippet: `The highlight of Power Plant Mall’s newly renovated area is Harlan + Holden Dine. Even prior to its release, the restaurant was already making waves on social media, and it’s no wonder why. Its interiors are`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/rockwell-random-people-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/rockwell-restaurant-cover-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/rockwell-architecture-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/rockwell-architecture-002.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/rockwell-professionals-001.jpg' },
    ],
  },

  {
    id: 10,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Urdaneta',
    slug: 'urdaneta',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Urdaneta, Makati City | Hoppler.com.ph',
    metaDescription: "Urdaneta Village is a small and low-density neighborhood. It places a high premium on the safety and wellbeing of its residents.  Discover the advantages of living in Urdaneta, Makati where privacy and safety are valued.",
    contentTitle: 'Welcome to Urdaneta, Makati CIty',
    contentBody: [
      "One of the smallest neighborhoods in Makati, Urdaneta Village is an exclusive and private enclave that is solely residential for it prohibits the operation of commercial establishments within its confines. Urdaneta and Bel-Air used to be one village called Beldaneta. Now, Urdaneta is a gated community home to affluent families and expatriates. Conveniently located near BGC - Bonifacio Global City and Makati Central Business District, and major roads including Kalayaan Flyover and Buendia Avenue, traveling to and fro key areas is stress-free.",
      "Residents of Urdaneta Village are easygoing but notably driven. Living in a dynamic city opens the door to countless opportunities and different lifestyles; thus, the neighborhood is home to well-to-do families, business executives, and expatriates. Due to its accessibility, residents frequent hangouts in BGC and Makati. When they do not want to go outside the neighborhood but want to relax or exercise, the expansive park is their go-to spot."
    ],
    uniqueInfoTitle: 'Perfect for secure living in the metro',
    uniqueInfo: "Regardless of Urdaneta Village’s close proximity to main thoroughfares and central business districts, its security is never on the line. A small and low-density neighborhood, it places a high premium on the safety and wellbeing of its residents. Without a go signal from its residents, visitors cannot enter its confines. Additionally, its round-the-clock security assures a peaceful community, making residents bask in safety in spite of living in a progressive city.",
    attractions: [
      {
        title: 'Destinations in Urdaneta for Fine Dining',
        link: '//www.hoppler.com.ph/blog/lifestyle/destinations-in-urdaneta-for-fine-dining',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-destinations-in-urdaneta-for-fine-dining.jpg',
        snippet: `The Peninsula Manila, located at the corner of Ayala Avenue and Makati Avenue, is a 5 star hotel that has been around for just a little over 40 years. The Manila Pen, as it is locally`
      },
      {
        title: 'Nikko’s Baking Studio: A Homey Restaurant in Urdaneta Village',
        link: '//www.hoppler.com.ph/blog/featured-articles/nikkos-baking-studio-a-homey-restaurant-in-urdaneta-village',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-nikkos-baking-studio-a-homey-restaurant-in-urdaneta-village.jpg',
        snippet: `Humbly located at 59-A Paseo de Roxas Street in Urdaneta Village, Makati, is Nikko’s Baking Studio. It is a bakeshop, culinary school, and resto-café all rolled into one must-visit place`
      },
      {
        title: 'Where to Find Art in Urdaneta',
        link: '//www.hoppler.com.ph/blog/lifestyle/where-to-find-art-in-urdaneta-makati',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-where-to-find-art-in-urdaneta.jpg',
        snippet: `Makati may be the home for many multinational corporations, a booming business district, even the Philippine Stock Exchange – but it also is a hub for artists and creatives. In the lengthy`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/urdaneta-playground-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/urdaneta-park-002.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/urdaneta-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/urdaneta-basketball-court-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/urdaneta-cover-soccer-field-001.jpg' },
    ],
  },

  {
    id: 11,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Bel-Air',
    slug: 'bel-air',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to Bel-Air, Makati City | Hoppler.com.ph',
    metaDescription: "Bel-Air is an orderly neighborhood that houses prominent establishments and lifestyle hubs, and is near the Makati CBD. Discover a thriving albeit laid-back community in Bel-Air.",
    contentTitle: 'Welcome to Bel-Air, Makati City',
    contentBody: [
      "Initially developed for pilots of Philippine Airlines, Bel-Air has already come a long way. Despite being a private and gated community, it is accessible via major roads in Makati. Awarded for being the cleanest and greenest barangay in Makati for eight consecutive years and was inducted into the Makati Clean and Green Hall of Fame in 2005, its tree-lined streets are an indicator of its commitment to provide a healthy environment for its residents. Moreover, although it houses prominent establishments and lifestyle hubs, and is near the Makati Central Business District, it remains to be an orderly neighborhood.",
      "Its close proximity to the Makati CBD is notable; however, that does not affect the laid-back atmosphere Bel-Air has. Residents often dine and hang out with friends in attractive restaurants and cafés that serve palatable cuisines and drinks, and leisurely walk and exercise in the airy park. It is popular for Pasinaya, a yearly thanksgiving event that fosters community spirit and promotes camaraderie among its residents. The diversity of its residents is noteworthy; it is home to entrepreneurs, corporation executives, and artists. An all-inclusive community, it leaves no room for discrimination no matter what lifestyle one has."
    ],
    uniqueInfoTitle: 'Perfect for visits to trendy restaurants',
    uniqueInfo: "Bel-Air is famous for its hip restaurants and bars. Dining options offering local and international cuisines to satisfy cravings are plentiful and at hand. Residents can simply go to one on foot. Whether one is a foodie or not, Jupiter St. is a must-visit stretch to discover and savor delectable food.",
    attractions: [
      {
        title: 'Three Authentic Korean Restaurants in Bel-Air Makati',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-authentic-korean-restaurants-in-bel-air-makati',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-authentic-korean-restaurants-in-bel-air-makati.jpg',
        snippet: `The popularity of K-Drama and K-Pop has influenced many Filipinos to explore the Korean lifestyle. In fact, Korean food is presently having its moment in the Philippines`
      },
      {
        title: 'Cow Bell Steak Café: Inexpensive Quality Steaks in Makati',
        link: '//www.hoppler.com.ph/blog/featured-articles/cow-bell-steak-cafe-inexpensive-quality-steaks-in-makati',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-cow-bell-steak-cafe-inexpensive-quality-steaks-in-makati.jpg',
        snippet: `Many Filipinos are intimidated of dining in steakhouses as they find steaks a luxury and too expensive. Nevertheless, steak lovers need not have to fret anymore. Cow Bell`
      },
      {
        title: 'Lazy Bastard: Bel-Air’s Place-To-Be for Uncompromised Bacon Goodness',
        link: '//www.hoppler.com.ph/blog/featured-articles/lazy-bastard-bel-airs-place-to-be-for-uncompromised-bacon-goodness',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-lazy-bastard-bel-airs-place-to-be-for-uncompromised-bacon-goodness.jpg',
        snippet: `Regulars in Jupiter St. know exactly where to find it, but first timers just need to keep their eyes peeled for the “bacon & burgers this way” sign and downward-pointing neon`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bel-air-basketball-court-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bel-air-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bel-air-park-people-003.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bel-air-soccer-field-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bel-air-cover-street-001.jpg' },
    ],
  },

  {
    id: 12,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Dasmariñas Village',
    slug: 'dasmarinas-village',
    citySlug: 'makati',
    type: 2,
    metaTitle: 'Know Your Neighborhood - Guide to Dasmariñas, Makati City | Hoppler.com.ph',
    metaDescription: "Known for its exclusivity and tree-lined streets, Dasmariñas Village is home to residents who prefer a relaxed neighborhood. Learn why Dasmariñas Village remains one of the best upscale communities in Makati.",
    contentTitle: 'Welcome to Dasmariñas, Makati City',
    contentBody: [
      "Dasmariñas Village is a private, gated village in Makati City developed in 1965.  A number of prominent politicians, businessmen, celebrities, as well as diplomats are known to reside within this high-end neighborhood. Located within Dasmariñas Village are a number of foreign embassies and a private school.",
      "Dasmariñas Village offers a number of amenities for residents of all ages. When it comes to staying active, residents need not exit the neighborhood. A gym, a basketball and badminton court, and tennis court are available for residents. An enclosed pavilion by Campanilla Park is home to bazaars and other events like birthday parties and receptions."
    ],
    uniqueInfoTitle: 'Perfect for park lovers and neighborhood junkies',
    uniqueInfo: "Residents can take a walk or go out on a night jog through Dasmariñas’ tree-lined streets and around its two parks, Campanilla Park and Mahogany Park.  Residents can go around freely and take their time because of the undemanding vibe within the village. Despite being nearby the central business district, life within Dasmariñas Village is easygoing, making it perfect for those who enjoy being inside the community and interacting with neighbors.",
    attractions: [
      {
        title: 'Three Dessert Places near Dasmariñas Village',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-dessert-places-near-dasmarinas-village',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-dessert-places-near-dasma-village.jpg',
        snippet: `Dasmariñas Village is solely residential. Nevertheless, due to it being a prime residential neighborhood in Makati, it is surrounded by the best things the city can offer`
      },
      {
        title: 'Find Exceptional and Authentic Italian Fare at Va Bene Pasta Deli',
        link: '//www.hoppler.com.ph/blog/featured-articles/find-exceptional-and-authentic-italian-fare-at-va-bene-pasta-deli',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-find-exceptional-and-authentic-italian-fare-at-va-bene-pasta-deli.jpg',
        snippet: `Perhaps one of the most authentic Italian restaurants in the country is a well-known Makati treasure, located on the second floor of the Petron gas station just outside the gate`
      },
      {
        title: 'Discover Heavenly Desserts at Karen’s Kitchen',
        link: '//www.hoppler.com.ph/blog/featured-articles/discover-heavenly-desserts-at-karens-kitchen',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-karens-kitchen.jpg',
        snippet: `Delicious cakes available on-the-go can be found at Karen’s Kitchen. The quaint restaurant is filled with chic interiors and a captivating chandelier, identical to that of`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-cover-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-covert-court-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-park-002.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-playground-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/city-module/dasmarinas-village-street-001.jpg' }
    ],
  },

  {
    id: 13,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'Forbes Park',
    slug: 'forbes-park',
    citySlug: 'makati',
    type: 2,
    metaTitle: 'Know your Neighborhood - Guide to Forbes Park, Makati City | Hoppler.com.ph',
    metaDescription: "Forbes is an elite private village in Makati where most of the richest Filipinos live. It has become synonymous with upscale residential living. Live in the most expensive village in Metro Manila and revel in an elevated lifestyle.",
    contentTitle: 'Welcome to Forbes Park, Makati City',
    contentBody: [
      "Named after a millionaire and one-time governor general of the Philippines, William Cameron Forbes, Forbes is a residential community where most of the richest Filipinos live. It is home to Filipino business moguls and prominent celebrities. Resting between the Makati Central Business District and BGC - Bonifacio Global City, it is accessible via main thoroughfares making traveling stress-free.",
      "Forbes is an elite private village with residents who are naturally laid-back. Trees, some are over half a century old, highlight the tranquility enveloping the entire district. Its streets are so quiet and secure it promises a life of privacy and comfort. The Manila Polo Club and the Manila Golf and Country Club are two of the exclusive go-to hangouts of residents who are into polo and golf and want to unwind. "
    ],
    uniqueInfoTitle: 'Perfect for upscale residential living',
    uniqueInfo: "Over the years, Forbes has become synonymous with upscale residential living. Purely residential in use, Filipinos are not its only residents; it is also popular to expatriates who prefer to reside in a sought-after and high-end yet secure and peaceful neighborhood.",
    attractions: [
      {
        title: 'Three Must-Try Restaurants in San Antonio Plaza',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-must-try-restaurants-in-san-antonio-plaza',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-must-try-restaurants-in-san-antonio-plaza.jpg',
        snippet: `San Antonio Plaza Arcade is a two-story lifestyle and community center in Forbes Park, Makati. It is the main public square in the village and is home to a few cafés, a grocery`
      },
      {
        title: 'Santuario de San Antonio: A 19th-Century Church inside Forbes Park',
        link: '//www.hoppler.com.ph/blog/featured-articles/santuario-de-san-antonio-a-19th-century-church-inside-forbes-park',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-santuario-de-san-antonio-a-19th-century-church-inside-forbes-park.jpg',
        snippet: `Churches play a big role in our lives. It helps us grow in our faith and often serves as a place where many special occasions happen. In the upscale Forbes Park in Makati rests`
      },
      {
        title: 'Exclusive Country Clubs in Forbes Park',
        link: '//www.hoppler.com.ph/blog/featured-articles/exclusive-country-clubs-in-forbes-park',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-exclusive-country-clubs-in-forbes-park.jpg',
        snippet: `It’s no mystery that Forbes Park upholds its exclusivity. The gated community, established back in the 1940s, is home to some of the country’s wealthiest families and`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/forbes-village-basketball-court-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/forbes-village-park-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/forbes-village-park-cover-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/forbes-village-playground-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/forbes-village-street-001.jpg' },
    ],
  },

  {
    id: 14,
    addreCity: 'Makati',
    addreRegion: 'National Capital Region',
    name: 'San Lorenzo',
    slug: 'san-lorenzo',
    citySlug: 'makati',
    metaTitle: 'Know Your Neighborhood - Guide to San Lorenzo, Makati City | Hoppler.com.ph',
    metaDescription: "San Lorenzo Village is one of the largest neighborhoods in Makati offering thousands of hangout places its residents and those who live nearby visit. Explore the best hangout spots and residential communities in San Lorenzo Village.",
    contentTitle: 'Welcome to San Lorenzo, Makati City',
    contentBody: [
      "One of the first gated residential neighborhoods in Makati City, San Lorenzo Village was developed in 1954. A few minutes away from Ayala Center, a major lifestyle district in the city, and is accessible via main thoroughfares, residents revel in the convenience it provides. It is popular as the home of the exclusive all-girls school, Assumption College, and as an upscale community where affluent families reside.",
      "Residents of San Lorenzo Village enjoy well-kept village amenities that include a swimming pool, clubhouse, playground, and function rooms. Moreover, despite the quiet nature of the village, there is never a boring moment within its confines. Residents can be seen strolling around the neighborhood, walking their dogs, or playing with kids in the park.",
      "Restaurants offering different cuisines, prominent malls in Makati, first-rate hotels, churches, schools, and hangout spots for leisure surround San Lorenzo. When the weather is not too hot, residents opt to visit these places on foot."
    ],
    uniqueInfoTitle: 'Perfect for people who after a dynamic lifestyle',
    uniqueInfo: "Life is never a bore at San Lorenzo Village as it is home to many institutions and commercial establishments. A haven for foodies, popular dining options including Bondi and Bourke, Your Local, Wildflour Café + Bakery, and Little Tokyo are within its confines. If one wants to relax and leisurely spend time at the mall, two of the most popular shopping malls in the Philippines, Glorietta and Greenbelt, are within reach. Overall, San Lorenzo Village is one of the largest neighborhoods in Makati offering thousands of hangout places its residents and those who live nearby visit.",
    attractions: [
      {
        title: 'Three Well-Loved Restaurants in Little Tokyo',
        link: '//www.hoppler.com.ph/blog/featured-articles/3-well-loved-restaurants-in-little-tokyo',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-3-well-loved-restaurants-in-little-tokyo.jpg',
        snippet: `It’s a no-brainer for those who live near San Lorenzo Village: if you want to feast on delicious Japanese food at affordable rates, Little Tokyo is the way to go. Located along Chino Roces`
      },
      {
        title: 'Europe-Inspired Croque Café + Bakery is San Lorenzo’s Quiet Hideout',
        link: '//www.hoppler.com.ph/blog/featured-articles/europe-inspired-croque-cafe-bakery-is-san-lorenzos-quiet-hideout',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-europe-inspired-croque-cafe-bakery-is-san-lorenzos-quiet-hideout.jpg',
        snippet: `Right in the middle of Arnaiz Avenue in San Lorenzo Village sits Croque (pronounded krohk) Café + Bakery. It would have been easy to miss if not for the vibrant yellow and blue palette`
      },
      {
        title: 'Hineleban Café: Serving Local Coffee with a Higher Purpose',
        link: '//www.hoppler.com.ph/blog/featured-articles/hineleban-cafe-serving-local-coffee-with-a-higher-purpose',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-hineleban-cafe-serving-local-coffee-with-a-higher-purpose.jpg',
        snippet: `Coffee is perhaps the most popular beverage in the world right now. Many people even say their day only begins after drinking a cup of their favorite espresso or macchiato, and that`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/san-lorenzo-street-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/san-lorenzo-street-architecture-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/san-lorenzo-street-cover-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/san-lorenzo-street-sunset-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/san-lorenzo-street-sunset-002.jpg' },
    ],
  },

  // ---- Taguig ----

  {
    id: 5,
    addreCity: 'Taguig',
    addreRegion: 'National Capital Region',
    name: 'BGC - Bonifacio Global City',
    slug: 'bgc-bonifacio-global-city',
    citySlug: 'taguig',
    metaTitle: 'Know Your Neighborhood - BGC - Bonifacio Global City, Taguig | Hoppler.com.ph',
    metaDescription: "BGC - Bonifacio Global City is a major urban development and financial district in Taguig where residents know how to live life in the fast lane. Explore places to enjoy modern living at BGC.",
    contentTitle: 'Welcome to BGC - Bonifacio Global City, Taguig',
    contentBody: [
      "BGC - Bonifacio Global City, formerly known as Fort McKinley and once a Philippine Army camp, is now a major urban development and financial district. It houses high-rise residential towers, multinational companies, world-class schools, hotels, and hundreds of commercial establishments including high-end restaurants, wellness hubs, and parks that add quality to life. It is the Home of the Passionate Minds where residents know how to live life in the fast lane. They know how to juggle career and personal life cleverly.",
      "No matter what passion one has, BGC can support. Upper-middle class residents and visitors frequent go-to hangouts including High Street, The Fort, Park Triangle, and Burgos Circle. For those who love the arts or just want to geek out, there are museums and libraries within the neighborhood. Famous and Instagram-worthy restaurants and cafés are where families, friends, and workmates meet up to dine and spend time no matter what time of day it is. At night, bars and clubs are open to let guests have fun. Overall, despite BGC being a progressive central business district, its vibe is laid-back and welcoming."
    ],
    uniqueInfoTitle: 'Perfect for passionate individuals who aspire to grow constantly',
    uniqueInfo: "People from different walks of life visit and reside in BGC for the life-changing experiences it offers. Home to top-level companies and lifestyle hubs, one no longer has to go outside its confines just to revel in the benefits of a modern lifestyle and to grow in the many aspects of life.",
    attractions: [
      {
        title: 'Weekend Food Trip: Burgos Eats at BGC - Bonifacio Global City, Taguig',
        link: '//www.hoppler.com.ph/blog/featured-articles/weekend-food-trip-burgos-eats-at-bonifacio-global-city-taguig',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2017/04/hoppler-ph-weekend-food-trip-burgos-eats-at-bonifacio-global-city-taguig.jpg',
        snippet: `There are exciting things happening in Taguig’s neighborhood. The newly built dining concept caters five restaurants within a shared space -pioneered by the one of the largest casual`
      },
      {
        title: 'Casa Italia: An Italian-Inspired Resto-Café in BGC',
        link: '//www.hoppler.com.ph/blog/featured-articles/casa-italia-an-italian-inspired-resto-cafe-in-bgc',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-casa-italia-an-italian-inspired-resto-cafe-in-bgc.jpg',
        snippet: `Casa Italia is a restaurant-café in Burgos Circle in BGC, Taguig. It originated from Singapore where it is popular as the largest Italian café chain. It serves handcrafted gelato, specialty`
      },
      {
        title: '% Arabica: Changing the Coffee Shop Landscape in Manila',
        link: '//www.hoppler.com.ph/blog/featured-articles/arabica-changing-the-coffee-shop-landscape-in-manila',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-arabica-changing-the-coffee-shop-landscape-in-manila.jpg',
        snippet: `Born out of a man’s belief that an amazing cup of coffee is necessary at the start of each day, Kenneth Shoji created % Arabica, whose origins trace back to Kyoto, Japan. % Arabica`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bgc-architecture-cover-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bgc-art-center-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bgc-burgos-restaurant-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bgc-burgos-the-tree-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/bgc-street-signs-001.jpg' },
    ],
  },

  {
    id: 6,
    addreCity: 'Taguig',
    addreRegion: 'National Capital Region',
    name: 'McKinley Hill',
    slug: 'mckinley-hill',
    citySlug: 'taguig',
    metaTitle: 'Know Your Neighborhood - McKinley Hill, Taguig City | Hoppler.com.ph',
    metaDescription: "McKinley Hill prides itself in offering a perfect work-life balance for its residents with its exclusive activities and venues. Find places to stay and to enjoy as you discover extravagance and exclusivity in Taguig City.",
    contentTitle: 'Welcome to McKinley Hill, Taguig City',
    contentBody: [
      "At first glance, it is impossible to miss the sophisticated architecture and lush greens that make McKinley so inviting. This lush environment, with its 24-hour security, is home to a fast-moving business district of more than 90 multi-national companies. Since McKinley is accessible through all major roads, travel to and from this cosmopolitan destination is made easy.",
      "Exclusive activities and venues are what separate McKinley from other neighborhoods. McKinley prides itself in offering a perfect work-life-balance for its residents that visitors can also get a glimpse of. Practice with friends at the 18-hole golf course of the Manila Golf Club surrounded by a beautiful environment. Enthusiasts of the sport can watch football matches at the McKinley Hill stadium. Go for a romantic gondola ride at the Venice Grand Canal Mall or experience its world-class cinema."
    ],
    uniqueInfoTitle: 'Perfect for those looking a holistic living experience',
    uniqueInfo: "Opportunities flourish for its residents with easy access to top-of-the-line office facilities, modern shopping centers, quality schools and colleges, and an array of local and international food hubs. On slow days, residents can enjoy a stroll around the neighborhood and take in the beauty and class in every corner. This marriage of work and leisure is what residents embrace about McKinley.",
    attractions: [
      {
        title: 'Venice Grand Canal Mall: A Glimpse of Italy in Manila',
        link: '//www.hoppler.com.ph/blog/featured-articles/venice-grand-canal-mall-a-glimpse-of-italy-in-manila',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/03/hoppler-venice-grand-canal-mall-a-glimpse-of-italy-in-manila.jpg',
        snippet: `Despite the upscale appearance and atmosphere that envelope the establishment, Venice Grand Canal Mall is open to everyone. Since its opening in 2015, it has become one of the go-to`
      },
      {
        title: 'Three Places to Drop by During Your Venice Grand Canal Mall Dessert Hop',
        link: '//www.hoppler.com.ph/blog/featured-articles/3-places-to-drop-by-during-your-venice-grand-canal-mall-dessert-hop',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-3-places-to-drop-by-during-your-venice-grand-canal-mall-dessert-hop.jpg',
        snippet: `Everyone knows there’s always room for dessert. Nothing excites more than a great dessert waiting at the end of the meal, be it a scoop of ice cream, a slice of cake, or a`
      },
      {
        title: 'Three European-Inspired Restaurants in McKinley',
        link: '//www.hoppler.com.ph/blog/featured-articles/three-european-inspired-restaurants-in-mckinley',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-three-european-inspired-restaurants-in-mckinley.jpg',
        snippet: `McKinley Hill is a 50-hectare development in Taguig City. A globally-inspired township, it has structures reminiscent of European landmarks. For people who love European cuisines`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/mckinley-architecture-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/mckinley-grand-canal-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/mckinley-grand-canal-cover-001.jpg' },
    ],
  },

// ---- Muntinlupa ----

  {
    id: 7,
    addreCity: 'Muntinlupa',
    addreRegion: 'National Capital Region',
    name: 'Alabang',
    slug: 'alabang',
    citySlug: 'muntinlupa',
    type: 2,
    metaTitle: 'Know Your Neighborhood - Guide to Alabang, Muntinlupa City | Hoppler.com.ph',
    metaDescription: "With its close knit communities and its buzzing cosmopolitan lifestyle, Alabang is the go-to destination for families living down south. Explore places to stay and enjoy laid-back living in Muntinlupa City.",
    contentTitle: 'Welcome to Alabang, Muntinlupa City',
    contentBody: [
      "Alabang leads the way as the go-to hangout place every weekend for many families living down south. A number of companies have taken residence at its vast commercial spaces, but do not disrupt the relaxed community vibe that Alabang has long been known for. Life down Alabang would never get monotonous with its many parks to visit for morning walks, indoor and outdoor malls to shop, and a long list of bazaars to look forward to throughout the year.",
      "Because of its close-knit community, Alabang residents occasionally host house parties. For a change of scene, while still remaining within the neighborhood, Alabang Country Club is available for those who want to swim, relax, and de-stress. Cuenca Park is also available for those who want to bike around or play soccer in the field. During holidays or long weekends, staycation with family at any of the upscale, yet unbelievably affordable hotels within the area!"
    ],
    uniqueInfoTitle: 'Perfect for families that enjoy an urban modern lifestyle',
    uniqueInfo: "Indoor and outdoor malls are the highlight of the neighborhood, especially on weekends. The malls practically offer everything one would need, like shopping and restaurants. But it doesn’t end there. Indoor amusement parks, biking trails, art galleries, and elegant cinemas punctuate these malls, leaving boredom never an option.",
    attractions: [
      {
        title: 'The South District: Alabang’s Newest Food Hall',
        link: '//www.hoppler.com.ph/blog/featured-articles/the-south-district-alabangs-newest-food-hall',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-the-south-district-alabangs-newest-food-hall.jpg',
        snippet: `Located inside Alabang Hills Village in Muntinlupa City, Madison Galleries is a miniature mall with an inspired, community-focused concept.
        It’s a complete mall, consisting of five floors of restaurants, concept stores`
      },
      {
        title: 'Explore Molito: A Thriving Lifestyle Haven in Alabang',
        link: '//www.hoppler.com.ph/blog/featured-articles/explore-molito-a-thriving-lifestyle-haven-in-alabang',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-explore-molito-a-thriving-lifestyle-haven-in-alabang-1.jpg',
        snippet: `Built in 2012, Molito has established itself as the go-to destination in Alabang for those who prefer hanging outdoors. Along Madrigal Avenue, across Alabang Town Center, is Molito`
      },
      {
        title: 'Serving the Community is Priority at Exchange Alley Coffee',
        link: '//www.hoppler.com.ph/blog/featured-articles/serving-the-community-is-priority-at-exchange-alley-coffee-house',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-serving-the-community-is-priority-at-exchange-alley-coffee-house.jpg',
        snippet: `Located in Molito, Exchange Alley Coffee House, or EACH as it is commonly known within the neighborhood, is an offshoot from BF Homes’ well-known specialty coffee shop`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/alabang-town-center-cover-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/alabang-creative-shot-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/alabang-creative-shot-002.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/alabang-molito-restaurant-001.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/alabang-mother-and-daughter-001.jpg' },
    ],
  },

  {
    id: 8,
    addreCity: 'Muntinlupa',
    addreRegion: 'National Capital Region',
    name: 'Filinvest',
    slug: 'filinvest',
    citySlug: 'muntinlupa',
    metaTitle: 'Know Your Neighborhood - Guide to Filinvest, Muntinlupa City | Hoppler.com.ph',
    metaDescription: "Filinvest City is guided by its green initiative to create a balanced work-play environment for professionals in the south. Discover Muntinlupa City's thriving mixed use development and its active community.",
    contentTitle: 'Welcome to Filinvest, Muntinlupa City',
    contentBody: [
      "Two things shape Filinvest City: its premier central business district and its booming residential area. Filinvest City is known for its lush greens expanding throughout its vast area. It effortlessly accomplishes its goal of being a comprehensive residential-commercial development while still maintaining a calm, leisure-centric environment.",
      "A number of sophisticated hotels and condominiums are available for those who desire to remain within close proximity of Filinvest City’s thriving cosmopolitan lifestyle. A number of malls are accessible, including the famous Filinvest Alabang (formerly known as Festival Supermall) which features a mini theme park for kids. For outdoor thrill seekers, The Filinvest Trails along Commerce Avenue is the perfect place to train test their wits at mountain biking. The Festino Market sets up shop every Friday – Sunday offering a wide selection of organic ingredients and food, as well as recreational activities for all ages."
    ],
    uniqueInfoTitle: 'Perfect for residents who prioritize privacy and nature',
    uniqueInfo: "Filinvest City prioritizes transforming its streets into creating a greener and cleaner neighborhood. This is reinforced by the presence of the foliage-canopied walkway that can be scene all-throughout the pedestrian-friendly business district. Filinvest City encourages biking and walking within the neighborhood to promote a healthier lifestyle among its residents. Despite the presence of multiple international companies, Filinvest City conserves its environment as a private residential area perfect for those who prioritize being away from the rest of the city to enjoy their day-to-day, relaxed and calm.",
    attractions: [
      {
        title: 'Explore Westgate: Filinvest City’s Unparalleled Lifestyle',
        link: '//www.hoppler.com.ph/blog/featured-articles/explore-westgate-filinvest-citys-unparalleled-lifestyle-destination',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-explore-westgate-filinvest-citys-unparalleled-lifestyle-destination.jpg',
        snippet: `Westgate Center is a 9.2 hectare open-air lifestyle center in Muntinlupa, a favorite destination among families and friends in Filinvest City. Anyone who steps into`
      },
      {
        title: 'Three Must Try Restaurants at Commercenter',
        link: '//www.hoppler.com.ph/blog/featured-articles/3-must-try-restaurants-at-commerce-center',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-3-must-try-restaurants-at-commerce-center.jpg',
        snippet: `Commercenter in Filinvest City was intended to provide residents, visitors, and working professionals a go-to mall for unwinding after a long day, and it successfully serves its purpose as it continues to draw people in`
      },
      {
        title: 'Get Active in Filinvest City',
        link: '//www.hoppler.com.ph/blog/lifestyle/get-active-in-filinvest-city',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2018/04/hoppler-get-active-in-filinvest-city.jpg',
        snippet: `Acting on its initiative of being a neighborhood that encourages an active lifestyle amongst its residents, Filinvest City is filled with outdoor activities that are a stone’s throw away from its business districts and residential areas`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/filinvest-architecture-001.jpg'},
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/filinvest-festival-mall-cover-001.jpg'},
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/filinvest-street-001.jpg'},
    ],
  },

  {
    id: 8,
    addreCity: 'Pasig',
    addreRegion: 'National Capital Region',
    name: 'Kapitolyo',
    slug: 'kapitolyo',
    citySlug: 'pasig',
    metaTitle: 'Know Your Neighborhood - Kapitolyo, Pasig | Hoppler.com.ph',
    metaDescription: "Kapitolyo is a dynamic neighborhood with a strategic location and laid-back lifestyle. Find your home in Kapitolyo and experience its vibrant lifestyle. ",
    contentTitle: 'Welcome to Kapitolyo, Pasig',
    contentBody: [
      "Any serious foodie knows that Kapitolyo is a must-visit destination for some of the best dining concepts and experiences. It’s become so popular that many people, even from far cities, travel to Kapitolyo to try out the restaurants.  In this neighborhood, one can find restaurants, cafés, and speakeasies. They range from all time best-sellers and newcomers, vying for the attention of those who visit each day.",
      "Kapitolyo has maintained its quiet and relaxed residential roots despite its sudden evolution into a more commercialized neighborhood. Many of the homes in the area have been around for decades, and those who live there are mostly long-time residents of the area. But it continues to garner interest for its strategic location and laid-back lifestyle."
    ],
    uniqueInfoTitle: 'Perfect for young professionals and families looking for a dynamic neighborhood to call home',
    uniqueInfo: "Kapitolyo manages to avoid any impression that it’s too high-end. Instead, its modern simplicity is both welcoming and captivating. This has piqued the interest of many young professionals looking for a place to call home. The lifestyle is dynamic: the mornings are slow and quiet while its evenings are exciting and lively, but not once does it feel too hurried and frantic.",
    attractions: [
      {
        title: '3 Cozy Must-Try Cafés in Kapitolyo',
        link: '//www.hoppler.com.ph/blog/lifestyle/3-cozy-must-try-cafes-in-kapitolyo',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-3-cozy-must-try-cafes-in-kapitolyo.jpg',
        snippet: `These days, cafés have upped their services not only to serve delicious coffee but also to provide a cozy and comfortable setting for students, creatives, and freelancers to work`
      },
      {
        title: 'Your Guide to Kapitolyo’s Lifestyle Spots',
        link: '//www.hoppler.com.ph/blog/lifestyle/your-guide-to-kapitolyos-lifestyle-spots',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-your-guide-to-kapitolyos-lifestyle-spots.jpg',
        snippet: `Kapitolyo is a relatively small neighborhood in Pasig City, but it’s packed with a colorful lifestyle. The number of places to go and things to do draw people in from other cities to`
      },
      {
        title: 'Restaurants in Kapitolyo You’ll Keep Coming Back To',
        link: '//www.hoppler.com.ph/blog/lifestyle/restaurants-in-kapitolyo-youll-keep-coming-back-to',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-restaurants-in-kapitolyo-youll-keep-coming-back-to.jpg',
        snippet: `It’s a known fact that Kapitolyo is a food haven in the metro, and it has tons of unique and exciting restaurants that are packed each night. Some of these restaurants have been in the`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/kapitolyo-cover.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/kapitolyo-1.jpg' },
      // Removed -- image shows heavy traffic
      // { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/kapitolyo-2.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/kapitolyo-3.jpg' },
    ],
  },
  {
    id: 9,
    addreCity: 'Pasig',
    addreRegion: 'National Capital Region',
    name: 'Ortigas Center',
    slug: 'ortigas-center',
    citySlug: 'pasig',
    metaTitle: 'Know Your Neighborhood - Ortigas Center, Pasig | Hoppler.com.ph',
    metaDescription: "Live conveniently and comfortably at the heart of the business district. Explore places to live in Ortigas Center.",
    contentTitle: 'Welcome to Ortigas Center, Pasig',
    contentBody: [
      "It’s no secret that Ortigas Center has blossomed into a prosperous business district in Metro Manila over the last decade. The massive urban development shares boundaries with the cities of Pasig, Mandaluyong, and Quezon City. Though known as an industrial and business center ever since, it has now made a name for itself as a hip and modern neighborhood to settle in.",
      "There are many residential developments in Ortigas Center situated close-by to many offices. Thus, many professionals who work in the area consider investing in a property to avoid the long hours of a daily commute. Aside from being able to walk to and from their offices, they can also live near shopping centers, restaurants, parks, schools, and hospitals."
    ],
    uniqueInfoTitle: 'Perfect for individuals who enjoy exploring what a neighborhood has to offer',
    uniqueInfo: "Because the neighborhood has much to offer, there’s much to explore as well. Hidden between the center of activities in the business center is Ortigas Park, a pocket garden open to the public. Those looking for an after-work hangout can walk towards the many restaurants and bars in the area that stay open until the late-night. Meanwhile, fans of the arts can visit the Meralco Theater and catch a production or concert in the 1,000-seater auditorium.",
    attractions: [
      {
        title: '3 New Coworking Spaces in Ortigas',
        link: '//www.hoppler.com.ph/blog/lifestyle/3-new-coworking-spaces-in-ortigas',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-3-new-coworking-spaces-in-ortigas.jpg',
        snippet: `The rapid emergence of coworking spaces in many cities around Metro Manila is an incredible phenomenon, but it is also an expected result of the rising popularity of collaboration`
      },
      {
        title: '3 Famous Lifestyle Spaces Near Valle Verde',
        link: '//www.hoppler.com.ph/blog/lifestyle/3-famous-lifestyle-spaces-near-valle-verde',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-3-famous-lifestyle-spaces-near-valle-verde.jpg',
        snippet: `When you’re not rushing to a meeting or an appointment in Pasig City, you’ll learn that there’s actually much to discover and experience in this neighborhood. In fact, there’s a wide`
      },
      {
        title: 'The Best Places for Lunch in Ortigas Center',
        link: '//www.hoppler.com.ph/blog/lifestyle/the-best-places-for-lunch-in-ortigas-center',
        imageUrl: 'https://hoppler-blog.imgix.net/blog/wp-content/uploads/2019/03/hoppler-the-best-places-for-lunch-in-ortigas-center1.jpg',
        snippet: `Each day, Ortigas Center is filled with hundreds of people heading back and forth their respective workplaces in the buzzing neighborhood. Thankfully, there are many restaurants`
      },
    ],
    multimedias: [
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/village-slide-4-edsa.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/village-slide-1-ortigas-place.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/village-slide-2-uap.jpg' },
      { filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/village-slide-3-adb.jpg' },
    ],
  },

  {
    id: 1,
    addreCity: 'Santa Rosa',
    addreRegion: 'Calabarzon',
    name: 'Nuvali',
    slug: 'nuvali',
    citySlug: 'santa-rosa',
    listingQuery: '/house-and-lots-for-sale/laguna/santa-rosa-nuvali',
    metaTitle:
      'Know Your Neighborhood - Nuvali, Santa Rosa, Laguna | Hoppler.com.ph',
    metaDescription:
      'Nuvali is a fast-growing residential, commercial, and recreational development located in Santa Rosa, Laguna. Find homes in the south of Metro Manila and experience the quiet and serenity of the area.',
    contentTitle: 'Welcome to Nuvali, Santa Rosa City',
    contentBody: [
      'Before it became the fast-growing residential, commercial, and recreational development that it is today, Nuvali’s history traces all the way back to the 1800s. Nuvali, which spans 1,840 hectares, was acquired by a group of enterprising Americans who developed the land into a sugar plantation. It was in the 1970s that lawyer Jose Yulo acquired the property, with an initiative of establishing industrial, residential, and recreational developments.',
      'Today, lush greens, wide roads, and a peaceful community are some of the first things that come to mind at the mention of Nuvali. Located in Santa Rosa, Laguna,  Nuvali has emerged into a frequented stopover for travellers heading further south to areas like Tagaytay and Batangas. It has also become a new favorite weekend destination because of its accessibility.',
      'Nuvali has something for everyone. Foodies will be able to find homegrown restaurants, adrenaline-junkies can enjoy a number of outdoor activities, and leisure travellers can experience the quiet and serenity of the area.'
    ],
    uniqueInfoTitle: 'Perfect for city lovers looking for a quieter alternative',
    uniqueInfo:
      'There’s a distinct difference in the atmosphere of Nuvali from that of highly urbanized cities in Metro Manila. There’s a relaxed vibe that permeates throughout the neighborhood, absent of rush and overcrowded roads. It’s why Nuvali has become a prime destination for people, young and old, who want to be within an easy driving distance from Metro Manila - Nuvali is accessible through the South Luzon Expressway - yet are looking to stay far away from its congestion. City lovers looking for a change of scene can find everything they need in Nuvali, especially with the emergence of premiere shopping malls nearby the residential communities and recreational developments with outdoor activities that can also be enjoyed.',
    attractions: [
      {
        title: "Nonna's Pasta and Pizza Offer Contemporary Interpretations of Italian Flavors",
        link: 'https://www.hoppler.com.ph/blog/featured-articles/nonnas-pasta-and-pizza-offer-contemporary-interpretations-of-italian-flavors',
        imageUrl: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/hoppler-nonnas-pasta-and-pizza-offers-contemporary-interpretations-of-italian-flavors.jpg',
        snippet: `Known as the daughter of famous restaurant Mama Lou’s (whose origins are rooted in BF Homes, Parañaque), Nonna’s Pasta and Pizza is owners David and Crystal Sison’s more`
      },
      {
        title: 'Outdoor Activities to Do in Nuvali',
        link: 'https://www.hoppler.com.ph/blog/featured-articles/outdoor-activities-to-do-in-nuvali',
        imageUrl: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/hoppler-outdoor-activities-to-do-in-nuvali.jpg',
        snippet: `Perhaps the best spot to unwind in Laguna, Nuvali offers more than a premiere shopping experience. Whether to immerse one’s self in nature or to embark on thrilling`
      },
      {
        title: '3 Places to Get Your Dessert Fix in Nuvali',
        link: 'https://www.hoppler.com.ph/blog/lifestyle/3-places-to-get-your-dessert-fix-in-nuvali',
        imageUrl: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/hoppler-3-places-to-get-your-dessert-fix-in-nuvali.jpg',
        snippet: `Solenad in Nuvali has become the go-to destination for food lovers in the neighborhood. With gorgeous interiors and refreshing al fresco dining available for most restaurants in`
      }
    ],
    multimedias: [
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali01.jpg'
      },
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali02.jpg'
      },
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali03.jpg'
      },
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali04.jpg'
      },
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali05.jpg'
      },
      {
        filename: 'https://hoppler-inc.imgix.net/hoppler/pages/static/village-module/nuvali/nuvali06.jpg'
      }
    ]
  }

];
