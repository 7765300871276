import { DivCat } from 'div-cat.model';

export class PropertyUtils {
  shouldShowPricePerSqm(divCat: DivCat) {
    let retVal = true;

    if (divCat.division == 'Residential' || divCat.category == 'Sale') {
      retVal = false;
    }

    if (divCat.division == 'Commercial' && divCat.category == 'Rent') {
      retVal = false;
    }
    
    return retVal;
  }

  shouldShowRentalPrice(divCat: DivCat) {
    let retVal = true;

    if (divCat.category == 'Sale') {
      retVal = false;
    }

    return retVal;
  }

  shouldShowSalePrice(divCat: DivCat) {
    let retVal = true;

    if (divCat.category == 'Rent') {
      return false;
    }

    return retVal;
  }

  isExtraPricePerSqmInfoRequired(divCat: DivCat): boolean {
    const isCommercial = divCat.division == 'Commercial';
    const isLease = divCat.category == 'Rent';
    const isResort = divCat.type == 'Resort';

    return isCommercial && !isResort;
  }

  isValidPropertyKey(key: string) {
    key = key.toUpperCase();

    // Assume that the number of digits in a property key can increase
    return !!key.match(/^[RC][RS]\d+$/);
  }
}
