import { h, Component } from "preact";
import cx from 'classnames';
import linkState from 'linkstate';
import getStore from "../../store";

import { Observable, of, Subject, Subscription } from "rxjs";
import { map, switchMap } from 'rxjs/operators';
import { appState$ } from '../../store';
import { AppState } from '../../reducers/app-state';

import URLRouter from '../../../../core/url-router';

import * as UserActions from '../../reducers/user/user.actions';
import { UserAction, UserState } from '../../reducers/user/user.actions';
import * as RequestStates from '../../../../types/request-state.model';

import { Form, Field, FieldList } from '../../../utils/forms/form';
import hopplerLinkState from "../../../utils/forms/hoppler-linkstate";
import { emailRegex, validateForm, cxValidateField, Validator } from "../../../utils/forms/validators";
import { SignupAction, CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL } from "../../reducers/signup/signup.actions";

export interface LoginFormProps {
  onClose?: Function
}

interface LoginFormState {
  rememberMe?: boolean;
  passwordInputType?: string;
  passwordToggleString?: string;
  loginForm?: Form;

  requestInProgress: boolean;

  isForgotPasswordVisible?: boolean;
  forgotPasswordEmail?: string;
  isForgotEmailValid?: boolean;
  forgotPasswordState?: RequestStates.RequestState;
  
  requiresUserVerification?: boolean;
  isResendSuccesful?: boolean;
  isResendInProgress?: boolean;
}

'hi-login-form';
export class LoginForm extends Component<LoginFormProps, LoginFormState> {
  form$: Subject<Form>;
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);

    this.setState({
      passwordInputType: 'password',
      passwordToggleString: 'Show password'
    });

    let fields: FieldList = {
      'email': new Field(['required', 'email'], null, ''),
      'password': new Field(['required'], null, '')
    };

    this.form$ = new Subject<Form>();
    this.setState({
      loginForm: new Form(fields)
    });

    this.form$.subscribe((form) => {
      validateForm(form);

      let state = {};
      state['loginForm'] = form;
      this.setState(state);
    });

  }

  componentDidMount() {
    const user$ = appState$.pipe(
      map(appState => appState.user)
    );

    const signup$ = appState$.pipe(
      map(appState => appState.signup)
    );

    this.subscriptions.push(
      user$.subscribe((user) => {

        if (user.loginState == RequestStates.REQUEST_IN_PROGRESS) {
          this.setState({
            requestInProgress: true
          });
        } else {
          this.setState({
            requestInProgress: false
          });

          if (user.loginState == RequestStates.REQUEST_SUCCESS) {
            // for any chained actions like modal calls
            if (this.props.onClose) {
              this.props.onClose();
            } else {
              URLRouter.navigateToURL('/');
            }
          }
        }

        if (user.forgotPasswordState) {
          this.setState({
            forgotPasswordState: user.forgotPasswordState
          });
        }

      })
    );

    this.subscriptions.push(
      user$.pipe(
        map(user => user.requiresUserVerification)
      ).subscribe((requiresUserValidation) => {

        if (requiresUserValidation) {
          this.setState({
            requiresUserVerification: true
          });
        } else {
          this.setState({
            requiresUserVerification: false
          });
        }

      })
    );

    this.subscriptions.push(
      signup$.pipe(
        map(signup => signup.resendCustomerEmailStatus)
      ).subscribe(resendStatus => {

        if (resendStatus == RequestStates.REQUEST_IN_PROGRESS) {
          this.setState({
            isResendInProgress: true
          });
        } else {

          if (resendStatus == RequestStates.REQUEST_SUCCESS) {
            this.setState({
              isResendInProgress: false,
              isResendSuccesful: true
            });
          } else {
            this.setState({
              isResendInProgress: false,
              isResendSuccesful: false
            });
          }

        }

      })
    );
  }

  componentWillUnmount() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  onToggleShowPassword = () => {
    if (this.state.passwordInputType == 'password') {
      this.setState({
        passwordInputType: 'text',
        passwordToggleString: 'Hide password'
      });
    } else {
      this.setState({
        passwordInputType: 'password',
        passwordToggleString: 'Show password'
      });
    }
  }

  onLoginClicked = (e) => {
    e.preventDefault();

    validateForm(this.state.loginForm);

    if (!this.state.loginForm.isValid) {
      return;
    }

    const store = getStore();
    const payload: UserState = {
      email: this.state.loginForm.fields['email'].value,
      password: this.state.loginForm.fields['password'].value,
      rememberMe: this.state.rememberMe
    };

    store.dispatch({
      type: UserActions.LOGIN,
      payload: payload
    } as UserAction);

    // for any chained actions like modal calls
    // if (this.props.onClose) {
    //   this.props.onClose();
    // }
  }

  onForgotPasswordClicked = (e) => {
    e.preventDefault();

    const store = getStore();

    store.dispatch({
      type: UserActions.FORGOT_PASSWORD,
      payload: {
        email: this.state.forgotPasswordEmail
      }
    } as UserAction);
  }

  resendEmail = () => {
    const form = this.state.loginForm;

    const store = getStore();
    const action: SignupAction = {
      type: CUSTOMER_SIGNUP_RESEND_ACTIVATION_EMAIL,
      payload: {
        usernameForResend: form.fields['email'].value
      }
    };

    store.dispatch(action);
  }

  getForgotPasswordBlock = (state: LoginFormState) => {
    let spinner;
    let buttonMsg = 'Send me the reset link';
    let buttonDisabled = state.forgotPasswordState == RequestStates.REQUEST_IN_PROGRESS ||
      state.forgotPasswordState == RequestStates.REQUEST_SUCCESS;

    if (state.forgotPasswordState == RequestStates.REQUEST_IN_PROGRESS) {
      spinner = (
        <div class="spinner-border text-primary mt-3" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      );
    } else if (state.forgotPasswordState == RequestStates.REQUEST_SUCCESS) {
      spinner = null;
      buttonMsg = 'Email Sent!'
    } else {
      spinner = null;
    }

    if (state.isForgotPasswordVisible) {
      return (
        <form class="hi-forgot-password mt-3">
          <p>
            If you've forgotten your password, enter your email below, and we'll send you a link to help you reset your password.
          </p>
          
          <input type="email"
            class="hi-text-input form-control"
            onKeyUp={ this.validateForgotEmail }
            value={ state.forgotPasswordEmail }
            placeholder="Email address"/>

          <button class="btn btn-outline-dark w-100 mt-3"
            type="submit"
            disabled={ !state.isForgotEmailValid || buttonDisabled }
            onClick={this.onForgotPasswordClicked}>
            { buttonMsg }
          </button>

          { spinner }
        </form>
      );
    }
  };
  
  validateForgotEmail = (e) => {
    const email = e.target.value;
    const isValid = emailRegex.test(email);
    this.setState({
      isForgotEmailValid: isValid,
      forgotPasswordEmail: email
    })
  }

  toggleForgotPassword = e => {
    this.setState({
      isForgotPasswordVisible: !this.state.isForgotPasswordVisible
    });
  }

  getFormBlock(state:LoginFormState) {
    if (state.requiresUserVerification) {
      return;
    }

    let loginButton;

    if (state.requestInProgress) {
      loginButton = (
        <button class="btn btn-secondary hi-btn-loading w-100"
          disabled={true}
          type="submit"
          onClick={this.onLoginClicked}>
          Logging you in
        </button>
      );
    } else {
      loginButton = (
        <button class="btn btn-secondary w-100"
          type="submit"
          onClick={this.onLoginClicked}>
          Log-in
        </button>
      );
    }

    return (
      <form class="my-4">
        <div class="mt-3">
          <input type="email"
            required={true}
            value={ state.loginForm.fields['email'].value }
            className={cx('hi-text-input form-control', {
              'invalid' : cxValidateField(state.loginForm.fields['email'])
            })}
            onChange={hopplerLinkState(this.form$, state.loginForm, 'email')}
            placeholder="Email address"/>
        </div>

        <div class="mt-3 pt-1">
          <input type={state.passwordInputType}
            value={ state.loginForm.fields['password'].value }
            className={cx('hi-text-input form-control', {
              'invalid' : cxValidateField(state.loginForm.fields['password'])
            })}
            required={true}
            onChange={hopplerLinkState(this.form$, state.loginForm, 'password')}
            placeholder="Password"/>
        </div>

        <div class="mt-3 mb-3 hi-login-form__remember-me-container">
          <span>
            <input class="hi-checkbox" type="checkbox" name="rememberMe" 
              onChange={linkState(this, 'rememberMe')}/>
            <span>Remember me</span>
          </span>

          <span>
            <a href="#" class="hi-login-form__show-password"
              onClick={this.onToggleShowPassword}>
              {state.passwordToggleString}
            </a>
          </span>
        </div>

        {/* <button class="btn btn-secondary w-100"
          type="submit"
          onClick={this.onLoginClicked}>
          Log-in
        </button> */}

        {loginButton}

        <div class="my-3">
          <a href="#" onClick={ this.toggleForgotPassword }
            class="hi-login-form__forgot-password">
            Forgot password?
          </a>

          { this.getForgotPasswordBlock(state) }
        </div>

        <hr/>

        <div class="my-3">
          <span class="hi-login-form__sign-up">
            Don't have an account yet? <a href="/signup-type">Sign-up</a>
          </span>
        </div>

      </form>
    );
  }

  getSuccessMessage(state: LoginFormState): string {
    if (state.isResendInProgress) {
      return 'Resending email';
    } else if (state.isResendSuccesful) {
      return 'Email sent!';
    } else {
      return 'Resend verification email';
    }
  }

  getResendVerificationEmailBlock(state: LoginFormState) {
    if (!state.requiresUserVerification) {
      return;
    }

    return (
      <div>
        <p class="">
          Your account is not yet verified. Please verify it using the verification email sent during sign up.
        </p>
        <p class="mb-1">
          If you still have not received an email, you may click the button below to resend
          the email.
        </p>
        <button type="button" class="btn btn-secondary w-100 mt-3"
            disabled={ state.isResendInProgress || state.isResendSuccesful }
            onClick={ this.resendEmail }>
          { state.isResendInProgress ? <i class="fa fa-cog fa-spin mr-1"></i> : null }
          { this.getSuccessMessage(state) }
        </button>
      </div>
    );
  }

  render(props, state: LoginFormState) {

    return(
      <div class="hi-login-form">
        <h2 class="my-3 hi-login-form__heading">
          Welcome back to Hoppler
        </h2>

        { this.getFormBlock(state) }
        { this.getResendVerificationEmailBlock(state) }

        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}

      </div>
    );
  }
}