import { Component, h } from "preact";
import { InfoWindowPropertyCard } from "./info-window-property-card.component";
import { Listing } from "../../../../types";
import getStore, { appState$ } from "../../store";
import { map } from "rxjs/operators";

interface InfoWindowContentProps {
}

interface InfoWindowContentState {
  listings?: Listing[];
  currentActiveListingIndex?: number;
}

'hi-info-window-content';
export class InfoWindowContent extends Component<InfoWindowContentProps, InfoWindowContentState> {
  constructor(props: InfoWindowContentProps) {
    super(props);

    const appStateSnapshot = getStore().getState();
    const listings = appStateSnapshot.results.activeInfoWindowProperties;

    this.setState({
      listings: listings,
      currentActiveListingIndex: 0
    });
  }

  componentDidMount() {
    // const results$ = appState$.pipe(
    //   map(appState => appState.results)
    // );

    // results$.
  }

  onPreviousProperty = (e) => {
    let previous = this.state.currentActiveListingIndex - 1;

    if (previous < 0) {
      previous = this.state.listings.length - 1;
    }

    this.setState({
      currentActiveListingIndex: previous
    });

  }

  onNextProperty = (e) => {
    let next = this.state.currentActiveListingIndex + 1;

    if (next > this.state.listings.length - 1) {
      next = 0
    }

    this.setState({
      currentActiveListingIndex: next
    });

  }

  getControlsBlock(props: InfoWindowContentProps, state: InfoWindowContentState) {

    if (state.listings.length > 1) {
      return (
        <div class="hi-properties-map__info-window__controls">
          <button class="btn hi-properties-map__info-window__controls__btn"
            disabled={ state.currentActiveListingIndex == 0 }
            onClick={this.onPreviousProperty}>
            Previous Property
          </button>

          <button class="btn hi-properties-map__info-window__controls__btn"
            disabled={ state.currentActiveListingIndex == state.listings.length - 1 }
            onClick={ this.onNextProperty }>
            Next Property 
          </button>
        </div>
      );
    }
  }

  render(props: InfoWindowContentProps, state: InfoWindowContentState) {
    return (
      <div class="hi-properties-map__info-window">
        <InfoWindowPropertyCard property={state.listings[state.currentActiveListingIndex]}>

        </InfoWindowPropertyCard>

        { this.getControlsBlock(props, state) }

      </div>
    );
  }

}